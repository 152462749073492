import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import { PriorityHigh } from '@mui/icons-material';
import IconUnderlay from '../../components/IconUnderlay';
import { forgotPassword } from '../../services/Authentication';
import { Transitions } from '../../services/Transition';
import CardFooter from '../../components/CardFooter';
import ACSnackbar from '../../components/ACSnackbar';

const useStyles = () => ({
  sentEmail: {
    marginTop: '10px',
    maxWidth: '450px',
    textAlign: 'center',
    color: 'secondary.dark',
  },
  textClickEmail: {
    marginTop: '30px',
    marginBottom: '30px',
    maxWidth: '400px',
    textAlign: 'center',
    color: 'secondary.dark',
  },
  icon: {
    fontSize: '70px',
    color: 'error.main',
  },
});

function ErrorReset(props) {
  const { errorType } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');
  const [severityHandler, setSeverityHandler] = useState('');

  async function handleRequest() {
    const isRequestOk = await forgotPassword(email);

    if (isRequestOk) {
      setText(t('REQUEST_RESET_SNACKBAR'));
      setSeverityHandler('success');
      setOpen(true);
    } else {
      setText(t('FORGOT_PASSWORD_API_ERROR'));
      setSeverityHandler('error');
      setOpen(true);
    }
  }

  function handleClose() {
    setOpen(false);
  }

  const ExpiredLinkWindow = () => {
    return (
      <>
        <Typography
          variant="h2_old_font"
          color="textSecondary"
          sx={classes.sentEmail}
        >
          {`${t('EXPIRED_LINK')} ${email}.`}
        </Typography>
        <Typography
          variant="h2_old_font"
          color="textSecondary"
          sx={classes.textClickEmail}
        >
          {t('REQUEST_RESET_CLICK_EMAIL')}
        </Typography>

        <CardFooter onClickFooter={handleRequest} mailto />
      </>
    );
  };

  const FailWindow = () => {
    return (
      <>
        <Typography
          variant="h2_old_font"
          color="textSecondary"
          sx={classes.sentEmail}
        >
          {t('VERIFY_ERROR')}
        </Typography>
        <Typography
          variant="h2_old_font"
          color="textSecondary"
          sx={classes.textClickEmail}
        >
          {t('CALL_US')}
        </Typography>
      </>
    );
  };

  function resetWindowSelection() {
    if (errorType === Transitions.Types.EXPIRED_CODE) {
      return <ExpiredLinkWindow />;
    }
    if (errorType === Transitions.Types.FAIL_CODE) {
      return <FailWindow />;
    }
    return undefined;
  }

  const ErrorResetWindow = () => {
    return (
      <>
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item>
            <IconUnderlay size={90}>
              <PriorityHigh sx={classes.icon} />
            </IconUnderlay>
          </Grid>
          <Grid item>
            <Grid
              container
              justify="center"
              alignItems="center"
              direction="column"
            >
              {resetWindowSelection()}

              <ACSnackbar
                open={open}
                text={text}
                onClose={handleClose}
                severity={severityHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  return <ErrorResetWindow />;
}

export default ErrorReset;
