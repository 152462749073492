import React, { useState } from 'react';
import { Card, Avatar, Typography, Box, Button } from '@mui/material';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import { ReactComponent as PersonAdd } from '../../icons/person_add.svg';
import { saveCurrentGoalIdea } from '../../services/advisor_services';
import { inviteToSpeak } from '../../services/company_services';
import dateFormat from '../../utils/dateFormat';
import { useAccount } from '../../contexts/Account';
import ACSnackbar from '../../components/ACSnackbar';
import { SnackbarType } from '../../services/utils/types';
import eventBus, { eventBusValues } from '../../eventBus';
import { extractUrl } from '../../utils/urlUtils';

export default function IdeaCard({ idea, setLoading }) {
  const { accountDetails } = useAccount();
  const [expanded, setExpanded] = useState(false);
  const [isSaved, setIsSaved] = useState(idea.saved);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(SnackbarType.SUCCESS);

  function openAdvisorProfile(advisor) {
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      fetchAdvisor: true,
      id: advisor.id,
    });
  }

  const inviteRequestToSpeak = async () => {
    try {
      setLoading(true);
      const boardOwner = accountDetails.contacts.find(
        (contact) => contact.isBoardOwner
      );
      const response = await inviteToSpeak({
        advisorId: idea.advisor.id,
        companyName: accountDetails.companyName,
        currentGoal: idea.currentGoal,
        idea: idea.idea,
        boardOwnerEmail: boardOwner ? boardOwner.email : '',
        salesforceCompanyContactId: accountDetails.salesforceContactId,
      });
      setLoading(false);
      setSnackbarOpen(true);
      setSnackMessage(
        `${idea.advisor.displayName} has been invited to speak at your next board meeting.`
      );
      setSnackSeverity(SnackbarType.SUCCESS);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const save = async () => {
    try {
      setLoading(true);
      const response = await saveCurrentGoalIdea({
        ideaId: idea.id,
        saved: !isSaved,
        savedBy: accountDetails.userData.id,
      });
      setIsSaved(!isSaved);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getTruncatedText = (text) => {
    const maxLength = 500;
    if (text.length <= maxLength) return text;
    const truncated = text.substring(0, maxLength);
    const lastSpaceIndex = truncated.lastIndexOf(' ');
    return truncated.substring(0, lastSpaceIndex);
  };

  const fullText = idea.idea;
  const isLongText = fullText.length > 500;
  const displayedText =
    !isLongText || expanded ? fullText : getTruncatedText(fullText);

  return (
    <Card
      sx={{
        mb: 3,
        p: '20px',
        borderRadius: '8px',
        boxShadow: 'none',
      }}
    >
      <Box>
          <Box
            id="boardroom-current-goal"
            sx={{
              bgcolor: '#F1F9FF',
              borderRadius: '8px',
              py: '5px',
              px: '10px',
              width: '100%',
              borderTop: '1px solid #4FB5F9',
              borderRight: '1px solid #4FB5F9',
              borderBottom: '1px solid #4FB5F9',
              borderLeft: '5px solid #4FB5F9',
              display: 'block',
            }}
          >
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: { xxs: 14, xs: 16 },
                color: '#00548B',
                lineHeight: '21px',
              }}
            >
              Current Challenge or Goal:
            </Typography>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: { xxs: 12, xs: 14 },
                color: '#00548B',
                lineHeight: { xxs: '18px', xs: '21px' },
                whiteSpace: 'pre-wrap',
              }}
            >
              {extractUrl(idea.currentGoal)}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: { xxs: '12px', xs: '16px' },
              lineHeight: { xxs: '18px', xs: '24px' },
              color: '#4D586F',
              mt: 2,
              whiteSpace: 'pre-wrap',
            }}
          >
            {displayedText}
            {isLongText && (
              <span
                onClick={() => setExpanded(!expanded)}
                style={{
                  // color: '#4D586F',
                  cursor: 'pointer',
                  marginLeft: '5px',
                  textDecoration: 'underline',
                  fontWeight: '700',
                }}
              >
                {expanded ? 'Show less' : '... More'}
              </span>
            )}
          </Typography>
          <hr
            style={{
              border: '0',
              borderTop: '1px solid #E8E8E8',
              width: '100%',
              margin: '16px 0',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xxs: 'column-reverse', xs: 'row' },
              alignItems: { xxs: 'normal', xs: 'center' },
              justifyContent: 'space-between',
              backgroundColor: 'white',
              borderRadius: 2,
              p: 0,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mt: { xxs: 2, xs: 0 },
                cursor: 'pointer',
              }}
              onClick={() => openAdvisorProfile(idea.advisor)}
            >
              <Avatar
                alt={idea.advisor.displayName}
                src={
                  idea &&
                  idea.advisor &&
                  idea.advisor.image &&
                  idea.advisor.image.imageKey
                    ? `${process.env.REACT_APP_IMAGES_URL}${idea.advisor.image.imageKey}`
                    : ''
                }
                sx={{ width: 52, height: 52, mr: 2 }}
              />
              <Box>
                <Typography
                  sx={{
                    color: '#001030',
                    fontSize: '16px',
                    lineHeight: '20px',
                    fontWeight: '600',
                    mb: '5px',
                  }}
                >
                  {idea.advisor.displayName}
                </Typography>
                <Typography
                  sx={{
                    color: '#4D586F',
                    fontSize: '12px',
                    lineHeight: '18px',
                  }}
                >
                  {dateFormat(idea.createdAt, 'mmm dd, yyyy')}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xxs: 'normal', xs: 'flex-end' },
                gap: 1,
              }}
            >
              <Button
                id="boardroom-request-to-speak"
                variant="contained"
                color="primary"
                onClick={save}
                sx={{
                  textTransform: 'none',
                  bgcolor: isSaved ? '#4CAF50' : '#DFF1DF',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  width: 58,
                  height: 42,
                  fontSize: 14,
                  fontWeight: '600',
                  '&:hover': {
                    bgcolor: isSaved ? '#4CAF50' : '#DFF1DF',
                  },
                }}
              >
                {isSaved ? (
                  <BookmarkAddedIcon />
                ) : (
                  <BookmarkIcon
                    sx={{
                      color: '#006004',
                    }}
                  />
                )}
              </Button>
              <Button
                id="boardroom-request-to-speak"
                variant="contained"
                color="primary"
                startIcon={<PersonAdd />}
                onClick={inviteRequestToSpeak}
                sx={{
                  textTransform: 'none',
                  bgcolor: '#2B71F6',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  width: { xxs: '-webkit-fill-available', xs: 171 },
                  height: 42,
                  fontSize: 14,
                  fontWeight: '600',
                  '&:hover': {
                    bgcolor: '#2B71F6',
                  },
                }}
              >
                Invite to Speak
              </Button>
              <ACSnackbar
                // style={{ marginTop:  ? '50px' : '0px' }}
                open={snackbarOpen}
                text={snackMessage}
                severity={snackSeverity}
                onClose={() => {
                  setSnackbarOpen(false);
                }}
                autoHideDuration={5000}
              />
            </Box>
          </Box>
      </Box>
    </Card>
  );
}
