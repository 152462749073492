import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { browserName } from 'react-device-detect';
import { SwipeableDrawer, useMediaQuery } from '@mui/material';
import LoadingIndicatorLogo from './../LoadingIndicatorLogo';
import CompanyAdvisorProfile from './Profile/CompanyAdvisorProfile';
import eventBus, { eventBusValues } from './../../eventBus';
import { useAccount } from './../../contexts/Account';
import { AccountType } from './../../services/utils/types';
import {
  getAdvisor,
  addViewLog,
  getAdvisorByIdPublic,
  getAdvisorBySlug,
} from './../../services/Backend/account';
import Treatment from './../Treatment';
import { featureFlags } from './../../splitSetup';

const AdvisorDrawer = (props) => {
  const { publicProfileView } = props;
  const isXXS = useMediaQuery((theme) => theme.breakpoints.down('xxs'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const isLG = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const isXL = useMediaQuery((theme) => theme.breakpoints.down('xl'));
  const {
    isFreeTrial,
    type,
    currentUserInfo,
    asLoggedUserDetails,
  } = useAccount();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [isBoardRoomView, setIsBoardRoomView] = useState(false);
  // const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [blockDrawerClosing, setBlockDrawerClosing] = useState(false);
  const [advisor, setAdvisor] = useState({});
  // const [currentAdvisorId, setCurrentAdvisorId] = useState('');
  const [openInEdit, setOpenInEdit] = useState(false);
  const [clickSource, setClickSource] = useState(null);
  const [isFocus, setIsFocus] = useState(false);
  const { search } = useLocation();

  function closeDrawerOnEvent() {
    if (!blockDrawerClosing) {
      setDrawerIsOpen(false);
      setAdvisor({});
    }
  }

  function getDrawerStyling() {
    let top = 0;
    let left = 0;
    if (publicProfileView && !isSM) {
      left += 240;
      top += 50;
    }
    if (!publicProfileView) {
      if (type === AccountType.COMPANY && !isSM) {
        left += 268;
        top += 90;
        if (isFreeTrial) {
          top += 60;
        }
      }
      if (type === AccountType.ADVISOR && !isSM) {
        top += 75;
        if (isFreeTrial) {
          top += 50;
        }
        if (isBoardRoomView) {
          left += 268;
        }
      }
    }
    return `${top}px 0px 0px ${left}px`;
  }

  function viewLogCheck(advisorInfo) {
    const checkInfo = currentUserInfo || {};
    if (!drawerIsOpen && checkInfo.username !== advisorInfo.id) {
      addViewLog({
        BROWSER: browserName,
        PROFILE_ID: advisorInfo.id,
        VIEWER_ID: asLoggedUserDetails ? asLoggedUserDetails.userId : 'unknown',
        VIEWER_PROFILE_TYPE: type || 'unknown',
        VIEWER_TYPE: publicProfileView ? 'directorySlideIn' : 'inAppSlideIn',
      });
    }
  }

  function selectDrawerContent() {
    if (
      type === AccountType.ADVISOR &&
      !isBoardRoomView &&
      !publicProfileView
    ) {
      return (
        <Treatment
          userId={
            currentUserInfo && currentUserInfo.username
              ? currentUserInfo.username
              : 'not_id'
          }
          splitNames={featureFlags.publicProfilesCustomMetadata}
          updateOnSdkTimedout
          evaluatedComponent={
            <CompanyAdvisorProfile
              {...advisor}
              publicProfileView={publicProfileView}
              isBoardRoomView={isBoardRoomView}
              openInEdit={openInEdit}
              publicProfileFlag
              isOwnProfile
              isFocus={isFocus}
            />
          }
          conditionFlag
          conditionFailedComponent={
            <CompanyAdvisorProfile
              {...advisor}
              publicProfileView={publicProfileView}
              isBoardRoomView={isBoardRoomView}
              openInEdit={openInEdit}
              isOwnProfile
            />
          }
        />
      );
    }
    return (
      <CompanyAdvisorProfile
        {...advisor}
        advisorId={advisor.id}
        publicProfileView={publicProfileView}
        isBoardRoomView={isBoardRoomView}
        openInEdit={openInEdit}
        clickSource={clickSource}
      />
    );
  }

  useEffect(() => {
    eventBus.on(eventBusValues.triggerAdvisorDrawerAction, (data) => {
      setClickSource(data.source || null);
      setOpenInEdit(data.openInEdit);
      setIsFocus(data.isFocus);
      setDrawerIsOpen((prevValue) => !prevValue);
      if (data.boardRoomView) {
        setIsBoardRoomView(true);
      } else {
        setIsBoardRoomView(false);
      }
      if (!data.fetchAdvisor) {
        setAdvisor(data);
        viewLogCheck(data);
      } else {
        setIsLoading(true);
        if (data.fetchAdvisor === 'public') {
          getAdvisorByIdPublic({ ID: data.id })
            .then((response) => response.json())
            .then((publicResponse) => {
              setAdvisor(publicResponse.data.getAdvisor);
              viewLogCheck(publicResponse.data.getAdvisor);
              setIsLoading(false);
            });
        } else {
          getAdvisor(data.id, true).then((response) => {
            setAdvisor(response.data.getAdvisor);
            viewLogCheck(response.data.getAdvisor);
            setIsLoading(false);
          });
        }
      }
    });

    eventBus.on(eventBusValues.closeAdvisorDrawer, () => {
      closeDrawerOnEvent();
    });

    if (type === AccountType.ADVISOR) {
      eventBus.on(eventBusValues.triggerCloseBlocker, (data) => {
        setBlockDrawerClosing(data);
      });
      const profileParam = new URLSearchParams(search).get('profile');
      if (profileParam === 'view') {
        setDrawerIsOpen(true);
      } else if (profileParam === 'edit') {
        setOpenInEdit(true);
        setDrawerIsOpen(true);
        setBlockDrawerClosing(true);
      }
    }

    const advisorProfile = new URLSearchParams(search).get('advisor_profile');
    if (advisorProfile) {
      setIsLoading(true);
      setIsBoardRoomView(true);
      setDrawerIsOpen(true);
      getAdvisorBySlug(advisorProfile)
        .then((response) => {
          setAdvisor(response.data.getAdvisor);
          viewLogCheck(response.data.getAdvisor);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching advisor', error);
          setIsLoading(false);
          setDrawerIsOpen(false);
        });
    }

    return () => {
      eventBus.remove(eventBusValues.triggerAdvisorDrawerAction);
      eventBus.remove(eventBusValues.triggerCloseBlocker);
      eventBus.remove(eventBusValues.closeAdvisorDrawer);
    };
  }, []);

  // useEffect(() => {
  //   if (!drawerIsOpen) return;
  //   setIsOwnProfile(
  //     currentAdvisorId === asLoggedUserDetails.userId ||
  //       (currentUserInfo && currentAdvisorId === currentUserInfo.username)
  //   );
  // }, [drawerIsOpen, currentAdvisorId]);

  //if (!drawerIsOpen) return null;

  return (
    <SwipeableDrawer
      style={{ inset: getDrawerStyling() }}
      anchor={
        type === AccountType.COMPANY || publicProfileView || isBoardRoomView
          ? 'right'
          : 'left'
      }
      disableSwipeToOpen
      open={drawerIsOpen}
      onOpen={() => {
        setDrawerIsOpen(true);
      }}
      onClose={() => {
        if (!blockDrawerClosing) {
          setDrawerIsOpen(false);
        } else {
          eventBus.dispatch(eventBusValues.drawerTryToCloseOnEdit);
        }
      }}
      PaperProps={{
        style: {
          height: '100%',
          paddingBottom: '0px',
          position: 'fixed',
          width: isXXS
            ? '100%'
            : isXS
            ? '100%'
            : isSM
            ? '100%'
            : isMD
            ? '80%'
            : isLG
            ? '80%'
            : isXL
            ? '70%'
            : '60%',
        },
      }}
    >
      {!isLoading ? (
        selectDrawerContent()
      ) : (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
        </div>
      )}
    </SwipeableDrawer>
  );
};

export default AdvisorDrawer;
