import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import {
  getMyAdvisors,
  searchMyAdvisors,
} from '../services/company_services/index';
import { useAccount } from '../contexts/Account';
import AdvisorsTopBar from '../components/Advisor/TopBar';
import AdvisorFilter from '../components/Advisor/Filter';
import MyAdvisorsContent from '../components/Company/MyAdvisorsContent';
import CompanySavedAdvisorsEmptyState from '../components/EmptyState/CompanySavedAdvisorsEmptyState';
import ACSnackbar from '../components/ACSnackbar';
import eventBus, { eventBusValues } from '../eventBus';
import CardSkeleton from '../components/SkeletonLoading/cardSkeleton';

const getClasses = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: isSM ? '10px' : '30px',
    paddingTop: 0,
    paddingBottom: '20px',
    paddingRight: isSM ? '10px' : '30px',
  },
});

// react state is not managing these properties correctly
const PAGE_SIZE = 10;
let endPagination = false;
let offset = 1;
let cleanFilters = true;
let filters = {};

let advisorIds = new Set();

function SavedAdvisors(props) {
  const { boxRef } = props;
  const {
    accountDetails,
    currentUserInfo,
    asLoggedUserDetails,
    isImpersonated,
    isFreemium,
  } = useAccount();
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');
  const [myAdvisors, setMyAdvisors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const contact = isImpersonated
    ? accountDetails.contacts.find((c) => c.id === asLoggedUserDetails.userId)
    : accountDetails.contacts.find((c) => c.id === currentUserInfo.username);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses(isSM);

  function advisorsCall() {
    setIsLoading(true);
    getMyAdvisors({
      COMPANY_ID: accountDetails.id,
      TYPE: 'savedAdvisors',
      USER_ID: contact.id,
      LIMIT: PAGE_SIZE,
      START_KEY: offset,
      RAW_DATA: true,
      SAVED_FILTER: filters.savedFilter || 'allSaved',
    })
      .then(({ data }) => {
        // Filtrar asesores duplicados usando Set
        const newAdvisors = data.getMyAdvisors.filter((advisor) => {
          if (advisorIds.has(advisor.id)) {
            return false; // Si el asesor ya está en el Set, no lo agregamos
          }
          advisorIds.add(advisor.id); // Agregamos el nuevo ID al Set
          return true; // Permitimos que el asesor sea agregado
        });
        if (offset === 0) {
          setMyAdvisors(data.getMyAdvisors);
        } else {
          setMyAdvisors((prev) => [...prev, ...data.getMyAdvisors]);
        }
        if (data.getMyAdvisors.length < PAGE_SIZE) {
          endPagination = true;
        }
        offset += 1;
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleSearch(props) {
    offset = 0;
    advisorIds.clear();
    const emptyFilters =
      (!props.keywords || props.keywords === '') &&
      (!props.skills || props.skills.length === 0) &&
      (!props.industries || props.industries.length === 0);
    cleanFilters = emptyFilters;
    filters = { ...props };
    endPagination = false;
    if (boxRef && boxRef.current) {
      boxRef.current.lastScrollHeight = null;
    }
    if (emptyFilters) {
      advisorsCall();
    } else {
      setMyAdvisors([]);
      setIsLoading(true);
      searchMyAdvisors({
        SEARCH_STRING: props.keywords,
        OFFSET: 0,
        SKILLS_TAGS_IDS: props.skills.map((skill) => skill.id),
        INDUSTRY_TAGS_IDS: props.industries.map((industry) => industry.id),
        POSITION_TAGS_IDS: [],
        COMPANY_ID: accountDetails.id,
        CONTACT_ID: contact.id,
        TYPE: 'savedAdvisors',
        SAVED_FILTER: props.savedFilter,
      })
        .then(({ data }) => {
          if (data.searchMyAdvisors.advisors.length < PAGE_SIZE) {
            endPagination = true;
          }
          setMyAdvisors(data.searchMyAdvisors.advisors);
          offset = data.searchMyAdvisors.offset;
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }

  function paginate() {
    if (!endPagination && !isLoading) {
      if (cleanFilters) {
        advisorsCall();
      } else {
        setIsLoading(true);
        searchMyAdvisors({
          SEARCH_STRING: filters.keywords,
          OFFSET: offset,
          SKILLS_TAGS_IDS: filters.skills,
          INDUSTRY_TAGS_IDS: filters.industries,
          POSITION_TAGS_IDS: [],
          COMPANY_ID: accountDetails.id,
          CONTACT_ID: contact.id,
          TYPE: 'savedAdvisors',
          SAVED_FILTER: filters.savedFilter || 'allSaved',
        })
          .then(({ data }) => {
            const newAdvisors = data.searchMyAdvisors.advisors.filter(
              (advisor) => {
                if (advisorIds.has(advisor.id)) {
                  return false;
                }
                advisorIds.add(advisor.id);
                return true;
              }
            );
            setMyAdvisors((prev) => [...prev, ...newAdvisors]);
            if (newAdvisors.length < PAGE_SIZE) {
              endPagination = true;
            }
            offset += PAGE_SIZE;
          })
          .catch((err) => {
            console.log({ err });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getMyAdvisors({
      COMPANY_ID: accountDetails.id,
      TYPE: 'savedAdvisors',
      USER_ID: contact.id,
      LIMIT: PAGE_SIZE,
      START_KEY: 0,
      RAW_DATA: true,
      SAVED_FILTER: filters.savedFilter || 'allSaved',
    })
      .then(({ data }) => {
        setMyAdvisors(data.getMyAdvisors);
        if (data.getMyAdvisors.length < PAGE_SIZE) {
          endPagination = true;
        }
      })
      .catch((err) => {
        console.log({ err });
      })
      .finally(() => {
        setIsLoading(false);
      });
    const handleScroll = () => {
      const currentScrollHeight = boxRef.current.scrollHeight;
      const currentScrollTop = boxRef.current.scrollTop;
      const offsetHeight = boxRef.current.offsetHeight;
      const threshold = 50;

      if (
        currentScrollHeight - offsetHeight - threshold <= currentScrollTop &&
        !isLoading
      ) {
        if (boxRef.current.lastScrollHeight !== currentScrollHeight) {
          paginate();
          boxRef.current.lastScrollHeight = currentScrollHeight;
        }
      }
    };

    if (boxRef && boxRef.current) {
      boxRef.current.addEventListener('scroll', handleScroll);
    }

    eventBus.on(eventBusValues.advisorUnsaveSB, (event) => {
      if (event && event.message) {
        setErrorMessage(event.message);
        setErrorSnackbar(true);
      }
    });

    return () => {
      eventBus.remove(eventBusValues.advisorUnsaveSB);
      if (boxRef && boxRef.current) {
        boxRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <>
      <AdvisorsTopBar boxRef={boxRef} />
      <div style={classes.container}>
        <AdvisorFilter
          onSearchButtonClicked={handleSearch}
          hidePeerFilter
          showSavedFilter
        />

        {isLoading && (
          <Box pt="30px">
            <CardSkeleton />
            <Box sx={{ height: '30px' }} />
            <CardSkeleton />
          </Box>
        )}
        {myAdvisors.length > 0 && (
          <Box pt="30px">
            <MyAdvisorsContent
              endpointResponse={myAdvisors}
              fetchAdvisor
              propsToPass={{ removeBelowButtons: true }}
              contentProps={{ setIsLoading }}
              endPagination={endPagination}
            />
          </Box>
        )}
        {!isLoading && myAdvisors.length === 0 && (
          <CompanySavedAdvisorsEmptyState />
        )}
      </div>
      <ACSnackbar
        open={errorSnackbar}
        text={errorMessage}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
    </>
  );
}

export default SavedAdvisors;
