import React, { useState, useEffect } from 'react';
import { Dialog, Slide, Typography, useMediaQuery } from '@mui/material';
import { Clear } from '@mui/icons-material';
import CompanyOnboardingCall from './UpgradeModals/UpgradeCall';
import { useAccount } from '../../contexts/Account';
import ACSnackbar from '../ACSnackbar';
import { getCompanyPlanUpgrades } from '../../services/company_services';

const useStyles = () => ({
  dialogContainer: {
    borderRadius: (isSM) => (isSM ? '0px' : '5px'),
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    padding: (isSM) => (isSM ? '5px' : '30px'),
  },
  header: {
    textAlign: 'center',
  },
  textHeadline: {
    textAlign: 'center',
    fontFamily: 'Open Sans',
    fontWeight: '400',
    fontSize: '24px',
    padding: '25px',
  },
  textSubHeader: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    padding: '15px',
    textAlign: 'center',
  },
  clear: {
    margin: '10px 10px 0 10px',
  },
  clearIcon: {
    float: 'right',
    cursor: 'pointer',
  },
  contentBox: {
    backgroundColor: '#f0f2f7',
    margin: '0.5rem 4rem 0.5rem 4rem',
    padding: '0.5rem 0.25rem 1rem 0.25rem',
    textAlign: 'center',
    borderRadius: 5,
    border: 'dotted #dedfea',
    cursor: 'pointer',
  },
  selectedContentBox: {
    border: 'solid #7fb4fa',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UpgradePlanModal({
  upgradePlanModal,
  setUpgradePlanModal,
}) {
  const { accountDetails, editAccountInformation } = useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [currentPlanCode, setCurrentPlanCode] = useState({});
  const [newAccountDetails, setNewAccountDetails] = useState({});

  console.log('accountDetails: ', accountDetails);
  function handleClose() {
    setUpgradePlanModal(false);
  }

  useEffect(() => {
    let isMounted = true;

    async function getPlanCodes() {
      try {
        const response = await getCompanyPlanUpgrades({
          COMPANY_ID: accountDetails.id,
          RECURLY_PLAN_CODE: accountDetails.recurlyPlanCode,
        });
        if (isMounted) {
          const upgradePlans = response.data.getRecurlyPlanUpgrades;
          const currentPlan = upgradePlans.pop();

          upgradePlans.sort((a, b) => {
            const first =
              a.boardSeatsAllowedCount == -1
                ? Infinity
                : a.boardSeatsAllowedCount;
            const second =
              b.boardSeatsAllowedCount == -1
                ? Infinity
                : b.boardSeatsAllowedCount;

            return first - second;
          });
          setCurrentPlanCode(currentPlan);
        }
      } catch (err) {
        console.log(err);
        setErrorSnackbar(true);
      }
    }
    getPlanCodes();

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Dialog
        open={upgradePlanModal}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        PaperProps={{
          sx: classes.dialogContainer,
        }}
        aria-describedby="alert-dialog-slide-description"
      >
        {isSM ? (
          <div style={classes.clear}>
            <Clear onClick={handleClose} sx={classes.clearIcon} />
          </div>
        ) : null}
        <div style={classes.header}>
          <Typography sx={classes.textHeadline}>
            You are currently on the{' '}
            <strong>
              {currentPlanCode.recurlyPlanCodeDisplayName ||
                accountDetails.recurlyPlanCode}{' '}
              plan
            </strong>
            , limited to{' '}
            <strong>
              {accountDetails.boardSeatsAllowed === -1
                ? 'Unlimited user seats'
                : accountDetails.boardSeatsAllowed +
                  ' user seat' +
                  (accountDetails.boardSeatsAllowed > 1 ? 's' : '')}
            </strong>
          </Typography>
          <Typography sx={classes.textSubHeader}>
            Schedule a call with your Account Manager to discuss additional user
            seats or any additional needs for {accountDetails.companyName}.
          </Typography>
        </div>
        <CompanyOnboardingCall
          meetingId="SOIDIV_b2bupgradeinquiry"
          meetingData="b2bupgradeinquiry"
        />
      </Dialog>
      <ACSnackbar
        open={successSnackbar}
        text="Plan was successfully upgraded."
        severity="success"
        onClose={() => {
          setSuccessSnackbar(false);
          editAccountInformation(newAccountDetails);
        }}
        autoHideDuration={3500}
      />
      <ACSnackbar
        open={errorSnackbar}
        text="Error trying to upgrade."
        severity="error"
        onClose={() => {
          setErrorSnackbar(false);
        }}
        autoHideDuration={6000}
      />
    </>
  );
}
