import React, { useEffect, useState } from 'react';
import CallWindow from './UpgradeCall';
import { Box } from '@mui/material';
import Arrow from './arrow.png';
import { getCompanyRecurlyPlan } from '../../../services/company_services';

export default function Step3({ goBack, hideBack, planCode }) {
  const [onboardingCalendar, setOnboardingCalendar] = useState('');

  useEffect(() => {
    async function fetchData() {
      if (planCode) {
        try {
          const planResponses = await getCompanyRecurlyPlan({
            recurlyPlanCode: planCode,
          });

          const { b2bOnboardingCalendar } = planResponses.data.getRecurlyPlan;
          setOnboardingCalendar(b2bOnboardingCalendar);
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
      }
    }

    fetchData();
  }, [planCode]);
  return (
    <div>
      {!hideBack && (
        <Box
          sx={{
            padding: '20px',
            width: '15px',
            cursor: 'pointer',
            marginLeft: '20px',
            marginBottom: '20px',
          }}
        >
          <img
            onClick={goBack}
            src={Arrow}
            alt="arrow"
            style={{
              width: '16px',
            }}
          />
        </Box>
      )}

      <CallWindow
        meetingId={
          onboardingCalendar
            ? `SOIDIV_${onboardingCalendar}`
            : 'SOIDIV_marlawilliamscomplimentaryplatformdemo'
        }
        meetingData={
          onboardingCalendar
            ? onboardingCalendar
            : 'marlawilliamscomplimentaryplatformdemo'
        }
        psz="00"
        style="border: 1px solid #d8d8d8; min-width: 290px; max-width: 900px;"
      />
    </div>
  );
}
