import React from 'react';
import { Menu, Badge, Divider } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import {
  FavoriteBorder,
  TrendingUp,
} from '@mui/icons-material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import eventBus, { eventBusValues } from '../../eventBus';
import { getAdvisorQueryParams } from '../../utils/queryParams';
import { useAccount } from '../../contexts/Account';
import HighlightedButton from './HighlightedButton';

const useStyles = () => ({
  container: {
    width: '410px',
    height: 'auto',
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
  },
  menuItemsContainer: {
    width: '330px',
    backgroundColor: 'white',
    boxShadow: '0px 1px 10px rgba(230, 237, 254, 0.25)',
    border: '1px solid #F5F7FA',
    borderRadius: '12px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#232b35',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingBottom: '4px',
    paddingTop: '6px',
    paddingRight: '20px',
    paddingLeft: '20px',
    marginRight: '-20px',
    marginLeft: '-20px',
    width: '310px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Poppins',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  menuItemLogout: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingBottom: '4px',
    paddingTop: '6px',
    marginLeft: '13px',
    color: '#232b35',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    width: '260px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
  },
  divider: {
    width: '94%',
    marginTop: '10px',
    marginBottom: '10px',
    color: '#F5F7FA',
  },
  itemIcon: {
    marginLeft: '17px',
    marginRight: '11px',
    fontSize: '23px',
  },
  userBox: {
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  userName: {
    fontWeight: 'bold',
  },
  userEmail: {
    color: '#666',
    fontSize: '14px',
  },
});

function ProfileMenu({
  profileAnchorEl,
  handleProfileMenuClose,
  handleLogout,
  accountDetails,
}) {
  const classes = useStyles();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const history = useHistory();
  const { currentUserInfo, isFreeTrial, isFreemium, conversations } =
    useAccount();

  function getUnreadMessages() {
    let totalUnread = 0;
    conversations.forEach((conv) => {
      totalUnread += conv.unreadCount;
    });
    return totalUnread;
  }

  function handleMenuItem(url, newTab = false) {
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  }

  function navigateInApp(path) {
    history.push(path);
  }

  function getOptions() {
    return (
      <div style={classes.menuItemsContainer}>
        <button
          id="Videos&TutorialsMenuOption"
          style={classes.menuItem}
          onClick={() =>
            window.location.replace(
              `https://register.advisorycloud.com/platform/jb-faqs?${getAdvisorQueryParams(
                {
                  accountDetails,
                  isFreeTrial,
                  isFreemium,
                }
              )}`
            )
          }
          type="button"
        >
          <HelpOutlineIcon sx={classes.itemIcon} />
          FAQs
        </button>

        {accountDetails.upgradeUrl && (
          <button
            id="upgradeMenuOption"
            style={classes.menuItem}
            onClick={() =>
              handleMenuItem(
                `${accountDetails.upgradeUrl}?iwm_advisor_id=${accountDetails.iwmId}&sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.email}&plancode=${accountDetails.recurlyPlanCode}`,
                true
              )
            }
            type="submit"
          >
            <TrendingUp sx={classes.itemIcon} />
            Upgrade Membership
          </button>
        )}
        <button
          id="settingsMenuOption"
          style={classes.menuItem}
          onClick={() => navigateInApp('/account')}
          type="submit"
        >
          <FavoriteBorder sx={classes.itemIcon} />
          Membership Settings
        </button>
        {conversations && conversations.length > 0 && (
          <Badge
            color="error"
            sx={{
              '& .MuiBadge-badge': {
                top: '20%',
                right: '50%',
                transform: 'translate(50%, -50%)',
              },
            }}
            badgeContent={getUnreadMessages()}
          >
            <button
              id="settingsMenuOption"
              style={classes.menuItem}
              onClick={() => {
                eventBus.dispatch(
                  eventBusValues.triggerConversationDrawerV2,
                  {}
                );
              }}
              type="submit"
            >
              <MessageOutlinedIcon sx={classes.itemIcon} />
              Messages
            </button>
          </Badge>
        )}
        <HighlightedButton />
        <Divider sx={classes.divider} />

        <button
          id="advisorLogoutMenuOption"
          style={classes.menuItemLogout}
          type="submit"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    );
  }

  return (
    <Menu
      anchorEl={profileAnchorEl}
      open={Boolean(profileAnchorEl)}
      sx={classes.container}
      elevation={0}
      onMouseLeave={() => {
        if (!isSM) {
          handleProfileMenuClose();
        }
      }}
      onClose={handleProfileMenuClose}
    >
      {getOptions()}
    </Menu>
  );
}

export default ProfileMenu;
