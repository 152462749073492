import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import MyAdvisorsContent from '../components/Company/MyAdvisorsContent';
import { searchMyAdvisors } from '../services/company_services/index';
import { useAccount } from '../contexts/Account';
import AdvisorsTopBar from '../components/Advisor/TopBar';
import AdvisorFilter from '../components/Advisor/Filter';
import CardSkeleton from '../components/SkeletonLoading/cardSkeleton';
import CompanyMyAdvisorsEmptyState from '../components/EmptyState/CompanyMyAdvisorsEmptyState';

const getClasses = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: isSM ? '10px' : '30px',
    paddingTop: 0,
    paddingBottom: '20px',
    paddingRight: isSM ? '10px' : '30px',
    overflowY: isSM ? 'auto' : 'unset',
    height: isSM ? '100%' : 'auto',
    marginLeft: "center",
    marginRight: "center",
  },
});

const PAGE_SIZE = 10;
let endPagination = false;
let offset = PAGE_SIZE;
let cleanFilters = true;
let filters = {};

function MyAdvisors(props) {
  const { boxRef } = props;
  const {
    currentUserInfo,
    accountDetails,
    asLoggedUserDetails,
    isImpersonated,
  } = useAccount();
  const localAdvisors = useMemo(() => {
    const uniqueAdvisors = Array.from(
      new Map(
        accountDetails.boardAdvisors
          .filter((advisor) => advisor.advisorStatus === 'active')
          .map((advisor) => [advisor.id, advisor])
      ).values()
    );

    return uniqueAdvisors.sort((a, b) => {
      if (a.joinedOnDate < b.joinedOnDate) return 1;
      if (a.joinedOnDate > b.joinedOnDate) return -1;
      return 0;
    });
  }, [accountDetails.boardAdvisors]);

  const [myAdvisors, setMyAdvisors] = useState(
    localAdvisors.slice(0, PAGE_SIZE)
  );

  const [isLoading, setIsLoading] = useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses(isSM);

  const contact = isImpersonated
    ? accountDetails.contacts.find((c) => c.id === asLoggedUserDetails.userId)
    : accountDetails.contacts.find((c) => c.id === currentUserInfo.username);

  function handleSearch(props) {
    setMyAdvisors([]);
    cleanFilters =
      (!props.keywords || props.keywords === '') &&
      (!props.skills || props.skills.length === 0) &&
      (!props.industries || props.industries.length === 0);
    filters = { ...props };
    endPagination = false;
    if (boxRef && boxRef.current) {
      boxRef.current.lastScrollHeight = null;
    }
    if (cleanFilters) {
      setMyAdvisors(localAdvisors.slice(0, PAGE_SIZE));
      offset = PAGE_SIZE;
    } else {
      setIsLoading(true);
      searchMyAdvisors({
        SEARCH_STRING: props.keywords,
        OFFSET: 0,
        SKILLS_TAGS_IDS: props.skills.map((skill) => skill.id),
        INDUSTRY_TAGS_IDS: props.industries.map((industry) => industry.id),
        POSITION_TAGS_IDS: [],
        COMPANY_ID: accountDetails.id,
        CONTACT_ID: contact.id,
        TYPE: 'boardAdvisors',
      })
        .then(({ data }) => {
          if (data.searchMyAdvisors.advisors.length < PAGE_SIZE) {
            endPagination = true;
          }
          setMyAdvisors(data.searchMyAdvisors.advisors);
          offset = data.searchMyAdvisors.offset;
        })
        .catch((err) => {
          console.log({ err });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }
  // console.log({ endPagination, from: 'outside' });

  function paginate() {
    // console.log({ endPagination, from: 'paginate' });
    if (!endPagination) {
      if (cleanFilters) {
        if (offset + PAGE_SIZE >= localAdvisors.length) {
          // setEndPagination(true);
          endPagination = true;
        }
        setMyAdvisors((prev) => [
          ...prev,
          ...localAdvisors.slice(prev.length, offset + PAGE_SIZE),
        ]);
        offset += PAGE_SIZE;
      } else {
        setIsLoading(true);
        searchMyAdvisors({
          SEARCH_STRING: filters.keywords,
          OFFSET: offset,
          SKILLS_TAGS_IDS: filters.skills,
          INDUSTRY_TAGS_IDS: filters.industries,
          POSITION_TAGS_IDS: [],
          COMPANY_ID: accountDetails.id,
          CONTACT_ID: contact.id,
          TYPE: 'boardAdvisors',
        })
          .then(({ data }) => {
            setMyAdvisors((prev) => [
              ...prev,
              ...data.searchMyAdvisors.advisors,
            ]);
            if (data.searchMyAdvisors.advisors.length < PAGE_SIZE) {
              // setEndPagination(true);
              endPagination = true;
            }
            offset = data.searchMyAdvisors.offset;
          })
          .catch((err) => {
            console.log({ err });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollHeight = boxRef.current.scrollHeight;
      const currentScrollTop = boxRef.current.scrollTop;
      const offsetHeight = boxRef.current.offsetHeight;
      const threshold = 50;

      if (
        currentScrollHeight - offsetHeight - threshold <= currentScrollTop &&
        !isLoading
      ) {
        if (boxRef.current.lastScrollHeight !== currentScrollHeight) {
          paginate();
          boxRef.current.lastScrollHeight = currentScrollHeight;
        }
      }
    };

    if (boxRef && boxRef.current) {
      boxRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (boxRef && boxRef.current) {
        boxRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollToInput = () => {
    if (boxRef.current) {
      boxRef.current.scrollTo({
        top: 440,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <AdvisorsTopBar boxRef={boxRef} />
      <div style={classes.container}>
        {accountDetails.boardAdvisors &&
          accountDetails.boardAdvisors.length > 0 && (
            <AdvisorFilter
              onSearchButtonClicked={handleSearch}
              hidePeerFilter
              scrollToInput={scrollToInput}
            />
          )}
        {isLoading && (
          <Box pt="30px" sx={{}}>
            <CardSkeleton />
            <Box sx={{ height: '30px' }} />
            <CardSkeleton />
          </Box>
        )}

        {myAdvisors.length > 0 && (
          <Box pt="30px" sx={{minWidth: "100%"}}>
            <MyAdvisorsContent
              endpointResponse={myAdvisors}
              fetchAdvisor
              contentProps={{ setIsLoading }}
              endPagination={endPagination}
            />
          </Box>
        )}

        {!isLoading && myAdvisors.length === 0 && (
          <CompanyMyAdvisorsEmptyState />
        )}
      </div>
    </>
  );
}
export default MyAdvisors;
