import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAccount } from '../../contexts/Account';
import { getAdvisorQueryParams } from '../../utils/queryParams';
import { ReactComponent as StarIcon } from '../../icons/star-symbol.svg';

const UpsellBanner = () => {
  const { currentPlan, accountDetails, isFreeTrial, isFreemium } = useAccount();
  const { specialOffersUrl } = currentPlan;
  if (!specialOffersUrl) return null;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openDialog, setOpenDialog] = useState(false);
  const [bannerText, setBannerText] = useState('Upgrade Available - Get 4 Months Free');
  const [bannerCtaText, setBannerCtaText] = useState('VIEW');
  const finalUrl = `${specialOffersUrl}?${getAdvisorQueryParams({
    accountDetails,
    isFreeTrial,
    isFreemium,
  })}`;
  const handleBannerClick = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (isMobile) {
      setBannerText('Upgrade Available');
      setBannerCtaText('Get 4 Months Free');
    }else{
      setBannerText('Upgrade Available - Get 4 Months Free');
      setBannerCtaText('VIEW');
    }
  }, [isMobile]);

  return (
    <>
      <Box
        id="upsell-banner"
        sx={{
          backgroundColor: '#eef8ff',
          borderRadius: '8px',
          height: isMobile ? 60 : 38,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          // temporary fix for padding, remove when re-add the button of src/components/TopBar/index.jsx
          paddingLeft: isMobile ? '0' : '132px',
        }}
        onClick={handleBannerClick}
      >
        <StarIcon style={{ fontSize: 14, mr: 1 }} />

        <Typography
          sx={{
            fontSize: isMobile ? 12 : 14,
            color: '#232B35',
            fontWeight: '600',
            lineHeight: isMobile ? '20px' : '24px',
            letterSpacing: '0.5px',
            mx: 2,
          }}
        >
          {bannerText}
        </Typography>

        <Button
          variant="contained"
          sx={{
            color: 'white',
            textTransform: 'none',
            fontSize: isMobile ? 12 : 10,
            fontWeight: '600',
            lineHeight: '18px',
            letterSpacing: '0.5px',
            width: isMobile ? 135 : 60,
            height: isMobile ? 27 : 21,
            backgroundColor: '#3171F6',
            px: 0,
            ':hover': {
              backgroundColor: '#3171F6',
            },
          }}
        >
          {bannerCtaText}
        </Button>
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullScreen={isMobile}
        PaperProps={{
          sx: {
            width: isMobile ? '100%' : 800,
            maxWidth: '100%',
            height: isMobile ? '100%' : 775,
            maxHeight: '100%',
            borderRadius: '8px',
          },
        }}
      >
        <IconButton
          onClick={handleCloseDialog}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            zIndex: 1,
            display: { xs: 'block', md: 'none' },
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent sx={{ p: 0, height: '100%', width: '100%' }}>
          <Box sx={{ width: '100%', height: 'calc(100% - 10px)' }}>
            <iframe
              src={finalUrl}
              style={{
                border: 'none',
                width: '100%',
                height: '100%',
              }}
              title="Upsell Offer"
            />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UpsellBanner;
