import React, { useEffect, useState } from 'react';
import { Box, Typography, Paper, Button } from '@mui/material';
import SyncIcon from '@mui/icons-material/Sync';
import dateFormat from '../../utils/dateFormat';
import { useAccount } from '../../contexts/Account';
import { getUpcomingBoardMeetings } from '../../services/advisor_services';
import { useHistory } from 'react-router-dom';
import CalendarSyncMenu from '../../components/CalendarSyncMenu';
import ACSnackbar from '../../components/ACSnackbar';
import { SnackbarType } from '../../services/utils/types';
import LoadingLogoSkeleton from '../../components/SkeletonLoading/loadingLogoSkeleton';

const UpcomingBoardMeetings = () => {
  const { accountDetails, isFreemium } = useAccount();
  const [meetings, setMeetings] = useState([]);
  const history = useHistory();
  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);

  const [googleSync, setGoogleSync] = useState(
    accountDetails?.calendarTokens?.google || false
  );
  const [appleSync, setAppleSync] = useState(
    accountDetails?.calendarTokens?.apple || false
  );

  const [calendarSynced, setCalendarSynced] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(SnackbarType.SUCCESS);

  const advisorId = accountDetails?.id;
  const companyIds = accountDetails?.boards?.memberBoards?.map(
    (board) => board.id
  );

  const showSnackbar = (message, severity = SnackbarType.SUCCESS) => {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpenCalendarMenu = (event) => {
    setCalendarAnchorEl(event.currentTarget);
  };

  const handleCloseCalendarMenu = () => {
    setCalendarAnchorEl(null);
  };

  useEffect(() => {
    const fetchMeetings = async () => {
      const response = await getUpcomingBoardMeetings({
        advisorId: accountDetails.id,
      });
      setMeetings(
        response.data.getUpcomingBoardMeetings.sort((a, b) => {
          return new Date(a.meetingTime) - new Date(b.meetingTime);
        })
      );
    };

    fetchMeetings();
  }, [accountDetails.id]);

  useEffect(function isCalendarSynced() {
    setCalendarSynced(googleSync || appleSync);
  }, [googleSync, appleSync, isLoading]);



  return (
    <Paper
      id="upcoming-board-meetings-tile"
      elevation={3}
      sx={{
        padding: '15px',
        borderRadius: '12px',
        backgroundColor: '#fff',
        marginBottom: '20px',
        boxShadow: 'none',
        minHeight: '100%',
      }}
    >
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: 20,
          lineHeight: '24px',
          color: '#001030',
          marginBottom: '15px',
        }}
      >
        Upcoming Board Meetings
      </Typography>

      {calendarSynced ? null : (
        <Typography
          sx={{
            fontSize: '12px',
            color: '#4D586F',
            fontWeight: 400,
            lineHeight: '18px',
          }}
        >
          As you join advisory boards, their upcoming board meetings will be listed below.
          Use the Calendar Sync button to have all current and future meetings added to your favorite calendar!
        </Typography>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '15px' }}>
        {meetings.map((meeting, index) => {
          const dateObj = new Date(meeting.meetingTime);
          const month = dateFormat(dateObj, 'mmm');
          const dayNumber = dateFormat(dateObj, 'd');
          const timeFormatted = dateFormat(dateObj, 'h:MM TT');

          return (
            <Paper
              className="upcoming-board-meeting"
              key={index}
              elevation={1}
              onClick={() => {
                history.push(`/post/${meeting.id}`);
              }}
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '16px',
                borderRadius: '12px',
                border: '1px solid #C9CDD3',
                backgroundColor: '#fff',
                boxShadow: 'none',
                height: '108px',
                cursor: 'pointer',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '84px',
                  height: '84px',
                }}
              >
                <Box
                  sx={{
                    backgroundColor: '#7BC8FF',
                    color: '#002F51',
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    textAlign: 'center',
                    p: '4px',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '21px',
                    }}
                  >
                    {month}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor: '#EEF8FF',
                    color: '#001030',
                    borderBottomLeftRadius: 8,
                    borderBottomRightRadius: 8,
                    textAlign: 'center',
                    p: '4px',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: '500',
                      fontSize: '24px',
                      lineHeight: '36px',
                      margin: '6px 0',
                    }}
                  >
                    {dayNumber}
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ flex: 1, marginLeft: '16px' }}>
                <Typography
                  sx={{ fontSize: '12px', color: '#4D586F', fontWeight: 400 }}
                >
                  {meeting.postCompanyContact.companyName}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    marginTop: '12px',
                    marginBottom: '12px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'normal',
                  }}
                >
                  {meeting.postTitle}
                </Typography>
                <Typography
                  sx={{ fontSize: '12px', color: '#4D586F', fontWeight: 400 }}
                >
                  <span style={{ color: '#2b71f6' }}>•</span> {timeFormatted}
                </Typography>
              </Box>
            </Paper>
          );
        })}
      </Box>

      <Button
        id="Sync Calendar"
        variant="contained"
        startIcon={<SyncIcon />}
        onClick={handleOpenCalendarMenu}
        disabled={isLoading}
        sx={{
          marginTop: '20px',
          textTransform: 'none',
          fontSize: '16px',
          padding: '10px 20px',
          borderRadius: '8px',
          backgroundColor: '#2B71F6',
          color: '#fff',
          fontWeight: '600',
          fontSize: '14px',
          width: '100%',
          '&:hover': {
            backgroundColor: '#2B71F6',
          },
        }}
      >
        {isLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <LoadingLogoSkeleton />
          </div>
        ) : calendarSynced ? (
          'Manage Calendar Sync'
        ) : (
          'Sync Calendar'
        )}
      </Button>

      <CalendarSyncMenu
        anchorEl={calendarAnchorEl}
        setAnchorEl={setCalendarAnchorEl}
        syncStates={{
          googleSync,
          appleSync,
        }}
        setSyncStates={{
          setGoogleSync,
          setAppleSync,
        }}
        advisorId={advisorId}
        companyIds={companyIds}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        showSnackbar={showSnackbar}
      />
      <ACSnackbar
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
        open={snackbarOpen}
        text={snackMessage}
        severity={snackSeverity}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        autoHideDuration={5000}
      />
    </Paper>
  );
};

export default UpcomingBoardMeetings;
