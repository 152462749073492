import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  useMediaQuery,
} from '@mui/material';
import { ArrowForwardIosOutlined } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useLocation, useHistory } from 'react-router-dom';
import { getConversationMembers } from '../../../services/conversation_services';
import { useAccount } from '../../../contexts/Account';
import eventBus, { eventBusValues } from '../../../eventBus';

const useStyles = ({ isSM, isXS }) => ({
  root: {
    backgroundColor: '#F9FBFF',
    color: 'black',
    boxShadow: 'none',
    borderBottom: '1px solid #EFEFEF',
    position: 'fixed',
    zIndex: '1',
    width: !isXS ? (isSM ? 'calc(100% - 300px)' : '700px') : '100%',
  },
  rootDrawer: {
    backgroundColor: '#F9FBFF',
    color: 'black',
    boxShadow: 'none',
    borderBottom: '1px solid #EFEFEF',
    position: 'fixed',
    width: isSM ? '90vw' : '60vw',
    zIndex: '1',
  },
  name: {
    fontSize: '16px',
    fontWeight: 700,
    marginLeft: '10px',
    fontFamily: 'Poppins',
  },
  position: {
    fontSize: '10px',
    fontWeight: 400,
    marginLeft: '10px',
    fontFamily: 'Poppins',
    color: '#232B35',
  },
  button: {
    width: '150px',
    borderRadius: '40px',
    backgroundColor: '#6736FA',
    textTransform: 'none',
    height: '40px',
    margin: '5px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#FFFFFF',
    padding: '5px',
    '&:hover': {
      backgroundColor: '#6736FA',
    },
  },
  iframeContainer: {
    position: 'relative',
    paddingBottom: '50%',
    height: '750px',
    width: '755px',
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    overflowX: 'visible',
  },
});

export default function Header({
  propConversationId,
  isDrawer,
  groupDates,
  setShowLeftMenu,
}) {
  const [conversationId, setConversationId] = useState('');
  const [sender, setSender] = useState({});
  const [senderName, setSenderName] = useState('');
  const [senderPosition, setSenderPosition] = useState('');
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isSM, isXS });
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const {
    accountDetails,
    asLoggedUserDetails,
    type,
    conversations,
  } = useAccount();
  const [inviteEnabled, setInviteEnabled] = useState(true);
  const [openMeetIFrame, setOpenMeetIFrame] = useState(false);
  const [meetUrl, setMeetUrl] = useState(false);
  const [isOnBoard, setIsOnBoard] = useState(false);

  function getMembers(conversationParam, senderParam) {
    if (conversationParam) {
      setConversationId(conversationParam);
    }
    const params = conversationParam
      ? {
          senderId: asLoggedUserDetails.userId,
          conversationId: conversationParam,
        }
      : {
          senderId: asLoggedUserDetails.userId,
          receiver: senderParam,
        };
    getConversationMembers(params)
      .then(({ data }) => {
        if (data.getConversationMembers) {
          const senderResponse = data.getConversationMembers;
          setSenderName(senderResponse.displayName);
          setSender(senderResponse);
          if (accountDetails.boardAdvisors) {
            const boardMember = accountDetails.boardAdvisors.some(
              (x) => x.id === senderResponse.id
            );
            const boardInvitation = accountDetails.invitedAdvisors.some(
              (x) => x.id === senderResponse.id
            );
            setInviteEnabled(!(boardMember || boardInvitation));
          }
          setSenderPosition(senderResponse.title || 'Advisor');
        }
      })
      .catch(() => {
        history.push('/messages');
      });
  }

  useEffect(() => {
    if (propConversationId) {
      getMembers(propConversationId);
    }
  }, [propConversationId]);

  function hideMenu() {
    if (propConversationId) {
      eventBus.dispatch(eventBusValues.triggerConversationDrawerV2, {
        close: true,
      });
    } else {
      eventBus.dispatch(eventBusValues.onHideMenu, {
        show: true,
      });
    }
  }

  function senderExistsInBoardAdvisors() {
    if (!accountDetails || !accountDetails.boardAdvisors) {
      return false;
    }
    return accountDetails.boardAdvisors.some(
      (advisor) => advisor.id === sender.id
    );
  }

  useEffect(() => {
    const result = senderExistsInBoardAdvisors();
    setIsOnBoard(result);
  }, [accountDetails, sender]);

  useEffect(() => {
    if (
      queryParams.get('senderId') &&
      queryParams.get('senderId') === asLoggedUserDetails.userId
    ) {
      history.push('/messages');
    } else if (
      queryParams.get('conversationId') ||
      queryParams.get('senderId')
    ) {
      getMembers(
        queryParams.get('conversationId'),
        queryParams.get('senderId')
      );
    }
    const hsubs = history.listen((locationParams) => {
      const newQueryParams = new URLSearchParams(locationParams.search);
      const conversationParam = newQueryParams.get('conversationId');
      const senderParam = newQueryParams.get('senderId');
      if (!conversationParam && !senderParam && !propConversationId) {
        setConversationId('');
        setSenderName('');
        setSenderPosition('');
        setSender({});
      }
      if (
        (conversationParam && conversationParam !== conversationId) ||
        senderParam
      ) {
        setSenderName('');
        setSenderPosition('');
        getMembers(conversationParam, senderParam);
      }
    });
    return () => {
      hsubs();
    };
  }, []);

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            ...(isDrawer ? classes.rootDrawer : classes.root),
            ...(type === 'company' ? { backgroundColor: '#FBFCFD' } : {}),
          }}
        >
          <Toolbar
            style={{
              paddingLeft: '20px',
              minHeight: '65px',
            }}
          >
            <div
              style={{
                flexGrow: 2,
                mr: 2,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {(isSM && conversations[0]) || isDrawer ? (
                <IconButton
                  color="primary"
                  aria-label="back"
                  onClick={() => hideMenu(true)}
                  component="label"
                >
                  <ArrowForwardIosOutlined />
                </IconButton>
              ) : null}
              <Typography
                onClick={() => {
                  if (sender.accountType === 'advisor') {
                    eventBus.dispatch(
                      eventBusValues.triggerAdvisorDrawerAction,
                      {
                        fetchAdvisor: true,
                        id: sender.id,
                        boardRoomView: true,
                      }
                    );
                  } else {
                    let companyIdToSend = null;
                    for (let item of groupDates) {
                      for (let message of item.messages) {
                        if (message.senderId === sender.id) {
                          companyIdToSend = message.profile.companyId;
                          break;
                        }
                      }

                      if (companyIdToSend) {
                        break;
                      }
                    }
                    if (companyIdToSend) {
                      eventBus.dispatch(
                        eventBusValues.triggerCompanyDrawerAction,
                        {
                          companyId: companyIdToSend,
                        }
                      );
                    }
                  }
                }}
                variant="h6"
                component="div"
                sx={{ ...classes.name, cursor: 'pointer' }}
              >
                {senderName}
              </Typography>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  ...classes.position,
                  display: { xxs: 'none', sm: 'block' },
                }}
              >
                {senderPosition}
              </Typography>
            </div>
            <Box sx={{ flexGrow: 1 }} />
            {isXS ? (
              <IconButton
                color="primary"
                aria-label="see conversations"
                onClick={() => {
                  setShowLeftMenu(true);
                }}
                component="label"
              >
                <GroupIcon />
              </IconButton>
            ) : null}
          </Toolbar>
        </AppBar>
      </Box>
      <Dialog
        open={openMeetIFrame}
        fullScreen={isSM}
        maxWidth="md"
        height="md"
        onClose={() => setOpenMeetIFrame(false)}
      >
        <div style={classes.iframeContainer}>
          <iframe
            src={meetUrl}
            title="Meet"
            frameBorder="0"
            style={classes.iframe}
          />
        </div>
      </Dialog>
    </>
  );
}
