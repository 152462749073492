import React, { useState } from 'react';
import { Box, Dialog, DialogContent, useMediaQuery } from '@mui/material';
import { Clear } from '@mui/icons-material';
import LoadingIndicatorLogo from './LoadingIndicatorLogo';

const useStyles = ({ isSM, fullSize, width, height }) => {
  const containerHeight = height || (fullSize ? '99%' : '650px');
  const containerWidth =
    width || (isSM ? '100%' : fullSize ? '800px' : '710px');
  const contentHeight = height || (fullSize ? '750px' : '650px');
  const contentWidth = isSM ? '100%' : width || (fullSize ? '800px' : '710px');
  const clearSendWidth = isSM ? '100%' : '710px';

  return {
    container: {
      height: containerHeight,
      overflow: 'hidden',
      width: containerWidth,
    },
    contentNoBorder: {
      height: contentHeight,
      overflow: 'hidden',
      width: contentWidth,
      padding: '0px',
    },
    clearSend: {
      marginRight: '0px',
      width: clearSendWidth,
    },
    loadingIndicatorPosition: {
      alignItems: 'center',
      bottom: '0px',
      display: 'flex',
      justifyContent: 'center',
      left: '0px',
      position: 'fixed',
      right: '0px',
      top: '0px',
    },
  };
};

export default function IframeEmbedModal({
  openModal,
  setOpenModal,
  iframeEmbedLink,
  fullSize,
  width,
  height,
  backgroundColor = 'white',
}) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(true);
  const classes = useStyles({ isSM, fullSize, width, height });
  function handleClose() {
    setOpenModal(false);
  }

  function handlePersonalizedClose() {
    setOpenModal(false);
  }

  function onLoad() {
    setLoading(false);
  }

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      maxWidth="sm"
      fullScreen={isSM}
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          boxSizing: 'border-box',
          borderRadius: isSM ? 0 : '15px',
          maxWidth: width || fullSize ? '800px' : '710px',
        },
      }}
    >
      {isSM ? (
        <div style={{ ...classes.clearSend, backgroundColor: backgroundColor }}>
          <Clear
            onClick={handlePersonalizedClose}
            sx={{ float: 'right', cursor: 'pointer' }}
          />
        </div>
      ) : null}
      <DialogContent sx={classes.contentNoBorder}>
        <Box sx={classes.container}>
          {loading ? (
            <div>
              <div style={classes.loadingIndicatorPosition}>
                <LoadingIndicatorLogo
                  size={200}
                  iconFontSize={93}
                  iconRight={105}
                />
              </div>
            </div>
          ) : null}
          <iframe
            style={
              loading
                ? { display: 'none' }
                : { border: 'none', overflow: 'hidden' }
            }
            src={iframeEmbedLink}
            onLoad={onLoad}
            title="demoVideo"
            allow="autoplay; fullscreen; picture-in-picture"
            width="100%"
            height="100%"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
