import React from 'react';
import { Paper, Box, Typography, Button, Avatar } from '@mui/material';
import { useHistory } from 'react-router-dom';
import union_left from '../icons/union_left.svg';
import union_right from '../icons/union_right.svg';
import add_boards from '../icons/add_boards.svg';
import dateFormat from '../utils/dateFormat';

const BoardCard = (props) => {
  const {
    board,
    hideMemberSince,
    hideHeadline,
    bigCompanyName,
    companyList,
    onClick,
  } = props;
  const history = useHistory();
  const dateObj = board.createdAt ? new Date(board.createdAt) : new Date();
  const year = dateFormat(dateObj, 'yyyy');
  const haveImage =
    (board.companyLogo && !board.companyLogo.includes('undefined')) ||
    board.image?.location;

  return (
    <Paper
      className={
        board.createdAt
          ? `my-board-${board.boardType === 'P2P' ? 'p2p' : 'card'}`
          : 'view-boardrooms'
      }
      elevation={1}
      sx={{
        height: !board.createdAt ? 'auto' : '265px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: companyList ? '0px 16px 16px' : '16px',
        borderRadius: '12px',
        textAlign: 'center',
        boxShadow: '0px 10px 12px 0px #0010300D',
        width: '100%',
      }}
    >
      <div
        style={{
          width: 'calc(100% + 32px)',
          left: '-16px',
          height: '56px',
          background: 'linear-gradient(#2C71F633, #25A4FF33)',
          borderRadius: '8px 8px 0px 0px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <img
          src={union_left}
          alt="left"
          style={{ position: 'absolute', zIndex: 1, left: 0, marginTop: 25 }}
        />
        <img
          src={union_right}
          alt="right"
          style={{ position: 'absolute', zIndex: 1, right: 0 }}
        />
        <Box
          sx={{
            zIndex: 2,
            position: 'absolute',
            marginTop: '26px',
            height: 72,
            width: 72,
            backgroundColor: 'white',
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Avatar
            src={
              haveImage
                ? `https://${board.companyLogo || board.image?.location}`
                : add_boards
            }
            sx={{
              height: haveImage ? '100%' : 60,
              width: haveImage ? '100%' : 60,
              borderRadius: '8px',
            }}
          />
        </Box>
      </div>
      <Box sx={{ marginTop: '38px' }}>
        <Typography
          sx={{
            fontSize: bigCompanyName ? '24px' : '16px',
            fontWeight: '600',
            marginBottom: !board.createdAt ? '10px' : '0',
            textOverflow: bigCompanyName ? null : 'ellipsis',
            overflow: bigCompanyName ? null : 'hidden',
            whiteSpace: bigCompanyName ? null : 'nowrap',
          }}
        >
          {board.companyName}
        </Typography>

        {!hideMemberSince && board.createdAt ? (
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '400',
              color: '#4D586F',
              marginBottom: '5px',
            }}
          >
            Board Member Since: {year}
          </Typography>
        ) : null}

        {board.headline && !hideHeadline ? (
          <Typography
            sx={{
              fontSize: '12px',
              color: '#4D586F',
              fontWeight: '400',
              lineHeight: '18px',
              marginBottom: '6px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
              whiteSpace: 'normal',
            }}
          >
            {board.headline}
          </Typography>
        ) : null}
      </Box>

      <Button
        variant="outlined"
        className={`${companyList ? 'view-company-button' : ''}`}
        onClick={() => {
          if (companyList && onClick) {
            onClick();
          } else if (board.createdAt) {
            history.push(`/${board.slug}/boardroom`);
          } else {
            history.push(`/board-directory`);
          }
        }}
        sx={{
          textTransform: 'none',
          fontSize: '14px',
          padding: '6px 12px',
          borderRadius: 2,
          borderColor: 'hsla(219, 92%, 57%, 1)',
          color: 'hsla(219, 92%, 57%, 1)',
          borderWidth: '1px',
          backgroundColor: 'white',
        }}
      >
        {board.createdAt ? 'View' : 'View Boards'}
      </Button>
    </Paper>
  );
};

export default BoardCard;
