import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import CustomIcon from './../../../icons/customIcon';
import ACButton from './../../ACButton';

const useStyles = () => ({
  buttonText: {
    fontWeight: 'bold',
  },
  iconStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    marginRight: '7px',
  },
  buttonContentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
  },
});

function CompanyPostButton(props) {
  const {
    type,
    onClick,
    buttonColor,
    buttonSize,
    buttonVariant,
    loading,
    isDisabled,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const Question = () => {
    return (
      <div style={classes.buttonContentContainer}>
        <CustomIcon style={classes.iconStyle} iconname="questions" />
        <Typography variant="old_font" sx={classes.buttonText}>
          Post Question
        </Typography>
      </div>
    );
  };

  const Update = () => {
    return (
      <div style={classes.buttonContentContainer}>
        <CustomIcon style={classes.iconStyle} iconname="updates" />
        <Typography variant="old_font" sx={classes.buttonText}>
          Post Update
        </Typography>
      </div>
    );
  };

  function renderContents() {
    if (type === 'question') {
      return <Question />;
    }
    if (type === 'update') {
      return <Update />;
    }
    return null;
  }

  return (
    <ACButton
      color={buttonColor}
      size={buttonSize}
      variant={buttonVariant}
      style={{ borderWidth: 2, display: 'flex' }}
      onClick={() => onClick()}
      loading={loading ? 1 : 0}
      disabled={isDisabled}
    >
      {renderContents()}
    </ACButton>
  );
}

export default CompanyPostButton;
