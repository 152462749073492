import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Fade,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useAccount } from './../../../../contexts/Account';
import IframeEmbedModal from './../../../IframeEmbedModal';
import { AccountType } from './../../../../services/utils/types';
import CustomIcon from './../../../../icons/customIcon';

const useStyles = ({ isSM }) => ({
  mainActions: {
    color: '#ffffff',
    backgroundColor: '#3171F6',
    '&:hover': {
      backgroundColor: '#3171F6',
    },
    borderRadius: '40px',
    width: isSM ? '80%' : '148px',
    height: '48.38px',
    textTransform: 'none',
    fontWeight: 700,
    fontSize: '15px',
    fontStyle: 'normal',
    letterSpacing: -0.43,
    padding: '6px 15px 6px 15px',
  },
  actionsMenu: {
    width: isSM ? '80%' : undefined,
    borderRadius: '12px',
    border: '1px solid var(--Gray-5, #F5F7FA)',
    boxShadow: '0px 1px 10px 0px rgba(230, 237, 254, 0.25)',
  },
});

export default function ActionButton(props) {
  const [openIframe, setOpenIframe] = useState(false);
  const { type, isFreemium } = useAccount();

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const {
    isPublicProfileView,
    isOnBoard,
    onInvite,
    onConversation,
    isMobilePublicProfile,
    splitButton,
    showInactiveAdvisorBar,
  } = props;
  const classes = useStyles({ isSM: isMobilePublicProfile });
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [iFrameSource, setIframeSource] = useState('');
  const [iFrameFullSize, setIframFullSize] = useState(false);

  function registerAction() {
    setIframeSource('https://register.advisorycloud.com/directory/b2b-step-1/');
    setIframFullSize(true);
    setOpenIframe(true);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function renderContentMenu() {
    return (
      <React.Fragment>
        {(!isFreemium && !isOnBoard && type === AccountType.COMPANY) ||
        (isPublicProfileView && !isFreemium) ? (
          <MenuItem
            onClick={isPublicProfileView ? registerAction : onInvite}
            id="AdvisorProfileInviteAction"
          >
            <CustomIcon
              iconname="invite-join"
              sx={{
                fontSize: '12px',
                marginBottom: '5px',
              }}
            />
            <Typography style={{ marginLeft: '15px' }}>
              Invite to Board
            </Typography>
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            handleClose();
            onConversation();
          }}
          id="AdvisorProfileConversationAction"
        >
          <CustomIcon
            iconname={'airplane'}
            sx={{
              fontSize: '14px',
              marginBottom: '5px',
            }}
          />
          <Typography style={{ marginLeft: '12px' }}>Send Message</Typography>
        </MenuItem>
        {(isFreemium && !isOnBoard && type === AccountType.COMPANY) ||
        (isPublicProfileView && isFreemium) ? (
          <MenuItem
            onClick={isPublicProfileView ? registerAction : onInvite}
            id="AdvisorProfileInviteAction"
          >
            <CustomIcon
              iconname="invite-join"
              sx={{
                fontSize: '12px',
                marginBottom: '5px',
              }}
            />
            <Typography style={{ marginLeft: '15px' }}>Invite</Typography>
          </MenuItem>
        ) : null}
      </React.Fragment>
    );
  }

  function splitButtonMenu() {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        {!isOnBoard && type === AccountType.COMPANY && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={isPublicProfileView ? registerAction : onInvite}
          >
            <CustomIcon
              iconname="invite-join"
              sx={{
                fontSize: '14px',
                marginBottom: '5px',
              }}
            />
            <Typography
              style={{
                marginLeft: '15px',
                fontWeight: 500,
                fontSize: '14px',
              }}
            >
              Invite
            </Typography>
          </div>
        )}
      </Box>
    );
  }

  return (
    <>
      {splitButton && !isSM ? (
        <div>{splitButtonMenu()}</div>
      ) : (
        <>
          <Button
            onClick={handleClick}
            sx={{
              ...classes.mainActions,
              ...(showInactiveAdvisorBar
                ? { backgroundColor: '#EFF0F6', color: '#232b35' }
                : {}),
            }}
            id="AdvisorProfileActions"
            disabled={showInactiveAdvisorBar}
          >
            Connect
            <CustomIcon
              iconname="arrow-down"
              sx={{
                fontSize: '6px',
                marginBottom: '5px',
                marginLeft: '15px',
              }}
            />
          </Button>
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={openMenu}
            onClose={handleClose}
            TransitionComponent={Fade}
            sx={{ '& .MuiMenu-paper': { ...classes.actionsMenu } }}
            getcontentanchorel={null}
            anchorOrigin={
              isMobilePublicProfile
                ? undefined
                : {
                    vertical: 50,
                    horizontal: 'right',
                  }
            }
            transformOrigin={
              isMobilePublicProfile
                ? undefined
                : {
                    vertical: 'top',
                    horizontal: 'right',
                  }
            }
          >
            <div>{renderContentMenu()}</div>
          </Menu>
        </>
      )}
      <IframeEmbedModal
        openModal={openIframe}
        setOpenModal={setOpenIframe}
        iframeEmbedLink={iFrameSource}
        fullSize={iFrameFullSize}
      />
    </>
  );
}
