import React from 'react';
import PostResponseReply from './Post/ResponseReply';

function FilteredFeed(props) {
  const {
    endpointResponse,
    contentProps,
    incrementOffset,
    isMainCallFetching,
    doNotShowBackClick,
    addPostLike,
    scrollOnFirstLoad,
    loading,
  } = props;

  function isMultiplePosts() {
    if (
      contentProps.asyncName === 'getPosts' ||
      contentProps.asyncName === 'getResponsesByAdvisor' ||
      contentProps.asyncName === 'getBoardMeetings'
    ) {
      return true;
    }
    return false;
  }

  function isNoUrlChangeProp() {
    if (contentProps.noUrlChange === null || undefined) {
      return false;
    }
    return contentProps.noUrlChange;
  }

  return (
    <PostResponseReply
      gotPostList={endpointResponse}
      multiplePosts={isMultiplePosts()}
      noUrlChange={isNoUrlChangeProp()}
      isMainCallLoading={isMainCallFetching}
      incrementOffset={incrementOffset}
      doNotShowBackClick={doNotShowBackClick}
      addPostLike={addPostLike}
      scrollOnFirstLoad={scrollOnFirstLoad || false}
      endpointLoading={loading}
    />
  );
}

export default FilteredFeed;
