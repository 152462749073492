import React from 'react';
import CompanyList from './CompanyList';

function AdvisorSearchCompanies(props) {
  const {
    endpointResponse,
    isMainCallFetching,
    incrementOffset,
    loading,
    emptySearchParams,
    lastFetch,
  } = props;

  function companyListGrid() {
    return (
      <CompanyList
        companiesList={endpointResponse}
        removeBelowButtons
        isMainCallLoading={isMainCallFetching}
        incrementOffset={incrementOffset}
        isCompanyCard
        isBoardSekeleton
        emptySearchParams={emptySearchParams}
        buildBoardFlagOn
        lastFetch={lastFetch}
      />
    );
  }

  return (
    <>{endpointResponse.length > 0 && !loading ? companyListGrid() : null}</>
  );
}

export default AdvisorSearchCompanies;
