import React from 'react';
import { useMediaQuery } from '@mui/material';
import PostHeaderAvatar from './../Card/PostCardHeader/PostHeaderAvatar';
import MoreMenu from './MoreMenu';
const useStyles = () => ({
  answerHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  rightGroup: {
    display: 'flex',
    width: 'fit-content',
    height: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
  },
  answerAvatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins',
  },
});

function AnswerCardHeader({
  requestMeetingButton,
  headerName,
  answerAvatar,
  subtitle,
  isOwner,
  onEditClick,
  isStar,
  isStarFilled,
  starClicked,
  answerUrl,
  avatarClick,
}) {
  const classes = useStyles();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <div style={classes.answerHeaderContainer}>
      <div style={classes.answerAvatarContainer}>
        <PostHeaderAvatar
          titleName={headerName}
          image={answerAvatar}
          subtitle={subtitle}
          fontSize={isSM ? 'xs' : 'sm'}
          avatarSize={isSM ? 'sm' : 'compact'}
          isStar={isStar}
          isStarFilled={isStarFilled}
          starClicked={starClicked}
          avatarClick={avatarClick}
        />
      </div>
      {requestMeetingButton ? (
        <div style={classes.rightGroup}>Request Meeting Button</div>
      ) : null}
      <MoreMenu
        editClick={onEditClick}
        deleteClick={console.log}
        answerUrl={answerUrl}
        isOwner={isOwner}
      />
    </div>
  );
}

export default AnswerCardHeader;
