export const eventBusValues = {
  boardroomChangeSection: 'boardroomChangeSection',
  triggerAdvisorDrawerAction: 'triggerAdvisorDrawerAction',
  triggerCompanyDrawerAction: 'triggerCompanyDrawerAction',
  closeAdvisorDrawer: 'closeAdvisorDrawer',
  triggerCloseBlocker: 'triggerCloseBlocker',
  drawerTryToCloseOnEdit: 'drawerTryToCloseOnEdit',
  mobileMenuTrigger: 'mobileMenuTrigger',
  mobileCompanyMenuTrigger: 'mobileCompanyMenuTrigger',
  advisorRequestedToJoinCompany: 'advisorRequestedToJoinCompany',
  advisorAnsweredInvitation: 'advisorAnsweredInvitation',
  advisorAnsweredInvitationV2: 'advisorAnsweredInvitationV2',
  triggerAdvisorUpgradeAction: 'triggerAdvisorUpgradeAction',
  triggerCompanyUpgradeAction: 'triggerAdvisorUpgradeAction',
  onAddResponse: 'onAddResponse',
  onAddReply: 'onAddReply',
  onLikePost: 'onLikePost',
  onLikeResponse: 'onLikeResponse',
  onLikeReply: 'onLikeReply',
  openRequestMeetingIframe: 'openRequestMeetingIframe',
  onNewMessage: 'onNewMessage',
  onSendNewMessage: 'onSendNewMessage',
  onNewConversation: 'onNewConversation',
  onReadConversation: 'onReadConversation',
  onSendConversationNewMessage: 'onSendConversationNewMessage',
  onInviteToJoinCompany: 'onInviteToJoinCompany',
  onInviteToJoinCompanyProfile: 'onInviteToJoinCompanyProfile',
  onHideMenu: 'onHideMenu',
  onCompanyStarClick: 'onCompanyStarClick',
  triggerConversationDrawer: 'triggerConversationDrawer',
  triggerConversationDrawerV2: 'triggerConversationDrawerV2',
  openCreatePost: 'openCreatePost',
  closeCreatePost: 'closeCreatePost',
  triggerUpgradeFeaturedAdvisorAction: 'triggerUpgradeFeaturedAdvisorAction',
  triggerInviteTeamModal: 'triggerInviteTeamModal',
  triggerMeetAdvisors: 'triggerMeetAdvisors',
  triggerInviteContactsModal: 'triggerInviteContactsModal',
  advisorSaved: 'advisorSaved',
  advisorUnsaveSB: 'advisorUnsaveSB',
};

const eventBus = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};

export default eventBus;
