import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomIcon from './../../../icons/customIcon';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { uploadToS3 } from './../../../services/Amplify/amplify-files-upload';
import ACSnackbar from './../../ACSnackbar';
import ACButton from './../../ACButton';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import 'filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageResize,
  FilePondPluginImageTransform,
  FilePondPluginFileEncode
);

const filePondCompanyOptions = {
  dropOnPage: 'true',
  allowDrop: 'true',
  allowRemove: 'true',
  allowProcess: 'true',
  allowBrowse: 'true',
  allowFileSizeValidation: 'true',
  allowFileTypeValidation: 'true',
  allowImageExifOrientation: 'true',
  allowImagePreview: 'false',
  maxFileSize: '15MB',
  imageResizeTargetHeight: '310',
  imageTransformVariantsIncludeOriginal: 'true',
  credits: 'false',
  acceptedFileTypes: `${[
    'image/jpg',
    'image/jpeg',
    'image/png',
    'application/pdf',
  ]}`,
  imageTransformOutputMimeType: 'image/png',
};

const useStyles = makeStyles(() => ({
  uploader: {
    height: '100%',
    width: '100%',
    '& .filepond--file': {
      display: 'none',
    },
    '& .filepond--drop-label': {
      display: 'none',
      backgroundColor: 'transparent',
      zIndex: 999,
    },
    '& .filepond--panel': {
      backgroundColor: 'transparent',
      display: 'none',
    },
  },
}));

const getStyles = (uploadError) => ({
  iconStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '25px',
    width: 'auto',
    height: 'auto',
    color: '#767680',
  },
  iconErrorStyle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '15px',
    color: '#ffffff',
    width: 'auto',
    height: 'auto',
    marginRight: '7px',
  },
  iconRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    minHeight: '70px',
    outline: 'none',
    border: uploadError ? 'none' : '1px dashed grey',
    borderRadius: '10px',
    backgroundColor: uploadError ? '#E57373' : '#F0F2F7',
    position: 'relative',
  },
  errorMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  defaultMessageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  errorRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '7px',
  },
  errorLabel: {
    fontSize: '0.7rem',
    color: '#ffffff',
  },
  defaultLabel: {
    fontSize: '0.7rem',
    color: 'secondary.dark',
    textAlign: 'center',
  },
  browse: {
    color: '#0F78FD',
  },
});

function FileUploader(props) {
  const { resultFiles } = props;
  const { t } = useTranslation();
  const [uploadError, setUploadError] = useState(false);
  const [fileUploadError, setFileUploadError] = useState({});
  const classes = useStyles();
  const styles = getStyles(uploadError);
  const ref = React.useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [attachmentThumbnailBlob, setAttachmentThumbnailBlob] = useState(null);
  const [attachmentOriginalFile, setAttachmentOriginalFile] = useState(null);

  function getEncodedFileFormat(encodedFile) {
    const format = encodedFile.split('/')[1];
    return format;
  }

  async function catchOutputFiles(error) {
    setIsLoading(true);
    try {
      if (error === null) {
        let attachmentID = '';
        let attachmentURL = '';
        const logoId = uuidv4();
        const format = getEncodedFileFormat(attachmentOriginalFile.type);
        attachmentID = `${logoId}.${format}`;
        attachmentURL = `https://${process.env.REACT_APP_S3_BUCKET_ATTACHMENTS_ID}.s3.us-west-2.amazonaws.com/public/${attachmentID}`;
        await uploadToS3({
          BLOB_LOAD: attachmentOriginalFile,
          CONTENT_TYPE: attachmentOriginalFile.type,
          FILE_ID: attachmentID,
          S3_BUCKET: process.env.REACT_APP_S3_BUCKET_ATTACHMENTS_ID,
        });
        if (attachmentThumbnailBlob) {
          await uploadToS3({
            BLOB_LOAD: attachmentThumbnailBlob,
            CONTENT_TYPE: 'image/png',
            FILE_ID: `${attachmentID.split('.')[0]}.png`,
            S3_BUCKET:
              process.env.REACT_APP_S3_BUCKET_THUMBNAILS_ATTACHMENTS_ID,
          });
        }
        resultFiles({
          id: attachmentID,
          url: attachmentURL,
          name: attachmentOriginalFile.name,
        });

        setIsLoading(false);
        setUploadError(false);
      } else {
        console.log('3', error);
        setFileUploadError(error);
        setIsLoading(false);
        setUploadError(true);
      }
    } catch (err) {
      if (err.errors && err.errors[0]) {
        const errJSON = JSON.parse(err.errors[0].message);

        if (errJSON.error && errJSON.error.code === 'InfectedFileError') {
          setSnackbarText(t('POST_UPLOADER_INFECTED_FILE_ERROR'));
        } else {
          setSnackbarText(t('POST_UPLOADER_ERROR'));
        }
      } else {
        setSnackbarText(t('POST_UPLOADER_ERROR'));
      }

      setUploadError(false);
      setIsLoading(false);
      setShowSnackbar(true);
    }
  }

  const ErrorMessageDisplay = ({ error }) => {
    return (
      <div style={styles.errorMessageContainer}>
        <div style={styles.errorRow}>
          {isLoading ? (
            <ACButton
              color="secondary"
              size="small"
              style={{
                marginLeft: '-30px',
                height: '20px',
                outline: 'none',
                backgroundColor: 'transparent',
                boxShadow: 'none',
                position: 'relative',
              }}
              loading={1}
              disabled
            />
          ) : (
            <CustomIcon style={styles.iconErrorStyle} iconname="cancel-outlined" />
          )}
          <Typography variant="old_font" sx={styles.errorLabel}>
            {t('POST_UPLOADER_ERROR')}
          </Typography>
        </div>
        {error === 'File is of invalid type' ? (
          <Typography variant="old_font" sx={styles.errorLabel}>
            {t('POST_UPLOADER_INVALID_TYPE')}
          </Typography>
        ) : (
          <Typography variant="old_font" sx={styles.errorLabel}>
            {t('POST_UPLOADER_MAX_SIZE')}
          </Typography>
        )}
      </div>
    );
  };

  const DefaultMessageDisplay = () => {
    return (
      <div style={styles.defaultMessageContainer}>
        {isLoading ? (
          <ACButton
            style={{
              width: '5px',
              height: '25px',
              outline: 'none',
              backgroundColor: 'transparent',
              boxShadow: 'none',
              position: 'relative',
            }}
            loading={1}
            disabled
          />
        ) : (
          <CustomIcon style={styles.iconStyle} iconname="upload-cloud-outlined" />
        )}
        <Typography variant="old_font" sx={styles.defaultLabel}>
          {t('POST_UPLOADER_DEFUALT')}
          <span style={styles.browse}>{t('POST_UPLOADER_BROWSE')}</span>
        </Typography>

        <Typography variant="old_font" sx={styles.defaultLabel}>
          {t('POST_UPLOADER_MAX_SIZE')}
        </Typography>
      </div>
    );
  };

  function displayMessage() {
    if (uploadError) {
      return <ErrorMessageDisplay error={fileUploadError.main} />;
    }
    return <DefaultMessageDisplay />;
  }

  function browse() {
    ref.current.browse();
  }

  return (
    <>
      <ACSnackbar
        open={showSnackbar}
        text={snackbarText}
        severity="error"
        onClose={() => setShowSnackbar(false)}
        autoHideDuration={6000}
      />
      <div
        role="button"
        tabIndex={0}
        disabled={isLoading ? 1 : 0}
        onClick={browse}
        style={{
          ...styles.iconRow,
          opacity: isLoading ? 0.5 : 1,
          zIndex: 1,
          cursor: 'pointer',
        }}
      >
        {displayMessage()}
        <FilePond
          {...filePondCompanyOptions}
          ref={ref}
          labelIdle=""
          className={classes.uploader}
          onaddfile={(error) => {
            catchOutputFiles(error);
          }}
          onpreparefile={(files, output) => {
            if (output.type === 'application/pdf') {
              setAttachmentOriginalFile(output);
              setAttachmentThumbnailBlob(null);
            } else {
              setAttachmentOriginalFile(output[0].file);
              setAttachmentThumbnailBlob(output[1].file);
            }
          }}
        />
      </div>
    </>
  );
}

export default FileUploader;
