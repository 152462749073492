import React, { useState, useEffect, useRef } from 'react';
import { companyFetchPostsCall } from '../../services/company_services';
import {
  AccountType,
  PostType,
  EndpointParameterFilters,
} from '../../services/utils/types';
import { useAccount } from './../../contexts/Account';
import BoardMeetingCard from '../../components/BoardMeetingsCard';
import EmptyState from '../BoardMeetings/EmptyState';
import { Typography, Box } from '@mui/material';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';

const Meetings = React.memo(function Meetings({
  company,
  postContainerRef,
  scrollOnFirstLoad,
}) {
  const { accountDetails } = useAccount();
  const [items, setItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBoardMeetings, setLoadingBoardMeetings] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;
  const containerRef = useRef(null);

  const fetchItems = async () => {
    if (isLoadingBoardMeetings || !hasMore) return;
    setLoadingBoardMeetings(true);
    try {
      const response = await companyFetchPostsCall({
        ACCOUNT_TYPE: AccountType.ADVISOR,
        ADVISOR_ID: accountDetails.id,
        COMPANY_ID: company.id,
        LIMIT: limit,
        OFFSET: offset,
        ORDER_BY: 'createdAt',
        REVERSE: false,
        TYPE: PostType.BOARD_MEETINGS,
      });
      setItems((prev) => [...prev, ...response.data.getPosts]);
      setHasMore(response.data.getPosts.length > 0);
      setOffset((prev) => prev + limit);
      if(offset === 0 && scrollOnFirstLoad) {
        postContainerRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.error(error);
    }
    setLoadingBoardMeetings(false);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const clientHeight = window.innerHeight;
      const scrollHeight = document.documentElement.scrollHeight;
      
      if (
        scrollTop + clientHeight >= scrollHeight - 100 &&
        hasMore &&
        !isLoadingBoardMeetings
      ) {
        fetchItems();
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hasMore, isLoadingBoardMeetings]);

  return (
    <Box
      ref={containerRef}
      sx={{
        minHeight: 'calc(100vh - 50px)',
        overflowY: 'auto',
        backgroundColor: '#f4f8ff',
      }}
    >
      {items.map((item) => (
        <BoardMeetingCard
          key={item.id}
          boardMeeting={item}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          company={company}
        />
      ))}
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
        </Box>
      )}
      {isLoadingBoardMeetings && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '180px',
          }}
        >
          <LoadingIndicatorLogo size={100} iconFontSize={40} iconRight={46} />
        </Box>
      )}
      {!hasMore && !items.length && <EmptyState />}
      {/* {!hasMore && <p>No more boardMeetings</p>} */}
    </Box>
  );
});

export default Meetings;
