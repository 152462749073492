import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Paper, Divider, Badge } from '@mui/material';
import {
  Launch,
  FavoriteBorder,
  TrendingUp,
  UpgradeOutlined,
  PersonAddAlt,
} from '@mui/icons-material';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { useAccount } from './../../../contexts/Account';
import eventBus, { eventBusValues } from './../../../eventBus';

function getTopValue({ isFreeTrial, showSmallBanner, isFreemium, isV2 }) {
  if (isV2) {
    if (isFreemium) return '0px';
    if (isFreeTrial) return '55px';
    if (showSmallBanner) return '30px';
  }
  if (isFreemium) return '0px';
  if (isFreeTrial) return '95px';
  if (showSmallBanner) return '70px';
  return '0px';
}

const useStyles = ({ isFreeTrial, isFreemium, showSmallBanner, isV2 }) => ({
  container: {
    width: '360px',
    height: isV2 ? '300px' : '340px',
    backgroundColor: 'transparent',
    position: 'absolute',
    zIndex: 2,
    top: getTopValue({ isFreeTrial, isFreemium, showSmallBanner, isV2 }),
    right: '-10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuItemsContainer: {
    width: '310px',
    backgroundColor: 'white',
    marginTop: isFreeTrial || showSmallBanner ? '25px' : '65px',
    border: '1px solid #F5F7FA',
    boxShadow: '0px 1px 10px rgba(230, 237, 254, 0.25)',
    borderRadius: '12px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
  },
  itemIcon: {
    marginRight: '17.5px',
    fontSize: '23px',
  },
  menuItemRed: {
    width: '300px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    paddingRight: '20px',
    paddingLeft: '20px',
    color: '#646D7A',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingBottom: '4px',
    paddingTop: '6px',
    marginRight: '-20px',
    marginLeft: '-20px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  menuItemLaunch: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#232B35',
    border: 'none',
    backgroundColor: '#D7F1FD',
    cursor: 'pointer',
    margin: '4px 10px 4px -10px',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.25px',
    borderRadius: '12px',
    padding: '10px',
    height: '50px',
    textAlign: 'left',
    '&:hover': {
      backgroundColor: '#D7F1FD',
      boxShadow: 'none',
    },
  },
  menuItemLogout: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px',
    paddingRight: '20px',
    paddingLeft: '20px',
    color: '#646D7A',
    border: 'none',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    paddingBottom: '4px',
    paddingTop: '6px',
    marginRight: '-20px',
    marginLeft: '-20px',
    width: '260px',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '32px',
    letterSpacing: '0.75px',
    '&:hover': {
      color: '#232B35',
    },
  },
  divider: {
    width: '90%',
    marginTop: '10px',
    marginBottom: '10px',
    color: '#F5F7FA',
  },
});

function Menu({ isV2, navBarRef }) {
  const { accountDetails, signOut, isFreeTrial, isFreemium, conversations } =
    useAccount();
  const menuRef = useRef();
  const showExFremiumBanner =
    accountDetails.freemiumRegistration &&
    accountDetails.opportunityStage === 'discovery' &&
    !isFreemium;
  const showOnboardingBanner =
    accountDetails.opportunityStage === 'discovery' &&
    accountDetails.onboardingStatus !== 'Not Requisite' &&
    accountDetails.onboardingStatus !== 'Complete' &&
    !accountDetails.freemiumRegistration;
  const classes = useStyles({
    isFreeTrial,
    showSmallBanner: showExFremiumBanner || showOnboardingBanner,
    isFreemium,
    isV2,
  });

  function getUnreadMessages() {
    let totalUnread = 0;
    conversations.forEach((conv) => {
      totalUnread += conv.unreadCount;
    });
    return totalUnread;
  }

  const history = useHistory();
  const { t } = useTranslation();

  function handleMenuItem(url, newTab = false) {
    if (newTab) {
      window.open(url, '_blank');
    } else {
      window.location.replace(url);
    }
  }

  async function onLogoutClick() {
    await signOut();
  }

  function navigateInApp(path) {
    history.push(path);
  }

  function dispatchEvent() {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      company: accountDetails,
    });
  }

  function openUpgradeModal() {
    eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {
      action: () => {},
    });
  }

  useEffect(() => {
    if (navBarRef && navBarRef.current) {
      try {
        // sets the menu items under the nav bar button
        const navBarNode = ReactDOM.findDOMNode(navBarRef.current);
        const menuNode = ReactDOM.findDOMNode(menuRef.current);
        const navBarRect = navBarNode.getBoundingClientRect();
        let itemsSubstract = 84;
        if (isV2) itemsSubstract -= 21;
        if (isFreemium) itemsSubstract -= 21;

        menuNode.style.top = `${navBarRect.height - itemsSubstract}px`;
      } catch (error) {
        console.log(error);
      }
    }
  }, [navBarRef]);

  function getOptions() {
    return (
      <div style={classes.menuItemsContainer}>
        <button
          style={classes.menuItemRed}
          onClick={dispatchEvent}
          type="button"
          id="navatarViewProfile"
        >
          <Launch sx={classes.itemIcon} />
          View Profile
        </button>
        {isFreemium && (
          <button
            style={classes.menuItemRed}
            onClick={openUpgradeModal}
            type="button"
            id="navatarUpgrade"
          >
            <UpgradeOutlined sx={classes.itemIcon} />
            View Upgrade Options
          </button>
        )}
        {accountDetails.contacts[0].upgradeUrl && (
          <button
            style={classes.menuItemRed}
            onClick={() =>
              handleMenuItem(
                `${accountDetails.contacts[0].upgradeUrl}?iwm_advisor_id=${accountDetails.contacts[0].iwmId}&sosfcontactid=${accountDetails.contacts[0].salesforceContactId}&email=${accountDetails.contacts[0].email}&plancode=${accountDetails.contacts[0].recurlyPlanCode}`,
                true
              )
            }
            type="submit"
          >
            <TrendingUp sx={classes.itemIcon} />
            Upgrade Membership
          </button>
        )}
        <button
          id="navatarActSettings"
          style={classes.menuItemRed}
          onClick={() => navigateInApp('/account')}
          type="submit"
        >
          <FavoriteBorder sx={classes.itemIcon} />
          Account Settings
        </button>
        {isV2 && (
          <>
            <button
              style={classes.menuItemRed}
              onClick={() =>
                window.location.assign(
                  'https://register.advisorycloud.com/bab/personal-assist/'
                )
              }
              type="button"
              id="navatarViewProfile"
            >
              <PersonAddAlt sx={classes.itemIcon} />
              Personalized Advisor Sourcing
            </button>
            {conversations && conversations.length > 0 && (
              <Badge
                color="error"
                sx={{
                  '& .MuiBadge-badge': {
                    top: '20%',
                    right: '50%',
                    transform: 'translate(50%, -50%)',
                  },
                }}
                badgeContent={getUnreadMessages()}
              >
                <button
                  style={classes.menuItemRed}
                  onClick={() => {
                    eventBus.dispatch(
                      eventBusValues.triggerConversationDrawerV2,
                      {}
                    );
                  }}
                  type="button"
                  id="navatarMessage"
                >
                  <MessageOutlinedIcon sx={classes.itemIcon} />
                  Messages
                </button>
              </Badge>
            )}
          </>
        )}

        <Divider sx={classes.divider} />

        <button
          id="navatarLogout"
          style={classes.menuItemLogout}
          type="submit"
          onClick={onLogoutClick}
        >
          Logout
        </button>
      </div>
    );
  }

  return (
    <Paper sx={classes.container} elevation={0} ref={menuRef}>
      {getOptions()}
    </Paper>
  );
}
export default Menu;
