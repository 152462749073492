import React, { useState, useRef } from 'react';
import {
  CircularProgress,
  Paper,
  InputBase,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { Send } from '@mui/icons-material';

const useStyles = ({isSM, isXS}) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: !isXS ? (isSM ? 'calc(100% - 300px)' : '700px') : '100%',
    position: 'fixed',
    bottom: '0px',
    borderTop: '2px solid #efefef',
  },
  rootisDrawer: {
    display: 'flex',
    bottom: '0px',
    padding: '2px 4px',
    alignItems: 'flex-start',
    width: '100%',
    borderTop: '2px solid #efefef',
  },
  input: {
    marginLeft: 'theme.spacing(1)',
    flex: 1,
  },
  iconButton: {
    padding: '10px',
    fontSize: '20px',
  },
});

export default function ChatBox(props) {
  const { sendMessage, disabled, chatboxHeight, setChatboxHeight, isDrawer } =
    props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({isSM, isXS});
  const [message, setMessage] = useState('');
  const [sending, setSending] = useState(false);
  const targetRef = useRef();

  function send() {
    if (sending) {
      return;
    }
    setSending(true);
    if (message) {
      sendMessage(message).then(() => setSending(false));
      setMessage('');
      setChatboxHeight(54);
    }
  }

  function textFieldKeyPress(event) {
    const { shiftKey } = event;
    if (targetRef.current && chatboxHeight !== targetRef.current.offsetHeight) {
      setChatboxHeight(targetRef.current.offsetHeight);
    }
    if (event.key === 'Enter') {
      if (!shiftKey) {
        event.preventDefault();
        send();
      }
    }
  }

  return (
    <Paper sx={isDrawer ? classes.rootisDrawer : classes.root} ref={targetRef}>
      <InputBase
        fullWidth
        multiline
        value={message}
        onChange={(evt) => setMessage(evt.target.value)}
        onKeyPress={(evt) => textFieldKeyPress(evt)}
        sx={classes.input}
        disabled={false}
        placeholder="Write your message here..."
        size="big"
        id="textBox"
        inputProps={{
          'aria-label': 'write your message here',
          style: {
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '20px',
            fontFamily: 'Poppins',
          },
        }}
      />
      <IconButton
        sx={classes.iconButton}
        disabled={disabled || !message}
        onClick={send}
        aria-label="send"
        id="sendButton"
      >
        {!sending ? <Send /> : <CircularProgress size={25} />}
      </IconButton>
    </Paper>
  );
}
