import React from 'react';
import {
  Step,
  StepLabel,
  Stepper,
  useMediaQuery,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const useStyles = (isSM) => ({
  stepperContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    width: isSM ? '100%' : '700px',
    backgroundColor: 'transparent',
    marginBottom: isSM ? '-30px' : '',
    marginTop: isSM ? '-20px' : '',
    padding: isSM ? '0px' : '24px',
  },
  stepperLabel: {
    fontWeight: 700,
    fontSize: '10px',
  },
  stepperBlueColor: {
    color: '#0F78FD',
  },
});

const STEPS_LOCALES = [
  { key: 'Basic Information', id: 0 },
  { key: 'Skills and Experience', id: 1 },
  { key: 'Last Step!', id: 2 },
];

function OnboardingStepper({ activeStep }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  function renderSteps() {
    return STEPS_LOCALES.map((STEP_LOCALE) => (
      <Step key={STEP_LOCALE.id}>
        <StepLabel>
          <Typography
            sx={{
              ...(STEP_LOCALE.id <= activeStep ? classes.stepperBlueColor : {}),
              ...classes.stepperLabel,
            }}
          >
            {t(STEP_LOCALE.key)}
          </Typography>
        </StepLabel>
      </Step>
    ));
  }

  return (
    <Box>
      <Stepper
        connector={null}
        activeStep={activeStep}
        sx={classes.stepperContainer}
      >
        {renderSteps()}
      </Stepper>
    </Box>
  );
}

export default OnboardingStepper;
