import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAccount } from '../contexts/Account';
import { AccountType } from '../services/utils/types';

function useLoginRedirect(props) {
  const { logInResponse } = props;
  const history = useHistory();
  const { accountDetails, currentPlan } = useAccount();

  useEffect(() => {
    if (!logInResponse || !accountDetails || !currentPlan) return;
    if (
      accountDetails.onboardingStatus === 'Complete' &&
      currentPlan.onboardingRedirectUrl
    ) {
      window.location.replace(currentPlan.onboardingRedirectUrl);
      return;
    }
    if (
      logInResponse.data.signInUserSession.idToken.payload.accountType.includes(
        AccountType.ADVISOR
      )
    )
      history.push('/advisor/aobf');
    else history.push('/company/onboarding/step1');
  }, [logInResponse, accountDetails, currentPlan]);
}

export default useLoginRedirect;
