import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ACSnackbar from '../../components/ACSnackbar';
import { useAccount } from './../../contexts/Account';
import { SnackbarType } from './../../services/utils/types';
import AdvisorList from '../../components/Advisor/List';
import Modal from './modal';
import eventBus, { eventBusValues } from './../../eventBus';

function CompanySearchAdvisorsContent(props) {
  const {
    endpointResponse,
    isMainCallFetching,
    incrementOffset,
    loading,
    searchApplied,
  } = props;

  const [snackMessage, setSnackMessage] = useState('');
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarWarning, setSnackbarWarning] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalContentText, setModalContentText] = useState('');
  const [modalButtonText, setModalButtonText] = useState('');
  const [modalOnButtonClick, setModalOnButtonClick] = useState();
  const [refresh, doRefresh] = useState(0);

  const { t } = useTranslation();
  const history = useHistory();
  const { accountDetails, asLoggedUserDetails, isFreeTrial, type } =
    useAccount();


  function requestMeetingClickAction(advisor) {
    if (isFreeTrial) {
      eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
    } else {
      setSelectedAdvisor(advisor);
      const url = `${process.env.REACT_APP_LEGACY_URL}/profile/${advisor.publicProfileUrl}/speak-with-me?advisor_first_name=${advisor.givenName}&advisor_last_name=${advisor.surName}`;
      window.open(url, '_blank');
    }
  }

  function openAdvisorProfile(advisor) {
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, advisor);
  }



  return (
    <>

      <ACSnackbar
        open={snackbarSuccess}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.SUCCESS}
        onClose={() => setSnackbarSuccess(false)}
        autoHideDuration={6000}
      />
      <ACSnackbar
        open={snackbarWarning}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.WARNING}
        onClose={() => setSnackbarWarning(false)}
        autoHideDuration={6000}
      />
      <Modal
        isVisible={showModal}
        contentText={modalContentText}
        buttonText={modalButtonText}
        onButtonClick={modalOnButtonClick}
        onClose={() => setShowModal(false)}
      />
      {endpointResponse.length > 0 && !loading ? (
        <AdvisorList
          onClickAction={openAdvisorProfile}
          requestMeetingClickAction={requestMeetingClickAction}
          advisorList={endpointResponse}
          removeBelowButtons
          isMainCallLoading={isMainCallFetching}
          incrementOffset={incrementOffset}
          refresh={refresh}
          searchApplied={searchApplied}
          endPagination={!loading}
        />
      ) : null}
    </>
  );
}

export default CompanySearchAdvisorsContent;
