import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@mui/material';
import PostPopUp from '../../Post/PopUp';

const useStyles = () => ({
  dialogTitle: {
    paddingBottom: '0px',
  },
  dialogContent: {
    paddingTop: '0px',
  },
});

function DialogPostPopUp(props) {
  const { showPostPopUp, postType, onClose } = props;
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <Dialog
      open={!isSM ? showPostPopUp : false}
      onClose={onClose}
      onExit={onClose}
      aria-labelledby="post-popUp-dialog"
      fullWidth
    >
      <DialogTitle id="post-popUp-dialog-title" sx={classes.dialogTitle}>
        <Typography variant="old_font">
          {postType === 'question'
            ? t('POST-QUESTION-TEXT')
            : t('POST-UPDATE-TEXT')}
        </Typography>
      </DialogTitle>
      <DialogContent sx={classes.dialogContent}>
        <PostPopUp onChange={onClose} type={postType} />
      </DialogContent>
    </Dialog>
  );
}

export default DialogPostPopUp;
