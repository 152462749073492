import React, { useState, useEffect, useRef } from 'react';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import { AccountType } from '../../../services/utils/types';
import Header from './header';
import ChatBox from './chatBox';
import NoChat from './NoChat';
import LoadingIndicatorLogo from '../../LoadingIndicatorLogo';
import dateFormat from '../../../utils/dateFormat';
import { useAccount } from '../../../contexts/Account';
import {
  getConversationMessages,
  createConversationMessage,
  readMessages,
} from '../../../services/conversation_services';
import eventBus, { eventBusValues } from '../../../eventBus';

const useStyles = ({ isSM, isXS }) => ({
  messagesContainer: {
    overflowY: 'scroll',
    overscrollBehavior: 'contain',
    backgroundColor: 'white',
    height: '100%',
    marginTop: '67px',
  },
  screenContainer: {
    position: 'fixed',
    backgroundColor: 'white',
    width: !isXS ? (isSM ? 'calc(100% - 300px)' : '700px') : '100%',
  },
  screenContainerSlide: {
    position: 'relative',
    // width: '100%',
    backgroundColor: 'white',
  },
  name: {
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: 'Poppins',
  },
  hour: {
    fontWeight: 500,
    fontSize: '10px',
    color: '#97A3B7',
    marginLeft: '10px',
    fontFamily: 'Poppins',
  },
  message: {
    marginTop: 5,
    fontWeight: 300,
    fontSize: '14px',
    color: '#232B35',
    fontFamily: 'Poppins',
    margin: '1px 0',
    whiteSpace: 'break-spaces',
    overflowWrap: 'anywhere',
  },
  loadingContainer: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'absolute',
    right: '0px',
    top: '0px',
    zIndex: 9999,
  },
  gapBottom: {
    height: isSM ? '180px' : '50px',
  },
  DrawerGapBottom: {
    height: isSM ? '120px' : '30px',
  },
});

const ConversationContainer = (props) => {
  const {
    propConversationId,
    isDrawer,
    setShowLeftMenu,
    showLeftMenu,
    setPropConversationId,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isSM, isXS });
  const [noItemSelected, setNoItemSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatboxHeight, setChatboxHeight] = useState(54);
  const [sortDates, setSortDates] = useState([]);
  const [groupDates, setGroupDates] = useState([]);
  const [conversationId, setConversationId] = useState('');
  const [senderConversationMemberId, setSenderConversationMemberId] =
    useState('');
  const divRef = useRef(null);
  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [groupDates]);

  const {
    accountDetails,
    type,
    asLoggedUserDetails,
    conversations,
    updateReadMessages,
    currentUserInfo,
  } = useAccount();
  const messagesEndRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  function moreThanfiveDays(date) {
    const date1ml = date.getTime();
    const now = new Date();
    const date2ml = now.getTime();
    const diferencesml = date2ml - date1ml;
    return Math.floor(diferencesml / (1000 * 60 * 60 * 24)) > 5;
  }

  function groupByDate(arr) {
    const groups = [];
    arr.forEach((item) => {
      const date = new Date(item.createdAt);
      const dateString = moreThanfiveDays(date)
        ? dateFormat(date, 'dddd, mmmm d yyyy')
        : dateFormat(date, 'DDDD');

      item.hourString = dateFormat(date, 'shortTime');

      const groupRelated = groups.find((gr) => gr.dateString === dateString);
      if (groupRelated) {
        groupRelated.messages.push(item);
      } else {
        groups.push({
          date,
          dateString,
          messages: [item],
        });
      }
    });

    return groups;
  }

  function sendMessage(message) {
    return createConversationMessage({
      conversationId,
      senderConversationMemberId,
      accountType: type,
      senderId: asLoggedUserDetails.userId,
      receiver: queryParams.get('senderId'),
      message,
    }).then(({ data }) => {
      const newMsg = data.createConversationMessage;
      if (conversationId) {
        eventBus.dispatch(
          `${eventBusValues.onNewMessage}${conversationId}`,
          newMsg
        );
      }
    });
  }

  function setNewMessage(newMessage) {
    sortDates.push(newMessage);
    setSortDates(sortDates);
    const group = groupByDate(sortDates);
    setGroupDates(group);
  }

  function getConversation(conversationParam, senderParam) {
    setLoading(true);
    setNoItemSelected(false);
    const params = conversationParam
      ? { conversationId: conversationParam }
      : {
          member1:
            type === AccountType.COMPANY
              ? asLoggedUserDetails.userId
              : senderParam,
          member2:
            type !== AccountType.COMPANY
              ? asLoggedUserDetails.userId
              : senderParam,
        };
    readMessages({
      accountId: asLoggedUserDetails.userId,
      accountType: type,
      conversationId: conversationParam,
    });
    updateReadMessages(conversations, conversationParam);
    getConversationMessages(params)
      .then(({ data }) => {
        setLoading(false);
        const msgs = data.getConversationMessages.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        setSortDates(msgs);
        const group = groupByDate(msgs);
        setGroupDates(group);
        setConversationId(conversationParam);
      })
      .catch((error) => {
        setLoading(false);
        history.push('/messages');
      });
  }

  useEffect(() => {
    if (propConversationId) {
      getConversation(propConversationId);
    }
  }, [propConversationId]);

  function viewProfile(msg) {
    eventBus.dispatch(eventBusValues.triggerConversationDrawerV2, {
      close: true,
    });
    if (
      msg.senderAccountType === AccountType.ADVISOR &&
      msg.senderId !== accountDetails.id
    ) {
      eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
        fetchAdvisor: true,
        id: msg.senderId,
        boardRoomView: true,
      });
    } else if (
      msg.senderAccountType === AccountType.COMPANY &&
      msg.profile?.companyId !== accountDetails.id
    ) {
      eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
        companyId: msg.profile?.companyId,
      });
    }
  }

  function getCursorStyle(msg) {
    if (
      ((msg.senderAccountType === AccountType.ADVISOR &&
        msg.senderId !== accountDetails.id) ||
        (type === AccountType.ADVISOR && msg.profile?.companyId)) &&
      !isDrawer
    ) {
      return 'pointer';
    }
    return 'default';
  }

  useEffect(() => {
    if (!conversationId) return;
    const eventName = `${eventBusValues.onNewMessage}${conversationId}`;

    const listener = (newMessage) => {
      if (conversationId === newMessage.conversationId) {
        setPropConversationId(newMessage.conversationId);
        setNewMessage(newMessage);
      }
    };
    eventBus.on(eventName, listener);

    return () => {
      eventBus.remove(eventName, listener);
    };
  }, [conversationId]);

  function newConversationContainer() {
    return (
      <div
        style={{
          ...(isDrawer
            ? classes.screenContainerSlide
            : classes.screenContainer),
          height: isDrawer
            ? `100vh`
            : `calc(100vh - 80px - ${chatboxHeight}px)`,
          overflow: isDrawer ? 'hidden' : null,
        }}
      >
        <Header
          propConversationId={propConversationId}
          isDrawer={isDrawer}
          groupDates={groupDates}
          setShowLeftMenu={setShowLeftMenu}
          showLeftMenu={showLeftMenu}
        />
        <div ref={divRef} style={classes.messagesContainer}>
          {noItemSelected ? <NoChat /> : null}
          {loading ? (
            <div style={classes.loadingContainer}>
              <LoadingIndicatorLogo
                size={200}
                iconFontSize={93}
                iconRight={105}
              />
            </div>
          ) : null}
          <List
            id="messagesList"
            style={{
              marginTop: isDrawer ? '150px' : '20px',
            }}
          >
            {groupDates.map((group) => (
              <React.Fragment key={group.dateString}>
                <Divider style={{ fontFamily: 'Poppins' }}>
                  {group.dateString}
                </Divider>
                {group.messages.map((chat, index) => (
                  <ListItem
                    style={{
                      paddingLeft: '30px',
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: 5,
                    }}
                    alignItems="flex-start"
                    key={`${chat.profile?.displayName}-${chat.createdAt}`}
                  >
                    <div style={{ display: 'flex' }}>
                      {chat.profile?.image ||
                      chat.profile?.contactLogoLocation ? (
                        <ListItemAvatar>
                          <img
                            alt=""
                            src={
                              chat.profile?.image
                                ? `${process.env.REACT_APP_IMAGES_URL}${chat.profile?.image.imageKey}`
                                : chat.profile?.contactLogoLocation
                            }
                            style={{
                              width: '45px',
                              height: '45px',
                              marginRight: '15px',
                              marginTop: '-5px',
                              cursor: getCursorStyle(chat),
                            }}
                            onClick={() => viewProfile(chat)}
                            role="none"
                          />
                        </ListItemAvatar>
                      ) : (
                        <ListItemAvatar>
                          <Avatar
                            onClick={() => viewProfile(chat)}
                            style={{
                              cursor: getCursorStyle(chat),
                            }}
                          >
                            {chat.profile?.displayName.charAt(0)}
                          </Avatar>
                        </ListItemAvatar>
                      )}

                      <ListItemText
                        primary={
                          <div>
                            <span style={classes.name}>
                              {chat.profile?.displayName}
                            </span>
                            <span style={classes.hour}>{chat.hourString}</span>
                          </div>
                        }
                        secondary={
                          <span style={classes.message}>{chat.message}</span>
                        }
                      />
                    </div>
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </List>
          {/* <div
            style={isDrawer ? classes.DrawerGapBottom : classes.gapBottom}
            ref={messagesEndRef}
          />*/}
        </div> 
        <ChatBox
          sendMessage={sendMessage}
          chatboxHeight={chatboxHeight}
          setChatboxHeight={setChatboxHeight}
          disabled={noItemSelected}
          isDrawer={isDrawer}
        />
      </div>
    );
  }

  return newConversationContainer();
};

export default ConversationContainer;
