import React, { useState, useRef } from 'react';
import { Switch, useHistory, useLocation, Route } from 'react-router-dom';
import { useMediaQuery, Box } from '@mui/material';
import CompanyUpperBar from '../components/Company/UpperBar';
import MyAdvisors from '../scenes/MyAdvisors';
import SearchAdvisors from '../scenes/SearchAdvisors';
import SavedAdvisors from '../scenes/SavedAdvisors';
import TopMatchesAdvisors from '../scenes/TopMatchesAdvisors';
import { useAccount } from '../contexts/Account';
import ImpersonateUpperBar from '../components/ImpersonationUpperBar';
import CompanyDashboard from '../scenes/CompanyDashboard';
import CompanyBoardMeetings from '../scenes/CompanyBoardMeetings';
import IndividualPostEdit from '../scenes/IndividualPostEdit';
import { CompanyExtraFeedProvider } from '../contexts/CompanyFeed';
import { PostType } from '../services/utils/types';
import FreeTrialBanner from '../components/Company/FreeTrialBanner';
import ExFreemiumBanner from '../components/Company/ExFreemiumBanner';
import OnboardingBanner from '../components/Company/OnboardingBanner';
import IndividualPost from '../scenes/IndividualPost';
import CompanyRightMenu from '../components/Company/RightMenu/index';
import eventBus, { eventBusValues } from '../eventBus';
import { AccountType } from '../services/utils/types';
import CompanyAccount from '../scenes/CompanyAccount';
import Ideas from '../scenes/Ideas';
import BoardMeetings from '../scenes/BoardMeetings';
import Treatment from '../components/Treatment';
import { featureFlags } from './../splitSetup';

function getTopValue(isSM, showSmallBanner) {
  if (isSM) {
    return '50px';
  }
  if (showSmallBanner) {
    return '50px';
  }
  return '50px';
}

const useStyles = ({ isSM, showSmallBanner }) => ({
  companyContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
  },
  viewContainer: {
    marginTop: getTopValue(isSM, showSmallBanner),
    overscrollBehavior: 'contain',
    display: 'flex',
    flex: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 50px)',
    '&::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: '#F8F8F8',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: '#C7C7C7',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#B7B7B7',
    },
    '&::-webkit-scrollbar-thumb:active': {
      backgroundColor: '#B7B7B7',
      boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    },
  },
  childrenContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    width: '100%',
  },
  companyContainerImpersonated: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    justifyContent: 'center',
  },
});

function BaseLayout() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const {
    isImpersonated,
    isFreeTrial,
    currentPlan,
    accountDetails,
    isFreemium,
    type,
    currentUserInfo,
  } = useAccount();
  const { salesforceValues } = currentPlan;
  const showExFremiumBanner =
    accountDetails.freemiumRegistration &&
    accountDetails.opportunityStage === 'discovery' &&
    !isFreemium;
  const showOnboardingBanner =
    !isFreeTrial &&
    accountDetails.opportunityStage === 'discovery' &&
    accountDetails.onboardingStatus !== 'Not Requisite' &&
    accountDetails.onboardingStatus !== 'Complete' &&
    !accountDetails.freemiumRegistration;
  const showBanner =
    type === AccountType.ADVISOR &&
    !isFreeTrial &&
    salesforceValues.billingCycle === 'Monthly' &&
    ['Essentials', 'Professional', 'Executive'].includes(salesforceValues.tier);
  const isSM = useMediaQuery((umq) => umq.breakpoints.down('sm'));
  const location = useLocation();
  const classes = useStyles({
    isSM,
    showSmallBanner: showExFremiumBanner || showOnboardingBanner,
  });
  const history = useHistory();
  const boxRef = useRef();

  function handleMenuClick() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <>
      <CompanyExtraFeedProvider>
        {isImpersonated && <ImpersonateUpperBar />}
        {isFreemium ? null : (
          <FreeTrialBanner show={isFreeTrial} isCompanyContact />
        )}
        {accountDetails.freemiumRegistration &&
          accountDetails.opportunityStage === 'discovery' &&
          !isFreemium && <ExFreemiumBanner show />}
        {showOnboardingBanner && <OnboardingBanner show />}
        <div
          style={
            isImpersonated
              ? classes.companyContainerImpersonated
              : classes.companyContainer
          }
        >
          <CompanyRightMenu
            onDismiss={() => {
              eventBus.dispatch(eventBusValues.mobileCompanyMenuTrigger);
            }}
            accountDetails={accountDetails}
          />

          <div style={classes.childrenContainer}>
            <CompanyUpperBar />

            <Box sx={classes.viewContainer} ref={boxRef}>
              <div style={{ width: '100%' }}>
                <Switch>
                  <Route path="/dashboard" component={CompanyDashboard} />
                  <Route
                    path="/board-meetings"
                    component={() => <BoardMeetings />}
                  />
                  <Route path="/post/:postId?" component={IndividualPost} />
                  <Route
                    path="/question/:postId?/edit"
                    component={() => (
                      <IndividualPostEdit postType={PostType.QUESTION} />
                    )}
                  />
                  <Route exact path="/advisor_ideas" component={Ideas} />
                  <Route
                    exact
                    path="/advisors"
                    component={() => (
                      <MyAdvisors history={history} boxRef={boxRef} />
                    )}
                  />
                  <Route
                    exact
                    path="/advisors/search"
                    component={() => <SearchAdvisors boxRef={boxRef} />}
                  />
                  <Route
                    exact
                    path="/advisors/saved"
                    component={() => (
                      <SavedAdvisors history={history} boxRef={boxRef} />
                    )}
                  />
                  <Route
                    exact
                    path="/advisors/top_matches"
                    component={() => (
                      <TopMatchesAdvisors history={history} boxRef={boxRef} />
                    )}
                  />
                  <Route
                    exact
                    path="/advisors/:publicProfileUrl"
                    component={MyAdvisors}
                  />
                  <Route exact path="/account" component={CompanyAccount} />
                </Switch>
              </div>
            </Box>
          </div>
        </div>
      </CompanyExtraFeedProvider>
    </>
  );
}

export default BaseLayout;
