import React, { useState, useEffect, useRef } from 'react';
import { companyFetchPostsCall } from '../../services/company_services';
import {
  AccountType,
  PostType,
  EndpointParameterFilters,
} from '../../services/utils/types';
import { useAccount } from './../../contexts/Account';
import BoardMeetingCard from '../../components/BoardMeetingsCard';
import EmptyState from './EmptyState';
import { Typography, Box } from '@mui/material';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';

const BoardMeetings = () => {
  const { accountDetails, asLoggedUserDetails } = useAccount();
  const [items, setItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBoardMeetings, setLoadingBoardMeetings] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;
  const containerRef = useRef(null);

  const fetchItems = async () => {
    if (isLoadingBoardMeetings || !hasMore) return;
    setLoadingBoardMeetings(true);
    try {
      const response = await companyFetchPostsCall({
        ACCOUNT_TYPE: AccountType.CONTACT,
        ADVISOR_ID: '',
        COMPANY_ID: accountDetails.id,
        LIMIT: limit,
        OFFSET: offset,
        ORDER_BY: EndpointParameterFilters.ORDER_BY_SORT_AT,
        REVERSE: false,
        TYPE: PostType.BOARD_MEETINGS,
        COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
      });
      setItems((prev) => [...prev, ...response.data.getPosts]);
      setHasMore(response.data.getPosts.length > 0);
      setOffset((prev) => prev + limit);
    } catch (error) {
      console.error(error);
    }
    setLoadingBoardMeetings(false);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
      if (
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 100 &&
        hasMore &&
        !isLoadingBoardMeetings
      ) {
        fetchItems();
      }
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, isLoadingBoardMeetings]);

  return (
    <Box
      ref={containerRef}
      sx={{
        p: { xxs: '20px', xs: '20px 48px' },
        height: 'calc(100vh - 50px)',
        overflowY: 'auto',
        backgroundColor: '#f4f8ff',
      }}
    >
      <Typography
        sx={{
          textAlign: 'left',
          fontSize: { xxs: 16, xs: 40 },
          fontWeight: '600',
          lineHeight: { xxs: '20px', xs: '52px' },
          color: '#001030',
          mb: !hasMore && !items.length ? 0 : { xxs: 1, xs: 3 },
          textAlign: 'center',
        }}
      >
        Board Meetings
      </Typography>
      {!hasMore && !items.length && (
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '20px',
            lineHeight: { xxs: '18px', xs: '24px' },
            color: '#4D586F',
            mb: 2,
            textAlign: 'center',
          }}
        >
          Gain valuable insights and guidance from experienced industry advisors
        </Typography>
      )}

      {items.map((item) => (
        <BoardMeetingCard
          key={item.id}
          boardMeeting={item}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      ))}
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
        </Box>
      )}
      {isLoadingBoardMeetings && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '120px',
          }}
        >
          <LoadingIndicatorLogo size={100} iconFontSize={40} iconRight={46} />
        </Box>
      )}
      {!hasMore && !items.length && <EmptyState />}
      {/* {!hasMore && <p>No more boardMeetings</p>} */}
    </Box>
  );
};

export default BoardMeetings;
