import React, { useState } from 'react';
import { Typography, useMediaQuery, TextField } from '@mui/material';
import ACButton from './../../ACButton';

const useStyles = (isSM) => ({
  mainContainer: {
    display: 'flex',
    width: '100%',
    height: 'fit-content',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  bodyIndividual: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    marginLeft: '5px',
    width: '100%',
    flexGrow: 1,
    flexShrink: 1,
    height: 'fit-content',
    color: '#232B35',
    wordBreak: 'break-word',
  },
  buttonBody: {
    borderRadius: '3px',
    border: 'none',
    outline: 'none',
    cursor: 'pointer',
    '&:disabled': {
      cursor: 'default',
    },
    backgroundColor: 'transparent',
    width: '100%',
    margin: '0px',
    padding: '0px',
  },
  input: {
    minHeight: '20px',
    padding: '7px',
  },
  warningText: {
    color: 'error.main',
  },
});

function AnswerBody(props) {
  const { body, bodyDisabled, bodyClick, editing, updateValue, setEditFalse } =
    props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [inputValue, setInputValue] = useState(body);
  const IS_INPUT_VALID = inputValue.trim().length > 0;

  function handleButtonClick() {
    setEditFalse();
    updateValue(inputValue);
  }

  function formatBody(text) {
    const textArray = text.split(' ');
    const elementsArray = [];
    let wordBucket = [];
    textArray.forEach((word, index) => {
      if (word.includes(`\n`)) {
        elementsArray.push(
          <span key={`${index}s1`}>{wordBucket.join(' ')}</span>
        );
        elementsArray.push(<span key={`${index}s2`}> </span>);
        let separatedWord = word.split(`\n`);
        separatedWord.forEach((piece, pieceIndex) => {
          if (piece == '') {
            elementsArray.push(<br key={`${index}k${pieceIndex}`} />);
          } else {
            elementsArray.push(
              <span key={`${index}sp0${pieceIndex}`}>{piece}</span>
            );
            elementsArray.push(<span key={`${index}sp1${pieceIndex}`}> </span>);
          }
        });
        wordBucket = [];
      } else {
        wordBucket.push(word);
      }
    });

    elementsArray.push(<span key="finalItem">{wordBucket.join(' ')}</span>);
    return (
      <React.Fragment>{elementsArray.map((element) => element)}</React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div style={classes.mainContainer}>
        <button
          type="button"
          style={classes.buttonBody}
          onClick={() => {
            if (!editing) {
              bodyClick();
            }
          }}
          disabled={bodyDisabled && !editing}
        >
          {editing ? (
            <div
              style={{
                dispaly: 'flex',
                flexDirection: 'column',
                width: '90%',
                marginLeft: '20px',
              }}
            >
              <TextField
                fullWidth
                multiline
                onChange={(evt) => setInputValue(evt.target.value)}
                value={inputValue}
                placeholder="Add a comment..."
                InputProps={{
                  style: classes.input,
                }}
                variant="outlined"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '5px',
                }}
              >
                <ACButton
                  color="primary"
                  variant="outlined"
                  onClick={handleButtonClick}
                  style={{ borderRadius: 5 }}
                  size={isSM ? 'small' : 'medium'}
                  disabled={!IS_INPUT_VALID}
                >
                  <Typography variant="body2">Update</Typography>
                </ACButton>
              </div>
            </div>
          ) : (
            <Typography
              id="bodyParagraph"
              sx={classes.bodyIndividual}
              align="left"
            >
              {formatBody(inputValue, bodyClick)}
            </Typography>
          )}
        </button>
      </div>
    </React.Fragment>
  );
}

export default AnswerBody;
