import React, { useState, useEffect } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@mui/material';
import { ChevronRight, ContentCopy } from '@mui/icons-material';
import { useAccount } from './../../../../contexts/Account';
import CustomIcon from './../../../../icons/customIcon';
import Connect from '../../../Company/Connect';
import useSavedAdvisor from '../../../../hooks/useSavedAdvisor';
import { AccountType } from '../../../../services/utils/types';
import { StarOutline, Star } from '@mui/icons-material';

const useStyles = (isPublicProfileView) => ({
  root: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '50px',
  },
  buttons: {
    display: 'flex',
    width: isPublicProfileView ? 'unset' : '100%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '& > *': {
      margin: 'theme.spacing(1)',
    },
  },
  shareButton: {
    color: '#232B35',
    backgroundColor: '#F5F7FA',
    width: '34px',
    height: '34px',
    margin: '7px',
    marginRight: '20px',
  },
  iconShare: {
    fontSize: '20px',
  },
  icon: {
    fontSize: '22px',
  },
  backButton: {
    marginLeft: '20px',
    cursor: 'pointer',
  },
  headlineLink: {
    width: '100%',
    fontWeight: 700,
    fontSize: '16px',
    fontStyle: 'normal',
    color: '#3171F6',
    paddingLeft: '5px',
    letterSpacing: -0.43,
  },
  skeletonStar: {
    color: '#F0F0F0',
    fontSize: '26px',
  },
  starButton: {
    position: 'relative',
    left: '32px',
    bottom: '10px',
    zIndex: 3,
  },
  starIconFilled: {
    color: '#FFC400',
    fontSize: '26px',
  },

});

export default function ButtonGroupBar(props) {
  const {
    isPublicProfileView,
    isPublicProfile,
    backAction,
    onConversation,
    interests,
    onShareProfile,
    onSharePublic,
    advisor,
    isAdvisorRoutes,
  } = props;

  const classes = useStyles(isPublicProfileView);
  const { conversations, isImpersonated, type } = useAccount();
  const [alreadyMessagedBoardOwner, setAlreadyMessagedBoardOwner] =
    useState(false);
  const [shareMenuAnchor, setShareMenuAnchor] = useState(null);
  const [isSaved, saveAdvisor] = useSavedAdvisor(advisor?.id);


  function findingConversation() {
    if (conversations && conversations.length > 0) {
      const index = conversations.findIndex(
        (conversation) =>
          conversation.conversationMemberAccountId === advisor.id
      );
      setAlreadyMessagedBoardOwner(index > -1);
    }
  }

  useEffect(() => {
    findingConversation();
  }, [advisor]);

  const openShareMenu = (event) => {
    setShareMenuAnchor(event.currentTarget);
  };

  const closeShareMenu = () => {
    setShareMenuAnchor(null);
  };

  const handleShareInApp = () => {
    onShareProfile();
    closeShareMenu();
  };

  const handleSharePublic = () => {
    onSharePublic();
    closeShareMenu();
  };

  function StarIcon() {
    if (isSaved === null) return <Star sx={classes.skeletonStar} />;
    if (isSaved) return <Star sx={classes.starIconFilled} />;
    return <StarOutline sx={classes.icon} />;
  }

  return (
    <div>
      <div
        style={{
          ...classes.root,
          marginBottom: isPublicProfile ? '-100px' : null,
        }}
      >
        {!isPublicProfile ? (
          <div style={classes.backButton}>
            <ChevronRight
              onClick={backAction}
              sx={{
                fontSize: '30px',
              }}
              color="primary"
            />
          </div>
        ) : (
          <div></div>
        )}
        <Box sx={classes.buttons}>
          {isImpersonated && (
            <IconButton sx={classes.shareButton} onClick={openShareMenu}>
              <ContentCopy sx={classes.iconShare} />
            </IconButton>
          )}
          {!isAdvisorRoutes &&
            !isPublicProfileView &&
            type === AccountType.COMPANY ? (
            <IconButton
              onClick={saveAdvisor}
              id="starButton"
            >
              <StarIcon />
            </IconButton>
          ) : null}
          {!isPublicProfile && !isPublicProfileView ? (
            <IconButton sx={classes.shareButton} onClick={onConversation}>
              <CustomIcon
                iconname={alreadyMessagedBoardOwner ? 'comments' : 'airplane'}
                style={{
                  fontSize: alreadyMessagedBoardOwner ? 24 : 20,
                  zIndex: 3,
                }}
              />
            </IconButton>
          ) : null}
          <Connect advisor={advisor} interests={interests} />
        </Box>
        <Menu
          anchorEl={shareMenuAnchor}
          open={Boolean(shareMenuAnchor)}
          onClose={closeShareMenu}
          className={classes.shareMenu}
        >
          <MenuItem onClick={handleShareInApp}>In-App</MenuItem>
          <MenuItem onClick={handleSharePublic}>Public</MenuItem>
        </Menu>
      </div>
    </div>
  );
}
