import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import ACButton from '../../../ACButton';

const useStyles = (buttonColor) => ({
  buttonIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'none',
    outline: 'none',
    padding: '0',
    '&:hover': { backgroundColor: 'transparent' },
    height: '50px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: buttonColor ? 'secondary.main' : 'primary.main',
    margin: '0px',
    padding: '0px',
  },
  iconText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    fontWeight: '500',
    marginLeft: '5px',
    fontSize: '14px',
    color: buttonColor ? 'secondary.main' : 'primary.main',
    textWrap: 'nowrap',
  },
});

const decoratedButton = (props) => {
  const {
    buttonIcon,
    buttonText,
    clickAction,
    loading,
    isDisabled,
    customFont,
  } = props;
  const [buttonColor, setButtonColor] = useState();
  const classes = useStyles(buttonColor);

  useEffect(() => {
    const isButtonDisabled = () => {
      if (loading || isDisabled) {
        return true;
      }
      return false;
    };
    setButtonColor(isButtonDisabled);
  }, [loading, isDisabled]);

  return (
    <ACButton
      onClick={() => clickAction()}
      style={{
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      loading={loading ? 1 : 0}
      disabled={isDisabled ? 1 : 0}
    >
      <div style={classes.iconContainer}>{buttonIcon}</div>
      <Typography
        variant="caption"
        sx={{
          ...classes.iconText,
          ...(customFont ? { fontFamily: customFont } : {}),
        }}
      >
        {buttonText}
      </Typography>
    </ACButton>
  );
};

export default decoratedButton;
