import React, { useState, useEffect, useRef } from 'react';
import { getAdvisorIdeasCall } from '../../services/advisor_services';
import { useAccount } from './../../contexts/Account';
import IdeaCard from './IdeaCard';
import EmptyState from './EmptyState';
import { Typography, Box } from '@mui/material';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';

const Ideas = () => {
  const { accountDetails } = useAccount();
  const [items, setItems] = useState([]);
  const [offset, setOffset] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [isLoadingIdeas, setLoadingIdeas] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const limit = 10;
  const containerRef = useRef(null);

  const fetchItems = async () => {
    if (isLoadingIdeas || !hasMore) return;
    setLoadingIdeas(true);
    try {
      const response = await getAdvisorIdeasCall({
        companyId: accountDetails.id,
        limit,
        offset,
      });
      setItems((prev) => [...prev, ...response.data.getAdvisorIdeas]);
      setHasMore(response.data.getAdvisorIdeas.length > 0);
      setOffset((prev) => prev + limit);
    } catch (error) {
      console.error(error);
    }
    setLoadingIdeas(false);
  };

  useEffect(() => {
    fetchItems();
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
      if (
        container.scrollTop + container.clientHeight >=
          container.scrollHeight - 100 &&
        hasMore &&
        !isLoadingIdeas
      ) {
        fetchItems();
      }
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, isLoadingIdeas]);

  return (
    <Box
      ref={containerRef}
      sx={{
        p: { xxs: '20px', xs: '20px 48px' },
        height: 'calc(100vh - 50px)',
        overflowY: 'auto',
        backgroundColor: '#f4f8ff',
      }}
    >
      <Typography
        sx={{
          textAlign: 'left',
          fontSize: { xxs: 16, xs: 40 },
          fontWeight: '600',
          lineHeight: { xxs: '20px', xs: '52px' },
          color: '#001030',
          mb: !hasMore && !items.length ? 0 : { xxs: 1, xs: 3 },
          textAlign: 'center',
        }}
      >
        Ideas from your advisors
      </Typography>
      {!hasMore && !items.length && (
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: '20px',
            lineHeight: { xxs: '18px', xs: '24px' },
            color: '#4D586F',
            mb: 2,
            textAlign: 'center',
          }}
        >
          Gain valuable insights and guidance from experienced industry advisors
        </Typography>
      )}

      {items.map((item) => (
        <IdeaCard key={item.id} idea={item} setLoading={setLoading} />
      ))}
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 9999,
          }}
        >
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
        </Box>
      )}
      {isLoadingIdeas && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '120px',
          }}
        >
          <LoadingIndicatorLogo size={100} iconFontSize={40} iconRight={46} />
        </Box>
      )}
      {!hasMore && !items.length && <EmptyState />}
      {/* {!hasMore && <p>No more ideas</p>} */}
    </Box>
  );
};

export default Ideas;
