import { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  Menu,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import CustomIcon from '../../icons/customIcon';
import { useAccount } from '../../contexts/Account';
import eventBus, { eventBusValues } from '../../eventBus';
import useCanMessage from '../../hooks/useMessagePermission';
import { AccountType } from '../../services/utils/types';
import LoginForm from '../LoginForm';
import AdvisoryCloudLogo from '../../components/AdvisoryCloudLogo2';
import IframeEmbedModal from '../../components/IframeEmbedModal';

function Connect({ advisor = {}, interests = [] }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const {
    type,
    conversations,
    isFreeTrial,
    isImpersonated,
    isAuthenticated,
    accountDetails,
  } = useAccount();
  const canMessage = useCanMessage(advisor.id, AccountType.ADVISOR);
  const [menuOpen, setMenuOpen] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [iFrameSource, setIframeSource] = useState('');
  const connectButtonRef = useRef(null);
  const [isAdvisorInvited, setIsAdvisorInvited] = useState(false);
  const interestsList = [
    'Board Seats',
    'Consulting',
    'Mentoring',
    'Networking',
    'New Ventures',
    'Full-Time Roles',
  ];
  const [loginFormOpen, setLoginFormOpen] = useState(false);

  const pendingActionsMap = {
    inviteToJoin,
    handleConversation,
  };

  const shouldExecutePendingAction = useCallback(() => {
    if (!isAuthenticated || !advisor.id || canMessage === undefined)
      return false;

    const savedAction = sessionStorage.getItem('pendingAction');
    return savedAction && pendingActionsMap[savedAction];
  }, [isAuthenticated, advisor.id, canMessage]);

  useEffect(
    function handlePendingAction() {
      if (!shouldExecutePendingAction()) return;

      const savedAction = sessionStorage.getItem('pendingAction');
      pendingActionsMap[savedAction]();
      sessionStorage.removeItem('pendingAction');
    },
    [shouldExecutePendingAction]
  );

  useEffect(
    function validateAdvisorIsInvited() {
      if (!accountDetails || !advisor) return;

      const filterAdvisors = [
        ...(accountDetails?.boardAdvisors || []),
        ...(accountDetails?.invitedAdvisors || []),
      ];
      setIsAdvisorInvited(
        filterAdvisors?.some((boardAdvisor) => boardAdvisor.id === advisor.id)
      );
    },
    [accountDetails, advisor]
  );

  // function handleUnauthenticatedUser(actionName) {
  //   setMenuOpen(false);
  //   setLoginFormOpen(true);
  //   sessionStorage.setItem('pendingAction', actionName);
  // }

  function handleUnauthenticatedUser(actionName) {
    setMenuOpen(false);
    setIframeSource(
      `https://register.advisorycloud.com/profile/connect/?trigger=${actionName}&email=${advisor.email}&first_name=${advisor.givenName}&last_name=${advisor.surName}&sosfcontactid=${advisor.salesforceContactId}`
    );
    setLoginModal(true);
  }

  function inviteToJoin(event) {
    event?.stopPropagation();
    if (!isAuthenticated) {
      handleUnauthenticatedUser('inviteToJoin');
      return;
    }

    if (isAdvisorInvited) return;

    setMenuOpen(false);
    if (isFreeTrial && !isImpersonated) {
      eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {
        action: () => {
          eventBus.dispatch(eventBusValues.onInviteToJoinCompany, {
            advisor: advisor,
            topMatch: false,
          });
        },
      });
    } else {
      eventBus.dispatch(eventBusValues.onInviteToJoinCompany, {
        advisor: advisor,
        topMatch: false,
      });
    }
  }

  function handleConversation(event, interest) {
    event?.stopPropagation();
    if (!isAuthenticated) {
      handleUnauthenticatedUser(interest);
      return;
    }
    if (!canMessage) return handleUnableToMessage();

    const conversation = conversations?.find(
      (c) => c.conversationMemberAccountId === advisor.id
    );

    dispatchMessage(interest, conversation?.conversationId);
  }

  function dispatchMessage(interest, conversationId = null) {
    eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
      senderId: advisor.id,
      senderName: advisor.displayName,
      interest,
      ...(conversationId && { conversationId }),
    });
  }

  function handleUnableToMessage() {
    eventBus.dispatch(
      type === AccountType.COMPANY
        ? eventBusValues.triggerCompanyUpgradeAction
        : eventBusValues.triggerAdvisorUpgradeAction,
      {
        action: () => {
          eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
            senderId: advisor.id,
            senderName: advisor.displayName,
          });
        },
      }
    );
    return;
  }

  return (
    <>
      <Dialog
        open={loginFormOpen}
        onClose={() => setLoginFormOpen(false)}
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '500px',
            borderRadius: '8px',
          },
        }}
      >
        <DialogContent
          sx={{ ':first-of-type': { marginLeft: 'auto', marginRight: 'auto' } }}
        >
          <AdvisoryCloudLogo />
          <LoginForm noRedirect />
        </DialogContent>
      </Dialog>
      <IframeEmbedModal
        openModal={loginModal}
        setOpenModal={setLoginModal}
        iframeEmbedLink={iFrameSource}
        width={isSM ? '100%' : '700px'}
        height="475px"
      />
      <Button
        id="AdvisorProfileActions"
        ref={connectButtonRef}
        variant="contained"
        disableElevation
        onClick={(event) => {
          event.stopPropagation();
          setMenuOpen(true);
        }}
        sx={{
          color: '#ffffff',
          backgroundColor: '#3171F6',
          '&:hover': {
            backgroundColor: '#3171F6',
          },
          borderRadius: '40px',
          width: isSM ? '100%' : '148px',
          textTransform: 'none',
          fontWeight: 700,
          fontSize: isSM ? '20px' : '15px',
          fontStyle: 'normal',
          letterSpacing: -0.43,
          padding: '6px 15px 6px 15px',
        }}
        endIcon={
          <CustomIcon
            iconname="arrow-down"
            sx={{
              fontSize: '6px',
              marginBottom: '5px',
              marginLeft: '10px',
            }}
          />
        }
      >
        Connect
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={connectButtonRef.current}
        open={menuOpen}
        onClose={(event) => {
          event.stopPropagation();
          setMenuOpen(false);
        }}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: isSM ? 'top' : 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: isSM ? 'bottom' : 'top',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiMenu-paper': {
            width: isSM ? '90%' : undefined,
            borderRadius: '12px',
            border: '1px solid var(--Gray-5, #F5F7FA)',
            boxShadow: '0px 1px 10px 0px rgba(230, 237, 254, 0.25)',
            marginBottom: isSM ? '20px' : '0px',
          },
        }}
      >
        {!isAdvisorInvited && isAuthenticated && (
          <MenuItem sx={{ width: '148px' }} onClick={inviteToJoin}>
            Invite to board
          </MenuItem>
        )}
        {interests && interests.length > 0
          ? interests.map((interest, index) => (
              <MenuItem
                key={'Advisor-Interest-' + index}
                onClick={(event) => handleConversation(event, interest)}
              >
                {interest}
              </MenuItem>
            ))
          : interestsList.map((interest, index) => (
              <MenuItem
                key={'Interest-' + index}
                onClick={(event) => handleConversation(event, interest)}
              >
                {interest}
              </MenuItem>
            ))}
      </Menu>
    </>
  );
}

export default Connect;
