import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  useMediaQuery,
  Dialog,
  Typography,
  Button,
} from '@mui/material';
import { Clear } from '@mui/icons-material';
import ACSnackbar from './../../ACSnackbar';
import { useAccount } from './../../../contexts/Account';
import {
  getAdvisorPlanUpgrades,
  mutationConvertAdvisorPlanCodeEarly,
} from './../../../services/advisor_services';
import eventBus, { eventBusValues } from './../../../eventBus';
import { planCodeTerms, SnackbarType } from './../../../services/utils/types';
import Shield from './../../../images/shield.png';
import Team from './../../../images/team-people.png';
import Stonks from './../../../images/stonk.png';
import Company from './company.png';
import {
  sendFSEventOnUpgrade,
  sendFSEventUpgradeViewed,
} from '../../../services/FullStory';
import LoadingLogoSkeleton from '../../SkeletonLoading/loadingLogoSkeleton';

const useStyles = ({ isSM, isXS }) => ({
  dialogContainer: {
    borderRadius: isSM ? '0px' : '15px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    padding: isSM ? '5px' : '30px',
  },
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: isSM ? '10px' : '50px',
    paddingRight: isSM ? '10px' : '50px',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: isXS ? 'column' : 'row',
    justifyContent: 'space-evenly',
    alignItems: 'flex-center',
    marginTop: isSM ? '5px' : '10px',
    padding: '10px',
  },
  textHeadline: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: '20px',
    lineHeight: '24px',
    padding: '25px',
    paddingBottom: '5px',
    color: '#232B35',
  },
  textSubHeader: {
    fontSize: '16px',
    padding: '15px',
    lineHeight: '24px',
    textAlign: 'center',
    fontWeight: 400,
    color: '#232B35',
  },
  perksContainer: {
    width: isXS ? '100%' : '50%',
    display: 'flex',
    textAlign: isSM ? 'center' : 'left',
    padding: isSM ? '10px 5px 25px 5px' : '0px',
    flexDirection: 'column',
  },
  perksList: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    textAlign: 'left',
  },
  plansContainer: {
    width: isXS ? '100%' : null,
    display: 'flex',
    textAlign: isSM ? 'center' : 'left',
    padding: isSM ? '10px 25px 10px 40px' : '10px',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
  },
  topButtonText: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 500,
    color: '#232B35',
    paddingBottom: '8px',
    display: 'flex',
  },
  bottomButtonText: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '24px',
    marginTop: '5px',
    color: '#232B35',
  },
  moneyText: {
    fontSize: '24px',
    fontWeight: 800,
    lineHeight: '24px',
    display: 'flex',
    alignItems: 'center',
    color: '#232B35',
  },
  sideText: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  planOption: {
    width: isXS ? '100%' : '225px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isXS ? 'center' : 'space-between',
    alignItems: 'flex-center',
    cursor: 'pointer',
    backgroundColor: 'white',
    marginBottom: '20px',
    borderRadius: '15px',
    outline: 'none',
    background: 'none',
    '& :focus': {
      outline: 'none',
    },
    position: 'relative',
  },
  upgradeContainer: {
    marginTop: isSM ? '-5px' : '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonText: {
    fontWeight: 700,
    fontSize: '15px',
  },
  savePill: {
    fontSize: '14px',
    fontWeight: 700,
    background: '#6736FA',
    borderRadius: '20px',
    padding: '5px 11px',
    color: 'white',
    whiteSpace: 'nowrap',
    position: 'absolute',
  },
  currentPlanText: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#232B35',
    position: 'absolute',
  },
  textNotice: {
    fontSize: isSM ? '10px' : '12px',
    marginBottom: isSM ? '5px' : '0px',
    marginTop: '15px',
    textAlign: 'center',
    color: '#646D7A',
    lineHeight: '18px',
    fontWeight: 400,
  },
  perkItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: isSM ? '10px' : '20px',
  },
  checkIcon: {
    marginRight: '12px',
    height: '100%',
    width: '38px',
  },
  perkText: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '22px',
    color: '#232B35',
  },
  clearIcon: {
    margin: '5px 5px 0 5px',
    float: 'right',
    cursor: 'pointer',
  },
  iframeContainer: {
    position: 'relative',
    paddingBottom: isSM ? '0px' : '50%',
    height: isSM ? '100%' : '0px',
    width: isSM ? '100vw' : '80vmax',
    overflow: 'hidden',
  },
  iframe: {
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    overflowX: 'visible',
  },
  mainButton: {
    width: '210px',
    borderWidth: '2px',
    padding: '15px 50px 15px 50px',
    textTransform: 'none',
    height: '45px',
    borderRadius: '40px',
    borderColor: '#3171F6',
  },
});

const errorCodes = {
  recurlyError: 'RECURLY_ERROR',
  dynamoError: 'DYNAMO_DB_ERROR',
  salesforceError: 'SALESFORCE_ERROR',
};

const AdvisorFreeTrialModal = () => {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles({ isSM, isXS });
  const [isGatedAction, setIsGatedAction] = useState();
  const [fromBanner, setFromBanner] = useState(false);
  const [companyName, setCompanyName] = useState();
  const [openModal, setOpenModal] = useState(false);

  const [snackMessage, setSnackMessage] = useState('');
  const [snackbarSuccess, setSnackbarSuccess] = useState(false);
  const [snackbarError, setSnackbarError] = useState(false);

  const [currentPlan, setCurrentPlan] = useState({});

  const [upgradePlan, setUpgradePlan] = useState(false);
  const {
    accountDetails,
    editAccountInformation,
    asLoggedUserDetails,
    type,
    currentUserInfo,
  } = useAccount();
  const { search } = useLocation();

  const [selectedPlan, setSelectedPlan] = useState(0);
  const [loading, setLoading] = useState(false);

  const [onboardingUrl, setOnboardingUrl] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const planResponses = await getAdvisorPlanUpgrades({
        RECURLY_PLAN_CODE: accountDetails.recurlyPlanCode,
      });
      if (planResponses.data && planResponses.data.getFreeTrialUpgrades) {
        const planCodesArr = planResponses.data.getFreeTrialUpgrades;
        setCurrentPlan(planCodesArr[0]);
        if (planCodesArr.length > 1) {
          setUpgradePlan(planCodesArr[1]);
        }
      }
    }

    eventBus.on(eventBusValues.triggerAdvisorUpgradeAction, (data) => {
      if (data) {
        setIsGatedAction(data.gatedAction);
        setFromBanner(data.fromBanner || false);
        if (data.gatedAction) {
          setCompanyName(data.companyName);
        }
      }
      setOpenModal(true);
    });

    const profileParam = new URLSearchParams(search).get('ft-pop-up');
    if (profileParam === 'banner') {
      setIsGatedAction(false);
      setOpenModal(true);
    } else if (profileParam === 'gate') {
      setIsGatedAction(true);
      setOpenModal(true);
    }

    fetchData();

    return () => {
      eventBus.remove(eventBusValues.triggerAdvisorUpgradeAction);
    };
  }, []);

  function handleClose() {
    setOpenModal(false);
    setSelectedPlan(0);
  }

  function getHeadline() {
    if (isGatedAction) {
      if (companyName) {
        return t('FREE_TRIAL_MODAL_GATED_HEADLINE_V2', {
          companyName,
        });
      }
      return t('FREE_TRIAL_MODAL_GATED_HEADLINE_NO_COMPANY');
    }
    return t('FREE_TRIAL_MODAL_BANNER_HEADLINE_V2');
  }

  function getSubHeader() {
    if (isGatedAction) {
      if (companyName) {
        return t('FREE_TRIAL_MODAL_GATED_SUBHEADER_V2', {
          companyName,
        });
      }
      return t('FREE_TRIAL_MODAL_GATED_SUBHEADER_NO_COMPANY');
    }
    return t('FREE_TRIAL_MODAL_BANNER_SUBHEADER_V2');
  }

  const sendFSEvent = (success, errorMessage = '', error = null) => {
    sendFSEventOnUpgrade({
      accountDetails,
      currentPlan: currentPlan,
      asLoggedUserDetails,
      newPlan: selectedPlan === 1 ? currentPlan : upgradePlan,
      source: fromBanner ? 'banner' : 'gate',
      type,
      upgradeType: 'freeTrial',
      success,
      errorMessage: success ? null : errorMessage,
      error: success ? null : error,
    });
  };

  const sendFSViewedEvent = (upgradeAttempted = false) => {
    sendFSEventUpgradeViewed({
      accountDetails,
      currentPlan: currentPlan,
      asLoggedUserDetails,
      currentUserInfo,
      source: fromBanner ? 'banner' : 'gate',
      type,
      upgradeType: 'free_trial',
      upgradeAttempted,
    });
  };

  async function upgradeCall() {
    sendFSViewedEvent(true);
    if (selectedPlan && !loading) {
      setLoading(true);
      mutationConvertAdvisorPlanCodeEarly({
        ADVISOR_ID: accountDetails.id,
        IS_UPGRADE: selectedPlan === 2,
      })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.convertAdvisorPlanCodeEarly
          ) {
            const newAdvisor = response.data.convertAdvisorPlanCodeEarly;
            setSnackMessage(t('FREE_TRIAL_CONVERTION_SUCCESS'));
            setSnackbarSuccess(true);
            setLoading(false);
            editAccountInformation({
              freeTrialEndsOn: newAdvisor.freeTrialEndsOn,
              recurlyPlanCode: newAdvisor.recurlyPlanCode,
            });
            sendFSEvent(true);
            if (
              selectedPlan === 2 &&
              upgradePlan.recurlyPlanCodeTerm === planCodeTerms.YEARLY
            ) {
              setOnboardingUrl(
                `https://go.oncehub.com/AnnualPremierOnboarding/?sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.email}&name=${accountDetails.displayName}`
              );
            } else {
              handleClose();
            }
          }
        })
        .catch((e) => {
          let errText = '';
          try {
            const err = JSON.parse(e.errors[0].message);
            if (err.code === errorCodes.recurlyError) {
              errText = t('FREE_TRIAL_CONVERTION_ERROR_RECURLY');
            } else if (err.code === errorCodes.dynamoError) {
              errText = t('FREE_TRIAL_CONVERTION_ERROR_DYNAMO');
            } else if (err.code === errorCodes.salesforceError) {
              setSnackMessage(t('FREE_TRIAL_CONVERTION_SUCCESS'));
              setSnackbarSuccess(true);
              return;
            } else {
              errText = t('FREE_TRIAL_CONVERTION_ERROR_UNEXP');
            }
            setLoading(false);
            handleClose();
          } catch (err) {
            errText = t('FREE_TRIAL_CONVERTION_ERROR_UNEXP');
            setLoading(false);
          }
          sendFSEvent(false, errText, e);
          setSnackMessage(errText);
          setSnackbarError(true);
        });
    } else {
      setSnackMessage('Select an upgrade option to continue');
      setSnackbarSuccess(true);
      sendFSEvent(false, 'no plan selected', 'no plan selected');
    }
  }

  function getPlanBorder(selected) {
    if (selected) {
      return { border: '3px #3171F6 solid', padding: '10px 35px 10px 13px' };
    }
    return { border: '1px #C7D0DE solid', padding: '12px 37px 12px 15px' };
  }

  function getPillPosition(selected) {
    if (selected) {
      return {
        top: '-15px',
        right: '-25px',
      };
    }
    return {
      top: '-13px',
      right: '-23px',
    };
  }

  function getCurrentPlanPosition(selected) {
    if (selected) {
      return {
        top: '-24px',
        left: '8px',
      };
    }
    return {
      top: '-22px',
      left: '10px',
    };
  }

  function getMonthlyOffer(price) {
    try {
      return Math.floor(price / 12);
    } catch (error) {
      return '???';
    }
  }

  function getPercentage() {
    try {
      const monthlyPerYear = currentPlan.recurlyPlanCodePrice * 12;
      const percentage = upgradePlan.recurlyPlanCodePrice / monthlyPerYear;
      return Math.floor((1 - percentage) * 100);
    } catch (error) {
      return '??';
    }
  }

  function getButtonBackground() {
    if (selectedPlan) {
      return '#3171F6';
    }
    return 'white';
  }

  function getButtonColor() {
    if (selectedPlan) {
      return '#FFFFFF';
    }
    return '#3171F6';
  }

  function newPerkText() {
    const boardroom = window.location.href.includes('boardroom');
    const texts = {
      withBoardroom: {
        perk1:
          'Unlock access to all resources and active discussions in this digital boardroom',
        perk2:
          'Boost your credentials by adding board roles like this to your professional materials',
        perk3:
          'Participate in live board meetings and meet directly with company leadership',
        perk4:
          'Network with fellow board members and thousands of other advisors in our network',
      },
      withoutBoardroom: {
        perk1:
          'Unlock access to all resources and active discussions in digital boardrooms you join',
        perk2:
          'Boost your credentials by adding board roles to your LinkedIn and professional materials',
        perk3:
          'Collaborate with fellow advisors and join live meetings with company leadership',
        perk4:
          'Secure your advisor profile and set yourself up to get hired as an advisor in a variety of ways',
      },
    };

    const selectedTexts = boardroom
      ? texts.withBoardroom
      : texts.withoutBoardroom;

    return {
      perk1: selectedTexts.perk1,
      perk2: selectedTexts.perk2,
      perk3: selectedTexts.perk3,
      perk4: selectedTexts.perk4,
    };
  }

  return (
    <div>
      <Dialog
        open={openModal}
        fullScreen={isSM}
        onClose={() => {
          handleClose();
          sendFSViewedEvent();
        }}
        PaperProps={{
          id: 'freeTrialUpgradeModal',
          sx: classes.dialogContainer,
        }}
      >
        {isSM || onboardingUrl ? (
          <div style={classes.clearIcon}>
            <Clear
              onClick={() => {
                handleClose();
                sendFSViewedEvent();
              }}
              style={{ float: 'right' }}
            />
          </div>
        ) : null}
        {onboardingUrl ? (
          <div style={classes.iframeContainer}>
            <iframe
              src={onboardingUrl}
              title="onboardingCall"
              frameBorder="0"
              style={classes.iframe}
            />
          </div>
        ) : null}
        {currentPlan && !onboardingUrl ? (
          <div>
            <div style={classes.container}>
              <Typography variant="h1" sx={classes.textHeadline}>
                {getHeadline()}
              </Typography>
              <Typography variant="h2" sx={classes.textSubHeader}>
                {getSubHeader()}
              </Typography>
              <div style={classes.mainContainer}>
                <div style={classes.perksContainer}>
                  <div style={classes.perksList}>
                    <div style={classes.perkItem}>
                      <img
                        src={Company}
                        alt="company"
                        style={{ marginRight: '20px' }}
                      />
                      <Typography sx={classes.perkText}>
                        {newPerkText().perk1}
                      </Typography>
                    </div>
                    <div style={classes.perkItem}>
                      <img
                        src={Stonks}
                        alt="stonks"
                        style={{ marginRight: '20px' }}
                      />

                      <Typography sx={classes.perkText}>
                        {newPerkText().perk2}
                      </Typography>
                    </div>
                    <div style={classes.perkItem}>
                      <img
                        src={Team}
                        alt="team"
                        style={{ marginRight: '20px' }}
                      />
                      <Typography sx={classes.perkText}>
                        {newPerkText().perk3}
                      </Typography>
                    </div>
                    <div style={classes.perkItem}>
                      <img
                        src={Shield}
                        alt="shield"
                        style={{ marginRight: '20px' }}
                      />
                      <Typography sx={classes.perkText}>
                        {newPerkText().perk4}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div style={classes.plansContainer}>
                  {upgradePlan ? (
                    <button
                      disabled={loading}
                      type="button"
                      style={{
                        ...classes.planOption,
                        ...getPlanBorder(selectedPlan == 2),
                      }}
                      onClick={() => setSelectedPlan(2)}
                      id="upgradePlanButton"
                    >
                      <div>
                        <Typography sx={classes.topButtonText}>
                          {t('ANNUAL_MEMBERSHIP')}
                        </Typography>
                        <Typography sx={classes.moneyText}>
                          {`$${getMonthlyOffer(
                            upgradePlan.recurlyPlanCodePrice
                          )}`}
                          <span style={classes.sideText}>
                            &nbsp;
                            {t('PER_MONTH')}
                          </span>
                        </Typography>
                        <Typography sx={classes.bottomButtonText}>
                          {t('FREE_TRIAL_MODAL_BILLED_ANNUAL_QTY', {
                            qty: upgradePlan.recurlyPlanCodePrice,
                          })}
                        </Typography>
                      </div>
                      <Typography
                        sx={classes.savePill}
                        style={getPillPosition(selectedPlan == 2)}
                      >
                        {t('FREE_TRIAL_SAVES_PERCENTAGE', {
                          save: getPercentage(),
                        })}
                      </Typography>
                    </button>
                  ) : null}
                  <button
                    disabled={loading}
                    type="button"
                    style={{
                      ...classes.planOption,
                      ...getPlanBorder(selectedPlan == 1),
                    }}
                    onClick={() => setSelectedPlan(1)}
                    id="currentPlanButton"
                  >
                    <div>
                      <Typography sx={classes.topButtonText}>
                        {t('MONTHLY_MEMBERSHIP')}
                      </Typography>
                      <Typography sx={classes.moneyText}>
                        {`$${currentPlan.recurlyPlanCodePrice}`}
                        <span style={classes.sideText}>
                          &nbsp;
                          {t('PER_MONTH')}
                        </span>
                      </Typography>

                      <Typography sx={classes.bottomButtonText}>
                        {t('FREE_TRIAL_MODAL_BILLED_QTY', {
                          qty: currentPlan.recurlyPlanCodePrice
                            ? currentPlan.recurlyPlanCodePrice
                            : 0,
                        })}
                      </Typography>
                    </div>
                    <Typography
                      sx={classes.currentPlanText}
                      style={getCurrentPlanPosition(selectedPlan == 1)}
                    >
                      {t('CURRENT_PLAN_V2')}
                    </Typography>
                  </button>
                </div>
              </div>
              <div style={classes.upgradeContainer}>
                <Button
                  color="primary"
                  size="large"
                  variant={selectedPlan ? 'contained' : 'outlined'}
                  disabled={loading}
                  style={{
                    ...classes.mainButton,
                    cursor: selectedPlan ? 'pointer' : 'not-allowed',
                    backgroundColor: getButtonBackground(),
                  }}
                  className="ftUpgradeButton"
                  onClick={upgradeCall}
                  id="freeTrialUpgradeButton"
                >
                  {loading ? (
                    <LoadingLogoSkeleton />
                  ) : (
                    <Typography
                      sx={classes.buttonText}
                      style={{
                        color: getButtonColor(),
                      }}
                    >
                      Upgrade Now
                    </Typography>
                  )}
                </Button>
                <Typography sx={classes.textNotice}>
                  {t('FREE_TRIAL_MODAL_UPGRADE_NOTICE_V2')}
                </Typography>
              </div>
            </div>
          </div>
        ) : null}
      </Dialog>
      <ACSnackbar
        open={snackbarSuccess}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.SUCCESS}
        onClose={() => {
          setSnackbarSuccess(false);
        }}
        autoHideDuration={6000}
      />
      <ACSnackbar
        open={snackbarError}
        text={snackMessage ? t(snackMessage) : ''}
        severity={SnackbarType.ERROR}
        onClose={() => {
          setSnackbarError(false);
        }}
        autoHideDuration={6000}
      />
    </div>
  );
};

export default AdvisorFreeTrialModal;
