import React from 'react';
import { Box } from '@mui/material';

function CustomIFrameScene(props) {
  const { url, title } = props;

  return (
    <Box sx={{ width: '100%', height: '98%' }}>
      <iframe
        about={title || 'Custom IFrame'}
        style={{ border: 'none', width: '100%', height: '100%' }}
        src={url}
        title={title || 'Custom IFrame'}
      />
    </Box>
  );
}

export default CustomIFrameScene;
