import { Avatar, Box, Typography } from "@mui/material";

function StakeholderCard({ name = "", title = "", imageURL = "" }) {
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '10px',
      columnGap: "10px",
      backgroundColor: '#F4F8FF',
      borderRadius: '8px',
      width: '100%',
    }}>
      <Avatar src={imageURL} alt="avatar" />
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}>
        <Typography sx={{
          fontFamily: 'Poppins',
          fontSize: 14,
          fontWeight: 600,
          color: "#001030",
          lineHeight: "18px",
        }}>{name}</Typography>
        <Typography sx={{
          fontFamily: 'Poppins',
          fontSize: 12,
          fontWeight: 400,
          color: "#4D586F",
          lineHeight: "18px",
        }}>{title}</Typography>
      </Box>
    </Box>
  );
}

export default StakeholderCard;