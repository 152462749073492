import React, { useState } from 'react';
import { Tooltip, IconButton } from '@mui/material';

const ACToolTip = ({ title, placement, children }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
      setOpen(true);
  };

  const handleTooltipClose = () => {
      setOpen(false);
  };

  return (
    <Tooltip
      title={title}
      arrow
      placement={placement}
      open={open}
      onClose={handleTooltipClose}
    >
      <IconButton
        onClick={handleTooltipOpen}
        onBlur={handleTooltipClose}
        onMouseEnter={handleTooltipOpen}
        onMouseLeave={handleTooltipClose}
        sx={{ cursor: 'pointer', color: 'black', padding: 0 }}
      >
        {children || null}
      </IconButton>
    </Tooltip>
  );
};

export default ACToolTip;

