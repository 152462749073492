import React, { useState, useEffect, useRef } from 'react';
import {
  Typography,
  CircularProgress,
  useMediaQuery,
  Box,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ACSnackbar from './../../ACSnackbar';
import LoadingIndicatorLogo from './../../LoadingIndicatorLogo';
import DefaultAdvisorAvatar from './../../../icons/user-avatar.svg';
import { AccountType, PostType } from './../../../services/utils/types';
import { useAccount } from './../../../contexts/Account';
import { isNil, getAdvisorImagePath } from './../../../services/utils';
import { advisorAddResponseCall } from './../../../services/advisor_services';
import {
  likeResponseCall,
  likeReplyCall,
  companyUpdateResponseCall,
  companyUpdateReplyCall,
  pinPostCall,
  addReplyCall,
  getPostRepliesCall,
} from './../../../services/company_services';
import ReviewPopUp from './../ReviewPopUp';
import Reply from './Reply';
import AnswerCard from './../Answers';
import PostCard from './../Card';
import eventBus, { eventBusValues } from './../../../eventBus';
import BoardMeetingCommentsHeader from './boardMeetingCommentsHeader';
import BoardMeetingCommentsBox from './boardMeetingsCommentsBox';
import TimeElapsed from './../../../utils/TimeElapsed';
import CustomIcon from './../../../icons/customIcon';
import AnswerSkeleton from '../../SkeletonLoading/answerSkeleton';
import ResponseSubmitedModal from '../../Advisor/ResponseSubmitedModal';

const useStyles = (isSM) => ({
  container: {
    width: '100%',
    borderRadius: 10,
  },
  containerFlex: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    marginBottom: '20px',
  },
  meetingPostContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid #E6EDFF',
  },
  meetingPostContainerNormalSize: {
    position: 'fixed',
    right: '0px',
    bottom: '0px',
    maxWidth: '35%',
  },
  answerContainer: {
    backgroundColor: 'white',
  },
  answerContainerHighlighted: {
    backgroundColor: '#e8e8e8',
    border: '2px solid #c8d0dd',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(240,242,247,0.5)',
  },
  answerReplyContainer: {
    backgroundColor: 'white',
    paddingLeft: '40px',
    paddingTop: '10px',
  },

  responseContainer: {
    backgroundColor: 'white',
    padding: '15px',
  },
  profileContainer: {
    zIndex: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  answersArrayDashboard: {
    backgroundColor: 'white',
    padding: '15px 33px 15px 33px',
  },
  moreAnswersText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    color: '#3171F6',
    backgroundColor: 'white',
    paddingBottom: 15,
    margin: 0,
    cursor: 'pointer',
  },
  acIcon: {
    color: '#D7F1FD',
    fontSize: '184px',
    display: 'flex',
  },
});

function PostAnswer(props) {
  const {
    // ///////////////PostCard
    postData,
    dashboardFormat,
    isSingleBoardMeetingPost,

    postBodyClick,
    postLikesIconClick,
    postLikesIconDisabled,
    postLikesFilled,
    postAnswersIconDisabled,
    postAnswersIconClick,
    postAnswersFilled,
    postAnswersHighlighted,
    postCommentsIconDisabled,
    postCommentsIconClick,
    postCommentsFilled,

    isOpportunityView,
    isFirst,
    isLoaded,
    id,
    isSinglePost,
    answerCommentsIconDisabled,
    answerCommentsIconClick,
    answerBodyClick,
    editClick,
    ///
    removeResponse,
    getPostReplies,
    responseLoading,
    contributorsDisabled,
    removeResponseActions,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();
  const [postingResponse, setPostingResponse] = useState(false);
  const [responsesData, setResponseData] = useState([]);
  const [repliesData, setRepliesData] = useState([]);
  const [isLoadingAnswers, setIsLoadingAnswers] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [newSingleReply, setNewSingleReply] = useState();
  const [newSingleReplyStatus, setNewSingleReplyStatus] =
    useState('NO_REPLY_CALL');
  const [showReply, setShowReply] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [freeTrialRedirection, setFreeTrialRedirection] = useState(false);
  const [newSingleResponse, setNewSingleResponse] = useState();
  const [newSingleResponseStatus, setNewSingleResponseStatus] =
    useState('NO_RESPONSE_CALL');
  const {
    accountDetails,
    type,
    isFreeTrial,
    isFreemium,
    asLoggedUserDetails,
    userPreferences,
    isImpersonated,
    currentUserInfo,
  } = useAccount();
  const [postLikesCount, setPostLikesCount] = useState(postData.likesCount);
  const [responseIndex, setResponseIndex] = useState();
  const [responseInputValue, setResponseInputValue] = useState('');
  const [replyInputValue, setReplyInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [repLoad, replyLoading] = useState(false);
  const [totalCommentsCount, setTotalCommentsCount] = useState(3);
  const [showSuccessPinned, setShowSuccesssPinned] = useState(false);
  const [reviewPopUpOpen, setReviewPopUpOpen] = useState(false);
  const [postResponsesCount, setPostResponsesCount] = useState(
    postData.responsesCount || 0
  );
  const [submitedAdvisorResponseModal, setSubmitedAdvisorResponseModal] =
    useState(false);
  const [commentedPostType, setCommentedPostType] = useState('');
  const isV2Version =
    window.location.pathname.includes('v2') && isSingleBoardMeetingPost;

  let userInfo = {};
  if (type === AccountType.COMPANY) {
    const contacts = accountDetails.contacts || [];
    userInfo = contacts.find((c) => c.id === asLoggedUserDetails.userId);
  }

  let contact = { id: undefined };

  if (type === AccountType.COMPANY) {
    contact = accountDetails.contacts.find(
      (c) => c.id === asLoggedUserDetails.userId
    );
  }
  const responsesEndRef = useRef(null);

  useEffect(() => {
    isLoaded(id)
      .then((response) => {
        setIsLoadingAnswers(false);
        setResponseData(response.data.getResponses);

        // if (isSingleBoardMeetingPost) {
        //   responsesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        // }
      })
      .catch(() => {
        setIsLoadingAnswers(false);
      });

    eventBus.on(`${eventBusValues.onLikePost}${id}`, (event) => {
      setPostLikesCount(event.likesCount);
    });

    eventBus.on(`${eventBusValues.onLikeResponse}${id}`, (event) => {
      setResponseData((responses) => {
        const newResponses = [...responses];
        const updatedIndex = responses.findIndex(
          (response) => response.id === event.id
        );
        if (updatedIndex >= 0) {
          newResponses[updatedIndex].likesCount = event.likesCount;
        }
        return newResponses;
      });
    });

    eventBus.on(`${eventBusValues.onLikeReply}${id}`, (event) => {
      setRepliesData((replies) => {
        const newReplies = [...replies];
        const updatedIndex = replies.findIndex(
          (reply) => reply.id === event.id
        );
        if (updatedIndex >= 0) {
          newReplies[updatedIndex].likesCount = event.likesCount;
        }
        return newReplies;
      });
    });

    eventBus.on(`${eventBusValues.onAddResponse}${id}`, (response) => {
      const accountInfo =
        type === AccountType.COMPANY ? userInfo : accountDetails;
      if (response.responseAccount.id !== accountInfo.id) {
        if (postData.type === PostType.BOARD_MEETING) {
          setResponseData((responses) => [...responses, response]);
        } else {
          setResponseData((responses) => [response, ...responses]);
        }
        setPostResponsesCount((prevValue) => {
          const newValue = prevValue + 1;
          return newValue;
        });
        if (isSingleBoardMeetingPost) {
          responsesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });

    eventBus.on(`${eventBusValues.onAddReply}${id}`, (reply) => {
      const accountInfo =
        type === AccountType.COMPANY ? userInfo : accountDetails;
      if (reply.replyAccount.id !== accountInfo.id) {
        setRepliesData((replies) => [...replies, reply]);
        setResponseData((responses) => {
          const newResponses = responses.map((response) => {
            if (response.id === reply.responseId) {
              response.repliesCount += 1;
            }
            return response;
          });
          return newResponses;
        });
      }
    });

    return () => {
      eventBus.remove(`${eventBusValues.onAddResponse}${id}`);
      eventBus.remove(`${eventBusValues.onAddReply}${id}`);
      eventBus.remove(`${eventBusValues.onLikePost}${id}`);
      eventBus.remove(`${eventBusValues.onLikeResponse}${id}`);
      eventBus.remove(`${eventBusValues.onLikeReply}${id}`);
    };
  }, []);

  useEffect(() => {
    if (responsesData && responsesData.length > 0 && isSinglePost) {
      getPostReplies(id)
        .then((response) => {
          setRepliesData(response.data.getReplies);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [responsesData]);

  useEffect(() => {
    if (newSingleResponseStatus === 'SUCCESS') {
      if (isSingleBoardMeetingPost) {
        setResponseData([...responsesData, newSingleResponse]);
      } else {
        setResponseData([newSingleResponse, ...responsesData]);
      }
      setShowResponse(false);
    }
  }, [newSingleResponseStatus]);

  useEffect(() => {
    if (newSingleReplyStatus === 'SUCCESS') {
      setRepliesData([...repliesData, newSingleReply]);
      setReplyInputValue('');
    }
  }, [newSingleReplyStatus]);

  function submitReply({ P_ID, REPLY_BODY, RESP_ID }) {
    replyLoading(true);
    addReplyCall({
      ACCOUNT_TYPE:
        type === 'company' ? AccountType.CONTACT : AccountType.ADVISOR,
      BODY: REPLY_BODY,
      POST_ID: P_ID,
      RESPONSE_ID: RESP_ID,
      USER_ID: type === AccountType.COMPANY ? contact.id : accountDetails.id,
    })
      .then((response) => {
        setNewSingleReply(response.data.addReply);
        setNewSingleReplyStatus('SUCCESS');
        replyLoading(false);
      })
      .catch((error) => {
        console.log('Add reply error');
        console.log(error);
        setNewSingleReplyStatus('NO_REPLY_CALL');
        replyLoading(false);
        setShowErrorSnackbar(true);
      })
      .finally(() => {
        replyLoading(false);
        setNewSingleReplyStatus('NO_REPLY_CALL');
      });
  }

  // AVATAR ERROR HANDLING

  function renderAnswerAvatarFilteredArray(data) {
    if (data.responseAccount) {
      if (
        data.responseAccount.imageURL === null ||
        data.responseAccount.imageURL.includes('undefined')
      ) {
        return DefaultAdvisorAvatar;
      }
      if (data.responseAccount.imageURL === null) {
        return DefaultAdvisorAvatar;
      }
      if (data.responseAccount.imageURL.startsWith('public')) {
        return getAdvisorImagePath({
          image: { imageKey: data.responseAccount.imageURL },
        });
      }
      return data.responseAccount.imageURL;
    }
    return DefaultAdvisorAvatar;
  }

  function advisorHasCommented(advisors) {
    for (const advisor of advisors) {
      if (accountDetails.id === advisor.id) {
        return false;
      }
    }
    return true;
  }

  function renderReplyAvatarFilteredArray(data) {
    if (data.replyAccount) {
      if (!data.replyAccount.imageURL) {
        return DefaultAdvisorAvatar;
      }
      if (data.replyAccount.imageURL.startsWith('public')) {
        return getAdvisorImagePath({
          image: { imageKey: data.replyAccount.imageURL },
        });
      }
      return data.replyAccount.imageURL;
    }
    return DefaultAdvisorAvatar;
  }

  // SHOW HIDE INPUT RESPONSE/REPLY
  function singlePostCommentIconClick() {
    if (isSinglePost) {
      setShowResponse(!showResponse);
      setFreeTrialRedirection(false);
    } else {
      postCommentsIconClick();
    }
  }

  function makeReply(data) {
    setResponseIndex(data);
    setReplyInputValue('');
    setShowReply(!showReply);
    setFreeTrialRedirection(false);
  }
  async function makeDashboardReply(data) {
    setResponseIndex(data);
    setIsLoading(true);
    const replies = await getPostRepliesCall({
      ACCOUNT_TYPE:
        type === 'company' ? AccountType.CONTACT : AccountType.ADVISOR,
      LIMIT: 50,
      OFFSET: 0,
      ONLY_BREAKTHROUGHS: false,
      POST_ID: postData.id,
      USER_ID: type === AccountType.COMPANY ? contact.id : accountDetails.id,
    });
    setRepliesData(replies.data.getReplies);
    setIsLoading(false);
    setReplyInputValue('');
    setShowReply(!showReply);
    setFreeTrialRedirection(false);
  }

  function handleResponseInput(event) {
    if (isFreeTrial && !isFreemium && type === AccountType.ADVISOR) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction, {
        gatedAction: true,
        companyName: postData.postCompanyContact.companyName,
      });
    } else {
      setResponseInputValue(event.target.value);
    }
  }

  function handleReplyInput(event) {
    if (isFreeTrial && !isFreemium && type === AccountType.ADVISOR) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction, {
        gatedAction: true,
        companyName: postData.postCompanyContact.companyName,
      });
    } else {
      setReplyInputValue(event.target.value);
    }
  }

  function attachmentsArray(data) {
    if (isNil(data)) return [];
    const results = data.map((element) => {
      const finalUrl = element.thumbnailUrl || element.url;
      return {
        url:
          finalUrl && finalUrl.includes('.pdf')
            ? finalUrl
            : `https://${finalUrl}`,
        name: element.filename,
      };
    });
    return results;
  }

  function renderPrimaryAttachment(data) {
    if (!isNil(data) && data !== null) {
      const finalUrl = data.thumbnailUrl || data.url;
      return `https://${finalUrl}`;
    }
    return null;
  }

  function editPost(postIndexReplace, isReply, newPost) {
    if (isReply) {
      const newPosts = [
        ...repliesData.slice(0, postIndexReplace),
        newPost,
        ...repliesData.slice(postIndexReplace + 1),
      ];
      setRepliesData(newPosts);
    } else {
      const newPosts = [
        ...responsesData.slice(0, postIndexReplace),
        newPost,
        ...responsesData.slice(postIndexReplace + 1),
      ];
      setResponseData(newPosts);
    }
  }

  function getPostBasedOnPostId(postId, isReply) {
    if (isReply) {
      const resultPost = repliesData.filter((element) => element.id === postId);
      return resultPost.length > 0 ? resultPost[0] : null;
    }
    const resultPost = responsesData.filter((element) => element.id === postId);
    return resultPost.length > 0 ? resultPost[0] : null;
  }

  function isAlreadyLiked(postLikesArray, userId) {
    return (
      postLikesArray.filter((element) => element.userId === userId).length > 0
    );
  }

  function addPostLike(likeDTO) {
    let post = getPostBasedOnPostId(likeDTO.postId, likeDTO.isReply);

    if (post) {
      if (!isAlreadyLiked(likeDTO.postLikes, likeDTO.userId)) {
        post = {
          ...post,
          likesCount: post.likesCount + 1,
          likes: [
            ...post.likes,
            {
              accountType: likeDTO.type,
              name: likeDTO.name,
              userId: likeDTO.userId,
            },
          ],
        };
      } else {
        post = {
          ...post,
          likesCount: post.likesCount - 1,
          likes: post.likes.filter(
            (likeElement) => likeElement.userId !== likeDTO.userId
          ),
        };
      }
      editPost(likeDTO.index, likeDTO.isReply, post);
    }
  }

  function submitResponse({ P_ID, RESPONSE_BODY, post }) {
    setPostingResponse(true);
    advisorAddResponseCall({
      ACCOUNT_TYPE:
        type === AccountType.COMPANY
          ? AccountType.CONTACT
          : AccountType.ADVISOR,
      BODY: RESPONSE_BODY,
      POST_ID: P_ID,
      USER_ID: type === AccountType.COMPANY ? contact.id : accountDetails.id,
    })
      .then((response) => {
        setCommentedPostType(post.type);
        post.acceptResponses = false;
        setReplyInputValue('');
        setResponseInputValue('');
        setNewSingleResponse(response.data.addResponse);
        setNewSingleResponseStatus('SUCCESS');
        setPostingResponse(false);
        setShowResponse(false);
        // Dialog open
        if (!userPreferences.hideTrustPilot) {
          setReviewPopUpOpen(true);
        }
        if (isSingleBoardMeetingPost) {
          responsesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        if (
          type === AccountType.ADVISOR &&
          (post.type === PostType.QUESTION || post.type === PostType.UPDATE)
        ) {
          setSubmitedAdvisorResponseModal(true);
        }
      })
      .catch(() => {
        setNewSingleResponseStatus('NO_RESPONSE_CALL');
        setPostingResponse(false);
        setShowErrorSnackbar(true);
      })
      .finally(() => {
        setNewSingleResponse();
        setNewSingleResponseStatus('NO_RESPONSE_CALL');
      });
  }

  function answerLikeAction(postInfo) {
    if (postInfo.responseAccount.id === asLoggedUserDetails.userId) {
      setErrorMessage('Not allowed to like your own content');
      setShowErrorSnackbar(true);
    } else if (postInfo.isReply) {
      addPostLike(postInfo);
      likeReplyCall({
        ACCOUNT_TYPE:
          type === AccountType.COMPANY
            ? AccountType.CONTACT
            : AccountType.ADVISOR,
        USER_ID: asLoggedUserDetails.userId,
        REPLY_ID: postInfo.postId,
      });
    } else {
      addPostLike(postInfo);
      likeResponseCall({
        ACCOUNT_TYPE:
          type === AccountType.COMPANY
            ? AccountType.CONTACT
            : AccountType.ADVISOR,
        USER_ID: asLoggedUserDetails.userId,
        RESPONSE_ID: postInfo.postId,
      });
    }
  }

  function setUserName() {
    if (type === AccountType.COMPANY) {
      return `${contact.givenName} ${contact.surName}`;
    }
    return accountDetails.givenName;
  }

  function setSubtitle(accountObject, createdAt) {
    if (accountObject.accountType === AccountType.ADVISOR) {
      return (
        <TimeElapsed
          initialTime={createdAt}
          dashboardFormat
          beforeText={'Advisor -'}
        />
      );
    } else if (accountObject.accountType === AccountType.CONTACT) {
      return (
        <TimeElapsed
          initialTime={createdAt}
          dashboardFormat
          beforeText={'Board Owner -'}
        />
      );
    }
    return '';
  }

  function userIsOwner(data) {
    return data.elementAccount.id === asLoggedUserDetails.userId;
  }

  function showStar(accountType) {
    return type === AccountType.COMPANY && accountType === AccountType.ADVISOR;
  }

  function showProfilePill(accountType) {
    if (type === AccountType.ADVISOR && accountType === AccountType.CONTACT)
      return 'Company Member';
    return false;
  }

  function getContributorAvatars(contributors) {
    let avatarsArr = [];
    if (
      contributors &&
      contributors.length &&
      contributors.length > 0 &&
      new Date(postData.createdAt) > new Date('2022-06-20')
    ) {
      avatarsArr = contributors.map((contr) => {
        if (contr.imageURL) {
          return `${process.env.REACT_APP_IMAGES_URL}${contr.imageURL}`;
        }
        return null;
      });
    }
    return avatarsArr;
  }

  function getClass(postClass, staticClass) {
    return isFirst ? staticClass : postClass;
  }

  function updateElementAction(input, elementInfo, isResponse) {
    // element info contains the response/reply
    const elementAccountId = isResponse
      ? elementInfo.responseAccount.id
      : elementInfo.replyAccount.id;

    if (elementAccountId !== asLoggedUserDetails.userId) {
      setErrorMessage('Not allowed to modify this content');
      setShowErrorSnackbar(true);
    } else if (isResponse) {
      companyUpdateResponseCall({
        ACCOUNT_TYPE:
          type === AccountType.COMPANY
            ? AccountType.CONTACT
            : AccountType.ADVISOR,
        BODY: input,
        COMPANY_ID: type === AccountType.COMPANY ? accountDetails.id : '',
        USER_ID: asLoggedUserDetails.userId,
        RESPONSE_ID: elementInfo.id,
      }).catch(() => {
        setErrorMessage('Response update failed, please try again.');
        setShowErrorSnackbar(true);
      });
    } else {
      companyUpdateReplyCall({
        ACCOUNT_TYPE:
          type === AccountType.COMPANY
            ? AccountType.CONTACT
            : AccountType.ADVISOR,
        BODY: input,
        COMPANY_ID: type === AccountType.COMPANY ? accountDetails.id : '',
        USER_ID: asLoggedUserDetails.userId,
        REPLY_ID: elementInfo.id,
      }).catch(() => {
        setErrorMessage('Reply update failed, please try again.');
        setShowErrorSnackbar(true);
      });
    }
  }

  function displayViewMoreText(answerType) {
    if (answerType === 'comment') {
      return t('POST-COMMENT-VIEW-MORE-TEXT');
    }
    return t('POST-ANSWER-VIEW-MORE-TEXT');
  }

  function displayUserImage() {
    if (
      type === AccountType.COMPANY &&
      !userInfo.image.location.endsWith('undefined')
    ) {
      return `https://${userInfo.image.location}`;
    }
    return getAdvisorImagePath(accountDetails);
  }

  function seeAdvisorProfile(advisorId, boardRoomView = false, source = null) {
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      fetchAdvisor: true,
      id: advisorId,
      boardRoomView,
      source,
    });
  }

  function advisorSeeAdvisor(clickedUser, userType) {
    if (userType === AccountType.ADVISOR) {
      return () =>
        seeAdvisorProfile(clickedUser, accountDetails.id !== clickedUser);
    }
    return null;
  }

  function getAnswerURL(answerId, postInfo) {
    const url = `${window.location.origin}/post/${postInfo.id}?answerId=${answerId}`;
    return url;
  }

  function pinPost(postId, companyId, isPinned) {
    pinPostCall({
      COMPANY_ID: companyId,
      POST_ID: postId,
      UN_PIN: isPinned,
    })
      .then((response) => {
        setShowSuccesssPinned(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getTopValue() {
    let top = 0;
    if (isV2Version) {
      return '80px';
    }

    if (type === AccountType.COMPANY) {
      top += 90;
    }
    if (type === AccountType.ADVISOR) {
      top += 75;
    }
    if (isFreeTrial) {
      top += 50;
    }
    return `${top}px`;
  }

  function filterReplies(data) {
    const filteredArrayReplies = repliesData.filter((replyElement) => {
      return replyElement.responseId === data;
    });

    return (
      <>
        {filteredArrayReplies.map((element, index) => {
          return (
            <AnswerCard
              key={element.id}
              likes={element.likesCount}
              likesFilled={
                element &&
                element.likes &&
                element.likes.filter(
                  (reply) => reply.userId === asLoggedUserDetails.userId
                ).length > 0
              }
              likesClick={() =>
                answerLikeAction({
                  postId: element.id,
                  userId: asLoggedUserDetails.userId,
                  companyId: type === AccountType.COMPANY && accountDetails.id,
                  postCompanyId: element.companyId,
                  accountType: type,
                  name: setUserName(),
                  postLikes: element.likes,
                  responseAccount: element.replyAccount,
                  isReply: true,
                  index,
                })
              }
              body={element.body}
              createdAt={element.createdAt}
              answerAvatar={renderReplyAvatarFilteredArray(element)}
              answerName={
                element.replyAccount.accountType === AccountType.ADVISOR
                  ? element.replyAccount.name
                  : element.replyAccount.companyName
              }
              subtitle={setSubtitle(element.replyAccount, element.createdAt)}
              answerBodyDisabled
              isOwner={userIsOwner({
                elementAccount: element.replyAccount,
              })}
              updateValue={(input) =>
                updateElementAction(input, element, false)
              }
              isStar={showStar(element.replyAccount.accountType)}
              userId={element.replyAccount.id}
              answerUrl={getAnswerURL(element.id, postData)}
              ProfileHighlighterType={showProfilePill(
                element.replyAccount.accountType
              )}
              avatarClick={
                showStar(element.replyAccount.accountType)
                  ? () =>
                      seeAdvisorProfile(element.replyAccount.id, false, 'reply')
                  : advisorSeeAdvisor(
                      element.replyAccount.id,
                      element.replyAccount.accountType
                    )
              }
            />
          );
        })}
      </>
    );
  }

  function answersArray(questionType) {
    if (dashboardFormat && !questionType) {
      const filteredArray = responsesData.slice(0, totalCommentsCount);
      return (
        <>
          {filteredArray.map((answer, index) => {
            return (
              <div key={answer.id} style={classes.answersArrayDashboard}>
                <AnswerCard
                  body={answer.body}
                  likes={answer.likesCount}
                  likesFilled={
                    answer &&
                    answer.likes &&
                    answer.likes.filter(
                      (element) => element.userId === asLoggedUserDetails.userId
                    ).length > 0
                  }
                  likesClick={() => {
                    if (removeResponseActions) return;
                    answerLikeAction({
                      postId: answer.id,
                      userId: asLoggedUserDetails.userId,
                      companyId:
                        type === AccountType.COMPANY && accountDetails.id,
                      postCompanyId: answer.companyId,
                      accountType: type,
                      name: setUserName(),
                      postLikes: answer.likes,
                      responseAccount: answer.responseAccount,
                      isReply: false,
                      index,
                    });
                  }}
                  comments={answer.repliesCount}
                  commentsClick={() => makeDashboardReply(index)}
                  answerAvatar={renderAnswerAvatarFilteredArray(answer)}
                  answerName={
                    answer.responseAccount.accountType === AccountType.ADVISOR
                      ? answer.responseAccount.name
                      : answer.responseAccount.companyName
                  }
                  subtitle={setSubtitle(
                    answer.responseAccount,
                    answer.createdAt
                  )}
                  createdAt={answer.createdAt}
                  answerBodyClick={() => answerBodyClick(answer.id)}
                  isOwner={userIsOwner({
                    elementAccount: answer.responseAccount,
                  })}
                  updateValue={(input) =>
                    updateElementAction(input, answer, true)
                  }
                  isStar={showStar(answer.responseAccount.accountType)}
                  isHighlighted={postAnswersHighlighted === answer.id}
                  answerUrl={getAnswerURL(answer.id, postData)}
                  userId={answer.responseAccount.id}
                  ProfileHighlighterType={showProfilePill(
                    answer.responseAccount.accountType
                  )}
                  avatarClick={
                    showStar(answer.responseAccount.accountType)
                      ? () =>
                          seeAdvisorProfile(
                            answer.responseAccount.id,
                            false,
                            'response'
                          )
                      : advisorSeeAdvisor(
                          answer.responseAccount.id,
                          answer.responseAccount.accountType
                        )
                  }
                />
                {isLoading && index === responseIndex ? (
                  <div style={classes.profileContainer}>
                    <LoadingIndicatorLogo
                      size={100}
                      iconFontSize={47}
                      iconRight={49}
                    />
                  </div>
                ) : null}
                {showReply &&
                  !freeTrialRedirection &&
                  index === responseIndex && (
                    <div style={classes.answerReplyContainer}>
                      {filterReplies(answer.id)}
                      <Reply
                        // TODO UPDATE ADVISOR IMAGE
                        image={displayUserImage()}
                        onChange={handleReplyInput}
                        value={replyInputValue}
                        type={
                          type === AccountType.COMPANY ? 'response' : 'reply'
                        }
                        showCancel={type === AccountType.COMPANY}
                        onCancel={() => setReplyInputValue('')}
                        onSubmit={(data) => {
                          submitReply({
                            ACCOUNT_TYPE: type,
                            REPLY_BODY: data,
                            P_ID: id,
                            RESP_ID: answer.id,
                          });
                        }}
                        loading={repLoad}
                      />
                    </div>
                  )}
              </div>
            );
          })}
          {responsesData.length > totalCommentsCount ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 0,
                padding: 0,
              }}
            >
              <Typography
                style={classes.moreAnswersText}
                onClick={() => setTotalCommentsCount(totalCommentsCount + 10)}
              >
                {displayViewMoreText(responsesData[0].responseType)}
              </Typography>
            </div>
          ) : null}
        </>
      );
    }

    return (
      <div
        style={{
          marginBottom:
            isSinglePost && !isSingleBoardMeetingPost ? '100px' : '20px',
          height: isSingleBoardMeetingPost ? '100%' : 'auto',
          overflowY: isSingleBoardMeetingPost ? 'auto' : 'unset',
        }}
      >
        {responsesData.map((answer, index) => {
          return (
            <div
              key={answer.id}
              style={{
                padding: isSingleBoardMeetingPost
                  ? '0px 20px 0px 20px'
                  : '20px',
                ...(postAnswersHighlighted === answer.id
                  ? classes.answerContainerHighlighted
                  : classes.answerContainer),
              }}
            >
              <AnswerCard
                body={answer.body}
                likes={answer.likesCount}
                likesFilled={
                  answer &&
                  answer.likes &&
                  answer.likes.filter(
                    (element) => element.userId === asLoggedUserDetails.userId
                  ).length > 0
                }
                likesClick={() =>
                  answerLikeAction({
                    postId: answer.id,
                    userId: asLoggedUserDetails.userId,
                    companyId:
                      type === AccountType.COMPANY && accountDetails.id,
                    postCompanyId: answer.companyId,
                    accountType: type,
                    name: setUserName(),
                    postLikes: answer.likes,
                    responseAccount: answer.responseAccount,
                    isReply: false,
                    index,
                  })
                }
                comments={
                  !isSingleBoardMeetingPost ? answer.repliesCount : null
                }
                commentsClick={() => makeReply(index)}
                commentsDisabled={answerCommentsIconDisabled}
                answerAvatar={renderAnswerAvatarFilteredArray(answer)}
                answerName={
                  answer.responseAccount.accountType === AccountType.ADVISOR
                    ? answer.responseAccount.name
                    : answer.responseAccount.companyName
                }
                subtitle={setSubtitle(answer.responseAccount, answer.createdAt)}
                createdAt={answer.createdAt}
                answerBodyDisabled={isSingleBoardMeetingPost}
                answerBodyClick={() => makeReply(index)}
                isOwner={userIsOwner({
                  elementAccount: answer.responseAccount,
                })}
                updateValue={(input) =>
                  updateElementAction(input, answer, true)
                }
                isStar={showStar(answer.responseAccount.accountType)}
                userId={answer.responseAccount.id}
                isHighlighted={postAnswersHighlighted === answer.id}
                answerUrl={getAnswerURL(answer.id, postData)}
                ProfileHighlighterType={showProfilePill(
                  answer.responseAccount.accountType
                )}
                avatarClick={
                  showStar(answer.responseAccount.accountType)
                    ? () =>
                        seeAdvisorProfile(
                          answer.responseAccount.id,
                          false,
                          'response'
                        )
                    : advisorSeeAdvisor(
                        answer.responseAccount.id,
                        answer.responseAccount.accountType
                      )
                }
              />
              {showReply &&
                !freeTrialRedirection &&
                index === responseIndex && (
                  <div style={classes.answerReplyContainer}>
                    {filterReplies(answer.id)}

                    <Reply
                      image={displayUserImage()}
                      onChange={handleReplyInput}
                      value={replyInputValue}
                      type={type === AccountType.COMPANY ? 'response' : 'reply'}
                      showCancel={type === AccountType.COMPANY}
                      onCancel={() => setReplyInputValue('')}
                      onSubmit={(data) => {
                        submitReply({
                          ACCOUNT_TYPE: type,
                          REPLY_BODY: data,
                          P_ID: id,
                          RESP_ID: answer.id,
                        });
                      }}
                      loading={repLoad}
                    />
                  </div>
                )}
            </div>
          );
        })}
        <div ref={responsesEndRef} />
      </div>
    );
  }

  return (
    <div
      className={`${
        postData.type === PostType.BOARD_MEETING ? 'boardMeetingPost' : ''
      }`}
      style={{
        ...classes.container,
        ...(isSingleBoardMeetingPost ? classes.containerFlex : {}),
      }}
    >
      <div
        className={getClass('', 'boardroomFeaturedPost')}
        style={{
          backgroundColor: 'white',
          borderBottom:
            postData.type === PostType.QUESTION
              ? ''
              : '1px solid rgb(230,237,254)',
          padding: isSM && isSingleBoardMeetingPost ? 10 : 20,
          ...(isSingleBoardMeetingPost
            ? {
                width: isSM ? '100%' : '55%',
              }
            : {}),
        }}
      >
        <ACSnackbar
          open={showErrorSnackbar}
          severity="error"
          text={errorMessage}
          onClose={() => setShowErrorSnackbar(false)}
          style={{ marginTop: isFreemium ? '50px' : '0px' }}
        />
        <ACSnackbar
          open={showSuccessPinned}
          severity="success"
          text="Post Updated"
          onClose={() => setShowSuccesssPinned(false)}
          style={{ marginTop: isFreemium ? '50px' : '0px' }}
        />
        <PostCard
          dashboardFormat={dashboardFormat}
          // body
          postData={postData}
          body={postData.body}
          attachments={
            postData.attachments !== null
              ? attachmentsArray(postData.attachments)
              : null
          }
          primaryAttachment={renderPrimaryAttachment(
            postData.primaryAttachment
          )}
          video={
            postData.video && postData.video.url ? postData.video.url : false
          }
          // likes
          likes={postLikesCount}
          likesDisabled={postLikesIconDisabled}
          likesClick={() => postLikesIconClick()}
          likesFilled={postLikesFilled}
          // answers
          answers={
            postData.type === PostType.UPDATE ? null : postResponsesCount
          }
          answersFilled={postAnswersFilled}
          answersDisabled={type === AccountType.COMPANY}
          answersClick={() => singlePostCommentIconClick()}
          // comments
          comments={
            postData.type === PostType.QUESTION || !postData.acceptResponses
              ? null
              : postResponsesCount
          }
          commentsFilled={postCommentsFilled}
          commentsDisabled={postCommentsIconDisabled}
          commentsClick={() => singlePostCommentIconClick()}
          // service clicks
          bodyClick={postBodyClick}
          // date and preview format
          type={postData.type}
          createdAt={postData.createdAt}
          companyName={
            postData.postCompanyContact
              ? postData.postCompanyContact.companyName
              : AccountType.COMPANY
          }
          companyAvatar={
            !isNil(postData) &&
            !isNil(postData.postCompanyContact) &&
            !isNil(postData.postCompanyContact.imageURL) &&
            postData.postCompanyContact.imageURL !== ''
              ? `${postData.postCompanyContact.imageURL}`
              : DefaultAdvisorAvatar
          }
          contributorsDisabled={contributorsDisabled}
          contributorAvatars={getContributorAvatars(postData.contributors)}
          noEllipsis={type === AccountType.ADVISOR}
          editClick={() => editClick()}
          pinClick={
            type === AccountType.COMPANY && isImpersonated
              ? () =>
                  pinPost(postData.id, postData.companyId, postData.isPinned)
              : false
          }
          isPinned={
            postData.isPinned &&
            (type === AccountType.ADVISOR || isImpersonated)
          }
          linkAtTheEnd={postData.linkAtTheEnd}
          isFirst={isFirst}
          isOpportunityView={isOpportunityView}
          isSingleBoardMeetingPost={isSingleBoardMeetingPost}
          boardMeetingLocked={
            type === AccountType.ADVISOR &&
            accountDetails.boards &&
            accountDetails.boards.memberBoards &&
            accountDetails.boards.memberBoards.findIndex(
              (board) => board.id === postData.companyId
            ) === -1
          }
        />
        {postData.type === PostType.QUESTION && postResponsesCount > 0 && (
          <Box
            sx={{
              marginTop: '40px',
              borderTop: '1px solid #EFF0F6',
            }}
          />
        )}
      </div>
      {isSingleBoardMeetingPost && (
        <div
          style={{
            top: getTopValue(),
            marginLeft: isSM ? '0px' : '20px',
            padding: isSM ? '0px' : '20px',
            width: isSM ? '100%' : '42%',
            ...classes.meetingPostContainer,
            ...(!isSM ? classes.meetingPostContainerNormalSize : {}),
            ...(isV2Version
              ? {
                  maxWidth: 'none',
                }
              : {}),
          }}
        >
          <BoardMeetingCommentsHeader
            contributors={getContributorAvatars(postData.contributors)}
          />
          {isLoadingAnswers && (
            <div style={classes.loadingContainer}>
              <CircularProgress />
            </div>
          )}
          {responsesData && responsesData.length > 0 && !isLoadingAnswers ? (
            answersArray()
          ) : (
            <div
              style={{
                height: '100%',
                padding: 0,
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                overflowY: 'auto',
                flexDirection: 'column',
              }}
            >
              <CustomIcon
                iconname="comments"
                classes={{
                  root: classes.acIcon,
                }}
              />
              <Typography
                style={{
                  fontStyle: 'normal',
                  fontWeight: 400,
                  fontSize: '16px',
                  textAlign: 'center',
                  color: '#646D7A',
                  width: '70%',
                }}
              >
                Meeting comments will appear here during the meeting
              </Typography>
            </div>
          )}
          <BoardMeetingCommentsBox
            image={displayUserImage()}
            onChange={handleResponseInput}
            value={responseInputValue}
            onSubmit={(data) => {
              submitResponse({
                P_ID: id,
                RESPONSE_BODY: data,
                post: postData,
              });
            }}
            loading={responseLoading || postingResponse}
          />
        </div>
      )}
      {postData.type === 'question' &&
      type === AccountType.ADVISOR &&
      !userPreferences.hideTrustPilot &&
      !isLoadingAnswers ? (
        <>
          <ReviewPopUp
            postData={postData}
            reviewPopUpOpen={reviewPopUpOpen}
            setReviewPopUpOpen={setReviewPopUpOpen}
          />
        </>
      ) : null}
      {type === AccountType.ADVISOR &&
      (postData.type === PostType.QUESTION ||
        postData.type === PostType.UPDATE) ? (
        <ResponseSubmitedModal
          open={submitedAdvisorResponseModal}
          setOpen={setSubmitedAdvisorResponseModal}
          type={commentedPostType}
        />
      ) : null}

      {/* {isLoadingAnswers && !isSingleBoardMeetingPost && (
        <div style={classes.loadingContainer}>
          <CircularProgress />
        </div>
      )} */}

      {isLoadingAnswers && <AnswerSkeleton />}

      {advisorHasCommented(postData.contributors) && !isLoadingAnswers && (
        <>
          {(postData.type === PostType.QUESTION &&
            type === AccountType.COMPANY) ||
          postData.type === PostType.BOARD_MEETING ||
          isSingleBoardMeetingPost ? null : (
            <>
              {!removeResponse &&
              !freeTrialRedirection &&
              postData.acceptResponses ? (
                <div
                  className={getClass('', 'boardroomFeaturedAnswer')}
                  style={classes.responseContainer}
                >
                  <Reply
                    image={displayUserImage()}
                    onChange={handleResponseInput}
                    value={responseInputValue}
                    onCancel={() => setResponseInputValue('')}
                    showCancel
                    type="response"
                    onSubmit={(data) => {
                      submitResponse({
                        P_ID: id,
                        RESPONSE_BODY: data,
                        post: postData,
                      });
                    }}
                    loading={responseLoading || postingResponse}
                  />
                </div>
              ) : null}
            </>
          )}
        </>
      )}
      {responsesData &&
        responsesData.length > 0 &&
        !isLoadingAnswers &&
        !isSingleBoardMeetingPost &&
        postData.type !== PostType.BOARD_MEETING &&
        answersArray('questionType')}
    </div>
  );
}

export default PostAnswer;
