import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Collapse, ListItem, ListItemText } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function MobileOptionsMenuItem(props) {
  const {
    id,
    label,
    onClick,
    paths = [],
    options = [],
    toggleDrawer = () => {},
    listItemStyle = {},
  } = props;
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const isActive = paths.includes(location.pathname);

  function renderCollapse() {
    return (
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        sx={{ paddingLeft: '24px' }}
      >
        {options.map((item, index) => {
          return (
            <ListItem
              key={index}
              id={item.id}
              sx={{
                p: 0,
                '&:not(:last-child)': { mb: 1 },
              }}
            >
              <Button
                onClick={() => {
                  item.onClick();
                  toggleDrawer(false);
                  setIsOpen(false);
                }}
                sx={listItemStyle}
              >
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    sx: { fontSize: '16px', lineHeight: '1.5' },
                  }}
                />
              </Button>
            </ListItem>
          );
        })}
      </Collapse>
    );
  }

  function handleMenuItemClick() {
    setIsOpen(!isOpen);

    if (onClick) {
      onClick();
    }
  }

  return (
    <>
      <ListItem
        id={id}
        sx={{
          p: 0,
          mb: 1,
        }}
      >
        <Button
          onClick={handleMenuItemClick}
          sx={{
            ...listItemStyle,
            color: isActive ? '#2563eb' : '#000',
            fontWeight: isActive ? 'bold' : 'normal',
            display: 'flex',
          }}
        >
          <ListItemText
            primary={label}
            primaryTypographyProps={{
              sx: { fontSize: '16px', lineHeight: '1.5' },
            }}
          />
          <ArrowDropDownIcon />
        </Button>
      </ListItem>

      {renderCollapse()}
    </>
  );
}

export default MobileOptionsMenuItem;
