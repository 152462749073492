import React, { useEffect, useState } from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LockOutlined } from '@mui/icons-material';
import AvatarsInvolved from './../../AvatarsInvolved';
import {
  getAdvisorRecurlyPlan,
  getCompanyPreviewPosts,
  getCompanyRecentAdvisors,
  getUpcomingBoardMeetings,
} from './../../../services/advisor_services';
import BoardJoinButton from './../BoardJoinButton';
import LoadingIndicatorLogo from './../../LoadingIndicatorLogo';
import { useAccount } from './../../../contexts/Account';
import { getCompanyBySlug } from './../../../services/company_services';
import CurrentGoal from '../CurrentGoal';
import BoardMeetingCard from '../../BoardMeetingsCard';

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topContainer: {
    color: '#232B35',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    backgroundColor: '#F5F7FA',
    borderRadius: '0 105px 0 0',
    border: '0px solid black',
    padding: '50px 16px 120px 16px',
  },
  postsContainer: {
    width: '100%',
    pointerEvents: 'none',
    backgroundColor: '#FBFCFD',
  },
  postContainer: {
    width: '100%',
    border: '1px solid rgb(230,237,254)',
    borderRadius: '5px',
    marginBottom: '30px',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'rgba(251, 252, 253, 0.9)',
    backdropFilter: 'blur(5px)',
    width: '100%',
    textAlign: 'center',
    color: 'white',
  },
  title: {
    display: 'inline',
    fontSize: '20px',
    color: '#232B35',
    fontWeight: 600,
  },
  subtitle: {
    fontSize: '16px',
    color: '#646D7A',
    fontWeight: 400,
  },
  subtitle2: {
    fontSize: '16px',
    color: '#646D7A',
  },
  subtitleContainer: {
    maxWidth: '550px',
  },
  bottomSubtitleContainer: {
    maxWidth: '320px',
  },
  newMembersText: {
    fontSize: '14px',
    color: '#232B35',
    fontWeight: 400,
  },
  membersContainer: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: '0px',
    paddingBottom: '0px',
    width: '100%',
  },
};

function CompanyBoardPreview(props) {
  const { company, companyId, companyName, companyCEO, invitation } = props;

  const { accountDetails } = useAccount();
  const { t } = useTranslation();

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const [isLoadingPosts, setIsLoadingPosts] = useState(true);
  const [isLoadingAdvisors, setIsLoadingAdvisors] = useState(true);
  const [companyFreemium, setCompanyFreemium] = useState(false);
  const [postList, setPostList] = useState([]);
  const [advisorAvatar, setAdvisorAvatar] = useState([]);
  const [futureBoardMeetings, setFutureBoardMeetings] = useState([]);
  const [soonestBoardMeeting, setSoonestBoardMeeting] = useState(null);

  const [isJoined, setIsJoined] = useState(
    accountDetails.boards.memberBoards.some((bc) => bc.id === companyId)
  );

  useEffect(() => {
    async function getPosts() {
      setIsLoadingPosts(true);
      getCompanyPreviewPosts({ COMPANY_ID: companyId }).then((response) => {
        setIsLoadingPosts(false);
        if (
          response.data &&
          response.data.getCompanyPreviewPosts &&
          response.data.getCompanyPreviewPosts.posts &&
          response.data.getCompanyPreviewPosts.posts.length
        ) {
          const newPosts = [];
          response.data.getCompanyPreviewPosts.posts.forEach((post) => {
            const temp = post;
            if (
              response.data.getCompanyPreviewPosts.responses &&
              response.data.getCompanyPreviewPosts.responses.length
            ) {
              temp.responses =
                response.data.getCompanyPreviewPosts.responses.filter(
                  (res) => res.postId === post.id
                );
            }
            newPosts.push(temp);
          });
          setPostList(newPosts);
        }
      });
    }

    async function getFutureBoardMeetings() {
      setIsLoadingPosts(true);
      getUpcomingBoardMeetings({ advisorId: accountDetails.id }).then((response) => {
        setFutureBoardMeetings(response.data.getUpcomingBoardMeetings);
        setIsLoadingPosts(false);
      });
    }


    async function getAdvisors() {
      setIsLoadingAdvisors(true);
      getCompanyRecentAdvisors({ COMPANY_ID: companyId }).then((response) => {
        setIsLoadingAdvisors(false);
        const tempAdvisors = response.data.getCompanyRecentAdvisors;
        let avatarsArr = [];
        if (tempAdvisors && tempAdvisors.length && tempAdvisors.length > 0) {
          avatarsArr = tempAdvisors.map((advisor) => {
            if (advisor.image && advisor.image.imageKey) {
              return `${process.env.REACT_APP_IMAGES_URL}${advisor.image.imageKey}`;
            }
            return null;
          });
        }
        setAdvisorAvatar(avatarsArr);
      });
    }
    getCompanyBySlug({ slug: company.slug }).then(({ data }) => {
      if (data.getCompany) {
        try {
          async function fetchData() {
            const planResponses = await getAdvisorRecurlyPlan({
              recurlyPlanCode: data.getCompany.recurlyPlanCode,
            });
            if (
              planResponses &&
              planResponses.data &&
              planResponses.data.getRecurlyPlan.isFreemium &&
              planResponses.data.getRecurlyPlan.isFreemium === true
            ) {
              setCompanyFreemium(true);
            }
          }
          fetchData();
        } catch (error) {
          console.log('error:', error);
        }
      }
    });

    getPosts();
    getAdvisors();
    getFutureBoardMeetings();
    setIsJoined(
      accountDetails.boards.memberBoards.some((bc) => bc.id === companyId)
    );
  }, [companyId]);


  function newestBoardMembersContainer() {
    if (advisorAvatar && advisorAvatar.length && advisorAvatar.length >= 1) {

      if (isSM) {
        return (
          <div className="newestBoardMembers" style={classes.membersContainer}>
            <div>
              <Typography
                style={{ whiteSpace: 'nowrap', fontSize: 16, fontWeight: 200 }}
              >
                {t('NEWEST-BOARD-MEMBERS')}
              </Typography>
            </div>
            <div style={{ marginTop: '10px' }}>
              <AvatarsInvolved
                noText
                avatars={advisorAvatar}
                avatarSize={'md'}
              />
            </div>
          </div>
        );
      }
      return (
        <div
          className="newestBoardMembers"
          style={{
            ...classes.container, ...{
              alignItems: isSM ? 'flex-end' : 'flex-start',
            }
          }}>
          <div>
            <Typography
              style={{ whiteSpace: 'nowrap' }}
              sx={classes.newMembersText}
            >
              {t('NEWEST-BOARD-MEMBERS')}
            </Typography>
          </div>
          <div style={{ marginTop: '10px' }}>
            <AvatarsInvolved noText avatars={advisorAvatar} avatarSize={'md'} />
          </div>
        </div>
      );
    }
    return null;
  }

  function findNextBoardMeeting(posts) {
    return posts
      .filter(post => post.type === 'boardMeeting' && post.meetingTime)
      .map(post => ({
        ...post,
        meetingTime: new Date(post.meetingTime),
      }))
      .sort((a, b) => a.meetingTime - b.meetingTime).shift() || false;
  }

  useEffect(() => {
    const nextMeeting = findNextBoardMeeting(futureBoardMeetings) || findNextBoardMeeting(postList);
    setSoonestBoardMeeting(nextMeeting);
  }, [futureBoardMeetings, postList]);

  function PreviewBoardMeetings() {
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: 3, rowGap: 3 }}>
          <CurrentGoal goal={company.currentGoal} />
          {
            soonestBoardMeeting &&
            <Box sx={{ pointerEvents: 'none', width: '100%', display: 'flex', justifyContent: 'center' }}>
              <BoardMeetingCard key={soonestBoardMeeting.id || ""} company={company} boardMeeting={soonestBoardMeeting || {}} />
            </Box>
          }

        </Box>
      </>
    )
  }

  return (
    <>
      {
        (isLoadingPosts || isLoadingAdvisors) ?
          <div
            style={{
              paddingTop: '10%',
              paddingBottom: '10%',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LoadingIndicatorLogo size={150} iconFontSize={65} iconRight={77} />
          </div>
          :
          <div style={classes.container}>
            <div
              style={{
                ...classes.topContainer,
                flexDirection: 'column',
                width: (isSM || isXS) ? 'unset' : '100%',
                marginInline: isSM ? '-16px' : '0',
              }}
            >
              <div style={{
                display: "flex",
                flexDirection: isXS ? "column" : "row",
                justifyContent: isXS ? "unset" : "space-between",
                paddingRight: isSM ? "5px" : "16px",
                width: '100%',
              }}
                className="peekInsideBlock"
              >
                <div display="flex" flexDirection="column" style={{ width: '100%' }}>
                  <div>
                    <Typography sx={classes.title}>
                      {companyFreemium
                        ? t('PEEK-BOARDROOM-TITLE-FREEMIUM')
                        : t('PEEK-BOARDROOM-TITLE')}
                    </Typography>
                  </div>
                  <div style={classes.subtitleContainer}>
                    <Typography style={classes.subtitle}>
                      {companyFreemium
                        ? t('PEEK-BOARDROOM-TEXT-FREEMIUM', {
                          companyName,
                        })
                        : t('PEEK-BOARDROOM-TEXT', {
                          companyName,
                        })}
                    </Typography>
                  </div>
                </div>
                {newestBoardMembersContainer()}
              </div>
              <PreviewBoardMeetings />
            </div>
            {!isJoined && (
              <div
                style={{
                  ...classes.bottomContainer,
                  marginBottom: isSM ? '150px' : '0px',
                }}
              >
                <div
                  style={{
                    transform: 'translateY(-75px)',
                    width: 75,
                    height: 75,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    color: 'black',
                  }}
                >
                  <LockOutlined
                    style={{ fontSize: 45, marginTop: 15 }}
                    color="black"
                  />
                </div>
                <div style={{ transform: 'translateY(-60px)' }}>
                  <Typography sx={classes.title}>
                    {t('PREVIEW-JOIN-BOARD-TITLE')}
                  </Typography>
                  {!isSM ? (
                    <div
                      style={{
                        marginBottom: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <BoardJoinButton
                        invitation={invitation}
                        company={company}
                        boardId={companyId}
                        companyCEO={companyCEO}
                        staticClass="joinButtonBottom"
                      />
                      <div style={classes.bottomSubtitleContainer}>
                        <Typography sx={{ ...classes.subtitle2, marginBottom: 15 }}>
                          {t('PREVIEW-JOIN-BOARD-TEXT-V2', {
                            companyName,
                          })}
                        </Typography>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
      }
    </>
  );
}

export default CompanyBoardPreview;
