import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Typography, Box, Grid } from '@mui/material';
import { ReactComponent as OneOnOne } from '../../icons/1-1.svg';
import { ReactComponent as CalendarCheckIcon } from '../../icons/calendar-check.svg';
import { ReactComponent as BoardMembers } from '../../icons/boardMembers.svg';
import { ReactComponent as Insights } from '../../icons/insights.svg';
import { useAccount } from '../../contexts/Account';
import { useCompanyExtraFeed } from '../../contexts/CompanyFeed';
import BoardroomHeader from '../Boardroom/BoardroomHeader';
import eventBus, { eventBusValues } from '../../eventBus';
import BoardTile from '../Boardroom/BoardTile';
import { getCompanyQueryParams } from '../../utils/queryParams';

function Dashboard(props) {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { accountDetails, authenticate, isAuthenticated, isImpersonated } =
    useAccount();
  const history = useHistory();
  const { removeAllPosts } = useCompanyExtraFeed();

  useEffect(() => {
    return () => {
      removeAllPosts();
    };
  }, []);

  const redirectWithParams = (url) => {
    window.location.assign(
      `${url}?${getCompanyQueryParams({ accountDetails })}`
    );
  };

  useEffect(() => {
    async function fetchUserData() {
      await authenticate();
    }

    if (
      !isAuthenticated &&
      !isImpersonated &&
      props.location.state &&
      props.location.state.onboarded
    ) {
      fetchUserData();
    }
  }, []);

  function seeOpportunity(companyId) {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      companyId,
      isEdit: true,
    });
  }

  return (
    <>
      <Helmet>
        <title>{t('DASHBOARD-HELMET-TITLE')}</title>
      </Helmet>

      <Box
        sx={{
          backgroundColor: '#F4F8FF',
          height: '100%',
          paddingTop: '30px',
          paddingLeft: isSM ? '10px' : '30px',
          paddingRight: isSM ? '10px' : '30px',
        }}
      >
        <BoardroomHeader
          company={accountDetails}
          seeOpportunity={seeOpportunity}
        />
        <Grid container spacing={2}>
          <BoardTile
            image={<Insights style={{ width: 60, height: 60 }} />}
            onClick={() => history.push('/advisor_ideas')}
          >
            <Typography
              sx={{
                fontSize: { xxs: 16, xs: 20 },
                lineHeight: { xxs: '20px', xs: '26px' },
                fontWeight: '600',
                marginBottom: { xxs: '7px', xs: '14px' },
                textAlign: 'left',
              }}
            >
              Insights From Your Advisors
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: '18px',
                fontWeight: '400',
                color: '#4D586F',
                textAlign: 'left',
              }}
            >
              See ideas and recommendations from your advisors on your
              challenges and goals.
            </Typography>
          </BoardTile>
          <BoardTile
            image={<BoardMembers style={{ width: 60, height: 60 }} />}
            onClick={() => history.push('/advisors')}
          >
            <Typography
              sx={{
                fontSize: { xxs: 16, xs: 20 },
                lineHeight: { xxs: '20px', xs: '26px' },
                fontWeight: '600',
                marginBottom: { xxs: '7px', xs: '14px' },
                textAlign: 'left',
              }}
            >
              Advisory Board Members
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: '18px',
                fontWeight: '400',
                color: '#4D586F',
                textAlign: 'left',
              }}
            >
              View your current board members, invite more board members and
              more.
            </Typography>
          </BoardTile>
          <BoardTile
            image={<CalendarCheckIcon style={{ width: 60, height: 60 }} />}
            onClick={() => history.push('/board-meetings')}
          >
            <Typography
              sx={{
                fontSize: { xxs: 16, xs: 20 },
                lineHeight: { xxs: '20px', xs: '26px' },
                fontWeight: '600',
                marginBottom: { xxs: '7px', xs: '14px' },
                textAlign: 'left',
              }}
            >
              Board Meetings
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: '18px',
                fontWeight: '400',
                color: '#4D586F',
                textAlign: 'left',
              }}
            >
              Schedule a board meeting, view previous board meetings and more.
            </Typography>
          </BoardTile>
          <BoardTile
            image={<OneOnOne style={{ width: 60, height: 60 }} />}
            onClick={() =>
              redirectWithParams(
                'https://register.advisorycloud.com/bab/personal-assist/'
              )
            }
          >
            <Typography
              sx={{
                fontSize: { xxs: 16, xs: 20 },
                lineHeight: { xxs: '20px', xs: '26px' },
                fontWeight: '600',
                marginBottom: { xxs: '7px', xs: '14px' },
                textAlign: 'left',
              }}
            >
              Get 1:1 Input From Board Members
            </Typography>
            <Typography
              sx={{
                fontSize: 12,
                lineHeight: '18px',
                fontWeight: '400',
                color: '#4D586F',
                textAlign: 'left',
              }}
            >
              Invite individual advisors for a 1:1 meeting to get more detailed
              insights.
            </Typography>
          </BoardTile>
        </Grid>
      </Box>
    </>
  );
}

export default Dashboard;
