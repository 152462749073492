import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, Typography, Button, useMediaQuery } from "@mui/material";
import { useAccount } from "../../contexts/Account";
import DefaultAdvisorAvatar from "../../icons/user-avatar.svg";
import DefaultCompany from "../../icons/building_round.svg";
import { getAdvisorQueryParams } from "../../utils/queryParams";
import CalendarSyncMenu from "../../components/CalendarSyncMenu";
import ACSnackbar from "../../components/ACSnackbar";
import { SnackbarType } from "../../services/utils/types";

const useStyles = ({ isSM }) => ({
  imageWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  ownerImageWrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    marginTop: isSM ? '20px' : '35px',
  },
  dialogCompanyImage: {
    width: '73px',
    height: '73px',
    maxHeight: '73px',
    maxWidth: '73px',
    borderRadius: '146px',
    marginBottom: '10px',
    cursor: 'pointer',
  },
  dialogContent: {
    maxWidth: '450px',
    overflow: 'hidden',
    maxHeight: isSM ? null : '400px',
    marginTop: isSM ? '0px' : '-30px',
  },
  dialogOwnerImage: {
    width: '60px',
    height: '60px',
    maxHeight: '60px',
    maxWidth: '60px',
    borderRadius: '146px',
    marginRight: '20px',
    cursor: 'pointer',
  },
  dialogContainer: {
    padding: '10px',
  },
  dialogActions: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    rowGap: isSM ? '10px' : 'unset',
    justifyContent: 'center',
    marginTop: isSM ? '15px' : '',
    marginBottom: isSM ? '-10px' : '',
    paddingBottom: isSM ? '20px' : '',
  },
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'center',
    width: isSM ? '' : '485px',
    height: 'auto',
    alignItems: 'center',
    background: '#FFFFFF',
    borderRadius: '15px',
    paddingTop: isSM ? '0px' : '30px',
    paddingBottom: isSM ? '0px' : '30px',
  },
  dialogText: {
    textAlign: 'center',
  },
  dialogTextCompanyName: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    maxWidth: '250px',
  },
  dialogTextCompanyNameContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  dialogBoardOwnerContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
export function FirstViewModal({ companyBoardData }) {

  const { t } = useTranslation();
  const params = useParams('/:companySlug/boardrooms');
  const { companySlug } = params;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);


  const { accountDetails, asLoggedUserDetails, currentPlan, isFreeTrial, isFreemium } = useAccount();
  const [isFirstViewModalVisible, setIsFirstViewModalVisible] = useState(true);
  const [companyBoardOwner, setCompanyBoardOwner] = useState();

  const [googleSync, setGoogleSync] = useState(
    accountDetails?.calendarTokens?.google || false
  );
  const [appleSync, setAppleSync] = useState(
    accountDetails?.calendarTokens?.apple || false
  );
  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(SnackbarType.SUCCESS);

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isSM });

  useEffect(() => {
    console.log('currentPlan: ', currentPlan);
  }, []);


  useEffect(() => {
    let isMounted = true;

    const fetchCompanyData = () => {
      try {
        if (isMounted && companyBoardData) {
          const companyContacts = companyBoardData.contacts || [];
          const companyOwner = companyContacts?.find((c) => c.isBoardOwner);
          setCompanyBoardOwner(companyOwner || companyContacts[0]);
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
      }
    };

    const initializeModal = () => {
      if (!companySlug) return;

      const selectedCompany =
        asLoggedUserDetails.advisorRelatedCompanies.memberBoards.find(
          (element) => element.slug === companySlug
        );

      if (selectedCompany?.id) {
        fetchCompanyData(selectedCompany.id);
      }
    };

    initializeModal();

    return () => {
      isMounted = false;
    };
  }, [companySlug, asLoggedUserDetails, queryParams]);

  function getTextTrimmed(text, maxSize) {
    if (!text) return '';
    if (text.length <= maxSize) {
      return text;
    }
    let trimmedText = text.substring(0, maxSize) + '...';
    return trimmedText;
  }

  function handleOpenCalendarMenu(event) {
    setCalendarAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Dialog
        open={isFirstViewModalVisible}
        sx={classes.dialogContainer}
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: 15,
          },
        }}
        onClose={() => setIsFirstViewModalVisible(false)}
      >
        <div style={classes.dialog}>
          <DialogContent sx={classes.dialogContent}>
            <div role="none" style={classes.imageWrapper} onKeyDown={() => { }}>
              <img
                alt=""
                className="profileAvatar"
                style={classes.dialogCompanyImage}
                src={
                  companyBoardData &&
                    companyBoardData.image &&
                    companyBoardData.image.id &&
                    !companyBoardData.image.id.includes('undefined')
                    ? `${process.env.REACT_APP_IMAGES_URL}${companyBoardData.image.id}`
                    : DefaultCompany
                }
              />
            </div>
            <div style={classes.dialogTextCompanyNameContainer}>
              <DialogContentText sx={classes.dialogTextCompanyName}>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: '16px',
                    textTransform: 'none',
                    lineHeight: '24px',
                    fontWeight: 700,
                    color: '#232B35',
                  }}
                >
                  Welcome to our board,
                </Typography>
                <Typography
                  variant="caption"
                  style={{
                    fontSize: 16,
                    textTransform: 'none',
                    lineHeight: '24px',
                    fontWeight: 700,
                    color: '#232B35',
                  }}
                >
                  {accountDetails.givenName}!
                </Typography>
              </DialogContentText>
            </div>
            <DialogContentText style={classes.dialogText}>
              <Typography
                variant="caption"
                style={{
                  fontSize: '13px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  color: '#232B35',
                }}
              >
                {
                  (googleSync || appleSync) ?
                    "We’re excited to have you join the amazing group of professionals on our advisory board. Our board meetings have automatically been added to your AdvisoryCloud calendar. Looking forward to hearing your thoughts and ideas!" :
                    "We’re excited to have you join the amazing group of professionals on our advisory board. Our board meetings have automatically been added to your AdvisoryCloud calendar- if you haven’t sync’d your calendar yet, do so now as it makes everything so much easier. Looking forward to hearing your thoughts and ideas!"
                }
              </Typography>
            </DialogContentText>
            {companyBoardOwner && (
              <div role="none" style={classes.ownerImageWrapper}>
                <img
                  alt=""
                  style={classes.dialogOwnerImage}
                  src={
                    companyBoardOwner &&
                      companyBoardOwner.image &&
                      companyBoardOwner.image.id &&
                      !companyBoardOwner.image.id.includes('undefined')
                      ? `${process.env.REACT_APP_IMAGES_URL}${companyBoardOwner.image.id}`
                      : DefaultAdvisorAvatar
                  }
                />
                <div style={classes.dialogBoardOwnerContainer}>
                  <div>
                    <Typography
                      variant="caption"
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: 13,
                        fontWeight: 500,
                        color: '#232B35',
                      }}
                    >
                      {companyBoardOwner.displayName}
                    </Typography>
                  </div>
                  <div>
                    <Typography
                      variant="caption"
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: 13,
                        fontWeight: 400,
                        color: '#232B35',
                      }}
                    >
                      {`${getTextTrimmed(companyBoardOwner.title, 20)} - `}
                    </Typography>
                    <Typography
                      variant="caption"
                      style={{
                        fontFamily: 'Poppins',
                        fontSize: 13,
                        fontWeight: 400,
                        color: '#232B35',
                      }}
                    >
                      {getTextTrimmed(companyBoardData.displayName, 40)}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions sx={classes.dialogActions}>
            <>
              {(googleSync || appleSync) ? <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsFirstViewModalVisible(false);
                }}
                className="getStartedButton"
                style={{
                  backgroundColor: '#6736FA',
                  paddingTop: 15,
                  paddingBottom: 15,
                  textTransform: 'none',
                  fontFamily: 'Poppins',
                  fontWeight: 700,
                  fontSize: 15,
                  width: 180,
                  height: 44,
                  borderRadius: 30,
                }}
              >
                Enter Boardroom
              </Button> :
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setIsFirstViewModalVisible(false);
                    }}
                    className="getStartedButton"
                    style={{
                      backgroundColor: '#6736FA',
                      paddingTop: 15,
                      paddingBottom: 15,
                      textTransform: 'none',
                      fontFamily: 'Poppins',
                      fontWeight: 700,
                      fontSize: 15,
                      width: 180,
                      height: 44,
                      borderRadius: 30,
                    }}
                  >
                    Enter Boardroom
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenCalendarMenu}
                    className="getStartedButton"
                    style={{
                      paddingTop: 15,
                      paddingBottom: 15,
                      textTransform: 'none',
                      fontFamily: 'Poppins',
                      fontWeight: 700,
                      fontSize: 15,
                      width: 180,
                      height: 44,
                      backgroundColor: 'white',
                      color: '#6736FA',
                      borderColor: "#6736FA",
                      borderRadius: 30,
                      borderWidth: 2,
                      border: "solid",
                    }}
                  >
                    Sync Calendar
                  </Button>
                </>
              }
            </>
          </DialogActions>
          {currentPlan?.upgradeUrl ?
            <a href={`${currentPlan?.upgradeUrl}?${getAdvisorQueryParams({ accountDetails, isFreemium, isFreeTrial })}`}>
              <Typography>
                {currentPlan.salesforceValues.billincCycle === "Anually" ?
                  "Upgrade to VIP" : "View Upgrade Options"
                }
              </Typography>
            </a> : null}
        </div>
      </Dialog>
      <CalendarSyncMenu
        anchorEl={calendarAnchorEl}
        setAnchorEl={setCalendarAnchorEl}
        syncStates={{
          googleSync,
          appleSync,
        }}
        setSyncStates={{
          setGoogleSync,
          setAppleSync,
        }}
        advisorId={accountDetails?.id}
        companyIds={accountDetails?.boards?.memberBoards?.map(
          (board) => board.id
        )}
        setIsLoading={setIsLoading}
        showSnackbar={(message, severity) => {
          setSnackMessage(message);
          setSnackSeverity(severity);
          setSnackbarOpen(true);
        }}
      />
      <ACSnackbar
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
        open={snackbarOpen}
        text={snackMessage}
        severity={snackSeverity}
        onClose={() => {
          setSnackbarOpen(false);
        }}
        autoHideDuration={5000}
      />
    </>
  );
}