import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, Divider, Paper, Typography } from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DvrIcon from '@mui/icons-material/Dvr';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';
import CustomIcon from '../../icons/customIcon';
import AccountSettingsCard from '../../components/AccountSettings/ChangeEmailPassword/AccountSettingsCard';
import AdvisorNotificationsSettings from '../../components/Advisor/NotificationsSettings';
import { UpdateAttributesFlow } from '../../services/Transition';
import { useAccount } from '../../contexts/Account';
import { editBillingInfo, viewPastInvoices } from '../../services/Recurly';
import eventBus, { eventBusValues } from '../../eventBus';
import BuildYourOwnBoardTile from './BuildYourOwnBoardTile';
import { remainingAdvisorSeats } from '../../hooks/useAdvisorCanJoin';
import { Info } from '@mui/icons-material';
import ACToolTip from '../../components/ACToolTip';

const useStyles = (isSM) => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    backgroundColor: 'white',
  },
  screenContainer: {
    width: '100%',
    paddingTop: isSM ? '15px' : '40px',
    paddingLeft: isSM ? '15px' : '50px',
    paddingRight: isSM ? '15px' : '50px',
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
  },
  screenContentContainer: {
    width: isSM ? '100%' : '728px',
    display: 'flex',
    flexDirection: 'column',
  },
  loadingContainer: {
    alignItems: 'center',
    backgroundColor: '#FFF',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
  },
  waterMarkContainer: {
    width: '728px',
    display: 'flex',
    displayDirection: 'row',
  },
  waterMarkText: {
    fontSize: '14px',
    marginRight: '24px',
    color: 'info.light',
    whiteSpace: 'nowrap',
  },
  divider: {
    width: '100%',
    position: 'relative',
    top: '50%',
  },
  dividerContainer: {
    width: '100%',
  },
  card: {
    width: '100%',
    backgroundColor: 'white',
    borderRadius: '10px',
    paddingTop: '25px',
    paddingLeft: isSM ? '15px' : '38px',
    paddingRight: isSM ? '15px' : '38px',
    paddingBottom: isSM ? '15px' : '35px',
    border: 'solid 1px #F0F1F3',
  },
  text: {
    fontWeight: 'bold',
    width: isSM ? '85%' : '93%',
    fontSize: '14px',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'none',
    outline: 'none',
    padding: '0',
    '&:hover': { backgroundColor: 'transparent' },
  },
  iconContainer: {
    width: isSM ? '15%' : '7%',
    paddingTop: '4px',
  },
  actionsTable: {
    width: '100%',
  },
  actionCell: {
    height: isSM ? '60px' : '30px',
    width: isSM ? '30%' : '30%',
    textAlign: 'center',
    color: 'primary.main',
    cursor: 'pointer',
  },
  bottomRow: {
    display: 'flex',
    width: '100%',
    marginTop: '15px',
    flexGrow: 1,
  },
  accountStatus: {
    textTransform: 'capitalize',
  },
  iconInfo: {
    fontSize: '16px',
    cursor: 'pointer',
    marginLeft: '5px',
  },
});

function AdvisorAccount() {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { accountDetails, isFreeTrial, isFreemium, currentPlan } = useAccount();
  const classes = useStyles(isSM);
  const [isLoading, setIsLoading] = useState(false);
  const billingCycle = currentPlan.salesforceValues?.billingCycle || '';
  const remainingSeats = remainingAdvisorSeats();

  function upgradePlan() {
    if (isFreemium) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction);
    } else {
      window.open(
        `${accountDetails.upgradeUrl}?iwm_advisor_id=${accountDetails.iwmId}&sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.email}&plancode=${accountDetails.recurlyPlanCode}`
      );
    }
  }

  function goToCancelMembership() {
    const queryParams = `?email=${accountDetails.email}&sosfcontactid=${
      accountDetails.salesforceContactId || ''
    }&plancode=${accountDetails.recurlyPlanCode || ''}`;
    if (isFreemium) {
      window.open(
        `https://register.advisorycloud.com/freemium-cancellation-flow/${queryParams}`,
        '_self'
      );
    } else if (isFreeTrial) {
      window.open(
        `https://register.advisorycloud.com/deactivate-free-trial-step-1/${queryParams}`,
        '_self'
      );
    } else {
      window.open(
        `https://register.advisorycloud.com/deactivate-step-1/${queryParams}`,
        '_self'
      );
    }
  }

  function companyBillingEdit() {
    editBillingInfo(accountDetails.advisorRecurlyHostedLoginToken);
  }

  function viewPastInvoice() {
    viewPastInvoices(accountDetails.advisorRecurlyHostedLoginToken);
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');
  }

  function getStatus() {
    if (isFreemium) {
      return 'Free Account Active';
    }
    if (isFreeTrial) {
      return 'Free Trial Active';
    }
    return accountDetails.accountStatus || '';
  }

  const Icon = () => {
    return <CustomIcon icconname="key-outlined" />;
  };

  if (isLoading) {
    return (
      <div style={classes.loadingContainer}>
        <LoadingIndicatorLogo iconFontSize={15} iconRight={13} />
      </div>
    );
  }

  return (
    <div
      style={{
        ...classes.mainContainer,
        marginTop: isFreeTrial && !isFreemium ? '50px' : null,
      }}
    >
      <Helmet>
        <title>{t('ACCOUNT-HELMET-TITLE')}</title>
      </Helmet>
      <div style={classes.screenContainer}>
        <div style={classes.waterMarkContainer}>
          <CustomIcon
            iconname="settings-outlined"
            color="primary"
            fontSize="small"
            style={{
              width: 'fit-content',
              height: 'fit-content',
              justifyContent: 'center',
              display: 'flex',
              color: '#BDD2F0',
            }}
          />
          <div style={classes.waterMarkText}>Account Settings</div>
          <div style={classes.dividerContainer}>
            <Divider sx={classes.divider} />
          </div>
        </div>
      </div>

      <div style={classes.screenContainer}>
        <div style={classes.screenContentContainer}>
          <AccountSettingsCard
            flowType={UpdateAttributesFlow.CHANGE_EMAIL}
            title={'Your AdvisoryCloud Account'}
            givenName={accountDetails.givenName}
            surName={accountDetails.surName}
            buttonIcon={Icon}
            subtitle={accountDetails.email}
            buttonText={t('ADVISOR-ACCOUNT-CHANGE-EMAIL')}
          />
        </div>
      </div>
      {!isFreemium && (
        <div style={classes.screenContainer}>
          <div style={classes.screenContentContainer}>
            <Paper elevation={0} sx={classes.card}>
              <section>
                <div style={classes.topRow}>
                  <div style={classes.iconContainer}>
                    <CreditCardIcon icconname="key-outlined" />
                  </div>
                  <Typography variant="h1" sx={classes.text}>
                    {t('ADVISOR-ACCOUNT-YOUR-BILLING')}
                  </Typography>
                </div>
                <div style={classes.bottomRow}>
                  {!isSM ? <div style={classes.iconContainer} /> : null}
                  <table style={classes.actionsTable}>
                    <tbody>
                      <tr>
                        <td>{accountDetails.displayName}</td>
                        {accountDetails.advisorRecurlyHostedLoginToken ? (
                          <td style={classes.actionCell}>
                            <Typography
                              onClick={() => companyBillingEdit()}
                              style={{ fontWeight: 500 }}
                              color="primary"
                            >
                              {t('ADVISOR-ACCOUNT-EDIT-BILLING')}
                            </Typography>
                          </td>
                        ) : (
                          <td />
                        )}
                      </tr>
                      <tr>
                        <td>
                          {t('ADVISOR-ACCOUNT-BILLEDCYCLE', {
                            billedCycle: billingCycle || '',
                          })}
                        </td>
                        {accountDetails.advisorRecurlyHostedLoginToken ? (
                          <td style={classes.actionCell}>
                            <Typography
                              onClick={() => viewPastInvoice()}
                              style={{ fontWeight: 500 }}
                              color="primary"
                            >
                              {t('ADVISOR-ACCOUNT-PAST-INVOICES')}
                            </Typography>
                          </td>
                        ) : (
                          <td />
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </Paper>
          </div>
        </div>
      )}
      <div style={classes.screenContainer}>
        <div style={classes.screenContentContainer}>
          <Paper elevation={0} sx={classes.card}>
            <section>
              <div style={classes.topRow}>
                <div style={classes.iconContainer}>
                  <DvrIcon icconname="key-outlined" />
                </div>
                <Typography variant="h1" sx={classes.text}>
                  {t('ADVISOR-ACCOUNT-YOUR-MEMBERSHIP')}
                </Typography>
              </div>
              <div style={classes.bottomRow}>
                {!isSM ? <div style={classes.iconContainer} /> : null}
                <table style={classes.actionsTable}>
                  <tbody>
                    <tr>
                      <td>
                        {t('ADVISOR-ACCOUNT-STARTDATE', {
                          startDate: formatDate(accountDetails.createdAt) || '',
                        })}
                      </td>
                      <td style={classes.actionCell}>
                        <Typography
                          onClick={() => upgradePlan()}
                          style={{ fontWeight: 500 }}
                          color="primary"
                        >
                          {t('ADVISOR-ACCOUNT-UPGRADE-MEMBERSHIP')}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td style={classes.accountStatus}>
                        {t('ADVISOR-ACCOUNT-STATUS', {
                          status: getStatus(),
                        })}
                      </td>
                      <td style={classes.actionCell}>
                        <Typography
                          onClick={() => goToCancelMembership()}
                          style={{ fontWeight: 500 }}
                          color="primary"
                        >
                          {t('ADVISOR-ACCOUNT-CANCEL-MEMBERSHIP')}
                        </Typography>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Remaining Advisory Board Seats: {remainingSeats}
                        <ACToolTip
                          title="This represents the number of board you can request to join through our platform on your current plan. In some cases you will receive invites from companies that do not count against your plan limit."
                          placement="top"
                        >
                          <Info sx={classes.iconInfo} />
                        </ACToolTip>
                      </td>
                      <td style={classes.actionCell} />
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </Paper>
        </div>
      </div>
      <BuildYourOwnBoardTile />

      {accountDetails.preferences.isDualAccount ? (
        <div style={classes.screenContainer}>
          <div style={classes.screenContentContainer}>
            <AccountSettingsCard
              flowType={UpdateAttributesFlow.CHANGE_ACCOUNT_TYPE}
              changeAccountTypeLoader={setIsLoading}
              title="Change Account Type"
              buttonIcon={Icon}
              buttonText="Go to Company App"
            />
          </div>
        </div>
      ) : null}
      <div style={classes.screenContainer}>
        <div style={classes.screenContentContainer}>
          <AdvisorNotificationsSettings />
        </div>
      </div>
    </div>
  );
}

export default AdvisorAccount;
