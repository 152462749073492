import React, { useState } from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { ReactComponent as Arrow } from './arrowDown.svg';
import { ReactComponent as Team } from './team-icon.svg';
import CompanyIcon from './company.png';
import AdvisoryCloudLogo from '../../AdvisoryCloudLogo';

const CardPlanCode = ({ onClick, membership, onContinue, userSegment }) => {
  const handleClick = () => {
    if (membership === 'plus') {
      onClick(1);
    } else if (membership === 'managed') {
      onClick(2);
    }
  };

  const handleClickContinue = () => {
    if (membership === 'plus') {
      onClick(1);
      onContinue();
    } else if (membership === 'managed') {
      onClick(2);
      onContinue();
    }
  };

  const styles = {
    dotProps: {
      width: '5.87px',
      height: '5.87px',
      borderRadius: 99,
      marginRight: '10px',
      marginTop: '8px',
    },
    listItemContainer: {
      display: 'flex',
      marginLeft: '12px',
      marginBottom: '15px',
    },
    listItemText: {
      color: '#232B35',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '23px',
      letterSpacing: '-0.5px',
      width: '100%',
      paddingRight: '4px',
    },
  };

  const ManagedSegment =
    userSegment === 'managed_advisory_board' && membership === 'managed';
  const PlusSegment =
    userSegment !== 'managed_advisory_board' && membership === 'plus';
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {membership === 'current' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: { xxs: '16px', sm: '60px' },
            marginBottom: { xxs: '16px', sm: '0' },
          }}
        >
          <Arrow />
          <Typography
            sx={{
              color: '#646D7A',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '20px',
              letterSpacing: '-0.5px',
              textAlign: 'center',
              marginLeft: '5px',
            }}
          >
            Your current plan
          </Typography>
        </Box>
      )}
      <Box
        onClick={handleClick}
        sx={{
          width: '342px',
          height:
            membership === 'current'
              ? '240px'
              : membership === 'managed'
              ? '580px'
              : '550px',
          border: '1px solid #EFF0F6',
          borderColor: '#C7D0DE',
          borderRadius: '15px',
          position: 'relative',
          zIndex: 1,
          marginTop: {
            xxs: '0px',
            sm: membership === 'current' ? '20px' : '38.5px',
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: membership === 'current' ? 'none' : 'grid',
              placeItems: 'center',
              marginTop: '25px',
            }}
          >
            {membership === 'plus' && <Team alt="company" />}
            {membership === 'managed' && (
              <img
                src={CompanyIcon}
                alt="company"
                style={{ width: '52px', height: '52px' }}
              />
            )}
          </Box>

          <Typography
            sx={{
              display: membership === 'current' ? 'none' : null,
              color: '#232B35',
              fontSize: '18px',
              fontStyle: 'normal',
              fontWeight: 800,
              lineHeight: '24px',
              letterSpacing: '-0.43px',
              marginTop: '15px',
              marginBottom: '-10px',
              textAlign: 'center',
            }}
          >
            {membership === 'managed' && 'Gold'}
            {membership === 'plus' && 'Silver'}
          </Typography>

          <Typography
            sx={{
              color: '#232B35',
              fontSize: '28px',
              fontStyle: 'normal',
              fontWeight: 800,
              lineHeight: '32px',
              letterSpacing: '-0.5px',
              marginBottom: '0px',
              textAlign: 'center',
              padding: '20px',
            }}
          >
            {membership === 'managed' && 'Moderated Board Meetings'}
            {membership === 'plus' && 'Access Top Advisors'}
          </Typography>
          {membership === 'current' && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '28px',
                    fontStyle: 'normal',
                    fontWeight: 800,
                    lineHeight: '24px',
                    letterSpacing: '-0.43px',
                    textAlign: 'center',
                    marginBottom: '20px',
                  }}
                >
                  Free
                </Typography>
                <Divider sx={{ width: '70%' }} />
              </Box>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#D7F1FD',
                    ...styles.dotProps,
                  }}
                />
                <Typography sx={styles.listItemText}>
                  Invite your colleagues, contacts, or followers to be on your
                  advisory board
                </Typography>
              </Box>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#D7F1FD',
                    ...styles.dotProps,
                  }}
                />
                <Typography sx={styles.listItemText}>
                  Your own digital boardroom to get insights, share updates and
                  more
                </Typography>
              </Box>
            </>
          )}
          {membership === 'managed' && (
            <>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#D7F1FD',
                    ...styles.dotProps,
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                    width: '100%',
                    paddingRight: '4px',
                  }}
                >
                  Everything from Free & Silver plus
                </Typography>
              </Box>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#D7F1FD',
                    ...styles.dotProps,
                  }}
                />
                <Typography sx={styles.listItemText}>
                  Dedicated advisory board manager to coordinate everything
                  related to your advisory board
                </Typography>
              </Box>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#D7F1FD',
                    ...styles.dotProps,
                  }}
                />
                <Typography sx={styles.listItemText}>
                  Quarterly video-conference board meetings conducted by an
                  AdvisoryCloud Moderator
                </Typography>
              </Box>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#D7F1FD',
                    ...styles.dotProps,
                  }}
                />
                <Typography sx={styles.listItemText}>
                  Everything prepped for you for each board meeting and in
                  between meetings with advisors
                </Typography>
              </Box>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#D7F1FD',
                    ...styles.dotProps,
                  }}
                />
                <Typography sx={styles.listItemText}>
                  Only $495/month or 2 Months Free With Annual Discount
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <button
                  style={{
                    margin: '20px 0',
                    position: 'relative',
                    fontSize: '15px',
                    borderRadius: '999px',
                    fontWeight: 700,
                    width: '168px',
                    height: '44px',
                    backgroundColor: '#D7F1FD',
                    border: '1px solid #4BC6FF',
                    color: '#232B35',
                    borderColor: 'none',
                    fontFamily: 'Poppins',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                  variant="contained"
                  onClick={handleClickContinue}
                >
                  Upgrade to Gold
                </button>
              </Box>
            </>
          )}
          {membership === 'plus' && (
            <>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#DBF7EB',
                    ...styles.dotProps,
                  }}
                />
                <Typography
                  sx={{
                    color: '#232B35',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    lineHeight: '23px',
                    letterSpacing: '-0.5px',
                    width: '100%',
                    paddingRight: '4px',
                  }}
                >
                  Everything from Free plus
                </Typography>
              </Box>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#DBF7EB',
                    ...styles.dotProps,
                  }}
                />
                <Typography sx={styles.listItemText}>
                  Tap into the 10k+ top executives on AdvisoryCloud wanting to
                  be on advisory boards
                </Typography>
              </Box>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#DBF7EB',
                    ...styles.dotProps,
                  }}
                />
                <Typography sx={styles.listItemText}>
                  There is no payment to the advisors, just your monthly payment
                  to AdvisoryCloud
                </Typography>
              </Box>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#DBF7EB',
                    ...styles.dotProps,
                  }}
                />
                <Typography sx={styles.listItemText}>
                  Unlimited number of people on your advisory board
                </Typography>
              </Box>
              <Box sx={styles.listItemContainer}>
                <Box
                  sx={{
                    backgroundColor: '#DBF7EB',
                    ...styles.dotProps,
                  }}
                />
                <Typography sx={styles.listItemText}>
                  Only $195/Month or 2 Months Free With Annual Discount
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <button
                  style={{
                    margin: '20px 0',
                    position: 'relative',
                    fontSize: '15px',
                    borderRadius: '999px',
                    fontWeight: 700,
                    width: '168px',
                    height: '44px',
                    backgroundColor: '#DBF7EB',
                    border: '1px solid #2CF9A1',
                    color: '#232B35',
                    borderColor: 'none',
                    fontFamily: 'Poppins',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                  variant="contained"
                  onClick={handleClickContinue}
                >
                  Upgrade to Silver
                </button>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

function FreemiumStep1({ onContinue, onOptionChange, isUserSegment, setOpenModal }) {
  const [selectedPlan, setSelectedPlan] = useState('month');
  const [selectedOption, setSelectedOption] = useState(
    isUserSegment === 'managed_advisory_board' ? 2 : 1
  );

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    let selectedData;
    let counterpartData;

    onOptionChange(selectedPlan, option, selectedData, counterpartData);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: { xxs: '20px', sm: '5px' },
      }}
    >
      <Box
        sx={{
          display: { xxs: null, sm: 'none' },
        }}
      >
        <AdvisoryCloudLogo />
      </Box>
      <Typography
        sx={{
          color: '#232B35',
          textAlign: 'center',
          fontSize: '28px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: { xxs: '40px', sm: '28px' },
          letterSpacing: '-0.43px',
          margin: { xxs: '16px 20px 24px', sm: '40px 0px 10px' },
          maxWidth: '733px',
        }}
      >
        Upgrade to access all the ways an advisory board can help you succeed.
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xxs: 'column', xs: 'row' },
          justifyContent: { xxs: 'center', sm: 'space-between' },
          flexWrap: 'wrap',
          marginBottom: '30px',
          gap: 5,
        }}
      >
        <CardPlanCode membership="current" />
        <CardPlanCode
          selected={selectedOption === 1}
          membership="plus"
          onClick={handleSelectOption}
          onContinue={onContinue}
          userSegment={isUserSegment}
          setOpenModal={setOpenModal}
        />
        <CardPlanCode
          selected={selectedOption === 2}
          membership="managed"
          onClick={handleSelectOption}
          onContinue={onContinue}
          userSegment={isUserSegment}
          setOpenModal={setOpenModal}
        />
      </Box>
    </Box>
  );
}

export default FreemiumStep1;
