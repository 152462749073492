import React from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Button, Card, Grid, Typography, useMediaQuery } from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import AdvisoryCloudLogo from '../../components/AdvisoryCloudLogo';
import { useTranslation } from 'react-i18next';
import { EndpointParameterFilters } from '../../services/utils/types';
import { isNil } from '../../services/utils';
import OPPSImage from './opps-people-grid.jpg';
import AmazonLogo from './amazon.png';
import GELogo from './ge.png';
import WalmartLogo from './walmart.png';
import AppleLogo from './apple.png';
import BOALogo from './boa.png';
import WholeFoodsLogo from './wholefoods.png';
import BoeingLogo from './boeing.png';
import Mac from './mac.png';
import { skillTags, industryTags } from '../../utils/frontPageTags';
import AdvisorFilter from './Filter';

const useStyles = ({ isSM, isXS, isLG }) => ({
  container: {
    justifyContent: 'center',
  },
  imageContainer: {
    background: 'rgba(0,0,0,0.7)',
    backgroundImage: `url(${OPPSImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '75%',
  },
  topContainer: {
    background: 'rgba(0,0,0,0.7)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    color: 'white',
    padding: `30px 50px 85px ${isSM ? 45 : 50}px`,
  },
  headerContainer: {
    display: 'flex',
    direction: 'column',
    height: '10%',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filter: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    marginTop: '30px',
    alignContent: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'black',
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '55px',
    alignItems: 'flex-center',
  },
  futureTitleContainer: {
    textAlign: 'center',
  },
  icon: {
    color: '#273762',
    marginRight: '10px',
  },
  multimediaContainer: {
    marginTop: '5em',
    justifyContent: 'center',
  },
  benefitsContainer: {
    paddingTop: '20px',
    textAlign: isSM ? 'center' : 'left',
  },
  demoImage: {
    width: isSM ? '100%' : '450px',
  },
  videoContainer: {
    justifyContent: 'right',
    height: '220px',
  },
  futureTitleText: {
    fontWeight: 'bold',
    fontSize: '250%',
  },
  EngageTitleContainer: {
    marginTop: '15px',
    textAlign: 'center',
  },
  logosContainer: {
    marginTop: '65px',
    marginBottom: '10px',
    textAlign: 'center',
    justifyContent: 'center',
    maxWidth: '100%',
  },
  logoContainer: {
    display: 'flex',
    margin: '0 1rem 0 1rem',
    textAlign: 'center',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100px',
    height: '50px',
  },
  tagsMenuContainer: {
    width: '100%',
  },
  tagsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    maxHeight: !isLG ? '315px' : '290px',
    flexWrap: 'wrap',
    margin: '2em 0 2em 0',
    overflowX: isSM ? 'scroll' : 'visible',
  },
  industryTagsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    maxHeight: '210px',
    flexWrap: 'wrap',
    margin: '2em 0 2em 0',
    overflowX: isSM ? 'scroll' : 'visible',
  },
  tags: {
    display: 'flex',
    width: '100%',
  },
  tag: {
    marginRight: isSM ? '50px' : '0px',
    width: !isSM ? '28%' : 'auto',
    textAlign: 'left',
    background: 'transparent',
    border: 'none',
  },
  paragraph: {
    lineHeight: '1.6',
  },
  numberContainer: {
    width: '100%',
    marginBottom: '5px',
  },
  signinButton: {
    color: 'white',
    width: isXS ? '110px' : '150px',
    fontWeight: 'bold',
    fontSize: '12px',
    height: isXS ? '30px' : '40px',
    backgroundColor: '#0F78FD',
    '&:hover': {
      background: '#0F78FD',
    },
  },
  getStartedButton: {
    color: 'white',
    marginTop: '1em',
    width: isXS ? '100%' : '200px',
    fontWeight: 'bold',
    height: '40px',
    backgroundColor: '#0F78FD',
    '&:hover': {
      background: '#0F78FD',
    },
  },
  styledCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '3vh',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    color: 'white',
  },
});

function FrontPage() {
  const companiesLogos = [
    {
      name: 'Amazon Logo',
      location: AmazonLogo,
      width: '100',
      height: '30',
    },
    {
      name: 'GE Logo',
      location: GELogo,
      width: '30',
      height: '30',
    },
    {
      name: 'Walmart Logo',
      location: WalmartLogo,
      width: '100',
      height: '20',
    },
    {
      name: 'Apple Logo',
      location: AppleLogo,
      width: '30',
      height: '30',
    },
    {
      name: 'BOA Logo',
      location: BOALogo,
      width: '100',
      height: '20',
    },
    {
      name: 'WholeFoods Logo',
      location: WholeFoodsLogo,
      width: '50',
      height: '30',
    },
    {
      name: 'BoeingLogo',
      location: BoeingLogo,
      width: '100',
      height: '30',
    },
  ];
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isLG = useMediaQuery('(min-width:1200px)');
  const classes = useStyles({ isSM, isXS, isLG });
  const { t } = useTranslation();
  const history = useHistory();

  function goTo(type, tag) {
    history.push(`/use-advisors/browse/${type}/${tag}`);
  }
  function redirectTo(url) {
    history.push(url);
  }

  function renderVideoDiv() {
    return isSM ? (
      <Grid container justifyContent="center" xs={11} sm={10} md={9} lg={9}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="h4_old_font"
            style={{
              marginBottom: '10px',
              fontSize: '220%',
              textAlign: 'center',
            }}
          >
            {t('GET-ACCESS-TITLE')} 🚀
          </Typography>
        </Grid>
        <Grid sx={classes.videoContainer} item xs={12} sm={6} md={6} lg={6}>
          <iframe
            src="https://player.vimeo.com/video/663152857?h=4c05a54fcf&amp;wmode=opaque"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen=""
            title="Invitation"
            width="100%"
            height="100%"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography
            variant="h1_old_font"
            sx={classes.paragraph}
            style={{ marginTop: '1em', fontSize: '120%', textAlign: 'center' }}
          >
            {t('BUILD-COMPANY-TITLE')}
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <Grid container justifyContent="center" xs={11} sm={10} md={9} lg={9}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Typography variant="h4_old_font" style={{ fontSize: '220%' }}>
            {t('GET-ACCESS-TITLE')} 🚀
          </Typography>
          <Typography
            variant="h1_old_font"
            sx={classes.paragraph}
            style={{ marginTop: '1em', fontSize: '120%' }}
          >
            {t('BUILD-COMPANY-TITLE')}
          </Typography>
        </Grid>
        <Grid sx={classes.videoContainer} item xs={12} sm={6} md={6} lg={6}>
          <iframe
            src="https://player.vimeo.com/video/663152857?h=4c05a54fcf&amp;wmode=opaque"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen=""
            title="Invitation"
            width="100%"
            height="100%"
          />
        </Grid>
      </Grid>
    );
  }

  function handleSearch(props) {
    const { keywords, skills, position, industries } = props;
    const searchString = {
      SEARCH_STRING: null,
      SKILLS_TAGS_IDS: null,
      INDUSTRY_TAGS_IDS: null,
      LIMIT: EndpointParameterFilters.FETCH_LIMIT,
      OFFSET: 0,
    };
    if (keywords && keywords.length > 0) {
      searchString.SEARCH_STRING = keywords;
    } else {
      delete searchString.SEARCH_STRING;
    }
    if (!isNil(skills) && skills[0]) {
      searchString.SKILLS_TAGS_IDS = skills.map((skill) => skill.id);
    } else {
      delete searchString.SKILLS_TAGS_IDS;
    }
    if (!isNil(position) && position[0]) {
      searchString.POSITION_TAGS_IDS = position.map(
        (singlePosition) => singlePosition.id
      );
    } else {
      delete searchString.POSITION_TAGS_IDS;
    }
    if (!isNil(industries) && industries[0]) {
      searchString.INDUSTRY_TAGS_IDS = industries.map(
        (industry) => industry.id
      );
    } else {
      delete searchString.INDUSTRY_TAGS_IDS;
    }
    history.push(`/use-advisors/search?keyword=${searchString.SEARCH_STRING}`);
  }

  return (
    <>
      <Helmet>
        <title>{t('LOGIN_TITLE')}</title>
        <meta name="description" content={t('LOGIN_HELMET_META')} />
      </Helmet>

      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={classes.imageContainer}>
            <div style={classes.topContainer}>
              <div style={classes.headerContainer}>
                <AdvisoryCloudLogo
                  onClick={() => redirectTo('/dashboard')}
                  removeHyperlink
                  width={150}
                  variant="dark"
                />
                <Button
                  onClick={() =>
                    redirectTo('https://app.advisorycloud.com/login')
                  }
                  sx={classes.signinButton}
                >
                  Sign In
                </Button>
              </div>
              <Grid
                container
                justifyContent="center"
                sx={classes.gridContainer}
              >
                <Card sx={classes.styledCard}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={classes.futureTitleContainer}
                  >
                    <Grid item lg={12}>
                      <Typography
                        variant="h1_old_font"
                        sx={classes.futureTitleText}
                      >
                        {t('SEARCH-ADVISORS-INVITATION')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    sx={classes.EngageTitleContainer}
                  >
                    <Grid item lg={9}>
                      <Typography
                        variant="h4_old_font"
                        style={{ fontSize: '140%' }}
                      >
                        {t('REACH-GOALS')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    sx={classes.logosContainer}
                  >
                    {companiesLogos.map((logo) => (
                      <div key={logo.name} style={classes.logoContainer}>
                        <img
                          width={logo.width}
                          height={logo.height}
                          src={logo.location}
                          alt={logo.name}
                        />
                      </div>
                    ))}
                  </Grid>
                </Card>
              </Grid>
              <div style={classes.filter}>
                <AdvisorFilter
                  onSearchButtonClicked={handleSearch}
                  disableSearchButton={false}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item xs={8} sm={8} md={8} lg={8}>
          <div style={classes.tagsMenuContainer}>
            <Grid container>
              <ConstructionIcon sx={classes.icon} />
              <Typography
                color="primary"
                variant="h4_old_font"
                style={{ fontSize: '120%' }}
              >
                {t('EXPERTISE-BROWSE-TITLE')}
              </Typography>
              <Grid sx={classes.tagsContainer} item lg={12}>
                {skillTags.map((tag) => (
                  <button
                    key={tag}
                    type="submit"
                    onClick={() => goTo('expertise', tag)}
                    style={classes.tag}
                  >
                    <Typography
                      variant="h1_old_font"
                      sx={classes.text}
                      style={{ fontSize: '100%' }}
                    >
                      {tag}
                    </Typography>
                  </button>
                ))}
              </Grid>
            </Grid>
            <div style={classes.tags}>
              <Grid container>
                <CorporateFareIcon sx={classes.icon} />
                <Typography
                  color="primary"
                  variant="h4_old_font"
                  style={{ fontSize: '120%' }}
                >
                  {t('INDUSTRY-BROWSE-TITLE')}
                </Typography>
                <Grid sx={classes.industryTagsContainer} item lg={12}>
                  {industryTags.map((tag) => (
                    <button
                      key={tag}
                      type="submit"
                      onClick={() => goTo('industry', tag)}
                      style={classes.tag}
                    >
                      <Typography
                        variant="h1_old_font"
                        sx={classes.text}
                        style={{ fontSize: '100%' }}
                      >
                        {tag}
                      </Typography>
                    </button>
                  ))}
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container sx={classes.multimediaContainer} justifyContent="center">
        {renderVideoDiv()}
      </Grid>
      <Grid container sx={classes.multimediaContainer} justifyContent="center">
        <Grid container xs={9} sm={9} md={9} lg={9}>
          <Grid item xs={12} sm={12} md={7} lg={6}>
            <img src={Mac} alt="Mac" style={classes.demoImage} />
          </Grid>
          <Grid
            item
            sx={classes.benefitsContainer}
            xs={12}
            sm={12}
            md={5}
            lg={6}
          >
            <Typography
              variant="h4_old_font"
              style={{ fontSize: isSM ? '200%' : '140%' }}
            >
              {t('BUILD-BOARD-TITLE')}
            </Typography>
            <Typography
              variant="h1_old_font"
              style={{ marginTop: '1em', fontSize: isSM ? '140%' : '100%' }}
            >
              <span
                style={{
                  fontWeight: 800,
                  fontStyle: 'normal',
                  fontSize: '16px',
                  color: 'rgb(20, 120, 248)',
                }}
              >
                <span style={{ fontSize: '18px' }}>✔ </span>
              </span>
              {t('BUILD-BOARD-FIRST-BENEFIT-TITLE')}
            </Typography>
            <Typography
              variant="h1_old_font"
              style={{ marginTop: '1em', fontSize: isSM ? '140%' : '100%' }}
            >
              <span
                style={{
                  fontWeight: 800,
                  fontStyle: 'normal',
                  fontSize: '16px',
                  color: 'rgb(20, 120, 248)',
                }}
              >
                <span style={{ fontSize: '18px' }}>✔ </span>
              </span>
              {t('BUILD-BOARD-SECOND-BENEFIT-TITLE')}
            </Typography>
            <Typography
              variant="h1_old_font"
              style={{ marginTop: '1em', fontSize: isSM ? '140%' : '100%' }}
            >
              <span
                style={{
                  fontWeight: 800,
                  fontStyle: 'normal',
                  fontSize: '16px',
                  color: 'rgb(20, 120, 248)',
                }}
              >
                <span style={{ fontSize: '18px' }}>✔ </span>
              </span>
              {t('BUILD-BOARD-THIRD-BENEFIT-TITLE')}
            </Typography>
            <Typography
              variant="h1_old_font"
              style={{ marginTop: '1em', fontSize: isSM ? '140%' : '100%' }}
            >
              <span
                style={{
                  fontWeight: 800,
                  fontStyle: 'normal',
                  fontSize: '16px',
                  color: 'rgb(20, 120, 248)',
                }}
              >
                <span style={{ fontSize: '18px' }}>✔ </span>
              </span>
              {t('BUILD-BOARD-FOURTH-BENEFIT-TITLE')}
            </Typography>
            <Typography
              variant="h1_old_font"
              style={{ marginTop: '1em', fontSize: isSM ? '140%' : '100%' }}
            >
              <span
                style={{
                  fontWeight: 800,
                  fontStyle: 'normal',
                  fontSize: '16px',
                  color: 'rgb(20, 120, 248)',
                }}
              >
                <span style={{ fontSize: '18px' }}>✔ </span>
              </span>
              {t('BUILD-BOARD-FIFTH-BENEFIT-TITLE')}
            </Typography>
            <Button
              onClick={() =>
                window.location.assign(
                  'https://register.advisorycloud.com/start/'
                )
              }
              sx={classes.getStartedButton}
            >
              Get Started For Free
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container sx={classes.multimediaContainer} justifyContent="center">
        <Grid container xs={9} sm={9} md={9} lg={9}>
          <Grid container sx={classes.container} xs={12} sm={12} md={4} lg={4}>
            <Grid container xs={12} sm={12} md={10} lg={10}>
              <div style={classes.numberContainer}>
                <Typography
                  variant="h4_old_font"
                  color="primary"
                  style={{ fontWeight: 'bold', fontSize: '160%' }}
                >
                  1
                </Typography>
              </div>
              <Typography variant="h4_old_font" style={{ fontSize: '120%' }}>
                {t('BUILD-BOARD-TITLE')}
              </Typography>
              <Typography
                sx={classes.paragraph}
                variant="h1_old_font"
                style={{ marginTop: '1em', fontSize: '100%' }}
              >
                {t('REGISTER-ON-BODY')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={classes.container} xs={12} sm={12} md={4} lg={4}>
            <Grid container xs={12} sm={12} md={10} lg={10}>
              <div style={classes.numberContainer}>
                <Typography
                  variant="h4_old_font"
                  color="primary"
                  style={{ fontWeight: 'bold', fontSize: '160%' }}
                >
                  2
                </Typography>
              </div>
              <Typography variant="h4_old_font" style={{ fontSize: '120%' }}>
                {t('SETUP-TITLE')}
              </Typography>
              <Typography
                sx={classes.paragraph}
                variant="h1_old_font"
                style={{ marginTop: '1em', fontSize: '100%' }}
              >
                {t('SETUP-BODY')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={classes.container} xs={12} sm={12} md={4} lg={4}>
            <Grid container xs={12} sm={12} md={10} lg={10}>
              <div style={classes.numberContainer}>
                <Typography
                  variant="h4_old_font"
                  color="primary"
                  style={{ fontWeight: 'bold', fontSize: '160%' }}
                >
                  3
                </Typography>
              </div>
              <Typography variant="h4_old_font" style={{ fontSize: '120%' }}>
                {t('INTERACT-TITLE')}
              </Typography>
              <Typography
                sx={classes.paragraph}
                variant="h1_old_font"
                style={{ marginTop: '1em', fontSize: '100%' }}
              >
                {t('INTERACT-BODY')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default FrontPage;
