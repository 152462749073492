import React, { useEffect, useState, useRef, useMemo } from 'react';
import {
  SwipeableDrawer,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
} from '@mui/material';
import LoadingIndicatorLogo from './../../LoadingIndicatorLogo';
import { useAccount } from './../../../contexts/Account';
import { getPublicAdvisorTags } from './../../../services/Backend/account';
import {
  getCompanyContacts,
  getCompanyInfoBoardroomMenu,
} from './../../../services/company_services';
import {
  getAdvisorRecurlyPlan,
  getIsFavoriteCompany,
} from './../../../services/advisor_services';
import { AccountType } from './../../../services/utils/types';
import CompanyOpportunitySlide from './CompanyOpportunitySlide';
import CompanyOwnProfileSlide from './CompanyOwnProfileSlide';
import eventBus, { eventBusValues } from './../../../eventBus';
import {
  sendFSEventInviteViewed,
  sendFSEventJoinPageViewed,
} from '../../../services/FullStory';

const useStyles = (isXS) => ({
  advisorPaper: {
    width: isXS ? '100%' : '70%',
    position: 'fixed',
  },
  drawerContainer: {
    width: isXS ? '100%' : '70%',
    position: 'absolute',
  },
  loadingContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
  },
  dialogContainer: {
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  dialogText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
  },
  actionsButton: {
    textTransform: 'none',
    height: '40px',
    borderRadius: '50px',
    width: '142px',
    margin: '5px',
    flexGrow: 1,
    border: '2px solid #0F78FD',
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#3171F6',
    padding: '5px',
    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
      border: '2px solid #0F78FD',
    },
  },
});

const CompanyOpportunityDrawer = () => {
  const boxRef = useRef(null);
  const formRef = useRef();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isXS);
  const { type, asLoggedUserDetails, accountDetails } = useAccount();
  const [isOpen, setIsOpen] = useState(false);
  const [isFreemiumCompany, setIsFreemiumCompany] = useState(false);
  const [isAcPlusCompany, setIsAcPlusCompany] = useState(false);
  const [company, setCompany] = useState({});
  const [hidePostSection, setHideSelection] = useState(false);
  const [desiredSkills, setDesiredSkills] = useState([]);
  const [companyMembers, setCompanyMembers] = useState([]);
  const [boardOwner, setBoardOwner] = useState({});
  const [openExitDialog, setOpenExitDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingV2, setIsEditingV2] = useState(false);
  const [companiesListFiltered, setCompaniesListFiltered] = useState([]);
  const opportunityPlacement = companiesListFiltered?.findIndex(
    (companyItem) => companyItem.id === company.id
  );
  const [isFavorite, setIsFavorite] = useState(false);
  const localAccountDetails = useMemo(() => {
    return { ...accountDetails };
  }, [accountDetails]);
  const [invitation, setInvitation] = useState({});

  const prevIsOpen = useRef(isOpen);
  const stableCompanyRef = useRef(null);
  const stableOpportunityPlacement = useRef(-1);

  useEffect(() => {
    if (opportunityPlacement >= 0) {
      stableOpportunityPlacement.current = opportunityPlacement;
    }
  }, [opportunityPlacement]);

  useEffect(() => {
    if (company.id) {
      stableCompanyRef.current = company;
    }
  }, [company]);

  const triggerLambdaEvent = () => {
    if (!accountDetails || !stableCompanyRef.current?.id) {
      return;
    }

    sendFSEventJoinPageViewed({
      asLoggedUserDetails,
      advisor: accountDetails,
      company: stableCompanyRef.current,
      error: null,
      action: 'closed', // {closed/accepted/declined/requested to join}
      outcome: 'closed', //{closed/requested to join/joined/error}
      isFavorite: isFavorite,
      opportunityPlacement: stableOpportunityPlacement.current,
    });
  };

  useEffect(() => {
    const fetchFavoriteStatus = async () => {
      if (type === 'advisor' && company.id) {
        try {
          const { data } = await getIsFavoriteCompany({
            ADVISOR_ID: accountDetails ? accountDetails.id : '',
            COMPANY_ID: company.id,
          });

          if (data && data.getIsFavoriteCompany) {
            setIsFavorite(data.getIsFavoriteCompany.exists);
          }
        } catch (error) {
          console.error('Error fetching favorite status:', error);
        }
      }
    };

    fetchFavoriteStatus();
  }, [company.id, accountDetails, type]);

  useEffect(() => {
    if (prevIsOpen.current && !isOpen && stableCompanyRef.current?.id) {
      triggerLambdaEvent();
    }
    prevIsOpen.current = isOpen;
  }, [isOpen, accountDetails, isFavorite, opportunityPlacement]);

  useEffect(() => {
    getPublicAdvisorTags({
      skillsData: true,
    }).then((response) => {
      if (!response.errors) {
        setDesiredSkills(response.data.getTags.skillsData);
      }
    });

    eventBus.on(eventBusValues.triggerCompanyDrawerAction, (data) => {
      if (data.close) {
        setIsOpen(false);
        setBoardOwner({});
        setCompanyMembers([]);
        setCompany({});
      } else {
        setIsOpen((prevValue) => !prevValue);
      }
      if (data.companiesListFiltered) {
        setCompaniesListFiltered(data.companiesListFiltered);
      }
      if (data.isEditing) {
        setIsEditingV2(data.isEditing);
      } else {
        setIsEditingV2(false);
      }
      setHideSelection(data.hidePostSection);
      if (data.company) {
        setCompany(data.company);
        if (
          data.company.recurlyPlanCode &&
          localAccountDetails &&
          data.company.id != localAccountDetails.id
        ) {
          getAdvisorRecurlyPlan({
            recurlyPlanCode: data.company.recurlyPlanCode,
          }).then((result) => {
            const planCode = result.data.getRecurlyPlan;
            setIsAcPlusCompany(planCode.isAcPlus);
          });
        }
      } else if (
        data.companyId &&
        localAccountDetails &&
        data.companyId != localAccountDetails.id
      ) {
        getCompanyInfoBoardroomMenu({ companyId: data.companyId })
          .then((res) => {
            if (res && res.data && res.data.getCompany) {
              getAdvisorRecurlyPlan({
                recurlyPlanCode: res.data.getCompany.recurlyPlanCode,
              })
                .then((result) => {
                  setCompany(res.data.getCompany);
                  const planCode = result.data.getRecurlyPlan;
                  setIsFreemiumCompany(planCode.isFreemium);
                  setIsAcPlusCompany(planCode.isAcPlus);
                })
                .catch((err) => {
                  console.log('getAdvisorRecurlyPlan error', err);
                });
            }
          })
          .catch((err) => {
            console.log('getCompanyInfoBoardroomMenu error', err);
          });
      } else if (
        data.companyId &&
        localAccountDetails &&
        data.companyId == localAccountDetails.id
      ) {
        setCompany(localAccountDetails);
      }
    });

    return () => {
      eventBus.remove(eventBusValues.triggerCompanyDrawerAction);
    };
  }, []);

  useEffect(() => {
    if (company.id) {
      if (
        localAccountDetails.boards &&
        localAccountDetails.boards.invitedBoards?.length > 0
      ) {
        const foundInvitation = localAccountDetails.boards.invitedBoards.find(
          (x) => x.id === company.id
        );
        setInvitation(
          foundInvitation?.invitation ? foundInvitation.invitation : {}
        );
      }
      getCompanyContacts({
        companyId: company.id,
      })
        .then((response) => {
          if (response.data && response.data.getCompanyContacts) {
            const sortedContacts = response.data.getCompanyContacts.sort(
              (a, b) => {
                return b.isBoardOwner - a.isBoardOwner;
              }
            );
            setBoardOwner(sortedContacts[0]);
            setCompanyMembers(sortedContacts);
          } else {
            setBoardOwner({});
            setCompanyMembers([]);
          }
        })
        .catch(() => {
          setBoardOwner({});
          setCompanyMembers([]);
        });
    }
  }, [company]);

  useEffect(() => {
    if (
      !isOpen &&
      invitation.status !== 'declined' &&
      invitation.status !== 'accepted' &&
      invitation.invitationId
    ) {
      sendFSEventInviteViewed({
        companyDisplayName: company.companyName,
        asLoggedUserDetails,
        isBulkInvite: invitation.bulk,
        action: 'close',
        outcome: '',
        inviteId: invitation.invitationId,
        error: null,
        isFreeTrial: localAccountDetails.isFreeTrial ? true : false,
      });
    }
  }, [isOpen]);

  return (
    <div style={classes.drawerContainer}>
      <SwipeableDrawer
        disableSwipeToOpen
        anchor={
          type === AccountType.ADVISOR ||
          (company &&
            company.id &&
            accountDetails &&
            company.id != accountDetails.id)
            ? 'right'
            : 'left'
        }
        open={isOpen}
        onOpen={() => {}}
        onClose={() => {
          if (!isEditing) {
            setIsOpen(false);
            setBoardOwner({});
            setCompanyMembers([]);
            setCompany({});
          } else {
            setOpenExitDialog(true);
            // eventBus.dispatch(eventBusValues.triggerCloseBlocker, true);
          }
        }}
        PaperProps={{
          sx: classes.advisorPaper,
          style: {
            paddingBottom: '0px',
          },
          ref: boxRef,
        }}
      >
        {company && company.id ? (
          accountDetails && company.id != accountDetails.id ? (
            <CompanyOpportunitySlide
              invitation={invitation}
              company={company}
              skills={desiredSkills}
              handleClickAway={() => {
                setIsOpen(false);
              }}
              companyMembers={companyMembers}
              companyName={company.companyName}
              boardOwner={boardOwner}
              hidePostSection={hidePostSection}
              isFreemiumCompany={isFreemiumCompany}
              isAcPlusCompany={isAcPlusCompany}
            />
          ) : (
            <CompanyOwnProfileSlide
              company={company}
              setCompany={setCompany}
              handleClickAway={() => setIsOpen(false)}
              companyMembers={companyMembers}
              boxRef={boxRef}
              setIsEditing={setIsEditing}
              isEditing={isEditing}
              ref={formRef}
              isEditingV2={isEditingV2}
            />
          )
        ) : (
          <div style={classes.loadingContainer}>
            <LoadingIndicatorLogo
              size={200}
              iconFontSize={93}
              iconRight={105}
            />
          </div>
        )}
        <Dialog
          open={openExitDialog}
          sx={classes.dialogContainer}
          maxWidth="sm"
          onClose={() => {
            setOpenExitDialog(false);
          }}
        >
          <div style={classes.dialogContainer}>
            <DialogContent>
              <DialogContentText sx={classes.dialogText}>
                Are you sure you want to close without saving?
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={classes.dialogActions}>
              <Button
                variant="outlined"
                color="primary"
                sx={classes.actionsButton}
                onClick={() => {
                  setOpenExitDialog(false);
                  setIsEditing(false);
                  setIsOpen(false);
                  setBoardOwner({});
                  setCompanyMembers([]);
                  setCompany({});
                }}
                style={{
                  paddingTop: isSM ? '30px' : '0px',
                  paddingBottom: isSM ? '30px' : '0px',
                }}
              >
                Discard Changes
              </Button>
              <Button
                variant="outlined"
                color="primary"
                sx={classes.actionsButton}
                onClick={() => {
                  setOpenExitDialog(false);
                  if (formRef.current) {
                    formRef.current.triggerFunction();
                  }
                }}
                style={{
                  paddingTop: isSM ? '30px' : '0px',
                  paddingBottom: isSM ? '30px' : '0px',
                }}
              >
                Save Changes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </SwipeableDrawer>
    </div>
  );
};

export default CompanyOpportunityDrawer;
