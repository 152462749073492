export const advisorAllowedRoutes = [
  '/peer-directory',
  '/dashboard',
  '/account',
  '/advisor/onboarding/terms-of-service',
  '/advisor/aobf',
  '/board-directory',
  '/post/:postId?',
  '/:companySlug/post/:postId?',
  '/:companySlug/boardroom',
  '/:companySlug/benefits',
  '/faqs',
  '/tools-resources',
];
