import React from 'react';
import { Card, Typography, Box } from '@mui/material';
import { useAccount } from '../../contexts/Account';
import InviteTeamMembers from '../../components/Company/InviteTeamMembers';
import { extractUrl } from '../../utils/urlUtils';

export default function EmptyState() {
  const { accountDetails } = useAccount();
  return (
    <Card
      sx={{
        p: '20px',
        borderRadius: '8px',
        boxShadow: 'none',
        mt: '25px',
        // maxWidth: '1000px',
      }}
    >
      <Box>
        <Box
          id="boardroom-current-goal"
          sx={{
            bgcolor: '#F1F9FF',
            borderRadius: '8px',
            py: '5px',
            px: '10px',
            maxWidth: '720px',
            width: '100%',
            borderTop: '1px solid #4FB5F9',
            borderRight: '1px solid #4FB5F9',
            borderBottom: '1px solid #4FB5F9',
            borderLeft: '5px solid #4FB5F9',
            display: 'block',
            mx: 'auto',
          }}
        >
          <Typography
            sx={{
              fontWeight: '600',
              fontSize: { xxs: 14, xs: 16 },
              color: '#00548B',
              lineHeight: '21px',
            }}
          >
            Current Challenge or Goal:
          </Typography>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: { xxs: 12, xs: 14 },
              color: '#00548B',
              lineHeight: { xxs: '18px', xs: '21px' },
              whiteSpace: 'pre-wrap',
            }}
          >
            {extractUrl(accountDetails.currentGoal)}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontWeight: '400',
            fontSize: { xxs: '12px', xs: '16px' },
            lineHeight: { xxs: '18px', xs: '24px' },
            color: '#4D586F',
            my: 2,
            whiteSpace: 'pre-wrap',
            textAlign: 'center',
          }}
        >
          Invite your contacts so they can start providing insights on your current challenge or goal
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <InviteTeamMembers />
        </Box>
      </Box>
    </Card>
  );
}
