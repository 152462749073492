import React from 'react';
import { useMediaQuery, Typography } from '@mui/material';
import { ReactComponent as Check } from './ExFreemiumBanner/checkfilled.svg';

const useStyles = (isSM) => ({
  container: {
    width: '100%',
    height: isSM ? '64px' : '50px',
    zIndex: 1000,
    position: 'fixed',
  },
  fixedContainer: {
    width: '100%',
    height: isSM ? '64px' : '50px',
    backgroundColor: '#3171F6',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
  },
  icon: {
    color: '#19AD6E',
    height: '25px',
    width: '25px',
    marginTop: '-5px',
    marginLeft: '-7px',
  },
  background: {
    backgroundColor: 'white',
    height: '11px',
    width: '11px',
    marginRight: '15px',
    marginTop: '-5px',
    overflow: 'display',
  },
  text: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 700,
  },
  hide: {
    display: 'none',
  },
});

function OnboardingBanner(props) {
  const { show } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  return (
    <>
      <div
        style={show ? classes.container : classes.hide}
        id="B2BonboardingBanner"
      >
        <button type="button" style={classes.fixedContainer}>
          <div style={classes.background}>
            {!isSM && <Check style={classes.icon} />}
          </div>
          <Typography variant="h3" sx={classes.text}>
            Your profile is under review! Once you've completed your onboarding
            call your profile can go live.
          </Typography>
        </button>
      </div>
    </>
  );
}
export default OnboardingBanner;
