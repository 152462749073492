import { Link } from '@mui/material';

const extractUrl = (text) => {
  text = text || '';
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = text.split(urlRegex);

  return parts.map((part, index) =>
    part.match(urlRegex) ? (
      <Link key={index} href={part} target="_blank" rel="noopener noreferrer">
        {part}
      </Link>
    ) : (
      part
    )
  );
};

export { extractUrl };
