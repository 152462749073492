import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  Link,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingLogoSkeleton from '../../../../SkeletonLoading/loadingLogoSkeleton';

const AppleLoginPopup = ({ open, onClose, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async () => {
    if (!email || !password) {
      setError('Please fill in both fields.');
      return;
    }
    setError('');
    setIsLoading(true);

    try {
      await onSubmit({ email, password });
    } catch (err) {
      console.error('Error during submission:', err);
      setError('Failed to sign in. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile} // Habilita fullscreen en móvil
      maxWidth="md"
      fullWidth
      PaperProps={{
        onClick: (e) => e.stopPropagation(),
      }}
      sx={{
        '& .MuiDialog-paper': {
          width: isMobile ? '100%' : '90%',
          maxWidth: isMobile ? '100%' : '600px',
          maxHeight: isMobile ? '100%' : '90vh',
          margin: isMobile ? 0 : 'auto',
          borderRadius: isMobile ? 0 : '16px',
          overflowY: 'auto',
        },
      }}
    >
      <DialogContent
        sx={{
          textAlign: 'center',
          overflowY: 'auto',
          marginTop: '20px',
        }}
      >
        <Typography variant="body2" gutterBottom>
          To sync your Apple Calendar with our platform, please follow these
          steps:
        </Typography>
        <Box mt={2}>
          <Box component="ol" textAlign="left">
            <li>
              Go to{' '}
              <Link
                href="https://appleid.apple.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://appleid.apple.com/
              </Link>
            </li>
            <li>Sign in with your Apple ID.</li>
            <li>
              Select “Sign-In & Security” and click “App-Specific Passwords.”
            </li>
            <li>Generate a new password for this app.</li>
            <li>
              Return here and enter your Apple ID email and the password.
            </li>
          </Box>
        </Box>

        <Box sx={{ padding: { xs: '0 10px', sm: '0 20px' } }}>
          <TextField
            label="Apple ID Email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            margin="normal"
            type="email"
          />
          <TextField
            label="App-Specific Password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            margin="normal"
            type="password"
          />
          {error && (
            <Typography color="error" variant="body2" mt={1}>
              {error}
            </Typography>
          )}
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          paddingBottom: '20px',
        }}
      >
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={isLoading}
          sx={{
            backgroundColor: '#3171F6',
            color: '#FFFFFF',
            fontSize: '16px',
            fontWeight: 700,
            borderRadius: '999px',
            width: '200px',
            height: '44px',
            textTransform: 'none',
            '&:disabled': {
              backgroundColor: '#3171F6',
              color: '#FFFFFF',
              opacity: 0.7,
            },
          }}
        >
          {isLoading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
            >
              <LoadingLogoSkeleton />
            </Box>
          ) : (
            'Sign In'
          )}
        </Button>
        <Button
          onClick={onClose}
          color="secondary"
          sx={{ textTransform: 'none', width: { xs: '100%', sm: 'auto' } }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppleLoginPopup;
