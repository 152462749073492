import React, { useState } from "react";
import { Button } from "@mui/material";
import ShareYourIdeasDialog from "../../scenes/Boardroom/ShareYourIdeasDialog";
import lightbulb from "../../icons/lightbulb.svg";
import eventBus, { eventBusValues } from "../../eventBus";
import { useAccount } from "../../contexts/Account";

function PostBoardroomIdea({ company, text, buttonStyles = {}, setIsLoading = () => { } }) {

  const [openDialog, setOpenDialog] = useState(false);
  const { isFreeTrial } = useAccount();

  return (
    <>
      <ShareYourIdeasDialog
        open={openDialog}
        onClose={() => { setOpenDialog(false) }}
        company={company}
        setIsLoading={setIsLoading}
      />

      <Button
        onClick={() => { isFreeTrial ? eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction) : setOpenDialog(true) }}
        variant='contained'
        color="primary"
        startIcon={<img src={lightbulb} alt="crown" />}
        sx={{
          fontWeight: 600,
          fontSize: 14,
          textTransform: 'none',
          borderRadius: '8px',
          padding: '5px 20px',
          boxShadow: 'none',
          color: "#FFF",
          ...buttonStyles
        }}>
        {text || "Submit an Idea"}
      </Button>
    </>
  )
}

export default PostBoardroomIdea;