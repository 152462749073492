import React, { useState } from 'react';
import {
  Typography,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
} from '@mui/material';
import ACButton from './ACButton';
import { useTranslation } from 'react-i18next';

const useStyles = (isSM) => ({
  contentContainer: {
    height: isSM ? '480px' : '420px',
    overflowY: 'scroll',
    display: 'grid',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  sectionBody: {
    marginBottom: '10px',
  },
  endRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  continueButtonContainer: {
    width: '180px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: isSM ? 'auto' : '0px',
  },
});

function TermsOfService({ onContinueClick, loading }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const classes = useStyles(isSM);
  const [termsAccepted, setTermsAccepted] = useState(false);

  function handleContinueClick() {
    if (onContinueClick) {
      onContinueClick();
    }
  }

  function handleCheckboxClick() {
    setTermsAccepted(!termsAccepted);
  }

  return (
    <>
      <div
        style={classes.contentContainer}
        data-cy="termsOfServiceScrollableContent"
      >
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          AdvisoryCloud Terms of Service
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Last Modified: July 12th 2021
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Welcome to AdvisoryCloud
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          We provide a platform where professionals and companies connect and
          collaborate through Digital Advisory Boards being built and managed on
          AdvisoryCloud by corporate clients.
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          For professionals, we offer membership options that allow you to join
          the advisory boards being built and managed on AdvisoryCloud, add
          board work to your resume, and experience the value and new
          opportunities that can come from participating on an advisory board.
          Membership options for advisors also include a digital advisor
          profile, a custom url on AdvisoryCloud.com, listing of your profile in
          our directory of available advisors, and tools to market yourself as
          an advisor and potentially receive inquiries for hourly and
          project-based opportunities at your determined hourly rate.
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          For companies and teams within companies, we provide SaaS options that
          allow you to easily assemble, manage, and get insights from a diverse
          or targeted group of advisors through our Digital Advisory Boards.
          Digital Advisory Boards are composed of professionals from our network
          of advisors on AdvisoryCloud and allow you to post questions, updates,
          and engage in discussions with the members of your advisory board from
          a simple interface.
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Our goal is to make it possible for every professional to join an
          advisory board and simplify the process of building, managing, and
          using an advisory board for companies and teams through technology.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Acceptance of Terms of Service
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          These Terms of Service (these “Terms of Service”) are a legally
          binding contract between you and AdvisoryCloud Inc. (“AdvisoryCloud,”
          “we,” “us,” or “our”) and govern your access to and use of the website
          located at http://www.advisorycloud.com (the “Website”) and other
          services provided by AdvisoryCloud (collectively, the "Services")
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          These terms provide important information, including our limitation of
          liability, your waiver of any right to participate in a class action,
          and your agreement to resolve any disputes between you and
          AdvisoryCloud by binding individual arbitration. By using the
          Services, you agree to be bound by these Terms.
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          It is important that you read these Terms of Service carefully before
          using the Services. By accessing the Services and/or by clicking a box
          that states that you accept or agree to these Terms of Service, you
          signify your understanding of, and agreement with, these Terms of
          Service. If you do not agree to and accept these Terms of Service, you
          do not have permission to access the Services and you should stop
          using the Services immediately.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Additional Policies
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          When using particular services, such as specific sections of
          AdvisoryCloud Platform (as defined below), you are subject to any
          guidelines, rules and/or additional terms and conditions applicable to
          such services that may be posted on the Services from time to time.
          All such guidelines, rules, and/or additional terms and conditions are
          hereby incorporated by reference into these Terms of Service. To the
          extent of any inconsistency between any provision of these Terms of
          Service and any other guidelines, rules and/or additional terms and
          conditions posted on the Services from time to time, the provision(s)
          of these Terms of Service shall control.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Changes to these Terms of Service
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          We may revise and update these Terms of Service from time to time at
          our sole discretion. All changes are effective immediately when we
          post them, and apply to all access to and use of the Services
          thereafter. However, any changes to the Governing Law and Jurisdiction
          and Dispute Resolution sections below will not apply to any disputes
          for which the parties have actual notice on or before the date the
          change is posted through the Services. Your continued use of the
          Services following the posting of revised Terms of Service means that
          you accept and agree to the changes. You are expected to check this
          page from time to time so you are aware of any changes, as they are
          binding on you.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Access to the Services
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          If you wish to access and participate in AdvisoryCloud online platform
          (“AdvisoryCloud Platform”), you must become either a registered
          executive member (an “Advisor”) or registered company member (a
          “Company Member”). You must be at least 18 years old to use the
          Services. As a condition of your use of AdvisoryCloud Platform, you
          agree to provide true, accurate, current and complete information
          about yourself and to keep such information up to date and current at
          all times. If you are registering as a Company Member on behalf of a
          company, organization or other entity (a "Business"), you represent
          and warrant that you are authorized to grant all permissions and
          licenses provided in these Terms of Service on behalf of such
          Business, to bind such Business to these Terms of Service, and that
          you agree to these Terms of Service on behalf of the Business. You are
          responsible for ensuring that all persons who access the Services
          through your internet connection are aware of and comply fully with
          these Terms of Service. We reserve the right to add other services,
          activities and features to the Services from time to time which are
          only available to members.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Advisor Membership and Fees
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          With respect to all Advisors:
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Upon completion of the registration process, each Advisor accepts
          membership in AdvisoryCloud Platform. For access to AdvisoryCloud
          Platform as an Advisor, you agree to pay a non-refundable membership
          fee (including applicable taxes), which will be billed on regular
          basis at the current membership rate (the “Advisor Membership Fee”),
          until such time as you cancel your membership. The Advisor Membership
          Fee is separate from, and additional to, your initial set-up fee, if
          applicable, both of which are non-refundable. The date on which your
          membership renews is referred to as your "Membership Renewal Date".
          You can obtain a copy of your invoice for the Advisor Membership Fee
          by contacting us by e-mail at CustomerService@AdvisoryCloud.com.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          By registering as an Advisor, you agree that we may start your
          subscription immediately upon our acceptance of your registration.
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          If you have a free-trial membership, you can cancel at anytime during
          your free-trial period by visiting{' '}
          <a href="https://advisorycloud.com/deactivate">
            https://advisorycloud.com/deactivate
          </a>{' '}
          or by e-mailing{' '}
          <a href="mailto:customerservice@advisorycloud.com">
            customerservice@advisorycloud.com.
          </a>{' '}
          If you do not request to cancel during your free-trial period, you
          agree that we may start your subscription immediately upon the end of
          your free-trial period
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Once your subscription has started, you can cancel your membership at
          any time by visiting{' '}
          <a href="https://advisorycloud.com/deactivate">
            https://advisorycloud.com/deactivate
          </a>{' '}
          or by e-mailing{' '}
          <a href="mailto:customerservice@advisorycloud.com">
            customerservice@advisorycloud.com
          </a>{' '}
          at least fifteen (15) calendar days prior to the next Membership
          Renewal Date. Please note, this 15 day notice requirement does not
          apply during your free-trial period
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Joining Digital Advisory Boards
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Barring any conflicts of interest identified by AdvisoryCloud or the
          company owner of the advisory board, Advisors are guaranteed the
          ability to join and participate on a number of non-compensated
          advisory boards that are being built and managed on AdvisoryCloud’s
          platform at any given time in their membership. The number of advisory
          boards you can join is defined by your current membership level. You
          understand that companies operating their advisory board on
          AdvisoryCloud’s platform have the right to close their advisory board
          at any time, at which point you will have the opportunity to join
          another advisory board available on our platform in line with this
          guarantee.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Accepting Advisory Engagements
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          AdvisoryCloud Platform allows Advisors to accept engagements for
          meetings or projects at a rate set by the Advisor from time to time.
          Advisors also have the ability to accept engagements on an annual
          basis or other custom arrangements. For Advisors that complete any per
          meeting engagements, AdvisoryCloud will remit payment to the Advisor
          within thirty (30) days of the completed meeting date. In order to
          receive payment, the Advisor must have provided AdvisoryCloud with a
          current W-9.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          NO GUARANTEE OF PAID WORK
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          While the Services provide a platform to pursue advisory
          opportunities, AdvisoryCloud makes no guarantee, express or implied,
          that you will be able to secure paid advisory opportunities through
          the Services or as to the ultimate success of any engagement that you
          enter into as a result of your use of the Services.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Company Membership and Fees
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          With respect to Company Members only:
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Upon completion of the registration process, each Company Member
          accepts membership in AdvisoryCloud Platform. For access to
          AdvisoryCloud Platform as a Company Member, you agree to pay the
          applicable fees (including applicable taxes) specific to the level of
          AdvisoryCloud services that you select at the time you register. Your
          membership will automatically continue until such time as you request
          that your membership be terminated. By entering your email address at
          registration, you represent to us that you are authorized to represent
          your company on the Services and agree that we may start your
          membership immediately.
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Additionally, you agree that AdvisoryCloud is authorized to post
          advisor and advisory board opportunities on the AdvisoryCloud Platform
          and to our network of potential advisors on your behalf, including
          questions and updates from your digital boardroom, company logo and
          name, and any other information you provide during the registration
          process intended to market your company to advisors. Any affiliated
          company information will be listed based on your email address domain
          name.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Engaging Advisors
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          In the event that you engage an Advisor through AdvisoryCloud Platform
          for meetings or projects, you agree to pay the per meeting rate
          displayed when selecting the Advisor, plus all applicable taxes,
          charges and fees, including a per meeting administrative fee at
          then-current rates. AdvisoryCloud will periodically charge you using
          your payment information for per meeting engagements. All amounts paid
          are non-refundable. Late payments may be subject to late fees
          established by AdvisoryCloud from time to time.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Hiring Advisors
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          In addition to engaging advisors for meetings or projects,
          AdvisoryCloud Platform allows you to hire Advisors for any non per
          meeting engagement (a “Custom Advisor Agreement”). Compensation for
          Custom Advisor Agreements are determined by you and the Advisor. For a
          period of twelve (12) months after you identify an advisor through
          AdvisoryCloud Platform, regardless of whether the Custom Advisor
          Agreement is finalized through AdvisoryCloud Platform, you agree to
          pay to AdvisoryCloud an administrative fee at the then-current rates,
          either in a single payment or over a selected payment schedule, at the
          time the Custom Advisor Agreement is finalized. Late payments may be
          subject to late fees established by AdvisoryCloud from time to time.
          Failure to report the hiring of an Advisor to AdvisoryCloud which
          would give rise to a commission payment may result in the termination
          of your access to AdvisoryCloud Platform.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          COMPANY MEMBERS – NO GUARANTEE OF SUCCESS
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          While the Services provide a platform for you to build a Digital
          Advisory Board, search for and hire advisors, AdvisoryCloud makes no
          guarantee, express or implied, that you will be able to secure
          advisors through the Services or as to the ultimate success of any
          engagement that you enter into as a result of your use of the
          Services.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Use and Protection of User Name and Password
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          In connection with your membership, you will select a user name and
          password. We authorize you to access and use AdvisoryCloud Platform,
          subject to these Terms of Service. You are responsible for maintaining
          the confidentiality of your user name and password. You agree to
          notify us immediately by contacting us at the address below as soon as
          you become aware of any breach of the confidentiality of your user
          name or password, or any breach of security through your account.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Use of Payment Information
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          If you provide credit or debit card information, we use this
          information as described in our Privacy Policy. To the extent
          permitted by applicable law and subject to our Privacy Policy, you
          acknowledge and agree that we may use certain third-party payment
          processors. By providing credit or debit card information, you
          represent, warrant and covenant that: (i) you are legally authorized
          to provide such information, (ii) you are legally authorized to
          initiate payments from the credit or debit card account(s), and (iii)
          such action does not violate the terms and conditions applicable to
          your use of such credit or debit card account(s) or applicable law.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Privacy Policy
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          We are firmly committed to safeguarding the privacy of all visitors to
          the Website and all users of the Services. Please see our Privacy
          Policy which is hereby incorporated by reference into these Terms of
          Service. A copy of our Privacy Policy is available on the Website at{' '}
          <a href="https://www.advisorycloud.com/privacy-policy">
            https://www.advisorycloud.com/privacy-policy
          </a>
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Use of Content
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          You are permitted to view, download, and print content from the
          Services subject strictly to the following conditions: (i) the content
          may be used solely for information purposes and for your own personal
          non-commercial private use, (ii) the content may not be modified,
          adapted or altered in any way; and (iii) you do not acquire any
          ownership rights to any content on the Services. The rights granted to
          you constitute a limited license and not a transfer of title. You may
          not copy, store in any medium (including, without limitation, on any
          other website), modify, adapt, publish, distribute by any means
          (including, without limitation, via any peer-to-peer network), prepare
          derivative works from, broadcast, communicate to the public or
          transmit any part of the Services or any of the contents contained in
          the Services or otherwise use the content for any other purpose other
          than as expressly permitted herein.
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Elements of the Services are protected by trade dress and other laws
          and may not be copied or imitated in whole or in part. You agree not
          to use any means to circumvent log in, password, and other protections
          that we put in place to restrict access to certain parts of the
          Services. Any other use requires our prior written permission. Any
          rights not expressly granted in these Terms of Service are reserved
          wholly and exclusively to us.
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Any unauthorized use terminates the permission or license granted by
          us and shall constitute a clear and material breach by you of these
          Terms of Service. The Services can be accessed from countries around
          the world and may contain references to our content and services that
          may not be available in your country. These references do not imply
          that we intend to provide such content or services in your country. If
          applicable law prohibits such use, you are not authorized to use the
          Services. These Terms of Service are void where prohibited by law, and
          the right to access and use the Services, AdvisoryCloud Platform,
          content and services is revoked in such jurisdictions.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Advisor Contributions and Content
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          The Services allow you to post content, including your name,
          biographical information, photos, and other materials. Anything that
          you post or otherwise make available on the Services is referred to as
          "User Content." We share your User Content in multiple ways, such as
          through your public profile and in email communications. By choosing
          to provide User Content, you acknowledge and agree that such User
          Content may be viewed by the general public and will not be treated as
          private, proprietary, or confidential. If you would like to opt-out of
          the public profile feature of the Services, you must contact us at
          CustomerService@AdvisoryCloud.com. You retain all rights in, and are
          solely responsible for, the User Content you post to the Service.
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          If you submit any User Content, you grant to us a worldwide,
          perpetual, royalty-free, non-exclusive, sub-licensable and
          transferable right and license to use, reproduce, modify, copy,
          perform, distribute and/or to incorporate such User Content on the
          Services, in print or other media. Further, by submitting any User
          Content, you represent, warrant and undertake that such User Content:
          (i) is your own original work, or submitted with the express
          permission of the owner, or is in the public domain, and that you have
          the right to make it available to us for all the purposes specified
          above; (ii) is not defamatory; and (iii) does not infringe any law or
          the intellectual property rights or other proprietary rights of any
          third party. You acknowledge and agree that to the extent that any
          User Content you submit to the Services is original work not
          previously published on any other website or otherwise (an “Original
          Work”) that you assign all of your right, title and interest in and to
          such Original Work to AdvisoryCloud.
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          We reserve the right to suspend or to terminate your access to the
          whole or any part of the Services without notice if, in our opinion,
          any User Content you submit to the Services infringes the intellectual
          property rights of any third party or otherwise does not comply with
          any provision of these Terms of Service, or in any way we determine is
          harmful to us.
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Advisor Conduct
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          AdvisoryCloud provides the Services and AdvisoryCloud Platform in
          order to promote economic opportunity for our advisors by enabling
          executives and companies to meet, exchange ideas, learn and share
          opportunities. In order to preserve the reputation of AdvisoryCloud
          and enable us to continue to offer the Services and AdvisoryCloud
          Platform, you agree to:
        </Typography>
        <ul>
          <li>
            <Typography variant="body1_old_font">
              Use the Services only in compliance with all applicable laws,
              including, without limitation, privacy laws, intellectual property
              laws, anti-spam laws, export control laws, tax laws, and
              regulatory requirements;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Use your real name when interacting with other guests or members
              on the Services or on AdvisoryCloud Platform; and
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Use your real name when interacting with other guests or members
              on the Services or on AdvisoryCloud Platform; and
            </Typography>
          </li>
        </ul>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          You agree that you will not
        </Typography>
        <ul>
          <li>
            <Typography variant="body1_old_font">
              Act dishonestly or unprofessionally, including by posting
              inappropriate, inaccurate, or objectionable content;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Add content that is not intended for, or is inaccurate for, a
              designated field (e.g. submitting a telephone number in the
              “title” field);
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Use an image that is not your likeness or a head-shot for your
              profile;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Create a false identity on AdvisoryCloud Platform;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Misrepresent your current or previous positions and
              qualifications;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Misrepresent your affiliations with a person or entity, past or
              present;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Misrepresent your identity, including, but not limited to, the use
              of a pseudonym;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Create an Advisor profile for anyone other than yourself;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Use or attempt to use another advisor’s account;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Harass, abuse or harm another person;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Send spam or other unwelcomed communications to others;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Scrape or copy profiles and information of others through any
              means (including crawlers, browser plugins and add-ons, and any
              other technology or manual work);
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Act in an unlawful, libelous, abusive, obscene, discriminatory or
              otherwise objectionable manner;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Make any statement, or induce any third party to make any such
              statement that in any way reflects adversely upon the business or
              reputation of AdvisoryCloud;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Disclose information that you do not have the right to disclose
              (such as the confidential information of others, including your
              employer);
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Violate the intellectual property rights of others, including
              patents, trademarks, trade secrets, copyrights, or other
              proprietary rights;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Post any unsolicited or unauthorized advertising, “junk mail,”
              “spam,” “chain letters,” “pyramid schemes,” or any other form of
              unauthorized solicitation;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Send messages from AdvisoryCloud Platform to distribution lists,
              newsgroup aliases, or group aliases;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Post anything that contains software viruses, worms, or any other
              harmful code;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Manipulate identifiers in order to disguise the origin of any
              message or post transmitted through AdvisoryCloud Platform;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Create or operate a pyramid scheme or engage in fraud or other
              similar practices;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Copy or use the information, content or data of others available
              on AdvisoryCloud Platform;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Copy or use the information, content or data on AdvisoryCloud
              Platform to solicit or recruit members in connection with a
              competitive service, organization or group (as determined by
              AdvisoryCloud);
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Reverse engineer, decompile, disassemble, decipher or otherwise
              attempt to derive the source code for the Services, AdvisoryCloud
              Platform or any related technology, or any part thereof;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Imply or state that you are affiliated with or endorsed by
              AdvisoryCloud without our express consent;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Rent, lease, loan, trade, sell/re-sell access to AdvisoryCloud
              Platform or related any information or data;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Sell, sponsor, or otherwise monetize any other feature of the
              Services or AdvisoryCloud Platform, without our written consent;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Remove any copyright, trademark or other proprietary rights
              notices contained in or on the Services;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Collect, use, copy, or transfer any information obtained from
              AdvisoryCloud without our written consent;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Share or disclose information of others without their express
              consent;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Use manual or automated software, devices, scripts robots, other
              means or processes to access, “scrape,” “crawl” or “spider” the
              Services, AdvisoryCloud Platform or any related data or
              information;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Use bots or other automated methods to access and interact with
              the Services or AdvisoryCloud Platform;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Monitor the Services'' availability, performance or functionality
              for any competitive purpose;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Engage in “framing,” “mirroring,” or otherwise simulating the
              appearance or function of the Services or AdvisoryCloud Platform;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Access the Services or AdvisoryCloud Platform except through the
              interfaces expressly provided by AdvisoryCloud;
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Override any security feature of the Services; and/or
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              Interfere with the operation of, or place an unreasonable load on,
              the Services (e.g., spam, denial of service attack, viruses,
              gaming algorithms).
            </Typography>
          </li>
          <li>
            <Typography variant="body1_old_font">
              These conduct obligations apply during and after the term of your
              membership. If you breach your conduct obligations, AdvisoryCloud
              may pursue any and all available remedies as described under
              “Remedies” below. We will fully co-operate with any law
              enforcement authorities or court order requesting or directing us
              to disclose the identity or locate anyone suspected to have acted
              or to have attempted to act in breach of these Terms of Service.
            </Typography>
          </li>
        </ul>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Links
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Links to third party websites on the Services are provided solely as a
          convenience to you. We have not necessarily reviewed all of these
          third party websites and pages and we do not control and are not
          responsible for the accuracy or availability of information, content
          or services provided by them. We therefore do not endorse or make any
          representations about them, or any content found on them, or any
          results that may be obtained from using them. If you decide to access
          any of the third party websites linked to the Services, you do so
          entirely at your own risk and subject to the terms and conditions that
          may be applicable to such websites
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Links from third party websites to the Services may be created through
          plain-text links only (i.e. no logos) and provided they do not create
          an association with a third party brand, product or service. For other
          types of links to the Services, you must obtain our express written
          permission. We reserve the right to deny or rescind permission to link
          to the Services, and to require termination of any link to the
          Services, for any reason in our sole and absolute discretion
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Intellectual Property
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          All intellectual property and other proprietary rights, including
          (without limitation) copyright, trademarks and database rights, in the
          Services and its contents are owned by or licensed to us, or otherwise
          used by us as permitted by applicable law. Logos and designs are our
          registered trademarks in territories around the world
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          The use or misuse of these trademarks or any content, except as
          expressly permitted herein, and including without limitation use as a
          domain name or as part of a domain name, is expressly prohibited and
          nothing stated or implied on the Services confers on you any license
          or right under any patent, copyright work, trade mark, design or other
          right of a similar nature of ours or any third party
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Nothing stated or implied in the Services is designed to grant any
          license or right under any copyright or other intellectual property
          rights of ours or any third party or to use any names, logos,
          pictures, trademarks or other works or content featured on the
          Services. No act of downloading or otherwise copying or reproducing
          from the Services will transfer title to you to any software or
          content on the Services, unless express statements are communicated to
          you by us to the contrary
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Notice of Copyright Infringement
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          We may, in appropriate circumstances and at our sole discretion,
          terminate the accounts of users involved in disputes regarding
          intellectual property rights and remove or disable access of such user
          to the Services. If you believe that your work has been used or copied
          in a way that constitutes copyright infringement and such infringement
          is occurring on the Services, you must provide our Copyright Agent
          with a written notice (“Notice”) containing the following elements:
          (a) a physical or electronic signature of the person authorized to act
          on behalf of the copyright owner that is alleged to have been
          infringed; (b) a description of the copyrighted work(s) that you claim
          have been infringed including the date the work(s) was created, the
          name of the author(s), the title of the work(s), and whether the
          work(s) has been registered with the United States Copyright Office.
          If the work(s) has been registered with the United States Copyright
          Office, also provide a copy of the Copyright Registration; (c) a
          description of the activity or the content on our Services claimed to
          infringe the copyright including where the content is located on the
          our Services; (d) information sufficient to permit us to contact you,
          such as your physical address, telephone number, email address, and
          facsimile number; (e) a statement by you that you have a good faith
          belief that the use of the content identified in your Notice in the
          manner complained of is not authorized by the copyright owner, its
          agent, or the law, and infringes upon the copyright identified; and
          (f) a statement by you under penalty of perjury, that the information
          in your Notice is accurate and, that you are the copyright owner or
          authorized to act on the copyright owner’s behalf
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Notice of claims of copyright infringement should be sent to:
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          AdvisoryCloud, Inc.
          <br />
          Corporate Headquarters
          <br />7 Hamilton Landing #100, Novato, CA 94949
          <br />
          E-mail:{' '}
          <a href="mailto:CustomerService@AdvisoryCloud.com">
            CustomerService@AdvisoryCloud.com
            <br />
          </a>
          Attn: Copyright Agent&nbsp
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Security
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          The security of Internet traffic is uncertain and so, if you
          communicate with us by e-mail, you accept the risks of such
          uncertainty and the possible lack of security and threat to
          confidentiality which may result from any such communication
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Disclaimers
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          INFORMATION ON THE SERVICES MAY BE OR BECOME OUT OF DATE FROM TIME TO
          TIME AND WE ARE UNDER NO OBLIGATION TO UPDATE IT. NO REPRESENTATION OR
          GUARANTEE (INCLUDING LIABILITY TOWARDS THIRD PARTIES), EXPRESSED OR
          IMPLIED, IS MADE AS TO THE ACCURACY, RELIABILITY OR COMPLETENESS OF
          ANY SERVICES CONTENT. WE ARE NOT RESPONSIBLE FOR THE CONTENT OF
          EXTERNAL INTERNET WEBSITES
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          IN ADDITION, YOU ACKNOWLEDGE AND AGREE THAT ANY DOCUMENTS, DATA,
          INFORMATION, CONTENT OR MATERIALS CONTAINED IN OR MADE AVAILABLE ON
          THE SERVICES HAVE NOT BEEN PREPARED WITH YOUR SPECIFIC CIRCUMSTANCES
          IN MIND, MAY NOT BE SUITABLE FOR USE IN YOUR BUSINESS, AND ARE NOT
          INTENDED AS A SUBSTITUTE FOR, THE KNOWLEDGE, EXPERTISE, SKILL AND
          JUDGMENT TAX, LEGAL OR OTHER PROFESSIONALS. THE SERVICES DO NOT
          PROVIDE TAX OR LEGAL ADVICE. YOU ARE RESPONSIBLE FOR OBTAINING SUCH
          ADVICE
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          YOUR USE OF THE SERVICES, OR OTHER CONTENT DOWNLOADED OR OTHERWISE
          OBTAINED THROUGH THE USE OF THE SERVICES, IS AT YOUR OWN DISCRETION
          AND SOLE RISK. YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
          COMPUTER SYSTEM OR DEVICE WITH WHICH YOU ACCESS THE SERVICES AS WELL
          AS FOR ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF ANY
          SUCH CONTENT. WE MAKE NO WARRANTY OR REPRESENTATION THAT THE SERVICES
          OR ANY FUNCTIONS, SERVICES AND FACILITIES CONTAINED OR PROVIDED ON THE
          SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE, THAT
          DEFECTS WILL BE CORRECTED, OR THAT THE SERVICES OR THE SERVER THAT
          MAKES IT AVAILABLE ARE FREE OF VIRUSES, BUGS, SPYWARE OR SIMILAR FLAWS
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND,
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE HEREBY EXPRESSLY
          DISCLAIM ALL WARRANTIES, CONDITIONS AND OTHER TERMS OF ANY KIND
          (WHETHER EXPRESS OR IMPLIED), INCLUDING (BUT NOT LIMITED TO) ANY
          IMPLIED TERM OF ACCURACY, COMPATIBILITY, SECURITY, MERCHANTABILITY,
          SATISFACTORY QUALITY, SUITABILITY, FITNESS FOR A PARTICULAR PURPOSE
          AND ANY TERM AS TO NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY
          RIGHT. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
          IMPLIED WARRANTIES OR LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY MAY
          LAST, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU. TO THE EXTENT
          PERMISSIBLE, ANY IMPLIED WARRANTIES THAT CANNOT BY LAW BE EXCLUDED ARE
          LIMITED TO SIXTY (60) DAYS
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Limitation of Liability
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          WE BE IN ANY WAY LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
          PUNITIVE, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING (WITHOUT
          LIMITATION) DAMAGES FOR LOSS OF PROFITS, CONNECTIVITY, OPPORTUNITIES,
          REPUTATION, EMPLOYMENT, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES
          (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) OR
          ANY OTHER DAMAGES OR LOSSES WHATSOEVER ARISING IN ANY WAY OUT OF,
          FROM, OR IN CONNECTION WITH, THE USE OF OR INABILITY TO USE THE
          SERVICES OR ANY INFORMATION, DATA OR CONTENT CONTAINED ON OR OBTAINED
          FROM THE SERVICES, OR OF ANY WEBSITE OR SERVICES REFERENCED OR LINKED
          TO FROM THE SERVICES, WHETHER ARISING IN AN ACTION IN CONTRACT,
          NEGLIGENCE, OTHER TORT ACTION, OR ON ANY THEORY OF LIABILITY AND
          WHETHER OR NOT WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. THE NEGOTIATION OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
          ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN US AND YOU
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Indemnity
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          As a condition of your access to and use of the Services, you hereby
          expressly agree to indemnify and hold us and our subsidiaries,
          affiliates, licensors, licensees, shareholders, officers, agents,
          employees and other partners harmless from and against any and all
          liabilities, losses, damages, claims, penalties, fines, costs, awards
          and expenses (including, without limitation, reasonable legal fees and
          costs) arising from, related to or in connection with: (i) any
          transaction or dispute between you and any third party, including in
          connection with any employment or other engagement between an Advisor
          and a Company Member; (ii) unauthorized access to the Services through
          your account; (iii) a breach of any of your representations,
          warranties, or covenants under the Terms of Service; (iv) a request by
          you for us to remove or disable access to any content in or on the
          Services; (v) content you submit, transmit or make available through
          us; (vi) your use of the Services; (vii) your violation(s) of these
          Terms of Service; or (viii) any investment decision you may make as a
          result of an introduction or other connection with another Advisor on
          AdvisoryCloud Platform. You shall not enter into a settlement of the
          foregoing nor incur expenses and fees in defending the above claims,
          actions, or allegations without our prior written approval
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Termination
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          If you breach any of the Terms of Service, your authorization to use
          the Services automatically terminates and you must immediately destroy
          materials downloaded, received by email or printed from the Services
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          We reserve the right at all times, at our absolute discretion and
          without prior notice to you, to terminate your membership, password
          and/or your use of the whole or any part of the Services, for any
          reason, including (without limitation) if we believe that you have
          breached or otherwise not complied with any provision of these Terms
          of Service. You agree that we will not be liable to you or any third
          party in any way for any termination of your access to the Services.
          We may also at our absolute discretion and at any time discontinue
          providing the whole or any part of the Services, with or without
          notice
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Remedies
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          You agree that our remedy at law for any actual or threatened breach
          of these Terms of Service would be inadequate and that we shall be
          entitled to specific performance or injunctive relief, or both, in
          addition to any damages that we may be legally entitled to recover,
          together with reasonable expenses of any form of dispute resolution,
          including, without limitation, attorneys’ fees
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          No right or remedy of ours shall be exclusive of any other, whether at
          law or in equity, including without limitation, damages, injunctive
          relief, and attorneys’ fees and expenses
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Governing Law and Jurisdiction
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          The Services (excluding linked sites) are controlled by us from our
          offices within the State of California in the United States of
          America. By accessing the Services, you agree that the statutes and
          laws of the State of California without regard to the conflicts of
          laws principles thereof shall govern all matters relating to your
          access to, or use of, the Services and any content or services. We
          make no representation that any content, products or service available
          on the Services are appropriate or available for use in other
          locations, and accessing them from jurisdictions where their contents
          are illegal is prohibited. Those who choose to access the Services
          from other locations do so at their own risk and are responsible for
          compliance with all local laws
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Dispute Resolution
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          You and AdvisoryCloud agree that any dispute, claim or controversy
          arising out of or relating to these Terms of Service or to your use of
          the Services (collectively "Disputes") will be settled by binding
          arbitration, except that each party retains the right to seek
          injunctive or other equitable relief in a court of competent
          jurisdiction to prevent the actual or threatened infringement,
          misappropriation, or violation of a party’s copyrights, trademarks,
          trade secrets, patents, or other intellectual property rights. You
          acknowledge and agree that you and AdvisoryCloud each are waiving the
          right to a trial by jury or to participate as a plaintiff as a class
          member in any class action proceeding. Further, unless you and
          AdvisoryCloud agree otherwise in writing, the arbitrator may not
          consolidate more than one person’s claims and may not preside over any
          form class action proceeding. If this specific paragraph is held
          unenforceable, then the entirety of this “Dispute Resolution” section
          will be deemed void. Except as provided in the preceding sentence,
          this “Dispute Resolution” section will survive any termination of the
          agreement contained in these Terms
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          <i>Arbitration Rules and Governing Law</i>. The arbitration will be
          administered by the American Arbitration Association ("AAA") in
          accordance with the Commercial Arbitration Rules and the Supplementary
          Procedures for Consumer Related Disputes (the "AAA Rules") then in
          effect, except as modified by this "Dispute Resolution" section. (The
          AAA Rules are available at http://www.adr.org or by calling the AAA at
          1-800-778-7879). The Federal Arbitration act will govern the
          interpretation of this section
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          <i>Arbitration Process</i>. A party who desires to initiate the
          arbitration must provide the other party with a written Demand for
          Arbitration as specified in the AAA Rules. The arbitrator will be
          either a retired judge or an attorney licensed to practice law in the
          state of California and will be selected by the parties from the AAA’s
          roster of consumer dispute arbitrators. If the parties are unable to
          agree upon an arbitrator within 7 days of delivery of the Demand for
          Arbitration, then the AAA will appoint the arbitrator in accordance
          with AAA Rules
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          <i>Arbitration Location and Procedure</i>. Unless you and
          AdvisoryCloud agree otherwise, the arbitration will be conducted in
          San Francisco. If your claim does not exceed $10,000, then the
          arbitration will be conducted solely on the basis of documents you and
          AdvisoryCloud submit to the arbitrator, unless you request a hearing
          and the arbitrator then determines that a hearing is necessary. If
          your claim exceeds $10,000, your right to a hearing will be determined
          by AAA Rules. Subject to AAA Rules, the arbitrator will have the
          discretion to direct a reasonable exchange of information by the
          parties, consistent with the expedited nature of the arbitration
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          <i>Arbitrator’s Decision</i>. The arbitrator will render an award
          within the timeframe specified in the AAA Rules. The arbitrator’s
          decision will include the essential findings and conclusions upon
          which the arbitrator based the award. Judgment on the arbitration may
          be entered in any court having jurisdiction thereof. Any award of
          damages by an arbitrator must be consistent with the “Disclaimers and
          Limitations of Liability” section above. The arbitrator may award
          declaratory or injunctive relief in favor of the claimant only to the
          extent necessary to provide relief warranted by the claimant’s
          individual claim
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Fees. Your responsibility to pay any AAA filing, administrative and
          arbitrator fees will be as set forth in the AAA Rules
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Miscellaneous
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          Failure on our part to exercise or enforce any right or remedy under
          these Terms of Service does not constitute a waiver of such right or
          remedy
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          If any provision of these Terms of Service is found to be
          unenforceable by any court having competent jurisdiction, the
          unenforceable portion will be construed in accordance with applicable
          law as nearly as possible to reflect the original intentions of the
          parties and if it cannot be so construed then severed from these Terms
          of Service, and the remaining provisions of these Terms of Service
          will remain in full force and effect. You may not assign, sub-license
          or otherwise transfer any of your rights under these Terms of Service.
          You agree that there are no third party beneficiaries to these Terms
          of Service
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          You agree that regardless of any statute or law to the contrary, any
          claim or cause of action you may have arising out of or related to use
          of the Services or these Terms of Service must be filed within one (1)
          year after such claim or cause of action arose or be forever barred.
          The section titles in these Terms of Service are for convenience only
          and have no legal or contractual effect
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          You agree that we may assign any of our rights or delegate any of our
          duties or obligations under this Agreement without your consent to any
          third party
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Entire Agreement
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          These Terms of Service contain the entire agreement of the parties
          with respect to the Services and supersede all prior agreements or
          previous discussions (written or oral) between you and us
        </Typography>
        <Typography sx={classes.sectionTitle} variant="body1_old_font">
          Contacting Us
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          You can contact AdvisoryCloud by e-mailing us at
          CustomerService@AdvisoryCloud.com or by writing to us at the following
          address
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          AdvisoryCloud Inc.
          <br />
          Corporate Headquarters
          <br />
          369-B Third Street, #301, San Rafael, CA 94901
        </Typography>
        <Typography sx={classes.sectionBody} variant="body1_old_font">
          © Copyright 2019 AdvisoryCloud. All Rights Reserved
        </Typography>
      </div>

      <div style={classes.endRow}>
        <FormControlLabel
          control={
            <Checkbox
              data-cy="companyAcceptTermsOfServiceCheckbox"
              checked={termsAccepted}
              onChange={handleCheckboxClick}
              color="primary"
            />
          }
          label="I have read and accept the Terms of Service"
        />

        <div style={classes.continueButtonContainer}>
          <ACButton
            data-cy="companyAcceptTermsOfServiceContinueButton"
            onClick={handleContinueClick}
            color="primary"
            loading={loading ? 1 : 0}
            style={{ width: 180 }}
            disabled={termsAccepted ? 0 : 1}
          >
            Continue
          </ACButton>
        </div>
      </div>
    </>
  );
}

export default TermsOfService;
