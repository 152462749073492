import React, { useState } from 'react';
import {
  Card,
  Grid,
  Avatar,
  Typography,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import CloseIcon from '@mui/icons-material/Close';
import { useAccount } from '../../contexts/Account';
import eventBus, { eventBusValues } from '../../eventBus';
import { getAdvisorImagePath } from '../../services/utils';
import { getAdvisorQueryParams } from '../../utils/queryParams';
import { ReactComponent as FormatList } from '../../icons/format-list.svg';

export default function BoardroomHeader({ advisor }) {
  const { accountDetails, isFreeTrial, isFreemium } = useAccount();
  const [open, setOpen] = useState(false);
  let url = 'https://register.advisorycloud.com/platform/board-matching/e.html';

  const onClickHeadline = () =>
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      ...accountDetails,
      openInEdit: true,
      isFocus: true,
    });

  const onClick = () =>
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      ...accountDetails,
      openInEdit: false,
    });

  const onClickOptimizeProfile = () => {
    let urlOptimize = `https://register.advisorycloud.com/platform/special-offers?${getAdvisorQueryParams(
      {
        accountDetails,
        isFreeTrial,
        isFreemium,
      }
    )}`;
    window.location.replace(urlOptimize);
  };

  return (
    <>
      <Card
        sx={{
          mb: 3,
          p: 3,
          borderRadius: '8px',
          boxShadow: 'none',
          marginTop: { xxs: '30px', sm: '0px' },
        }}
      >
        <Grid container spacing={3} alignItems="center" justifyContent="center">
          <Grid item>
            <Box
              onClick={onClick}
              id="advisor-boardroom-avatar"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: { xxs: 128, xs: 207 },
                height: { xxs: 128, xs: 207 },
                border: '1px solid #DEE9FE',
                borderRadius: '8px',
                cursor: 'pointer',
              }}
            >
              <Avatar
                alt={`${advisor.givenName} Logo`}
                src={getAdvisorImagePath(advisor)}
                sx={{
                  width: { xxs: 100, xs: 150 },
                  height: { xxs: 100, xs: 150 },
                  borderRadius: '8px',
                }}
              />
            </Box>
          </Grid>
          <Grid item xs>
            <Typography
              id="boardroom-advisor-name"
              sx={{
                fontWeight: '600',
                fontSize: { xxs: 24, xs: 40 },
                textAlign: { xxs: 'center', xs: 'left' },
                lineHeight: '41px',
                color: '#001030',
                display: 'flex',
              }}
            >
              Welcome,
              <Typography component="span"
                sx={{
                  fontWeight: '600',
                  fontSize: { xxs: 24, xs: 40 },
                  textAlign: { xxs: 'center', xs: 'left' },
                  lineHeight: '41px',
                  color: '#2B71F6',
                  marginLeft: '7px',
                }}
              >
                {advisor.givenName}.
              </Typography>
            </Typography>

            <Box
              id="advisor-boardroom-current-goal"
              onClick={advisor.headline ? onClick : onClickHeadline}
              sx={{
                bgcolor: '#F1F9FF',
                borderRadius: '8px',
                py: '5px',
                px: '10px',
                width: '100%',
                borderTop: '1px solid #4FB5F9',
                borderRight: '1px solid #4FB5F9',
                borderBottom: '1px solid #4FB5F9',
                borderLeft: '5px solid #4FB5F9',
                marginTop: '15px',
                cursor: 'pointer',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: { xxs: 12, xs: 14 },
                  color: '#00548B',
                  lineHeight: { xxs: '18px', xs: '21px' },
                }}
              >
                {advisor.headline ||
                  'Click here to create a 1-2 sentence description of your professional interests and expertise.'}
              </Typography>
            </Box>

            <Box
              sx={{
                display: { xxs: 'block', xs: 'flex' },
                justifyContent: 'flex-start',
                mt: 2,
                gap: 2,
              }}
            >
              <Button
                id="advisor-boardroom-request-to-speak"
                variant="contained"
                color="primary"
                startIcon={<RecordVoiceOverIcon />}
                onClick={() => setOpen(true)}
                sx={{
                  textTransform: 'none',
                  bgcolor: '#2B71F6',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  width: { xxs: '100%', xs: 268 },
                  height: 42,
                  fontSize: 14,
                  fontWeight: '600',
                  '&:hover': {
                    bgcolor: '#2B71F6',
                  },
                }}
              >
                Optimize My Profile
              </Button>
              <Button
                id="advisor-boardroom-request-to-speak"
                variant="contained"
                color="primary"
                startIcon={<FormatList />}
                onClick={onClickOptimizeProfile}
                sx={{
                  textTransform: 'none',
                  bgcolor: 'white',
                  borderRadius: '8px',
                  boxShadow: 'none',
                  width: { xxs: '100%', xs: 273 },
                  height: 42,
                  fontSize: 14,
                  fontWeight: '600',
                  border: '1px solid #2B71F6',
                  color: '#2B71F6',
                  mt: { xxs: 1, xs: 0 },
                  '&:hover': {
                    bgcolor: 'white',
                  },
                }}
              >
                Additional Executive Services
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Card>

      {/* Modal para el iframe */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullScreen
        sx={{
          '& .MuiDialog-paper': {
            width: { xxs: '100%', sm: '800px' },
            height: { xxs: '100vh', sm: '600px' },
            maxWidth: { xs: '100%', sm: '800px' },
            maxHeight: { xs: '100vh', sm: '600px' },
            borderRadius: { xxs: 0, sm: '10px' },
            overflow: 'hidden',
            position: 'relative',
          },
        }}
      >
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: '#777',
            zIndex: 10,
          }}
        >
          <CloseIcon />
        </IconButton>

        <iframe
          src={`${url}?${getAdvisorQueryParams({
            accountDetails,
            isFreeTrial,
            isFreemium,
          })}`}
          title="OptimizeMyProfileForBoards"
          frameBorder="0"
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
        />
      </Dialog>
    </>
  );
}
