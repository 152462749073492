import React from 'react';
import { Paper, Box, Grid, useMediaQuery } from '@mui/material';
import union_left from './../../icons/union_left.svg';
import union_right from './../../icons/union_right.svg';

const BoardTile = (props) => {
  const { children, image, onClick } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Grid item xxs={12} xs={6} md={3} flexBasis="100%">
      <Paper
        elevation={1}
        sx={{
          height: { xxs: '163px', xs: '232px' },
          borderRadius: '12px',
          boxShadow: '0px 10px 12px 0px #0010300D',
          width: '100%',
          cursor: 'pointer',
        }}
        onClick={onClick}
      >
        <div
          style={{
            width: '100%',
            height: !isSM ? '68px' : '52px',
            background: 'linear-gradient(#B1CBFC, #BBE2FD)',
            borderRadius: '8px 8px 0px 0px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            marginBottom: !isSM ? '53px' : '37px',
          }}
        >
          <img
            src={union_left}
            alt="left"
            style={{ position: 'absolute', zIndex: 1, right: 140, marginTop: 25 }}
          />
          <img
            src={union_right}
            alt="right"
            style={{ position: 'absolute', zIndex: 1, right: 0 }}
          />
          <Box
            sx={{
              zIndex: 1,
              position: 'absolute',
              left: '20px',
              marginTop: '20px',
              height: { xxs: 72, sm: 96 },
              width: { xxs: 72, sm: 96 },
              backgroundColor: 'white',
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {image}
          </Box>
        </div>
        <Box
          sx={{
            px: '20px',
            pb: { xxs: '8px', xs: '16px' },
          }}
        >
          {children}
        </Box>
      </Paper>
    </Grid>
  );
};

export default BoardTile;
