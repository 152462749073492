import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Avatar, Typography, useMediaQuery, Button } from '@mui/material';
import { ArrowBackIos, EditOutlined, CancelRounded } from '@mui/icons-material';
import { RWebShare } from 'react-web-share';
import ACSnackbar from '../../ACSnackbar';
import DefaultCompany from './../../../icons/building_round.svg';
import { useAccount } from '../../../contexts/Account';
import LinkedInPostModal from '../../LinkedInPostModal';
import {
  isLinkedinAuthenticated,
  authorizeWithPopUp,
  getAccessToken,
  closeChild,
} from '../../../services/LinkedIn';
import { ReactComponent as LinkedInIcon } from './../../Advisor/Profile/components/linkedin.svg';
import { ReactComponent as ShareIcon } from './../../Advisor/Profile/components/share.svg';
import { ReactComponent as AdvisoryCloudLogo } from './../../AdvisoryCloudLogo/AdvisoryCloudLogo.svg';
import CodeIcon from '@mui/icons-material/Code';
import LinkIcon from '@mui/icons-material/Link';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import CustomIcon from '../../../icons/customIcon';
import JoinMyBoardButton from './JoinMyBoardButton.png';
import eventBus, { eventBusValues } from './../../../eventBus';

const useStyles = ({ isSM }) => ({
  emptyHeadline: {
    fontSize: '12px',
    fontWeight: 400,
    color: '#646D7A',
    maxHeight: '50px',
    minWidth: '0px',
    marginTop: '0px',
    position: 'relative',
    wordBreak: 'break-all',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word',
    textAlign: isSM ? 'center' : 'auto',
    fontS: 'italic',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  imageAndBiographyContainer: {
    display: { xxs: 'block', sm: 'flex' },
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  imageContainer: {
    width: isSM ? '180px' : '150px',
    height: isSM ? '180px' : '150px',
    margin: isSM ? 'auto' : '0 20px 0 0',
    border: 'solid 2px white',
  },
  imageContainerOwnProfile: {
    width: isSM ? '180px' : '250px',
    height: isSM ? '180px' : '250px',
    margin: isSM ? 'auto' : '0 20px 10px 0',
    border: 'solid 2px white',
  },
  profileOptionDrawerContainerWithBar: {
    position: 'absolute',
    right: '1.5vw',
    display: 'flex',
  },
  profileOptionDrawerContainerWithBarMobile: {
    position: 'absolute',
    right: '30px',
    display: 'flex',
  },
  mobileImageContainer: {
    width: '100%',
    marginTop: '20px',
  },
  buttonContainer: {
    width: '100%',
    marginTop: '15px',
    justifyContent: 'center',
    position: 'fixed',
    bottom: '-2px',
    left: '0px',
    zIndex: 10,
  },
  editContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
    padding: '0px',
    marginRight: isSM ? '15px' : '20px',
    textTransform: 'none',
    color: '#232B35',
    '&:hover': {
      backgroundColor: '#fff',
    },
    // minWidth: '100px',
  },
  editContainerMobile: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    border: 'none',
    padding: '0px',
    // marginRight: '10px',
    minWidth: '100px',
  },
  editIcon: {
    fontSize: '20px',
    marginRight: '5px',
    color: '#323b35',
  },
  editText: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#323b35',
  },
  editTextMobile: {
    fontSize: isSM ? '11px' : '14px',
    fontWeight: 500,
    color: '#323b35',
  },
  logoContainer: {
    width: '180px',
    height: '40px',
    // cursor: props.cursor,
  },
  fieldContainer: {
    minWidth: '300px',
  },
  fieldContainerMobile: {
    width: '100%',
  },
});

function CompanyOwnSlideHeaderTemporary({
  company,
  handleClickAway,
  companyCEO,
  isAcPlusCompany,
  isEditingState,
  setIsEditingState,
  setInEditForm,
  sendFormData,
  boxRef,
}) {
  const navRef = useRef();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const hideLogo = useMediaQuery((theme) => theme.breakpoints.down([1280]));
  const compactHeader = useMediaQuery(
    '(min-width:1150px) and (max-width:1400px)'
  );
  const hideLogoEditState = useMediaQuery((theme) =>
    theme.breakpoints.down([1420])
  );
  const { t } = useTranslation();
  const classes = useStyles({ isSM });
  const { accountDetails, type, isFreemium } = useAccount();
  const [linkedInPostModal, setLinkedInPostModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [sticky, setSticky] = useState(false);

  let shareContent = {};

  if (accountDetails.userSegment === 'managed_advisory_board') {
    shareContent = {
      formTitle:
        'Let your LinkedIn network know you’re building an Advisory Board!',
      title: `Join the ${company.displayName} Advisory Board!`,
      text: `We’re seeking new advisory board members at ${company.displayName}. Join today and help us succeed! Open to anyone who supports our mission!\nRequest an invitation here if you're interested in joining! All perspectives are welcome.`,
      linkedInText: `We're using @advisorycloud to build our advisory board and are seeking outside experts to join our sounding board and help us reach our goals.\nRequest an invitation here if you’re interested in joining! All perspectives are welcome.`,
      link: `https://app.advisorycloud.com/join/advisors/${accountDetails.slug}/${accountDetails.id}`,
    };
  } else {
    shareContent = {
      formTitle:
        'Let your LinkedIn network know you’re building an AdvisoryCloud!',
      title: `Join our AdvisoryCloud`,
      text: `We’re seeking outside experts to join our sounding board and help us reach our goals.\nRequest an invitation here if you're interested in joining! All perspectives are welcome`,
      linkedInText: `We're using @advisorycloud to build our Advisory Board and we’re seeking new advisory board members for {company name}. \nJoin today and help us succeed! Open to anyone who supports our mission! Request an invitation here if you’re interested in joining! All perspectives are welcome.`,
      link: `https://app.advisorycloud.com/join/advisors/${accountDetails.slug}/${accountDetails.id}`,
    };
  }

  useEffect(() => {
    window.linkedinWait = true;

    const interval = setInterval(() => {
      if (window.linkedinSuccess) {
        clearInterval(interval);
        closeChild();
        getAccessToken(window.linkedinSuccess);
        setLinkedInPostModal(true);
      }
    }, 600);

    if (isLinkedinAuthenticated()) {
      window.linkedinWait = undefined;
      clearInterval(interval);
    }
    return () => {
      window.linkedinWait = undefined;
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (boxRef && boxRef.current) {
      boxRef.current.addEventListener('scroll', onScroll);
    }

    return () => {
      if (boxRef && boxRef.current) {
        boxRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  function edit() {
    setIsEditingState(!isEditingState);
  }

  async function linkedInAction() {
    const isAuthenticatedLinkedIn = isLinkedinAuthenticated();
    if (isAuthenticatedLinkedIn) {
      setLinkedInPostModal(true);
    } else {
      await authorizeWithPopUp();
    }
  }

  async function linkedInResponse(linkedinResponse) {
    if (linkedinResponse === 201) {
      setSuccessMessage('Your profile is shared successfully.');
      setSuccessSnackbar(true);
    } else if (linkedinResponse === 422) {
      setSuccessMessage(
        'You have already posted this content, either update the post or try again in 10 minutes'
      );
      setSuccessSnackbar(true);
    } else {
      setSuccessMessage('An error occurred while sharing your profile.');
      setSuccessSnackbar(true);
    }
  }

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `https://app.advisorycloud.com/join/advisors/${accountDetails.slug}/${accountDetails.id}`
    );
    setSuccessMessage(
      'Link copied, you can now share it with whoever you choose'
    );
    setSuccessSnackbar(true);
  };

  const handleCopyImage = async () => {
    try {
      const response = await fetch(JoinMyBoardButton);
      let blob = await response.blob();
      if (blob.type !== 'image/png') {
        blob = new Blob([blob], { type: 'image/png' });
      }
      const data = [new ClipboardItem({ [blob.type]: blob })];
      await navigator.clipboard.write(data);

      setSuccessMessage(
        'Image copied, you can now paste it wherever you choose'
      );
      setSuccessSnackbar(true);
    } catch (error) {
      console.error('Error al copiar la imagen:', error);
    }
  };

  function onScroll() {
    if (navRef && navRef.current && navRef.current.getBoundingClientRect) {
      setSticky(navRef.current.getBoundingClientRect().top < 20);
    }
  }

  function getImageSrc() {
    if (company.image && company.image.id) {
      return `https://${company.image.location}`;
    } else if (isAcPlusCompany && companyCEO && companyCEO.contactLogoId) {
      return companyCEO.contactLogoLocation;
    }
    return DefaultCompany;
  }

  return (
    <div
      style={{
        width: '100%',
      }}
    >
      <ACSnackbar
        open={successSnackbar}
        text={successMessage}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      {linkedInPostModal ? (
        <LinkedInPostModal
          linkedInPostModal={linkedInPostModal}
          setLinkedInPostModal={setLinkedInPostModal}
          linkedInResponse={linkedInResponse}
          fontFamily={'Poppins'}
          contents={{ ...shareContent, text: shareContent.linkedInText }}
        />
      ) : null}
      <Box ref={navRef}>
        <Box
          sx={{
            display: 'flex',
            position: sticky ? 'fixed' : null,
            width: { xxs: '100%', xs: 'calc(70% - 60px)' },
            paddingTop: sticky ? '10px' : 0,
            marginLeft: sticky ? '-20px' : 0,
            backgroundColor: 'white',
            zIndex: 1000,
            height: '60px',
            top: sticky ? '0' : null,
          }}
        >
          {!hideLogo && !(hideLogoEditState && isEditingState) && (
            <AdvisoryCloudLogo style={classes.logoContainer} />
          )}
          <div style={classes.profileOptionDrawerContainerWithBar}>
            {isEditingState ? (
              <>
                <button
                  onClick={sendFormData}
                  style={classes.editContainerMobile}
                >
                  <CustomIcon
                    iconname="save-outlined"
                    style={classes.editIcon}
                  />
                  <Typography color="primary" sx={classes.editText}>
                    Save
                  </Typography>
                </button>
                <button
                  onClick={() => {
                    setIsEditingState(false);
                    setInEditForm('');
                  }}
                  style={classes.editContainerMobile}
                >
                  <CancelRounded sx={classes.editIcon} />
                  <Typography color="primary" sx={classes.editText}>
                    Discard
                  </Typography>
                </button>
              </>
            ) : (
              <>
                <Button
                  onClick={edit}
                  sx={{
                    backgroundColor: '#fff',
                    border: 'none',
                    padding: '0px',
                    minWidth: '100px',
                    textTransform: 'none',
                  }}
                  startIcon={
                    <EditOutlined color="primary" sx={classes.editIcon} />
                  }
                >
                  <Typography color="primary" sx={classes.editText}>
                    {t('EDIT-BUTTON-TEXT')}
                  </Typography>
                </Button>
                <ArrowBackIos
                  sx={{
                    cursor: 'pointer',
                    color: '#3171F6',
                  }}
                  fontSize="small"
                  onClick={handleClickAway}
                />
              </>
            )}
          </div>
        </Box>

        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: { xxs: 'block', sm: 'flex' },
                width: '100%',
                alignItems: 'flex-start',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              <Avatar
                id="own-company-profile-image"
                sx={{
                  width: isSM ? '180px' : '230px',
                  height: isSM ? '180px' : '230px',
                  margin: isSM ? 'auto' : '0 40px 40px 0',
                  border: 'solid 2px white',
                }}
                src={getImageSrc()}
              />
              <Box sx={{}}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: isSM ? '20px' : '36px',
                    textAlign: isSM ? 'center' : 'auto',
                    fontFamily: 'Poppins',
                    lineHeight: 1.2,
                    marginTop: !isSM ? '0px' : '10px',
                  }}
                >
                  {`${company.displayName}`}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 500,
                    color: '#232B35',
                    marginTop: isSM ? '10px' : '35px',
                    textAlign: isSM ? 'center' : 'auto',
                  }}
                >
                  Included with your advisory board is the ability to invite
                  your own colleagues, contacts, and social media followers to
                  your advisory board for free.
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: {
                      xxs: 'column',
                      sm: compactHeader ? 'column' : 'row',
                    },
                    alignItems: isSM ? 'center' : 'flex-start',
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#2C71F6',
                        marginTop: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: isSM ? 'center' : 'flex-start',
                      }}
                    >
                      Your Unique Advisory Board URL:
                    </Typography>
                    <Box
                      sx={{
                        marginBottom: '5px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: isSM ? 'center' : 'flex-start',
                      }}
                    >
                      <Box
                        onClick={handleCopyLink}
                        id="own-company-profile-link"
                        sx={{
                          maxWidth: { xxs: '305px', xs: '405px' },
                          border: '1px solid #EFF0F6',
                          backgroundColor: '#FFFFFF',
                          padding: '6px',
                          paddingTop: '8px',
                          borderRadius: '10px',
                          cursor: 'pointer',
                          marginTop: '2px',
                          flexGrow: 1,
                          height: '35px',
                        }}
                      >
                        <Typography
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            fontSize: '12px',
                            fontWeight: 400,
                            letterSpacing: '-0.43px',
                          }}
                        >
                          https://app.advisorycloud.com/join/advisors/
                          {accountDetails.slug}/{accountDetails.id}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: isSM ? 'center' : 'flex-start',
                          marginTop: '15px',
                        }}
                      >
                        <Button
                          onClick={handleCopyLink}
                          style={classes.editContainer}
                          id="copy-link-button"
                        >
                          <LinkIcon
                            style={{
                              marginRight: 5,
                              fontSize: 25,
                            }}
                          />
                          <Typography sx={classes.editTextMobile}>
                            Copy Link
                          </Typography>
                        </Button>
                        <Button
                          onClick={linkedInAction}
                          sx={classes.editContainer}
                          id="share-linkedin-button"
                        >
                          <LinkedInIcon
                            style={{
                              marginRight: 5,
                              fontSize: 25,
                            }}
                          />
                          <Typography sx={classes.editTextMobile}>
                            Promote
                          </Typography>
                        </Button>
                        <RWebShare
                          data={{
                            text: shareContent.text,
                            url: shareContent.link,
                            title: shareContent.title,
                          }}
                        >
                          <Button
                            style={classes.editContainer}
                            id="share-button"
                          >
                            <ShareIcon style={classes.editIcon} />
                            <Typography sx={classes.editTextMobile}>
                              Share
                            </Typography>
                          </Button>
                        </RWebShare>
                        <Button
                          style={{
                            ...classes.editContainer,
                            marginRight: '0px',
                          }}
                          id="email-button"
                          onClick={() => {
                            eventBus.dispatch(
                              eventBusValues.triggerInviteContactsModal
                            );
                          }}
                        >
                          <MarkEmailUnreadOutlinedIcon
                            style={{
                              marginRight: 5,
                              fontSize: 25,
                            }}
                          />
                          <Typography sx={classes.editTextMobile}>
                            Email
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: isSM ? 'center' : 'start',
                      marginLeft: isSM ? 0 : '20px',
                      my: '15px',
                    }}
                  >
                    <img
                      src={JoinMyBoardButton}
                      alt="Join My Board Button"
                      style={{
                        display: 'block',
                        width: '212px',
                        cursor: 'pointer',
                      }}
                      id="join-my-board-image"
                      onClick={handleCopyImage}
                    />
                    <Button
                      onClick={handleCopyImage}
                      id="copy-image-button"
                      style={{
                        ...classes.editContainer,
                        marginTop: '12px',
                        marginRight: '0px',
                      }}
                    >
                      <CodeIcon
                        style={{
                          marginRight: 5,
                          fontSize: 25,
                        }}
                      />
                      <Typography sx={classes.editText}>Copy Image</Typography>
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default CompanyOwnSlideHeaderTemporary;
