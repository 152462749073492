import React from 'react';
import {
  Dialog,
  DialogContent,
  IconButton,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const UpsellDialog = ({ open, onClose, finalUrl }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isMobile}
      PaperProps={{
        sx: {
          width: isMobile ? '100%' : 800,
          maxWidth: '100%',
          height: isMobile ? '100%' : 775,
          maxHeight: '100%',
          borderRadius: '8px',
        },
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 1,
          display: { xs: 'block', md: 'none' },
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ p: 0, height: '100%', width: '100%' }}>
        <Box sx={{ width: '100%', height: 'calc(100% - 10px)' }}>
          <iframe
            src={finalUrl}
            style={{
              border: 'none',
              width: '100%',
              height: '100%',
            }}
            title="Upsell Offer"
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UpsellDialog;
