import React, { useState } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Box,
  Button,
  Typography,
  Chip,
  useMediaQuery,
} from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { useHistory } from 'react-router-dom';
import TimeIcon from './time.png';
import WorldIcon from './world.png';
import { getModeratorImagePath } from '../../services/utils';
import CalendarSyncMenu from '../CalendarSyncMenu';
import { useAccount } from '../../contexts/Account';
import { AccountType, SnackbarType } from '../../services/utils/types';
import ACSnackbar from '../ACSnackbar';
import union_left from '../../icons/union_left.svg';
import union_right from '../../icons/union_right.svg';
import DefaultCompany from '../../icons/building_round.svg';
import eventBus, { eventBusValues } from '../../eventBus';

function getPlainTextFromDraft(draftRaw) {
  if (!draftRaw) return '';
  try {
    const content = JSON.parse(draftRaw);
    if (content.blocks && Array.isArray(content.blocks)) {
      return content.blocks.map((block) => block.text).join('\n');
    }
  } catch (error) {
    console.error('Error parsing draft raw', error);
  }
  return draftRaw;
}

function formatDateTime(dateString) {
  const dateObj = new Date(dateString);
  const optionsDate = { month: 'long', day: 'numeric', year: 'numeric' };
  const optionsTime = { hour: 'numeric', minute: 'numeric' };

  const datePart = dateObj.toLocaleDateString('en-US', optionsDate);
  const timePart = dateObj.toLocaleTimeString('en-US', optionsTime);

  return `${datePart} at ${timePart}`;
}

export default function BoardMeetingCard({
  boardMeeting,
  company,
  isLoading,
  setIsLoading,
}) {
  const { accountDetails, type } = useAccount();
  const {
    postCompanyContact,
    moderatorName,
    moderatorTitle,
    moderatorAvatar,
    meetingTime,
    meetingTimeZone,
    postTitle,
    body,
  } = boardMeeting;
  const history = useHistory();
  const currentCompany =
    type === AccountType.COMPANY ? accountDetails : company;
  const contributors = currentCompany.contacts
    ? currentCompany.contacts.filter(
        (contact) => !contact.isBoardOwner && contact.accountStatus == 'active'
      )
    : [];
  const [expandedContributors, setExpandedContributors] = useState(false);
  const maxVisible = 1;
  const visibleContributors = expandedContributors
    ? contributors
    : contributors.slice(0, maxVisible);
  const showMoreContributors = contributors.length > maxVisible;
  const companyImage = postCompanyContact?.imageURL;
  const companyName = postCompanyContact?.companyName;
  const formatedMeetingTime = convertTimezoneToAbbr(meetingTimeZone);
  const formattedDateTime = formatDateTime(meetingTime);
  const meetingBodyText = getPlainTextFromDraft(body);

  const [expanded, setExpanded] = useState(false);
  const maxCharacters = 500;
  const isLong = meetingBodyText.length > maxCharacters;
  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  const [googleSync, setGoogleSync] = useState(
    accountDetails?.calendarTokens?.google || false
  );
  const [appleSync, setAppleSync] = useState(
    accountDetails?.calendarTokens?.apple || false
  );
  const showCalendarButton =
    type === AccountType.ADVISOR &&
    !accountDetails?.calendarTokens?.apple &&
    !accountDetails?.calendarTokens?.google;
  const advisorId = accountDetails?.id;
  const companyIds = accountDetails?.boards?.memberBoards?.map(
    (board) => board.id
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(SnackbarType.SUCCESS);
  const showSnackbar = (message, severity = SnackbarType.SUCCESS) => {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setSnackbarOpen(true);
  };
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const handleOpenCalendarMenu = (event) => {
    setCalendarAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  const handleCloseCalendarMenu = () => {
    setCalendarAnchorEl(null);
  };

  function openProfile(event) {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      company: currentCompany,
    });
    event.stopPropagation();
  }

  const getTruncatedText = (text, maxChars) => {
    const truncated = text.substr(0, maxChars);
    const lastSpace = truncated.lastIndexOf(' ');
    return truncated.substr(0, lastSpace);
  };

  function getTimezoneAbbreviation(ianaTimezone, date = new Date()) {
    const dtf = new Intl.DateTimeFormat('en-US', {
      timeZone: ianaTimezone,
      timeZoneName: 'short',
    });
    const parts = dtf.formatToParts(date);
    const tzPart = parts.find((part) => part.type === 'timeZoneName');
    return tzPart ? tzPart.value : '';
  }

  function getUTCOffset(ianaTimezone, date = new Date()) {
    const offsetInMinutes = -new Date(
      date.toLocaleString('en-US', { timeZone: ianaTimezone })
    ).getTimezoneOffset();
    const hours = Math.floor(Math.abs(offsetInMinutes) / 60);
    const minutes = Math.abs(offsetInMinutes) % 60;
    const sign = offsetInMinutes >= 0 ? '+' : '-';
    const minutesFormatted =
      minutes > 0 ? `:${minutes.toString().padStart(2, '0')}` : '';
    return `UTC ${sign}${hours}${minutesFormatted}`;
  }

  function convertTimezoneToAbbr(ianaTimezone, date = new Date()) {
    const abbr = getTimezoneAbbreviation(ianaTimezone, date);
    const offset = getUTCOffset(ianaTimezone, date);
    return `${abbr} (${offset})`;
  }

  const displayedText =
    isLong && !expanded
      ? getTruncatedText(meetingBodyText, maxCharacters)
      : meetingBodyText;

  const PillSection = ({ isMobile }) => {
    const chipStyles = {
      width: isXS ? '100%' : 'auto',
      backgroundColor: isMobile ? '#F4F8FF' : 'white',
      borderRadius: 16,
      padding: '0 8px',
      color: '#124CB9',
      border: isMobile ? 'none' : '1px solid #B1CBFC',
      fontWeight: 400,
    };
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: isXS ? 'column' : 'row',
          gap: 2,
          mb: 1,
        }}
      >
        <Chip
          icon={
            <img src={TimeIcon} alt="time" style={{ width: 20, height: 20 }} />
          }
          label={formattedDateTime}
          color="default"
          sx={chipStyles}
        />

        <Chip
          icon={
            <img
              src={WorldIcon}
              alt="world"
              style={{ width: 20, height: 20 }}
            />
          }
          label={formatedMeetingTime}
          sx={chipStyles}
        />
      </Box>
    );
  };

  const styles = {
    avatarContainer: {
      borderRadius: '8px',
      backgroundColor: '#F4F8FF',
      minWidth: '300px',
      height: '64px',
    },
    avatarContainerName: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '130%',
      color: '#001030',
      mb: '2px',
    },
  };
  return (
    <>
      <Card
        sx={{
          width: '100%',
          margin: '0 auto',
          mt: 2,
          borderRadius: '8px',
          cursor: 'pointer',
        }}
        onClick={(e) => {
          history.push(`/post/${boardMeeting.id}`, {
            companySlug: company?.slug
          });
        }}
      >
        {!isXS && (
          <CardHeader
            avatar={
              <Avatar
                src={companyImage || DefaultCompany}
                alt={companyName}
                sx={{ width: 40, height: 40 }}
                onClick={openProfile}
              />
            }
            title={
              <Typography
                onClick={openProfile}
                sx={{
                  fontSize: '16px',
                  fontWeight: 600,
                  color: '#001030',
                }}
              >
                {companyName}
              </Typography>
            }
            sx={{
              borderBottom: '1px solid #eee',
              height: '82px',
              '& .MuiCardHeader-action': {
                alignSelf: 'center',
              },
            }}
            action={
              showCalendarButton ? (
                <Button
                  startIcon={<CalendarTodayIcon />}
                  onClick={handleOpenCalendarMenu}
                  variant="contained"
                  sx={{
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    height: '42px',
                    width: '171px',
                    marginRight: '10px',
                    backgroundColor: '#2B71F6',
                  }}
                >
                  Sync Calendar
                </Button>
              ) : (
                <Button
                  // startIcon={<CalendarTodayIcon />}
                  // onClick={handleViewDetails}
                  variant="contained"
                  sx={{
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    height: '42px',
                    width: '171px',
                    marginRight: '10px',
                    backgroundColor: '#2B71F6',
                  }}
                >
                  View Details
                </Button>
              )
            }
          />
        )}
        {isXS && (
          <>
            <div
              style={{
                width: 'calc(100% + 32px)',
                left: '-16px',
                height: '56px',
                background: 'linear-gradient(#2C71F633, #25A4FF33)',
                borderRadius: '8px 8px 0px 0px',
                position: 'relative',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <img
                src={union_left}
                alt="left"
                style={{
                  position: 'absolute',
                  zIndex: 1,
                  left: 0,
                  marginTop: 25,
                }}
              />
              <img
                src={union_right}
                alt="right"
                style={{ position: 'absolute', zIndex: 1, right: 0 }}
              />
              <Box
                sx={{
                  zIndex: 2,
                  position: 'absolute',
                  marginTop: '26px',
                  height: 72,
                  width: 72,
                  backgroundColor: 'white',
                  borderRadius: '12px',
                  display: 'flex',
                }}
              >
                <Avatar
                  src={companyImage || DefaultCompany}
                  alt={companyName}
                  sx={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '8px',
                  }}
                />
              </Box>
            </div>
            <Box sx={{ marginTop: '42px', px: '10px' }}>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 600,
                  color: '#001030',
                  my: '8px',
                  textAlign: 'center',
                }}
                onClick={openProfile}
                gutterBottom
              >
                {companyName}
              </Typography>
              <PillSection isMobile />
              {showCalendarButton ? (
                <Button
                  startIcon={<CalendarTodayIcon />}
                  onClick={handleOpenCalendarMenu}
                  variant="contained"
                  sx={{
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    height: '42px',
                    width: '100%',
                    marginRight: '10px',
                    marginTop: '20px',
                    backgroundColor: '#2B71F6',
                  }}
                >
                  Sync Calendar
                </Button>
              ) : (
                <Button
                  variant="contained"
                  sx={{
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '18px',
                    height: '42px',
                    width: '100%',
                    marginRight: '10px',
                    marginTop: '20px',
                    backgroundColor: '#2B71F6',
                  }}
                >
                  View Details
                </Button>
              )}
            </Box>
          </>
        )}

        <CardContent>
          <Typography
            sx={{
              fontSize: isXS ? '18px' : '24px',
              fontWeight: 600,
              color: '#001030',
            }}
            gutterBottom
          >
            {postTitle}
          </Typography>
          {!isXS && <PillSection />}
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              color: '#4D586F',
            }}
            mb={3}
            whiteSpace="pre-line"
          >
            {displayedText}
            {isLong && (
              <>
                {!expanded && '... '}
                <Button
                  onClick={(event) => {
                    setExpanded(!expanded);
                    event.stopPropagation();
                  }}
                  sx={{
                    textTransform: 'none',
                    fontSize: '14px',
                    fontWeight: 600,
                    padding: 0,
                    minWidth: 0,
                  }}
                >
                  {expanded ? 'view less' : 'view more'}
                </Button>
              </>
            )}
          </Typography>
          <Box display="flex" flexWrap="wrap" gap={2}>
            {postCompanyContact && (
              <Box
                display="flex"
                alignItems="center"
                p={1}
                sx={styles.avatarContainer}
              >
                {postCompanyContact.companyContactImage ? (
                  <Avatar
                    src={postCompanyContact.companyContactImage}
                    alt={postCompanyContact.name}
                    sx={{ width: 40, height: 40, mr: 1 }}
                  />
                ) : (
                  <Avatar sx={{ width: 40, height: 40, mr: 1 }}>
                    {/* fallback */}
                  </Avatar>
                )}
                <Box>
                  <Typography sx={styles.avatarContainerName}>
                    {postCompanyContact.name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {postCompanyContact.title}, {postCompanyContact.companyName}
                  </Typography>
                </Box>
              </Box>
            )}

            {moderatorName && (
              <Box
                display="flex"
                alignItems="center"
                p={1}
                sx={styles.avatarContainer}
              >
                {moderatorAvatar ? (
                  <Avatar
                    src={getModeratorImagePath(moderatorAvatar)}
                    alt={moderatorName}
                    sx={{ width: 40, height: 40, mr: 1 }}
                  />
                ) : (
                  <Avatar sx={{ width: 40, height: 40, mr: 1 }}>
                    {/* fallback */}
                  </Avatar>
                )}
                <Box>
                  <Typography sx={styles.avatarContainerName}>
                    {moderatorName}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {moderatorTitle}
                  </Typography>
                </Box>
              </Box>
            )}
            {visibleContributors.map((contributor) => (
              <Box
                key={contributor.id}
                display="flex"
                alignItems="center"
                p={1}
                sx={styles.avatarContainer}
              >
                {contributor.image ? (
                  <Avatar
                    src={`https://${contributor.image.location}`}
                    alt={contributor.name}
                    sx={{ width: 40, height: 40, mr: 1 }}
                  />
                ) : (
                  <Avatar sx={{ width: 40, height: 40, mr: 1 }}>
                    {/* fallback */}
                  </Avatar>
                )}
                <Box>
                  <Typography sx={styles.avatarContainerName}>
                    {contributor.displayName}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {contributor.title}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box
              id="show-more"
              sx={{
                display: showMoreContributors ? 'flex' : 'none',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: '#E6EDFF',
                  height: '1px',
                  width: '100%',
                }}
              />
              <Typography
                onClick={(e) => {
                  e.stopPropagation();
                  setExpandedContributors(!expandedContributors);
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '20px',
                  marginRight: '20px',
                  color: '#74879A',
                  cursor: 'pointer',
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                }}
              >
                {expandedContributors ? 'Less' : 'More'}
                <ExpandCircleDownOutlinedIcon
                  sx={{
                    transform: expandedContributors ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'transform .5s',
                    marginLeft: '5px',
                  }}
                />
              </Typography>
              <Box
                sx={{
                  backgroundColor: '#E6EDFF',
                  height: '1px',
                  width: '100%',
                }}
              />
            </Box>
          <ACSnackbar
            style={{ marginTop: '50px' }}
            open={snackbarOpen}
            text={snackMessage}
            severity={snackSeverity}
            onClose={() => {
              setSnackbarOpen(false);
            }}
            autoHideDuration={5000}
          />
        </CardContent>
      </Card>
      <CalendarSyncMenu
        anchorEl={calendarAnchorEl}
        setAnchorEl={setCalendarAnchorEl}
        syncStates={{
          googleSync,
          appleSync,
        }}
        setSyncStates={{
          setGoogleSync,
          setAppleSync,
        }}
        advisorId={advisorId}
        companyIds={companyIds}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        showSnackbar={showSnackbar}
      />
    </>
  );
}
