import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useAccount } from '../contexts/Account';
import Dashboard from '../scenes/AdvisorDashboardV2';
import IndividualPost from '../scenes/IndividualPost';
import AdvisorAccount from '../scenes/AdvisorAccount';
import TopBar from '../components/TopBar';
import { Switch, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import AdvisoryBoards from '../scenes/AdvisoryBoards';
import Boardroom from '../scenes/Boardroom';
import PersonalAdvisoryBoards from '../scenes/PersonalAdvisoryBoards';
import CustomIFrameScene from '../scenes/CustomIFrameScene';
import { getAdvisorQueryParams } from '../utils/queryParams';

const useStyles = () => ({
  baseContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    marginTop: '0px',
    backgroundColor: '#f4f8ff',
    overscrollBehavior: 'contain',
  },
  impersonationBanner: {
    position: 'fixed',
    width: '100%',
    zIndex: 1000,
  },
  contentContainer: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100dvh',
  },
  mainViewContainer: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
  },
  viewContainer: {
    display: 'flex',
    flex: 'auto',
    overflowX: 'hidden',
  },
});

function Layout() {
  const { isImpersonated, accountDetails, isFreeTrial, isFreemium } =
    useAccount();
  const classes = useStyles();
  const topBarRef = useRef(null);
  const containerRef = useRef(null);
  const [retry, setRetry] = React.useState(true);

  function getContentMarginTop() {
    try {
      // dynamically set the margin top of the body container to the height of the top bar
      const topBarNode = ReactDOM.findDOMNode(topBarRef.current);
      const bodyContainerNode = ReactDOM.findDOMNode(containerRef.current);
      const topBarRect = topBarNode.children[0].getBoundingClientRect();
      bodyContainerNode.style.paddingTop = `${topBarRect.height}px`;
    } catch (error) {
      console.log(error);
      // retry after 20ms
      if (retry) {
        setRetry(false);
        setTimeout(() => {
          getContentMarginTop();
        }, 20);
      }
    }
  }

  useEffect(() => {
    getContentMarginTop();
  }, [topBarRef, containerRef]);

  useEffect(() => {
    const handleTouchStart = (event) => {
      if (event.touches.length === 1) {
        window._startY = event.touches[0].clientY;
      }
    };

    const handleTouchMove = (event) => {
      const startY = window._startY || 0;
      const currentY = event.touches[0].clientY;

      if (window.scrollY === 0 && currentY > startY) {
        event.preventDefault();
      }
    };

    document.addEventListener('touchstart', handleTouchStart, {
      passive: true,
    });
    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  const FAQsComponent = () => (
    <CustomIFrameScene
      title="FAQs"
      url={
        'https://register.advisorycloud.com/platform/jb-faqs/?' +
        getAdvisorQueryParams({
          accountDetails,
          isFreeTrial,
          isFreemium,
        })
      }
    />
  );

  const ToolsComponent = () => (
    <CustomIFrameScene
      title="FAQs"
      url={
        'https://register.advisorycloud.com/platform/jb-tools-1/?' +
        getAdvisorQueryParams({
          accountDetails,
          isFreeTrial,
          isFreemium,
        })
      }
    />
  );

  return (
    <div style={classes.baseContainer}>
      <Box ref={topBarRef}>
        <TopBar />
      </Box>
      {isImpersonated && (
        <div style={classes.impersonationBanner}>
          {/* Aquí podrías poner el componente de la banner de impersonation si existe */}
        </div>
      )}
      <div style={classes.contentContainer} ref={containerRef}>
        <div style={classes.mainViewContainer}>
          <Box sx={classes.viewContainer}>
            <Switch>
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/post/:postId?" component={IndividualPost} />
              <Route path="/account" component={AdvisorAccount} />
              <Route
                path="/:companySlug/boardroom"
                render={() => <Boardroom />}
              />
              <Route path="/account" component={AdvisorAccount} />
              <Route
                path="/personal-advisory-boards"
                component={PersonalAdvisoryBoards}
              />

              <Route path="/board-directory" component={AdvisoryBoards} />
              <Route path="/peer-directory" component={AdvisoryBoards} />
              <Route path="/faqs" component={FAQsComponent} />
              <Route path="/tools-resources" component={ToolsComponent} />
            </Switch>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Layout;
