import React, { useCallback } from 'react';

import { SplitClient, SplitTreatments } from '@splitsoftware/splitio-react';
import { addSplitImpression } from '../services/Backend/account';

function Treatment(props) {
  const {
    userId,
    splitNames,
    updateOnSdkTimedout,
    evaluatedComponent,
    conditionFlag,
    conditionFailedComponent,
  } = props;
  const addImpressionCall = useCallback(async (treatment) => {
    await addSplitImpression({
      SPLIT: splitNames,
      TREATMENT: treatment,
      USER_ID: userId,
    });
  }, []);

  return (
    <SplitClient splitKey={userId} updateOnSdkTimedout={updateOnSdkTimedout}>
      <SplitTreatments names={[splitNames]}>
        {({ treatments, isReady, isTimedout }) => {
          let result;
          if (
            (isReady && treatments[splitNames].treatment === 'on') ||
            isTimedout
          ) {
            result = evaluatedComponent;
          } else if (isTimedout) {
            result = <div>SDK timed out (check your API key)</div>;
          } else if (!isReady) {
            result = <div>Loading SDK...</div>;
          } else {
            result = conditionFlag ? conditionFailedComponent : <div />;
          }
          addImpressionCall(treatments[splitNames].treatment);
          return result;
        }}
      </SplitTreatments>
    </SplitClient>
  );
}

export default Treatment;
