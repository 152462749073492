import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../contexts/Account';
import ACSnackbar from '../ACSnackbar';
import Input from '../Company/OnboardingFreemium/Input';
import OnboardingAddTeamMembers from '../Company/OnboardingFreemium/OnboardingAddTeamMembers';
import CancelButton from './CancelButton';
import SaveButton from './SaveButton';
import {
  updateCompanyProfileData,
  updateCompanyAccountData,
  uploadCompanyContactImgCall,
  updateCompanyDisplayIndustry,
} from '../../services/company_services';
import FilePondProfile from '../ProfileUploaderFreemium';
import { inviteCompanyContact } from '../../services/company_services';
import AutocompleteCustome from '../Company/OnboardingFreemium/Autocomplete';

const useStyles = (isXS) => ({
  companyWebsiteContainer: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    marginTop: '15px',
  },
  fieldContainer: {
    marginBottom: '15px',
  },
  row: {
    display: 'flex',
    flexDirection: !isXS ? 'row' : 'column',
    width: '100%',
    height: '100%',
  },
  thumbnailProfileContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxHeight: '180px',
    width: !isXS ? '210px' : '100%',
  },
  updateCompanyNameContainer: {
    minWidth: isXS ? '94.8vw' : '370px',
    paddingRight: '20px',
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: -0.43,
  },
  subtitleDescription: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#646D7A',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: -0.43,
    paddingBottom: '20px',
    marginTop: '10px',
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    justifyContent: 'flex-end',
    marginRight: '10px',
    width: '220px',
  },
  updateCompanyNameContainer: {
    minWidth: isXS ? '94.8vw' : '370px',
    paddingRight: '20px',
  },
});

function Step2Form(props) {
  const {
    handleBlur,
    handleChange,
    setFieldValue,
    values,
    isUpdate,
    updateSubmit,
    onInformationUploaded,
    onCancelClick,
    isBoardOwner,
  } = props;

  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState(values.members || []);
  const {
    asLoggedUserDetails,
    editAccountInformation,
    accountDetails,
    editCompanyContactInformation,
    isImpersonated,
    tags,
  } = useAccount();
  const { t } = useTranslation();
  const history = useHistory();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isXS);
  const [filePondProfileStatus, setFilePondProfileStatus] = useState(true);
  const [uploaderProfileOutput, setUploaderProfileOutput] = useState([]);

  const companyIndustries = tags.industries.filter((industry) =>
    (accountDetails.industryTagIds || []).includes(industry.id)
  );

  const [textError, setTextError] = useState('');
  let updatedInfo = {};
  let updatedInfoCompany = {};

  function setAccountInfoCompany(final, value) {
    updatedInfoCompany = { ...updatedInfoCompany, ...value };
    if (final) {
      editCompanyContactInformation(updatedInfoCompany);
    }
  }

  function setAccountInfo(final, value) {
    updatedInfo = { ...updatedInfo, ...value };
    if (final) {
      editAccountInformation(updatedInfo);
    }
  }

  function getErrorMessageFromAPI(error) {
    try {
      const parsedError = JSON.parse(error.errors[0].message);
      if (parsedError && parsedError.error) {
        if (typeof parsedError.error === 'string') {
          return parsedError.error;
        }
        if (parsedError.error.length) {
          return parsedError.error[0];
        }
        if (
          parsedError.error.error &&
          typeof parsedError.error.error === 'string'
        ) {
          return parsedError.error.error;
        }
      }
      return '';
    } catch (err) {
      console.log(err);
      return '';
    }
  }

  async function onSendInvitationClick(data, callback = () => {}) {
    try {
      await inviteCompanyContact({
        userId: asLoggedUserDetails.userId,
        companyId: accountDetails.id,
        email: data.email,
        givenName: data.firstName,
        phoneCountryCode:
          values.phoneNumber && values.phoneNumber.countryCode
            ? values.phoneNumber.countryCode
            : 'US',
        phoneNumber:
          values.phoneNumber && values.phoneNumber.phoneNumber
            ? values.phoneNumber.phoneNumber
            : '5555555555',
        surName: data.lastName,
        title: '',
      }).then((res) => {
        if (
          callback &&
          members.length + 2 >= accountDetails.boardSeatsAllowed
        ) {
          callback(false);
        }
        setMembers([...members, data]);
        return res;
      });
    } catch (error) {
      console.log(error);
      const errorMessage = t('STEP1-ERROR-SNACKBAR-TEXT');
      let errorMessageFromAPI = getErrorMessageFromAPI(error);

      setTextError(
        errorMessageFromAPI.length === 0 ? errorMessage : errorMessageFromAPI
      );
      setIsSnackbarVisible(true);
    }
  }

  async function sendFormData() {
    try {
      let data = {
        CONTACT_ID: asLoggedUserDetails.userId,
        HEADLINE: values.headline || '',
      };
      const updateCalls = [updateCompanyAccountData(data)];
      if (isImpersonated) {
        updateCalls.push(
          updateCompanyDisplayIndustry({
            companyId: accountDetails.id,
            industryTagId:
              values.displayIndustry && values.displayIndustry.id
                ? values.displayIndustry.id
                : 'remove',
          })
        );
      }
      await Promise.all(updateCalls);
      let accountDetailsModifiedValues = {
        displayIndustry: values.displayIndustry,
      };
      setAccountInfo(false, accountDetailsModifiedValues);
    } catch (error) {
      throw error;
    }
  }

  async function sendContactFormData() {
    try {
      const contact = accountDetails.contacts.find(
        (companyContact) => companyContact.id === asLoggedUserDetails.userId
      );
      const phoneSettedAndUpdated =
        contact &&
        contact.phoneNumber?.phoneNumber &&
        values.yourPhoneNumber &&
        contact.phoneNumber.phoneNumber !== values.yourPhoneNumber;

      const updatePayload = {
        CONTACT_ID: asLoggedUserDetails.userId,
        CONTACT_FIRST_NAME: values.yourFirstName,
        CONTACT_LAST_NAME: values.yourLastName,
        CONTACT_TITLE: values.yourTitle,
        PHONE_COUNTRY_CODE: values.yourPhoneCountryCode,
        PHONE_NUMBER: phoneSettedAndUpdated
          ? values.yourPhoneNumber
          : '5555555555',
      };
      if (isUpdate) {
        updatePayload.CONTACT_BIO = values.yourBiography;
      }

      const updatedContact = await updateCompanyProfileData(updatePayload);

      const companyContactModifiedValues = {
        givenName: values.yourFirstName,
        surName: values.yourLastName,
        title: values.yourTitle,
        phoneNumber: updatedContact.data.updateCompanyContact.phoneNumber,
      };
      if (isUpdate) {
        companyContactModifiedValues.biography = values.yourBiography;
      }
      setAccountInfoCompany(false, companyContactModifiedValues);
    } catch (err) {
      throw err;
    }
  }

  async function sendProfileImg() {
    try {
      const response = await uploadCompanyContactImgCall({
        CONTACT_ID: asLoggedUserDetails.userId,
        CONTACT_EDITED_IMG: uploaderProfileOutput.image,
        CONTACT_ORIGINAL_IMG: uploaderProfileOutput.originalImage,
      });

      const fixedLocation =
        response.data.uploadContactImage.location.split('https://');

      const companyContactModifiedValues = {
        image: {
          ...response.data.uploadContactImage,
          location: fixedLocation[1],
        },
      };

      setAccountInfoCompany(false, companyContactModifiedValues);
      if (onInformationUploaded) {
        onInformationUploaded();
      }
    } catch (err) {
      console.log(err);
      throw new Error();
    }
  }

  function submitStep1Form() {
    setIsLoading(true);
    const promisesToResolve = isBoardOwner
      ? [sendFormData(), sendContactFormData()]
      : [sendContactFormData()];
    if (uploaderProfileOutput && uploaderProfileOutput.image) {
      promisesToResolve.push(sendProfileImg());
    }
    Promise.all(promisesToResolve)
      .then((x) => {
        const accountDetailsModifiedValues = {
          onboardingProcess: 'Complete',
          industryTagIds: companyIndustries
            ? companyIndustries.map((d) => d.id)
            : [],
        };
        setAccountInfo(true, accountDetailsModifiedValues);
        setAccountInfoCompany(true, {});
        history.push('/account');
        onCancelClick();
      })
      .catch((error) => {
        console.log(error);
        setTextError(getErrorMessageFromAPI(error));
        setIsSnackbarVisible(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function renderSaveAndCancelButtons() {
    return (
      <>
        <div style={classes.saveButtonContainer}>
          <SaveButton onClick={submitStep1Form} disabled={isLoading} />
          <div style={{ width: '20px' }}></div>
          <CancelButton onClick={onCancelClick} />
        </div>
      </>
    );
  }

  function handleCompanyDisplayIndustryAutocompleteChange(event, vals) {
    setFieldValue('displayIndustry', vals);
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isUpdate && updateSubmit) {
      updateSubmit(submitStep1Form);
    }
    return () => {
      isMounted = false;
    };
  }, [submitStep1Form]);

  return (
    <>
      <form>
        <ACSnackbar
          id="onboarding-step1-error-snackbar"
          onClose={() => setIsSnackbarVisible(false)}
          open={isSnackbarVisible}
          severity="error"
          text={textError || t('STEP1-ERROR-SNACKBAR-TEXT')}
        />
        <Grid container sx={{ height: '25px' }} justifyContent="space-between">
          <Grid item>
            <Typography sx={{ ...classes.subtitle }}>About you</Typography>
          </Grid>
          <Grid item>{renderSaveAndCancelButtons()}</Grid>
        </Grid>
        <Typography sx={classes.subtitleDescription}>
          Fill out your personal information
        </Typography>
        <div style={classes.row}>
          <div style={classes.thumbnailProfileContainer}>
            <FilePondProfile
              resultFiles={setUploaderProfileOutput}
              status={setFilePondProfileStatus}
            />
          </div>
          <div style={classes.companyWebsiteContainer}>
            <Grid
              container
              sx={{ display: !isXS ? 'flex' : 'inline' }}
              spacing={1}
            >
              <Grid item sm={6} xs={12}>
                <Input
                  style={classes.fieldContainer}
                  id="yourFirstName"
                  InputProps={{
                    'data-cy': 'txtYourFirstName',
                    style: {
                      height: '44px',
                      borderRadius: '10px',
                    },
                  }}
                  label="First name"
                  placeholder="First name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.yourFirstName}
                  hideLabel
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Input
                  style={classes.fieldContainer}
                  id="yourLastName"
                  InputProps={{
                    'data-cy': 'txtYourLastName',
                    style: {
                      height: '44px',
                      borderRadius: '10px',
                    },
                  }}
                  label="Last name"
                  placeholder="Last name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.yourLastName}
                  hideLabel
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Input
                  style={classes.fieldContainer}
                  id="yourTitle"
                  InputProps={{
                    'data-cy': 'txtYourTitle',
                    style: {
                      height: '44px',
                      borderRadius: '10px',
                    },
                  }}
                  label="Title"
                  placeholder="Title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.yourTitle}
                  hideLabel
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <div style={classes.phoneContainer}>
                  <Input
                    style={classes.fieldContainer}
                    id="yourPhoneNumber"
                    InputProps={{
                      'data-cy': 'txtYourPhoneNumber',
                      style: {
                        height: '44px',
                        borderRadius: '10px',
                      },
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    size="small"
                    value={values.yourPhoneNumber}
                    placeholder="Phone number"
                    hideLabel
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <div style={{ ...classes.row, marginTop: '20px' }}>
          <Input
            style={classes.fieldContainer}
            id="yourBiography"
            InputProps={{
              'data-cy': 'txtYourBiography',
              style: {
                borderRadius: '10px',
              },
            }}
            label="Biography"
            multiline
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={t('STEP2-YOUR-BIOGRAPHY-INPUT-PLACEHOLDER')}
            rows={4}
            value={values.yourBiography}
          />
        </div>
        {isBoardOwner && (
          <>
            {isImpersonated && (
              <div style={classes.row}>
                <Input
                  style={classes.fieldContainer}
                  id="headline"
                  InputProps={{
                    'data-cy': 'txtHeadline',
                    style: {
                      borderRadius: '10px',
                    },
                  }}
                  label="Headline"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={t('STEP1-COMPANY-HEADLINE-INPUT-PLACEHOLDER')}
                  value={values.headline}
                />
              </div>
            )}
            {isImpersonated && (
              <div style={classes.row}>
                <AutocompleteCustome
                  parentStyle={classes.fieldContainer}
                  value={values.displayIndustry}
                  onChange={handleCompanyDisplayIndustryAutocompleteChange}
                  onBlur={handleBlur}
                  multiple={false}
                  getOptionLabel={(option) => option.label}
                  id="companyDisplayIndustry"
                  input-data-cy="txtCompanyDisplayIndustry"
                  options={companyIndustries || []}
                  placeholder="Display industry"
                  sublabelText="Add your favorite industry"
                />
              </div>
            )}
            <OnboardingAddTeamMembers
              members={members}
              addMember={onSendInvitationClick}
              boardSeatsAllowed={accountDetails.boardSeatsAllowed}
            />
          </>
        )}
      </form>
    </>
  );
}

export default Step2Form;
