import React from 'react';
import { Box, Button, Modal, Typography, useMediaQuery } from '@mui/material';
import { useAccount } from '../../../contexts/Account';

const useStyles = (isSM) => ({
  dialogContainer: {
    borderRadius: '0px',
    padding: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isSM ? '95%' : '485px',
    height: '310px',
    background: '#fff',
    boxShadow: '1px 2px 5px #bebebe',
    border: 'solid 1px #E6EDFE',
    borderRadius: '15px',
    padding: '5px',
  },
  messageContainer: {
    margin: isSM ? '5px' : '15px',
    padding: isSM ? '5px' : '12px 0 12px',
  },
  mainMessageTitle: {
    textAlign: 'center',
    fontSize: '20px',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    paddingTop: '60px',
  },
  mainMessage: {
    textAlign: 'center',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '13px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    padding: '0px 50px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: isSM ? '1em' : '2em',
  },
  mainButton: {
    width: '218px',
    borderRadius: '40px',
    backgroundColor: '#3171F6',
    textTransform: 'none',
    height: '45px',
    margin: '5px',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '15px',
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    color: '#FFFFFF',
    padding: '5px',
    '&:hover': {
      backgroundColor: '#3171F6',
    },
  },
});

export default function NoMoreBoardSeats({ openModal, setOpenModal }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { accountDetails } = useAccount();

  function handleClose() {
    setOpenModal(false);
  }

  function getUpgradeText() {
    return isSM ? <Typography>View Options</Typography> : <>View Options</>;
  }

  function upgradePlan() {
    window.open(
      `${accountDetails.maxBoardsUpgradeUrl}?iwm_advisor_id=${accountDetails.iwmId}&sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.email}&plancode=${accountDetails.recurlyPlanCode}`
    );
  }

  return (
    <div style={classes.dialogContainer}>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <Box sx={classes.container}>
          <>
            <div style={classes.mainMessageTitle}>
              Upgrade to join more boards
            </div>
            <div style={classes.messageContainer}>
              <Typography sx={classes.mainMessage}>
                You've reached the board membership limit of your current plan.
                To join this board and unlock additional features, click below
                to explore your upgrade options.
              </Typography>
            </div>
            <div style={classes.actionsContainer}>
              <Button
                color="primary"
                size="large"
                variant="contained"
                sx={classes.mainButton}
                onClick={() => upgradePlan()}
              >
                {getUpgradeText()}
              </Button>
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
}
