import React from 'react';
import { PictureAsPdfSharp } from '@mui/icons-material';

const useStyles = (props) => ({
  projectCardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: props.width ? '105px' : `${props.width}px`,
    height: '69px',
  },
  pdfSizeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '105px',
    height: '69px',
  },
  pdfNameContainer: {
    marginTop: '5px',
    fontSize: '10px',
    wordBreak: 'break-word',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '3px',
  },
});

function CarosuelCard(props) {
  const { img, name, openImageOnNewTab } = props;
  const classes = useStyles(props);
  let componentToReturn = {};
  if (img.includes('.pdf')) {
    componentToReturn = (
      <div style={classes.pdfSizeContainer}>
        <PictureAsPdfSharp
          fontSize="large"
          onClick={() => window.open(img, '_blank')}
        />
        <div style={classes.pdfNameContainer}>{name}</div>
      </div>
    );
  } else {
    componentToReturn = (
      <div style={classes.projectCardContainer} id="carousel-card">
        <img
          src={img}
          style={classes.image}
          alt=""
          onClick={() =>
            openImageOnNewTab ? window.open(img, '_blank') : null
          }
        />
      </div>
    );
  }
  return componentToReturn;
}

export default CarosuelCard;
