import React from 'react';
import { Avatar, Button, Paper, Typography, useMediaQuery } from '@mui/material';
import MainImage from '../images/personal-boards-main-2.webp';
import PrivateIcon from '../images/private-icon.svg';
import MembershipIcon from '../images/membership-icon.svg';
import GolfBallIcon from '../images/golf-ball-icon.svg';
import DoubleClickIcon from '../images/double-click-icon.svg';
import BriefcaseIcon from '../images/briefcase-icon.svg';
import DocumentIcon from '../images/document-icon.svg';
import UserIcon from '../images/user-icon.svg';
import { getAdvisorQueryParams } from '../utils/queryParams';
import { useAccount } from '../contexts/Account';

const bulletPoints = [
  {
    text: "Included with your membership is the ability to start your own advisory board for your business, team, or a personal project.",
    image: MembershipIcon
  },
  {
    text: "Your advisory board is private and only for people you invite.",
    image: PrivateIcon
  },
  {
    text: "Anyone you invite, they can join just your board for free.",
    image: DoubleClickIcon
  },
  {
    text: "Everyone should have an advisory board, see how it helps you reach new heights faster.",
    image: GolfBallIcon
  }
]
const cards = [
  {
    title: "Business",
    image: BriefcaseIcon,
    text: "Are you a CEO, business owner, or founder? Start an advisory board for your business to post questions, hold meetings, and get insights from people that can help your business reach new heights.",
    type: "business"
  },
  {
    title: "Team",
    image: MembershipIcon,
    text: "Invite your team members and others you know that would have valuable input on your current challenges for your team.",
    type: "team"
  },
  {
    title: "Personal Project",
    image: DocumentIcon,
    text: "Working on a side-project or cause personally? Help it keep moving forward by inviting others to be a part of your advisory board.",
    type: "personal"
  },
  {
    title: "Career",
    image: UserIcon,
    text: "Invite mentors, former bosses & your smartest friends & colleagues to have one place to share career updates, ask their advice & more in a convenient & private way.",
    type: "career"
  },
]

export default function PersonalAdvisoryBoards() {

  const isWrapped = useMediaQuery((theme) => theme.breakpoints.down([1400]));
  const isSM = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const { accountDetails, isFreemium, isFreeTrial } = useAccount();
  function openAddBoardConfirmation(type) {
    window.location.assign(
      `https://register.advisorycloud.com/babs/add-your-advisory-board/?${getAdvisorQueryParams({accountDetails, isFreemium, isFreeTrial})}&type=${type}`,
      '_blank'
    );
  }

  return (
    <Paper sx={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: "center",
    }}>
      {/* First section */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: !isWrapped ? "row" : "column",
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <div style={{ marginLeft: 50, marginRight: 20 }}>
          <Typography sx={{
            width: "100%",
            font: "Poppins",
            fontWeight: "600",
            fontSize: "32px",
            lineHeight: "41.6px",
            marginTop: isWrapped ? 5 : "unset"
          }}>
            Every one should have an advisory board.<span style={{ color: "#0F4CB9" }}><br></br>We make it easy.</span>
          </Typography>
          {isSM ? <img
            src={MainImage}
            style={{
              marginTop: 20,
              marginBottom: 20,
              width: isSM ? "70%" : "unset",
              maxWidth: "600px"
            }}
            alt="Personal Advisory Boards"
          /> : null}
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }}>
            {bulletPoints.map((bullet, index) => (
              <div key={index} style={{ display: 'flex', flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
                <img src={bullet.image} alt="icon" style={{ marginRight: 15, width: "30px" }} />
                <Typography sx={{
                  font: "Poppins",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "20.8px",
                }}>
                  {bullet.text}
                </Typography>
              </div>
            ))}
          </div>
        </div>
        {!isSM ? <img
          src={MainImage}
          style={{
            marginTop: 20,
            marginBottom: 20,
            width: isSM ? "70%" : "unset",
            maxWidth: "600px"
          }}
          alt="Personal Advisory Boards"
        /> : null}

      </div>
      {/* Second section */}
      <div style={{
        width: '100%',
        height: "100%",
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20,
        backgroundColor: "hsla(218, 100%, 98%, 1)",
        paddingBottom: 60,
      }}>
        <Typography sx={{
          width: !isWrapped ? "45%" : "90%",
          font: "Poppins",
          fontWeight: "600",
          fontSize: "32px",
          lineHeight: "41.6px",
          textAlign: "center",
          alignSelf: "center",
          marginTop: 10,
          marginBottom: 5
        }}>
          Start your advisory board today in less than 2 minutes...
        </Typography>
        <div
          style={!isSM ? {
            display: !isWrapped ? 'flex' : "inline-grid",
            flexDirection: !isWrapped ? 'row' : "unset",
            gridRow: "auto auto",
            gridTemplateColumns: "1fr 1fr",
            justifyContent: !isWrapped ? 'space-around' : "space-evenly",
            rowGap: 40,
            columnGap: isWrapped ? 40 : "unset",
            marginBottom: 20,
            marginLeft: "10%",
            marginRight: "10%"
          } : {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            rowGap: 40,
            marginBottom: 20,
            marginLeft: "10%",
            marginRight: "10%"
          }}>
          {
            cards.map((card, index) => (
              <CustomCard key={index} title={card.title} image={card.image} text={card.text} type={card.type} onClick={openAddBoardConfirmation} isWrapped={isWrapped} />
            ))
          }
        </div>
      </div>
    </Paper>
  );
}

function CustomCard({ title, image, text, onClick, ...params }) {
  return (
    <div
      onClick={() => onClick(params.type)}
      style={{
        width: !params.isWrapped ? "22%" : "100%",
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: "rgba(255, 255, 255, 1)",
        boxShadow: "0px 8px 16px 0px rgba(0, 16, 48, 0.04)",
        borderRadius: "12px",
        paddingBottom: 20,
        cursor: "pointer"
      }}>
      <div
        style={{
          height: "4rem",
          background: "linear-gradient(90deg, #B1CBFC 0%, #BBE2FD 100%)",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}>
        <Avatar
          src={image}
          sx={{
            width: 50,
            height: 50,
            marginTop: 5,
            marginLeft: 3,
            backgroundColor: "rgba(255, 255, 255, 1)",
            borderRadius: "12px"
          }} />
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        width: "80%",
        marginLeft: 20,
        marginTop: 50,
        marginBottom: 20,
        rowGap: 10,
      }}>
        <Typography sx={{
          font: "Poppins",
          fontWeight: "600",
          fontSize: "24px",
          lineHeight: "20px",
          textAlign: "justify",
        }}>
          {title}
        </Typography>
        <Typography sx={{
          font: "Poppins",
          color: "rgba(77, 88, 111, 1)",
          fontWeight: "400",
          fontSize: "14px",
          lineHeight: "18px",
          textAlign: "left",
        }}>
          {text}
        </Typography>
      </div>
      <Button
        variant='contained'
        color='primary'
        onClick={onClick}
        sx={{
          textTransform: "none",
          alignSelf: "center",
          minWidth: "170px",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontWeight: 700,
          lineHeight: "24px",
          letterSpacing: "-0.43px",
          margin: "15px",
          boxShadow: "none",
          marginTop: "auto",
        }}>
        Start for Free
      </Button>
    </div>
  )
}