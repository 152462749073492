import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import CompanyInformationViewCard from '../../components/AccountSettings/CompanyInformationViewCard';
import CompanyInformationViewFreemiumCard from '../../components/AccountSettings/CompanyInformationViewFreemiumCard';
import CompanyInformationEditCard from '../../components/AccountSettings/CompanyInformationEditCard';
import CompanyInformationEditFreemiumCard from '../../components/AccountSettings/CompanyInformationEditFreemiumCard';
import { useAccount } from '../../contexts/Account';

function CompanyAccount() {
  const { t } = useTranslation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const location = useLocation();
  const { accountDetails, isFreemium, isFreeTrial } = useAccount();
  const queryParams = new URLSearchParams(location.search);
  const [isCompanyInformationEditing, setIsCompanyInformationEditing] =
    useState(queryParams.get('edit') === 'true');
  const showExFremiumBanner =
    accountDetails.freemiumRegistration &&
    accountDetails.opportunityStage === 'discovery' &&
    !isFreemium;
  const showOnboardingBanner =
    accountDetails.opportunityStage === 'discovery' &&
    accountDetails.onboardingStatus !== 'Not Requisite' &&
    accountDetails.onboardingStatus !== 'Complete' &&
    !accountDetails.freemiumRegistration;

  function getTopPadding() {
    if (
      showOnboardingBanner ||
      showExFremiumBanner ||
      (isFreeTrial && !isFreemium)
    ) {
      if (isSM && !isFreeTrial) {
        return '130px';
      }
      return '80px';
    }
    return '30px';
  }

  function openEditCompanyInformation() {
    setIsCompanyInformationEditing(true);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('edit', 'true');
    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    window.history.replaceState(null, null, newUrl);
  }

  function closeEditCompanyInformation() {
    setIsCompanyInformationEditing(false);
    const queryParams = new URLSearchParams(location.search);
    queryParams.delete('edit');
    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    window.history.replaceState(null, null, newUrl);
  }

  function renderCompanyInformationCard() {
    if (isCompanyInformationEditing) {
      if (isFreemium) {
        return (
          <CompanyInformationEditFreemiumCard
            onCancelClick={closeEditCompanyInformation}
          />
        );
      }
      return (
        <CompanyInformationEditCard
          onCancelClick={closeEditCompanyInformation}
        />
      );
    }
    if (isFreemium) {
      return (
        <CompanyInformationViewFreemiumCard
          isSM={isSM}
          onEditClick={openEditCompanyInformation}
        />
      );
    }
    return (
      <CompanyInformationViewCard
        isSM={isSM}
        onEditClick={openEditCompanyInformation}
      />
    );
  }

  useEffect(() => {
    if (queryParams.get('edit') === 'true') {
      setIsCompanyInformationEditing(true);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('ACCOUNT-HELMET-TITLE')}</title>
      </Helmet>

      <div
        style={{
          width: '100%',
          paddingTop: getTopPadding(),
          paddingLeft: isSM ? '0px' : '30px',
          paddingRight: isSM ? '0px' : '30px',
          display: 'flex',
          flexDirection: isSM ? 'column' : 'row',
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: isSM ? '10px' : '0px',
            paddingRight: isSM ? '10px' : '0px',
          }}
        >
          {renderCompanyInformationCard()}
        </div>
      </div>
    </>
  );
}

export default CompanyAccount;
