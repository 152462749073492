import React, { useState, useEffect, useRef } from 'react';
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useMediaQuery,
} from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import { AccountType } from './../../../services/utils/types';
import Header from './header';
import ChatBox from './chatBox';
import NoChat from './NoChat';
import LoadingIndicatorLogo from './../../LoadingIndicatorLogo';
import dateFormat from './../../../utils/dateFormat';
import { useAccount } from './../../../contexts/Account';
import {
  getConversationMessages,
  createConversationMessage,
  readMessages,
} from './../../../services/conversation_services';
import eventBus, { eventBusValues } from './../../../eventBus';
import CompanyEmptyState from './../../EmptyState/CompanyEmptyState';

const useStyles = (isSM) => ({
  messagesContainer: {
    overflow: 'scroll',
    overscrollBehavior: 'contain',
    backgroundColor: 'white',
    height: '100%',
    marginTop: '60px',
  },
  screenContainer: {
    position: 'fixed',
    backgroundColor: 'white',
    width: isSM ? '100%' : '80%',
    minWidth: isSM ? '100%' : 'calc(100% - 350px)',
    maxWidth: isSM ? '100%' : 'calc(100% - 280px)',
  },
  screenContainerSlide: {
    position: 'relative',
    width: '100%',
    backgroundColor: 'white',
  },
  name: {
    fontWeight: 700,
    fontSize: '14px',
    fontFamily: 'Poppins',
  },
  hour: {
    fontWeight: 500,
    fontSize: '10px',
    color: '#97A3B7',
    marginLeft: '10px',
    fontFamily: 'Poppins',
  },
  message: {
    marginTop: 5,
    fontWeight: 300,
    fontSize: '14px',
    color: '#232B35',
    fontFamily: 'Poppins',
    margin: '1px 0',
    whiteSpace: 'break-spaces',
    overflowWrap: 'anywhere',
  },
  loadingContainer: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
    zIndex: 9999,
  },
  gapBottom: {
    height: isSM ? '180px' : '50px',
  },
  DrawerGapBottom: {
    height: isSM ? '120px' : '30px',
  },
});

const ConversationContainer = (props) => {
  const { propConversationId, isDrawer } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const [noItemSelected, setNoItemSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatboxHeight, setChatboxHeight] = useState(54);
  const [sortDates, setSortDates] = useState([]);
  const [groupDates, setGroupDates] = useState([]);
  const [conversationId, setConversationId] = useState('');
  const [senderConversationMemberId, setSenderConversationMemberId] =
    useState('');
  const divRef = useRef(null);

  useEffect(() => {
    if (divRef.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }
  }, [groupDates]);

  const {
    accountDetails,
    type,
    asLoggedUserDetails,
    conversations,
    updateReadMessages,
  } = useAccount();

  const messagesEndRef = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  function moreThanfiveDays(date) {
    const date1ml = date.getTime();
    const now = new Date();
    const date2ml = now.getTime();
    const diferencesml = date2ml - date1ml;
    return Math.floor(diferencesml / (1000 * 60 * 60 * 24)) > 5;
  }

  function groupByDate(arr) {
    const groups = [];
    arr.forEach((item) => {
      const date = new Date(item.createdAt);
      const dateString = moreThanfiveDays(date)
        ? dateFormat(date, 'dddd, mmmm d yyyy')
        : dateFormat(date, 'DDDD');

      item.hourString = dateFormat(date, 'shortTime');

      const groupRelated = groups.find((gr) => gr.dateString === dateString);
      if (groupRelated) {
        groupRelated.messages.push(item);
      } else {
        groups.push({
          date,
          dateString,
          messages: [item],
        });
      }
    });

    return groups;
  }

  function sendMessage(message) {
    return createConversationMessage({
      conversationId,
      senderConversationMemberId,
      accountType: type,
      senderId: asLoggedUserDetails.userId,
      receiver: queryParams.get('senderId'),
      message,
    }).then(({ data }) => {
      const newMsg = data.createConversationMessage;
      if (conversationId) {
        eventBus.dispatch(
          `${eventBusValues.onNewMessage}${conversationId}`,
          newMsg
        );
      }
      if (!conversationId && data && newMsg) {
        setConversationId(newMsg.conversationId);
        setSenderConversationMemberId(newMsg.senderConversationMemberId);
        sortDates.push(newMsg);
        setSortDates(sortDates);
        const group = groupByDate(sortDates);
        setGroupDates(group);
      }
    });
  }

  function setNewMessage(newMessage) {
    sortDates.push(newMessage);
    setSortDates(sortDates);
    const group = groupByDate(sortDates);
    setGroupDates(group);
  }

  function getConversation(conversationParam, senderParam) {
    setLoading(true);
    setNoItemSelected(false);
    const params = conversationParam
      ? { conversationId: conversationParam }
      : {
          member1:
            type === AccountType.COMPANY
              ? asLoggedUserDetails.userId
              : senderParam,
          member2:
            type !== AccountType.COMPANY
              ? asLoggedUserDetails.userId
              : senderParam,
        };
    if (conversationParam && !isDrawer) {
      readMessages({
        accountId: asLoggedUserDetails.userId,
        accountType: type,
        conversationId: conversationParam,
      });
      updateReadMessages(conversations, conversationParam);
    }
    getConversationMessages(params)
      .then(({ data }) => {
        setLoading(false);
        const msgs = data.getConversationMessages.sort((a, b) => {
          return new Date(a.createdAt) - new Date(b.createdAt);
        });
        setSortDates(msgs);
        const group = groupByDate(msgs);
        setGroupDates(group);
        if (!conversationId && data.getConversationMessages.length > 0) {
          setConversationId(data.getConversationMessages[0].conversationId);
          setSenderConversationMemberId(
            data.getConversationMessages[0].senderConversationMemberId
          );
        }
      })
      .catch((error) => {
        console.error('Error: ', error);
        setLoading(false);
        history.push('/messages');
      });
  }

  useEffect(() => {
    if (propConversationId) {
      getConversation(propConversationId);
    }
  }, [propConversationId]);

  function viewProfile(msg) {
    if (
      msg.senderAccountType === AccountType.ADVISOR &&
      msg.senderId !== accountDetails.id &&
      !isDrawer
    ) {
      eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
        fetchAdvisor: true,
        id: msg.senderId,
        boardRoomView: true,
      });
    } else if (
      msg.senderAccountType === AccountType.COMPANY &&
      msg.profile?.companyId !== accountDetails.id &&
      !isDrawer
    ) {
      eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
        companyId: msg.profile?.companyId,
      });
    }
  }

  function getCursorStyle(msg) {
    if (
      ((msg.senderAccountType === AccountType.ADVISOR &&
        msg.senderId !== accountDetails.id) ||
        (type === AccountType.ADVISOR && msg.profile?.companyId)) &&
      !isDrawer
    ) {
      return 'pointer';
    }
    return 'default';
  }

  useEffect(() => {
    if (
      queryParams.get('senderId') &&
      queryParams.get('senderId') === asLoggedUserDetails.userId
    ) {
      setNoItemSelected(true);
    } else if (
      queryParams.get('conversationId') ||
      queryParams.get('senderId')
    ) {
      getConversation(
        queryParams.get('conversationId'),
        queryParams.get('senderId')
      );
    }
    const hsubs = history.listen((locationParams) => {
      const newQueryParams = new URLSearchParams(locationParams.search);
      const conversationParam = newQueryParams.get('conversationId');
      const senderParam = newQueryParams.get('senderId');
      if (!conversationParam && !senderParam && !propConversationId) {
        setConversationId('');
        setNoItemSelected(true);
        setGroupDates([]);
      }
      if (conversationParam && conversationParam !== conversationId) {
        setGroupDates([]);
        getConversation(conversationParam, senderParam);
      }
      if (senderParam) {
        setConversationId('');
        setGroupDates([]);
        setSortDates([]);
        setNoItemSelected(false);
      }
    });
    return () => {
      hsubs();
    };
  }, []);

  useEffect(() => {
    if (conversationId) {
      eventBus.on(
        `${eventBusValues.onNewMessage}${conversationId}`,
        (newMessage) => {
          if (
            window.location.search.includes(conversationId) ||
            conversationId === propConversationId
          ) {
            setNewMessage(newMessage);
          }
        }
      );
    }
    return () => {
      eventBus.remove(`${eventBusValues.onNewMessage}${conversationId}`);
    };
  }, [conversationId]);

  return (
    <div
      ref={divRef}
      style={{
        ...(isDrawer ? classes.screenContainerSlide : classes.screenContainer),
        height: isDrawer ? `100vh` : `calc(100vh - 80px - ${chatboxHeight}px)`,
        overflow: isDrawer ? 'hidden' : null,
      }}
    >
      <Header
        propConversationId={propConversationId}
        isDrawer={isDrawer}
        groupDates={groupDates}
      />
      {type === AccountType.ADVISOR ? (
        <Header
          propConversationId={propConversationId}
          isDrawer={isDrawer}
          groupDates={groupDates}
        />
      ) : null}
      <div style={classes.messagesContainer}>
        {noItemSelected && type === AccountType.ADVISOR ? <NoChat /> : null}
        {noItemSelected && type === AccountType.COMPANY ? (
          <Box padding="75px 45px" maxWidth="1200px">
            <CompanyEmptyState
              content="Your conversations with advisors will appear here"
              buttonText="Start messaging"
              buttonAction={() => {}}
              buttonId="getStartedButton"
            />
          </Box>
        ) : null}
        {loading ? (
          <div style={classes.loadingContainer}>
            <LoadingIndicatorLogo
              size={200}
              iconFontSize={93}
              iconRight={105}
            />
          </div>
        ) : null}
        <List
          style={{
            paddingBottom: '8px',
            marginTop: isDrawer ? '150px' : '20px',
          }}
        >
          {groupDates.map((group) => (
            <React.Fragment key={group.dateString}>
              <Divider style={{ fontFamily: 'Poppins' }}>
                {group.dateString}
              </Divider>
              {group.messages.map((chat, index) => (
                <ListItem
                  style={{
                    paddingLeft: '30px',
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 5,
                  }}
                  alignItems="flex-start"
                  key={`${chat.profile?.displayName}-${chat.createdAt}`}
                >
                  <div style={{ display: 'flex' }}>
                    {chat.profile?.image ||
                    chat.profile?.contactLogoLocation ? (
                      <ListItemAvatar>
                        <img
                          alt=""
                          src={
                            chat.profile?.image
                              ? `${process.env.REACT_APP_IMAGES_URL}${chat.profile?.image.imageKey}`
                              : chat.profile?.contactLogoLocation
                          }
                          style={{
                            width: '45px',
                            height: '45px',
                            marginRight: '15px',
                            marginTop: '-5px',
                            cursor: getCursorStyle(chat),
                          }}
                          onClick={() => viewProfile(chat)}
                          role="none"
                        />
                      </ListItemAvatar>
                    ) : (
                      <ListItemAvatar>
                        <Avatar
                          onClick={() => viewProfile(chat)}
                          style={{
                            cursor: getCursorStyle(chat),
                          }}
                        >
                          {chat.profile?.displayName.charAt(0)}
                        </Avatar>
                      </ListItemAvatar>
                    )}

                    <ListItemText
                      primary={
                        <div>
                          <span style={classes.name}>
                            {chat.profile?.displayName}
                          </span>
                          <span style={classes.hour}>{chat.hourString}</span>
                        </div>
                      }
                      secondary={
                        <span style={classes.message}>{chat.message}</span>
                      }
                    />
                  </div>
                </ListItem>
              ))}
            </React.Fragment>
          ))}
        </List>
        <div
          style={isDrawer ? classes.DrawerGapBottom : classes.gapBottom}
          ref={messagesEndRef}
        />
      </div>
      <ChatBox
        sendMessage={sendMessage}
        chatboxHeight={chatboxHeight}
        setChatboxHeight={setChatboxHeight}
        disabled={noItemSelected}
        isDrawer={isDrawer}
      />
    </div>
  );
};

export default ConversationContainer;
