import React from 'react';
import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import MembershipIcon from '../../images/membership-icon.svg';
import PrivateIcon from '../../images/private-icon.svg';
import DoubleClickIcon from '../../images/double-click-icon.svg';
import GolfBallIcon from '../../images/golf-ball-icon.svg';

const AdvisoryBoardsHeader = (props) => {
  const { peerPage } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const bulletPoints = [
    {
      text: 'Instantly join any of the 200+ advisory boards exclusively managed by AdvisoryCloud',
      image: MembershipIcon,
    },
    {
      text: "Receive monthly board recommendations and updates related to boards you're on",
      image: PrivateIcon,
    },
    {
      text: 'Attend video-conference board meetings, share insights, and add boards to your experience',
      image: DoubleClickIcon,
    },
    {
      text: 'Need help getting started or getting matched?',
      linkText: 'Book a call with our team here.',
      link: 'https://register.advisorycloud.com/platform/engagement-call',
      image: GolfBallIcon,
    },
  ];

  function getTitleSize() {
    if (isSM) return '20px';
    if (!peerPage) return '32px';
    return '40px';
  }

  function getTitleHeight() {
    if (isSM) return '24px';
    if (!peerPage) return '40px';
    return '52px';
  }

  function getImageWidth() {
    if (isSM) return '100%';
    if (!peerPage) return '480px';
    return null;
  }

  function getImageMaxWidth() {
    if (isSM) return '550px';
    if (!peerPage) return '480px';
    return null;
  }

  function getImageMinWidth() {
    if (isSM) return null;
    if (!peerPage) return '480px';
    return null;
  }

  return (
    <Box
      mb={peerPage ? '16px' : '32px'}
      sx={{
        display: 'flex',
        flexDirection: isSM ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography
          sx={{
            fontSize: getTitleSize(),
            fontWeight: 600,
            lineHeight: getTitleHeight(),
            textAlign: 'left',
            color: '#001030',
            marginBottom: '12px',
          }}
        >
          {peerPage
            ? 'Experience the benefits and ROI of joining a peer advisory board.'
            : 'Welcome to the largest collection of advisory boards, available to join exclusively on AdvisoryCloud.'}
        </Typography>
        {peerPage ? (
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '24px',
              textAlign: 'left',
              color: '#4D586F',
            }}
          >
            Immediately join one of our 9 types of peer advisory boards that
            have monthly meetings on current career opportunities, networking,
            compensation trends, and the sharing of best practices.
          </Typography>
        ) : (
          <Box>
            {bulletPoints.map((bullet, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '16px',
                  alignItems: 'center',
                }}
              >
                <img
                  src={bullet.image}
                  alt="icon"
                  style={{ marginRight: '15px', width: '30px' }}
                />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '20.8px',
                  }}
                >
                  {bullet.text}&nbsp;
                  <Link href={bullet.link} target="_blank">
                    {bullet.linkText}
                  </Link>
                </Typography>
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Box
        width={getImageWidth()}
        maxWidth={getImageMaxWidth()}
        minWidth={getImageMinWidth()}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          mt: isSM ? '16px' : '0',
          ml: !isSM ? '75px' : null,
        }}
      >
        <img
          src={
            peerPage
              ? 'https://d9hhrg4mnvzow.cloudfront.net/register.advisorycloud.com/platform/annual-offer/c4d29d7e-group-8699_10s80ku000000000000028.png'
              : 'https://cdn.advisorycloud.com/images/peer_boards.png'
          }
          alt="Peer Advisory Boards"
          style={{ width: '100%', height: 'auto' }}
        />
      </Box>
    </Box>
  );
};

export default AdvisoryBoardsHeader;
