import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Paper,
  useMediaQuery,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ConversationLeftMenuItem from './leftMenuItemV2';
import { useAccount } from '../../contexts/Account';
import eventBus, { eventBusValues } from '../../eventBus';

const useStyles = ({ isSM, isXS }) => ({
  outerContainer: {
    width: '300px',
  },
  container: {
    width: '300px',
    top: '0px',
    minHeight: '100vh',
    borderRadius: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 2,
    backgroundColor: '#FBFCFD',
  },
  messageContainer: {
    borderTop: '1px solid #E6EDFF',
    width: '300px',
    position: 'fixed',
    top: '0px',
    bottom: '0px',
    overflowY: 'auto',
    borderRadius: '0px',
    backgroundColor: '#FBFCFD',
  },
  conversationsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 30px',
    borderBottom: '1px solid #E6EDFF',
    maxHeight: '65px',
  },
  conversationsTitle: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.75px',
    color: '#646D7A',
  },
});

function ConversationLeftMenu({ setConversationId, conversationId, show, setShow }) {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const {
    conversations,
    isFreeTrial,
  } = useAccount();
  const [displayedConversations, setDisplayedConversations] =
    useState(conversations || []);
  const classes = useStyles({
    isSM,
    isXS,
    showBannerValue: isFreeTrial ? 50 : 0,
  });

  useEffect(() => {
    const orderConversations = (newMessage) => {
      setDisplayedConversations((oldConversations) => {
        const updatedValue = [...oldConversations];
        if (updatedValue[0].conversationId === newMessage.conversationId) {
          return updatedValue;
        }
        const index = updatedValue.findIndex(
          (conversation) =>
            conversation.conversationId === newMessage.conversationId
        );
        const item = updatedValue.splice(index, 1)[0];
        updatedValue.splice(0, 0, item);
        return updatedValue;
      });
    };

    eventBus.on(eventBusValues.onHideMenu, (data) => {
      setShow(data.show);
    });

    eventBus.on(eventBusValues.onNewConversation, () => {
      setDisplayedConversations((oldConversations) => {
        return [...oldConversations];
      });
    });

    eventBus.on(eventBusValues.onNewMessage, (data) => {
      orderConversations(data);
    });

    eventBus.on(eventBusValues.onSendNewMessage, (data) => {
      orderConversations(data);
    });

    return () => {
      eventBus.remove(eventBusValues.onNewConversation);
      eventBus.remove(eventBusValues.onHideMenu);
      eventBus.remove(eventBusValues.onNewMessage);
      eventBus.remove(eventBusValues.onSendNewMessage);
    };
  }, []);

  if (isXS && !show) {
    return null;
  }

  return (
    <div style={classes.outerContainer}>
      <Paper sx={classes.container}>
        <Paper sx={classes.messageContainer}>
          <div style={classes.conversationsHeader}>
            <Typography sx={classes.conversationsTitle}>
              Conversations
            </Typography>
            {
              isXS && (
                <IconButton onClick={() => setShow(false)}>
                  <CloseIcon />
                </IconButton>
              )
            }
          </div>
          {displayedConversations.map((conversation) => (
            <ConversationLeftMenuItem
              key={conversation.conversationId}
              id={conversation.conversationId}
              currentConversationId={conversationId}
              updatedConvestions={displayedConversations}
              setShow={setShow}
              setConversationId={setConversationId}
            />
          ))}
        </Paper>
      </Paper>
    </div>
  );
}

export default ConversationLeftMenu;
