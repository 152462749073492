import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import {
  useMediaQuery,
  Dialog,
  DialogContent,
  Paper,
  Typography,
} from '@mui/material';
import PostFeedDivider from './postFeedDivider';
import LikesPopUp from './../LikesPopUp';
import { useAccount } from './../../../contexts/Account';
import {
  companyFetchResponsesCall,
  likePostCall,
} from './../../../services/company_services';
import { AccountType } from './../../../services/utils/types';
import ACSnackbar from './../../ACSnackbar';
import PostAndAnswer from './../Answer';
import SinglePost from './../SinglePost';
import emptyStateImage from './db_empty_state_image.svg';
import { isNil } from './../../../services/utils';
import DashboardSkeleton from '../../SkeletonLoading/dashboardSkeleton';

const useStyles = () => ({
  screenContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 'auto',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    overflow: 'auto',
  },
  listOfPosts: {
    width: '100%',
    height: '100%',
    marginBottom: '100px',
  },
  postContainer: {
    width: '100%',
    border: '1px solid rgb(230,237,254)',
    borderRadius: '10px',
    overflow: 'hidden',
    marginBottom: '30px',
  },
  noContent: {
    display: 'none',
  },
  leftSideEmptyState: {
    display: 'flex',
    flexDirection: 'column',
    width: (isSM) => (isSM ? '100%' : '40%'),
  },
  rightSideEmptyState: {
    marginTop: (isSM) => (isSM ? '20px' : ''),
    display: 'flex',
    justifyContent: 'center',
    width: (isSM) => (isSM ? '100%' : ''),
  },
  splitHelperCard: {
    padding: '20px',
    height: '415px',
    marginBottom: '15px',
    display: 'flex',
    flexDirection: (isSM) => (isSM ? 'column' : 'row'),
  },
  splitHelperCardTitle: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '27px',
    color: '#232B35',
  },
  splitHelperCardContent: {
    marginTop: '20px',
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '40px',
    color: '#232B35',
  },
  helperCardButtonContent: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '40px',
    color: '#232B35',
  },
  actionButton: {
    marginTop: '30px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '101px',
    width: '333px',
    borderRadius: '50px',
    backgroundColor: '#DBF7EB',
  },
  infinteScrollContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

function PostResponseReply({
  gotPostList,
  showHelperCard,
  multiplePosts,
  isMainCallLoading,
  incrementOffset = (f) => f,
  doNotShowBackClick,
  addPostLike = (f) => f,
  scrollOnFirstLoad,
  endpointLoading,
}) {
  const location = useLocation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();
  const { type, asLoggedUserDetails, accountDetails, isFreemium } =
    useAccount();
  const history = useHistory();
  const [postingReply, setPostingReply] = useState(false);
  const [isSinglePost, setSinglePost] = useState(false);
  const [selectedIndividualPost, setSelectedIndividualPost] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [openLikesPopUp, setOpenLikesPopUp] = useState(false);
  const isBoardMeetings = location.pathname.endsWith('/board-meetings');
  const loader = useRef(null);
  const lastFetchedPosts = useRef([]);

  const firstPostRef = useRef(null);
  const [fisrtLoad, setFirstLoad] = useState(true);

  function sentToBoardDirectory() {
    history.replace('/board-directory?db_es=true');
  }

  useEffect(() => {
    try {
      if (
        scrollOnFirstLoad &&
        gotPostList.length > 0 &&
        fisrtLoad &&
        firstPostRef.current
      ) {
        setFirstLoad(false);
        firstPostRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    } catch (error) {
      console.log('error scrolling to first post', error);
    }
  }, [endpointLoading]);

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && !isNil(incrementOffset)) {
        if (!isMainCallLoading) {
          if (
            lastFetchedPosts.current.length > 0 &&
            JSON.stringify(lastFetchedPosts.current) ===
              JSON.stringify(gotPostList)
          ) {
            return;
          }
          lastFetchedPosts.current = gotPostList;
          incrementOffset();
        }
      }
    },
    [isMainCallLoading, gotPostList]
  );

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
  }, [handleObserver, isMainCallLoading]);

  let contact = { id: undefined };

  if (type === AccountType.COMPANY) {
    contact = accountDetails.contacts.find(
      (c) => c.id === asLoggedUserDetails.userId
    );
  }

  function bodyClickAction(postInfo) {
    let path = `/post/${postInfo.id}`;
    if (postInfo.answerId) {
      history.push({
        pathname: path,
        search: `?answerId=${postInfo.answerId}`,
      });
    } else {
      history.push(path);
    }
  }

  function isMounted(id) {
    return companyFetchResponsesCall({
      ACCOUNT_TYPE:
        type === AccountType.COMPANY
          ? AccountType.CONTACT
          : AccountType.ADVISOR,
      LIMIT: 50,
      OFFSET: 0,
      ONLY_BREAKTHROUGHS: false,
      ONLY_OWN_RESPONSES: false,
      POST_ID: id,
      USER_ID: type === AccountType.COMPANY ? contact.id : accountDetails.id,
    });
  }

  function backClick() {
    if (location.pathname.endsWith('/boardroom')) {
      setSinglePost(false);
    } else if (
      type === AccountType.ADVISOR &&
      location.pathname === '/dashboard'
    ) {
      history.replace('/dashboard');
    } else {
      history.goBack();
    }
  }

  function postLikeAction(postInfo) {
    if (postInfo.postCompanyId === accountDetails.id) {
      setErrorMessage('Not allowed to like your own content');
      setShowErrorSnackbar(true);
    } else {
      if (postInfo.index || postInfo.index == 0) {
        addPostLike(postInfo);
      } else {
        addIndividualPostLike(postInfo);
      }
      likePostCall({
        ACCOUNT_TYPE:
          type === AccountType.COMPANY
            ? AccountType.CONTACT
            : AccountType.ADVISOR,
        USER_ID: asLoggedUserDetails.userId,
        POST_ID: postInfo.postId,
      });
    }
  }

  function addIndividualPostLike(likeDTO) {
    if (selectedIndividualPost) {
      if (!isAlreadyLiked(likeDTO.postLikes, likeDTO.userId)) {
        setSelectedIndividualPost({
          ...selectedIndividualPost,
          likesCount: selectedIndividualPost.likesCount + 1,
          likes: [
            ...selectedIndividualPost.likes,
            {
              accountType: likeDTO.type,
              name: likeDTO.name,
              userId: likeDTO.userId,
            },
          ],
        });
      } else {
        setSelectedIndividualPost({
          ...selectedIndividualPost,
          likesCount: selectedIndividualPost.likesCount - 1,
          likes: selectedIndividualPost.likes.filter(
            (likeElement) => likeElement.userId !== likeDTO.userId
          ),
        });
      }
    }
  }

  function isAlreadyLiked(postLikesArray, userId) {
    return (
      postLikesArray.filter((element) => element.userId === userId).length > 0
    );
  }

  function setUserName() {
    if (type === AccountType.COMPANY) {
      return `${contact.givenName} ${contact.surName}`;
    }
    return accountDetails.givenName;
  }

  function editClickAction(postIndex, postInfo) {
    history.push(`/${postInfo.type}/${postInfo.id}/edit`, {
      editingPost: postInfo,
      editingPostIndex: postIndex,
      comingFrom: window.location.pathname,
    });
  }

  function renderMultiplePostsPost({ index, post }) {
    return (
      <PostAndAnswer
        postData={post}
        isFirst={index === 0}
        dashboardFormat
        postLikesIconClick={() =>
          postLikeAction({
            postId: post.id,
            userId: asLoggedUserDetails.userId,
            companyId: type === AccountType.COMPANY && accountDetails.id,
            advisorId: type === accountDetails.id,
            postCompanyId: post.companyId,
            accountType: type,
            name: setUserName(),
            postLikes: post.likes,
            index,
          })
        }
        postLikesFilled={
          post &&
          post.likes &&
          post.likes.filter(
            (element) => element.userId === asLoggedUserDetails.userId
          ).length > 0
        }
        postAnswersFilled={
          post &&
          post.contributors &&
          post.contributors.length &&
          post.contributors.filter(
            (element) => element.id === asLoggedUserDetails.userId
          ).length > 0
        }
        postAnswersIconClick={() =>
          bodyClickAction({
            index,
            id: post.id,
            postType: post.type,
            companyName: post.postCompanyContact.companyName,
            companyId: post.companyId,
          })
        }
        postBodyClick={() =>
          bodyClickAction({
            index,
            id: post.id,
            postType: post.type,
            companyName: post.postCompanyContact.companyName,
            companyId: post.companyId,
          })
        }
        postCommentsFilled={
          post &&
          post.contributors &&
          post.contributors.length &&
          post.contributors.filter(
            (element) => element.id === asLoggedUserDetails.userId
          ).length > 0
        }
        postCommentsIconClick={() =>
          bodyClickAction({
            index,
            id: post.id,
            postType: post.type,
            companyName: post.postCompanyContact.companyName,
            companyId: post.companyId,
          })
        }
        editClick={() => editClickAction(index, post)}
        contributorsDisabled
        // ANSWER CARD LOADING
        id={post.id}
        isLoaded={isMounted}
        replyLoading={postingReply}
        // ANSWER CARD PROPS
        answerCommentsIconClick={() =>
          bodyClickAction({
            index,
            id: post.id,
            postType: post.type,
            companyName: post.postCompanyContact.companyName,
            companyId: post.companyId,
          })
        }
        answerBodyClick={(answerId) =>
          bodyClickAction({
            index,
            id: post.id,
            postType: post.type,
            companyName: post.postCompanyContact.companyName,
            answerId: answerId,
            companyId: post.companyId,
          })
        }
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{t('DASHBOARD-HELMET-TITLE')}</title>
      </Helmet>
      <Dialog
        open={openLikesPopUp}
        onClose={() => {
          setOpenLikesPopUp(false);
        }}
        aria-labelledby="likes-popUp-dialog"
        maxWidth="sm"
      >
        <DialogContent>
          <LikesPopUp />
        </DialogContent>
      </Dialog>
      <ACSnackbar
        open={showErrorSnackbar}
        severity="error"
        text={errorMessage}
        onClose={() => setShowErrorSnackbar(false)}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <div style={isSinglePost ? classes.noContent : {}}>
        {type === AccountType.COMPANY ? (
          <div style={{ marginTop: isBoardMeetings ? 0 : '30px' }} />
        ) : (
          <div style={{ marginTop: '10px' }} />
        )}
        {false && !isSinglePost && multiplePosts && (
          <PostFeedDivider
            dropdownLabel={t('SORT_DROPDOWN_LABEL')}
            dropDownItemList={[
              {
                title: t('SORT_DROPDOWN_RECENT_ACTIVITY'),
                callback: () => {
                  console.log('test');
                },
              },
              {
                title: t('SORT_DROPDOWN_DATE_POSTED'),
                callback: () => {
                  console.log('test');
                },
              },
              {
                title: t('SORT_DROPDOWN_MY_ACTIVITY'),
                callback: () => {
                  console.log('test');
                },
              },
            ]}
          />
        )}

        {type === AccountType.ADVISOR && (
          <Paper
            sx={
              gotPostList.length > 0
                ? classes.noContent
                : classes.splitHelperCard
            }
            elevation={0}
          >
            <div style={classes.leftSideEmptyState}>
              <Typography sx={classes.splitHelperCardTitle} variant="caption">
                {t('WELCOME-EMPTY-STATE')}
              </Typography>
              <Typography sx={classes.splitHelperCardContent}>
                {t('WELCOME-BODY-EMPTY-STATE')}
              </Typography>
              <div
                className="viewBoardButtonEmptyState"
                style={classes.actionButton}
                onClick={() => sentToBoardDirectory()}
              >
                <Typography sx={classes.helperCardButtonContent}>
                  {t('WELCOME-BUTTON-EMPTY-STATE')}
                </Typography>
              </div>
            </div>
            <div style={classes.rightSideEmptyState}>
              <img
                style={{ width: '585px', marginRight: '20px' }}
                src={emptyStateImage}
                alt=""
              />
            </div>
          </Paper>
        )}
      </div>
      <div
        style={
          gotPostList.length > 0 ? classes.screenContainer : classes.noContent
        }
      >
        <div style={classes.listOfPosts}>
          {isSinglePost || !multiplePosts ? (
            <SinglePost
              postData={selectedIndividualPost}
              backClick={() => backClick()}
              replyLoading={(bool) => setPostingReply(bool)}
              doNotShowBackClick={doNotShowBackClick}
              singlePostLike={() =>
                postLikeAction({
                  postId: selectedIndividualPost.id,
                  userId: asLoggedUserDetails.userId,
                  companyId: type === AccountType.COMPANY && accountDetails.id,
                  advisorId: type === accountDetails.id,
                  postCompanyId: selectedIndividualPost.companyId,
                  accountType: type,
                  name: setUserName(),
                  postLikes: selectedIndividualPost.likes,
                })
              }
              postLikesFilled={
                selectedIndividualPost &&
                selectedIndividualPost.likes &&
                selectedIndividualPost.likes.filter(
                  (element) => element.userId === asLoggedUserDetails.userId
                ).length > 0
              }
            />
          ) : null}
          {!isSinglePost && multiplePosts && gotPostList.length > 0 && (
            <div>
              {gotPostList.map((post, index) => {
                return (
                  <div
                    key={post.id}
                    style={classes.postContainer}
                    ref={index === 0 ? firstPostRef : null}
                  >
                    {renderMultiplePostsPost({ index, post })}
                  </div>
                );
              })}
            </div>
          )}

          {isMainCallLoading && <DashboardSkeleton />}

          {!isSinglePost && multiplePosts && <div ref={loader} />}
        </div>
      </div>
    </>
  );
}

export default PostResponseReply;
