import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import IndividualAdvisor from './IndividualAdvisor';
import DialogPostPopUp from './DialogPostPopUp';
import PostWrapper from './../../Post/Wrapper';
import { isNil, getAdvisorImagePath } from './../../../services/utils';
import { useAccount } from './../../../contexts/Account';
import AdvisorItem from './AdvisorItem';
import AdvisorFetched from './AdvisorFetched';
import CardSkeleton from '../../SkeletonLoading/cardSkeleton';

const useStyles = () => ({
  removed: {
    display: 'none',
  },
  infinteScrollContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function AdvisorList(props) {
  const {
    showPostWrapperContent,
    onNoAdvisorsFound,
    advisorList,
    removeBelowButtons,
    removeBio,
    buttonTitles,
    isLoading,
    individualAdvisor,
    incrementOffset,
    isMainCallLoading,
    doNotInfiniteScroll,
    requestMeetingClickAction,
    isFavoritePage,
    onClickAction,
    refresh,
    fetchAdvisor,
    setIsLoading,
    searchApplied,
    endPagination,
  } = props;
  const [openPostPopUp, setOpenPostPopUp] = useState(false);
  const classes = useStyles();
  const [postType, setPostType] = useState('');
  const [boardAdvisorsMap, setBoardAdvisorsMap] = useState();
  const [invitedAdvisorsMap, setInvitedAdvisorsMap] = useState();
  const [displayedAdvisorsCount, setDisplayedAdvisorsCount] = useState(0);
  const location = useLocation();
  const { accountDetails, tags, isFreemium } = useAccount();
  const history = useHistory();

  let advisorListFiltered = [...advisorList]; // Sin eliminar duplicados

  if (location.pathname.includes('/advisors') && !fetchAdvisor && !isFreemium) {
    if (!location.pathname.includes('/advisors/') && advisorList) {
      advisorListFiltered = advisorListFiltered.filter((advisor) => {
        return ['active', 'new'].includes(advisor.accountStatus);
      });
    }
  }

  const loader = useRef();
  const clickedDetailCardMetric =
    location.pathname !== '/advisors' &&
    location.pathname !== '/advisors/search' &&
    location.pathname !== '/advisors/search'
      ? location.hash.substring(1) || 'answer'
      : false;
  const individualAdvisorPublicUrl = clickedDetailCardMetric
    ? location.pathname.substring(10)
    : false;
  const selectedAdvisor = clickedDetailCardMetric
    ? advisorList.find(
        ({ publicProfileUrl }) =>
          publicProfileUrl === individualAdvisorPublicUrl
      )
    : false;

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting && !isNil(incrementOffset)) {
        if (
          !isMainCallLoading &&
          (isNil(doNotInfiniteScroll) || !doNotInfiniteScroll)
        ) {
          incrementOffset();
        }
      }
    },
    [isMainCallLoading]
  );
  function commentsClickAction(advisor) {
    history.push(`/advisors/${advisor.publicProfileUrl}#comment`);
  }

  function answersClickAction(advisor) {
    history.push(`/advisors/${advisor.publicProfileUrl}#answer`);
  }

  useEffect(() => {
    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 0,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);

    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [handleObserver, isMainCallLoading]);

  useEffect(() => {
    const newInvitedAdvisorsMap = {};
    const invitedAdvisorsNew = accountDetails.invitedAdvisors || [];

    for (let i = 0; i < invitedAdvisorsNew.length; i += 1) {
      newInvitedAdvisorsMap[invitedAdvisorsNew[i].id] = {
        inviteDate: invitedAdvisorsNew[i].inviteDate,
      };
    }

    setInvitedAdvisorsMap(newInvitedAdvisorsMap);
  }, [refresh]);

  useEffect(() => {
    setDisplayedAdvisorsCount(advisorListFiltered.length);
  }, [advisorListFiltered]);

  function mapSkills(skills) {
    if (!isNil(skills) && tags.skills) {
      const filteredArray = tags.skills.filter((element) =>
        skills.includes(String(element.id))
      );
      const mapSkillTag = filteredArray.map((element) => {
        return element.label;
      });
      return mapSkillTag;
    }
    return [];
  }

  if (onNoAdvisorsFound && !isLoading && advisorList.length === 0) {
    onNoAdvisorsFound();
  }

  if (!invitedAdvisorsMap) {
    const newInvitedAdvisorsMap = {};
    const { invitedAdvisors } = accountDetails;

    if (!invitedAdvisors) {
      setInvitedAdvisorsMap({});
      return null;
    }

    for (let i = 0; i < invitedAdvisors.length; i += 1) {
      newInvitedAdvisorsMap[invitedAdvisors[i].id] = {
        inviteDate: invitedAdvisors[i].inviteDate,
      };
    }

    setInvitedAdvisorsMap(newInvitedAdvisorsMap);
    return null;
  }

  if (!boardAdvisorsMap) {
    const newBoardAdvisorsMap = {};
    const { boardAdvisors } = accountDetails;

    if (!boardAdvisors) {
      setBoardAdvisorsMap({});
      return null;
    }

    for (let i = 0; i < boardAdvisors.length; i += 1) {
      newBoardAdvisorsMap[boardAdvisors[i].id] = {
        joinedOnDate: boardAdvisors[i].joinedOnDate,
      };
    }

    setBoardAdvisorsMap(newBoardAdvisorsMap);
    return null;
  }

  return (
    <>
      {openPostPopUp ? (
        <PostWrapper
          showPostWrapperContent={showPostWrapperContent}
          postWrapperType={postType}
          hidePostWrapperContent={() => {
            setOpenPostPopUp(false);
          }}
        >
          <DialogPostPopUp
            showPostPopUp
            postType={postType}
            onClose={() => {
              setOpenPostPopUp(false);
            }}
          />
        </PostWrapper>
      ) : null}
      <div
        style={
          clickedDetailCardMetric && selectedAdvisor
            ? classes.removed
            : {
                marginBottom: '72px',
              }
        }
      >
        {advisorListFiltered.length > 0 &&
          advisorListFiltered.map((advisor, index) => {
            if (advisor.id === accountDetails.id) return null;
            return (
              <div key={advisor.id}>
                {fetchAdvisor ? (
                  <AdvisorFetched
                    id={advisor.id}
                    buttonTitles={buttonTitles}
                    requestMeetingClickAction={requestMeetingClickAction}
                    answersClickAction={answersClickAction}
                    commentsClickAction={commentsClickAction}
                    individualAdvisor={individualAdvisor}
                    invitedAdvisorsMap={invitedAdvisorsMap}
                    boardAdvisorsMap={boardAdvisorsMap}
                    mapSkills={mapSkills}
                    removeBio={removeBio}
                    removeBelowButtons={removeBelowButtons}
                    onClickAction={onClickAction}
                    isFavoritePage={isFavoritePage}
                    index={index}
                    setIsLoading={setIsLoading}
                  />
                ) : (
                  <AdvisorItem
                    advisor={advisor}
                    key={advisor.id}
                    buttonTitles={
                      !isNil(buttonTitles) && !boardAdvisorsMap[advisor.id]
                        ? buttonTitles(advisor, index)
                        : null
                    }
                    // answers
                    requestMeetingClick={() => {
                      requestMeetingClickAction(advisor);
                    }}
                    answers={advisor.countAnswers ? advisor.countAnswers : 0}
                    answersClick={() => {
                      individualAdvisor
                        ? individualAdvisor.answersClickAction(advisor)
                        : answersClickAction(advisor);
                    }}
                    comments={advisor.countComments ? advisor.countComments : 0}
                    commentsClick={() => {
                      individualAdvisor
                        ? individualAdvisor.commentsClickAction(advisor)
                        : commentsClickAction(advisor);
                    }}
                    profileHighlighter={boardAdvisorsMap[advisor.id]}
                    profileHighlighterType="Advisory Board Member"
                    profileHighlighterDate={
                      boardAdvisorsMap[advisor.id]
                        ? boardAdvisorsMap[advisor.id].joinedOnDate
                        : ''
                    }
                    invitedToJoinDate={
                      invitedAdvisorsMap[advisor.id]
                        ? invitedAdvisorsMap[advisor.id].inviteDate
                        : null
                    }
                    showBottom={boardAdvisorsMap[advisor.id]}
                    advisorName={advisor.displayName}
                    advisorInfo={advisor.title}
                    advisorAvatar={getAdvisorImagePath(advisor)}
                    skills={mapSkills(advisor.skillTagIds)}
                    bio={advisor.biography ? advisor.biography : ''}
                    removeBio={removeBio || !advisor.biography}
                    advisorDetailData={advisor}
                    isLoading={isLoading}
                    isStar
                    removeBelowButtons={removeBelowButtons}
                    cardClick={() => onClickAction(advisor)}
                    isCardClickable
                    isFavoritePage={isFavoritePage}
                  />
                )}
              </div>
            );
          })}

        {!endPagination && <CardSkeleton />}
        {isMainCallLoading &&
          (isNil(doNotInfiniteScroll) || !doNotInfiniteScroll) && (
            <div style={classes.infinteScrollContainer}>
              <CardSkeleton />
            </div>
          )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '16px',
            fontWeight: 400,
            color: '#a5a5a5',
            marginTop: '20px',
          }}
        >
          Displaying {displayedAdvisorsCount} results
        </div>

        <div ref={loader} />
      </div>
      {clickedDetailCardMetric && selectedAdvisor ? (
        <IndividualAdvisor
          advisorData={selectedAdvisor}
          advisorIndex={0}
          clickActions={individualAdvisor.clickActions}
          advisorDetailCardMetric={clickedDetailCardMetric}
          onBackButtonClicked={individualAdvisor.backButtonClickAction}
          mapSkills={mapSkills}
        />
      ) : null}
    </>
  );
}
export default AdvisorList;
