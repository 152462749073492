import React from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useMediaQuery, Typography } from '@mui/material';
import { useAccount } from '../../contexts/Account';
import { ReactComponent as FreeTrial } from '../../icons/octicon_goal-24.svg';
import eventBus, { eventBusValues } from '../../eventBus';

const useStyles = (isSM, setFixedPosition) => ({
  container: {
    width: '100%',
    height: isSM ? '64px' : '50px',
    zIndex: 1000,
    position: setFixedPosition ? 'fixed' : isSM ? 'sticky' : null,
    top: 0,
  },
  fixedContainer: {
    width: '100%',
    height: isSM ? '64px' : '50px',
    backgroundColor: '#232B35',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: isSM ? '10px' : '50px',
    paddingRight: isSM ? '20px' : '70px',
    paddingTop: isSM ? '7px' : '0px',
    paddingBottom: isSM ? '7px' : '0px',
    border: 'none',
    cursor: 'pointer',
  },
  icon: {
    color: 'white',
    height: '30px',
    width: '30px',
    marginRight: '15px',
  },
  text: {
    color: 'white',
    textAlign: 'center',
    fontWeight: 700,
  },
  hide: {
    display: 'none',
  },
});

function FreeTrialBanner(props) {
  const { show, isCompanyContact } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const location = useLocation();
  const { accountDetails, type } = useAccount();
  const setFixedPosition = location.pathname === '/account';
  const classes = useStyles(isSM, setFixedPosition);
  const params = useParams();
  const history = useHistory();

  function getBannerText() {
    return "You're currently on a free trial!";
  }

  function getUnderlineText() {
    if (params.companySlug) {
      return 'Click to unlock full access and formalize your role as a board member';
    }
    return 'Click to view upgrade options and unlock full access as an advisor';
  }

  function goToUpgradePage() {
    if (type === 'company') {
      console.log('go to upgrade page');
      history.push({
        pathname: '/upgrade/b2b_ft',
        state: { fromBanner: true },
      });
    } else {
      let companyName;
      if (params.companySlug) {
        const company = accountDetails.boards.memberBoards.find(
          (company) => company.slug === params.companySlug
        );
        if (company) {
          companyName = company.companyName;
        }
      }
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction, {
        gatedAction: params.companySlug ? true : false,
        companyName: params.companySlug && companyName ? companyName : null,
        fromBanner: true,
      });
    }
  }

  function calculateDaysLeft(endDate) {
    const end = new Date(endDate);
    const now = new Date();
    const diffTime = Math.abs(end - now);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  const daysLeft = calculateDaysLeft(accountDetails.freeTrialEndsOn);

  return (
    <>
      <div style={show ? classes.container : classes.hide} id="freeTrialBanner">
        <button
          type="button"
          style={classes.fixedContainer}
          onClick={() => goToUpgradePage()}
        >
          {!isSM && <FreeTrial style={classes.icon} />}
          {type === 'company' ? (
            <>
              {daysLeft === 1 ? (
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '16.34px',
                    color: '#FFFFFF',
                  }}
                >
                  You're free trial will expire{' '}
                  <span style={{ fontWeight: 700 }}>today. </span>
                  Click this banner launch your advisory board.
                </Typography>
              ) : (
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '16.34px',
                    color: '#FFFFFF',
                  }}
                >
                  You have{' '}
                  <span style={{ fontWeight: 700 }}> {daysLeft} days left</span>{' '}
                  on your free trial. Click this banner launch your advisory
                  board.
                </Typography>
              )}
            </>
          ) : (
            <Typography variant="h3" sx={classes.text}>
              {getBannerText()}
              &nbsp;
              <span style={{ textDecoration: 'underline' }}>
                {getUnderlineText()}
              </span>
            </Typography>
          )}
        </button>
      </div>
    </>
  );
}

export default FreeTrialBanner;
