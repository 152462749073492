import React from 'react';
import { isNil } from '../services/utils/index';

export default function ConditionalComponent({
  validationBeforeCondition = (f) => f,
  fallbackBeforeCondition = (f) => f,
  conditionFunction = (f) => f,
  ValidConditionComponent,
  InvalidConditionComponent,
}) {
  if (
    isNil(validationBeforeCondition()) ||
    (!isNil(validationBeforeCondition()) && validationBeforeCondition())
  ) {
    if (conditionFunction()) {
      return <ValidConditionComponent />;
    }
    return <InvalidConditionComponent />;
  }
  return fallbackBeforeCondition();
}
