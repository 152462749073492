import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import LoadingIndicatorLogo from '../LoadingIndicatorLogo';
import queryString from 'query-string';
import { useAccount } from '../../contexts/Account';
import {
  accountStatusAdvisorSubscription,
  accountStatusCompanySubscription,
} from '../../services/Authentication';
import {
  advisorRecurlyPLanSubscription,
  companyBoardSeatsFilledSubscription,
  onCompanyChangeOnboardingStatus,
} from '../../services/subscriptions';
import {
  AccountType,
  OnboardingProcess,
  OnboardingStatus,
} from '../../services/utils/types/index';
import {
  isNil,
  redirectToWithProvider,
  getConsoleEnvironmentUrl,
} from '../../services/utils';
import { companyAllowedRoutes } from './companyAllowedRoutes';
import { advisorAllowedRoutes } from './advisorAllowedRoutes';
import { completeOnboarding } from '../../services/advisor_services';
import { updateCompanyOnBoarding } from '../../services/company_services';

function AccountValidator(props) {
  const {
    location,
    isPublic,
    // isOnboarding,
    isOnboardingCall,
    component,
    path,
    impersonateOverride,
    wantsToUpgrade,
  } = props;
  const {
    accountDetails,
    type,
    isAuthenticated,
    currentUserInfo,
    authenticate,
    isImpersonated,
    isFreeTrial,
    isFreemium,
    asLoggedUserDetails,
    completeCompanyOnboarding,
    currentPlan,
    initializeTags,
  } = useAccount();
  let userData = {};
  const Component = component;
  const callNotRequiredValuesArray = [
    OnboardingStatus.SCHEDULED,
    OnboardingStatus.COMPLETE,
    OnboardingStatus.NOT_REQUISITE,
  ];

  async function authenticateFromContext() {
    await authenticate();
    await initializeTags();
  }

  function ifOnPathReturnComponentOrRedirect(currentPath) {
    if (location.pathname === currentPath) {
      return <Component {...props} />;
    }
    return redirectToWithProvider({
      path: currentPath,
      location,
    });
  }

  useEffect(() => {
    let accountStatusSubscriptionInstance = null;
    let advisorRecurlyPlanSubscriptionInstance = null;
    let companyBoardSeatsFilledSubscriptionInstance = null;

    async function getContext() {
      await authenticateFromContext();
    }
    if (!isAuthenticated) {
      getContext();
    }
    if (isAuthenticated) {
      if (type === AccountType.COMPANY) {
        accountStatusSubscriptionInstance = accountStatusCompanySubscription({
          id: accountDetails.id,
        });
        companyBoardSeatsFilledSubscriptionInstance =
          companyBoardSeatsFilledSubscription({
            id: accountDetails.id,
          });
        onCompanyChangeOnboardingStatus({
          id: accountDetails.id,
          cb: completeCompanyOnboarding,
        });
      }
      if (type === AccountType.ADVISOR) {
        accountStatusSubscriptionInstance = accountStatusAdvisorSubscription({
          id: accountDetails.id,
        });
        advisorRecurlyPlanSubscriptionInstance = advisorRecurlyPLanSubscription(
          {
            id: accountDetails.id,
          }
        );
      }
    }
    return () => {
      if (accountStatusSubscriptionInstance !== null) {
        accountStatusSubscriptionInstance.unsubscribe();
        if (type === AccountType.ADVISOR) {
          advisorRecurlyPlanSubscriptionInstance.unsubscribe();
        }
        if (type === AccountType.COMPANY) {
          companyBoardSeatsFilledSubscriptionInstance.unsubscribe();
        }
      }
    };
  }, []);

  if (window && window.opener && window.opener.linkedinWait) {
    const parsed = queryString.parse(window.location.search);
    window.opener.linkedinSuccess = parsed;
    window.opener.linkedinWait = undefined;
  }

  if (type === AccountType.ADMIN && !isImpersonated) {
    window.location.replace(getConsoleEnvironmentUrl());
  }
  if (isAuthenticated && type === AccountType.COMPANY) {
    if (!isImpersonated) {
      userData =
        currentUserInfo && currentUserInfo.username
          ? accountDetails.contacts.find(
              (contact) => contact.id === currentUserInfo.username
            )
          : {};
    }
    userData = accountDetails.contacts.find(
      (contact) => contact.id === asLoggedUserDetails.userId
    );
  }
  let acceptTermsOfService = null;
  let onboardingProcess = null;
  if (!isNil(accountDetails) && type === AccountType.ADVISOR) {
    acceptTermsOfService = accountDetails.acceptTermsOfService;
    onboardingProcess = accountDetails.onboardingProcess;
  } else if (!isNil(userData) && type === AccountType.COMPANY) {
    acceptTermsOfService = userData.acceptTermsOfService;
    onboardingProcess = userData.onboardingProcess;
  }

  if (isPublic) {
    return <Component {...props} />;
  }

  if (!isAuthenticated) {
    authenticateFromContext();
    if (!isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            search: `?redirectUrl=${location.pathname}${location.search || ''}`,
            state: { from: location },
          }}
        />
      );
    }
  }

  if (
    (type === AccountType.ADVISOR ||
      (type === AccountType.COMPANY && userData.isBoardOwner)) &&
    !acceptTermsOfService &&
    onboardingProcess === OnboardingProcess.IN_PROCESS &&
    !isImpersonated
  ) {
    return ifOnPathReturnComponentOrRedirect(
      `/${type}/onboarding/terms-of-service`
    );
  }

  // verify onboarding process for advisor
  if (
    type === AccountType.ADVISOR &&
    onboardingProcess === OnboardingProcess.IN_PROCESS &&
    !isImpersonated
  ) {
    const onboardingRoute = '/advisor/aobf';
    if (
      accountDetails.onboardingStatus &&
      accountDetails.onboardingStatus !== 'Complete' &&
      location.pathname !== onboardingRoute
    ) {
      return redirectToWithProvider({
        path: onboardingRoute,
        location,
      });
    }
    if (accountDetails.onboardingStatus === 'Complete') {
      completeOnboarding({
        id: accountDetails.id,
      }).then(() => {
        window.location.assign(
          `${asLoggedUserDetails.advisorOnboardingUrlRedirect}`
        );
      });
      return (
        <div
          style={{
            paddingTop: '20%',
            backgroundColor: 'transparent',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
        </div>
      );
    }
  }

  if (
    impersonateOverride ||
    (type === AccountType.COMPANY &&
      onboardingProcess === OnboardingProcess.IN_PROCESS)
  ) {
    if (userData.isBoardOwner && (impersonateOverride || !isImpersonated)) {
      if (
        !isImpersonated &&
        (isNil(acceptTermsOfService) || !acceptTermsOfService)
      ) {
        return ifOnPathReturnComponentOrRedirect(
          '/company/onboarding/terms-of-service'
        );
      }

      if (!isFreemium && !isFreeTrial && currentPlan.onboardingRedirectUrl) {
        updateCompanyOnBoarding({
          CONTACT_ID: userData.id,
          ON_BOARDING_STATE: true,
          PHONE_COUNTRY_CODE: userData.phoneNumber?.countryCode || 'US',
        })
          .then(() => {
            window.location.assign(currentPlan.onboardingRedirectUrl);
          })
          .catch((error) => {
            console.error(error);
          });

        return (
          <div
            style={{
              paddingTop: '20%',
              backgroundColor: 'transparent',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <LoadingIndicatorLogo
              size={200}
              iconFontSize={93}
              iconRight={105}
            />
          </div>
        );
      }

      if (isFreemium) {
        return ifOnPathReturnComponentOrRedirect('/company/onboarding');
      }

      return ifOnPathReturnComponentOrRedirect('/company/onboarding/step1');
    }

    if (!userData.isBoardOwner) {
      return ifOnPathReturnComponentOrRedirect(
        '/company-member/onboarding/profile'
      );
    }
  }

  if (
    callNotRequiredValuesArray.includes(accountDetails.onboardingStatus) &&
    isOnboardingCall &&
    !wantsToUpgrade
  ) {
    return redirectToWithProvider({
      path: '/dashboard',
      location,
    });
  }

  if (
    (type === AccountType.COMPANY && !companyAllowedRoutes.includes(path)) ||
    (type === AccountType.ADVISOR && !advisorAllowedRoutes.includes(path))
  ) {
    return redirectToWithProvider({
      path: '/dashboard',
      location,
    });
  }

  return <Component {...props} />;
}

export default AccountValidator;
