import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import { getCompanyRecentAdvisors } from './../../../services/advisor_services';
import BoardJoinButton from '../BoardJoinButton';
import { useAccount } from './../../../contexts/Account';
import AvatarsInvolved from './../../AvatarsInvolved';

const classes = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F5F7FA',
    borderRadius: '0 105px 0 0',
  },
  topContainer: {
    color: '#232B35',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'space-between',
    border: '0px solid black',
    width: '100%',
    height: '165px',
    padding: '25px 75px 50px 50px',
  },
  bottomContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#F5F7FA',
    backdropFilter: 'blur(5px)',
    width: '100%',
    textAlign: 'center',
    color: 'white',
  },
  title: {
    display: 'inline',
    fontSize: '20px',
    color: '#232B35',
    fontWeight: 600,
  },
  subtitle: {
    fontSize: '16px',
    color: '#232B35',
    fontWeight: 400,
    lineHeight: '24px',
  },
  bottomSubtitleContainer: {
    maxWidth: '490px',
  },
  newMembersText: {
    fontSize: '14px',
    color: '#232B35',
    fontWeight: 500,
    lineHeight: '24px',
  },
  membersContainer: {
    marginTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    textAlign: 'center',
    alignSelf: 'center',
    marginBottom: '0px',
    paddingBottom: '0px',
    width: '100%',
    // opacity: 0,
    // transition: 'opacity 1s ease-in-out'
  },
  paperText: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px',
    color: '#646D7A',
  },
};

function AcPlusPreview(props) {
  const { company, companyId, companyName, companyCEO } = props;
  const { accountDetails } = useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [advisorAvatar, setAdvisorAvatar] = useState([]);
  const [opacity, setOpacity] = useState(0);

  const [isJoined, setIsJoined] = useState(
    accountDetails.boards.memberBoards.some((bc) => bc.id === companyId)
  );

  useEffect(() => {
    async function getAdvisors() {
      getCompanyRecentAdvisors({ COMPANY_ID: companyId }).then((response) => {
        const tempAdvisors = response.data.getCompanyRecentAdvisors;
        let avatarsArr = [];
        if (tempAdvisors && tempAdvisors.length && tempAdvisors.length > 1) {
          avatarsArr = tempAdvisors.map((advisor) => {
            if (advisor.image && advisor.image.imageKey) {
              return `${process.env.REACT_APP_IMAGES_URL}${advisor.image.imageKey}`;
            }
            return null;
          });
        }
        setAdvisorAvatar(avatarsArr);
      });
    }

    getAdvisors();
    setIsJoined(
      accountDetails.boards.memberBoards.some((bc) => bc.id === companyId)
    );
  }, [companyId]);

  function newestBoardMembersContainer() {
    if (advisorAvatar && advisorAvatar.length && advisorAvatar.length > 0) {
      if (isSM) {
        return (
          <div className="newestBoardMembers" style={classes.membersContainer}>
            <div>
              <Typography
                style={{ whiteSpace: 'nowrap', fontSize: 16, fontWeight: 200 }}
              >
                Newest AdvisoryCloud members
              </Typography>
            </div>
            <div style={{ marginTop: '10px' }}>
              <AvatarsInvolved
                noText
                avatars={advisorAvatar}
                avatarSize={'md'}
                limit={5}
              />
            </div>
          </div>
        );
      }
      return (
        <div className="newestBoardMembers" style={classes.container}>
          <Typography
            style={{ whiteSpace: 'nowrap' }}
            sx={classes.newMembersText}
          >
            Newest AdvisoryCloud members
          </Typography>
          <div style={{ marginTop: '10px' }}>
            <AvatarsInvolved
              noText
              avatars={advisorAvatar}
              avatarSize={'md'}
              limit={5}
            />
          </div>
        </div>
      );
    }
    return null;
  }

  return (
    <>
      <div style={classes.container}>
        <div
          style={{
            ...classes.topContainer,
            flexDirection: isSM ? 'column' : 'row',
            height: isSM ? '200px' : '165px',
          }}
        >
          <Box display="flex" alignItems="center">
            <img
              src="https://cdn.advisorycloud.com/images/about_advisory_clouds.svg"
              alt="About Advisory Clouds"
              style={{
                width: '50px',
                height: '50px',
                marginRight: '16px',
              }}
            />
            <Typography sx={classes.title}>About Advisory Clouds</Typography>
          </Box>
          {newestBoardMembersContainer()}
        </div>
        <Paper
          sx={{
            padding: '34px 24px',
            margin: '0px 48px 100px',
            boxShadow: '0px 2px 20px 0px #0000001A',
            borderRadius: '20px',
          }}
        >
          <Typography sx={classes.paperText}>
            An AdvisoryCloud allows individuals to assemble a group of advisors
            on the platform without establishing a formal Advisory Board,
            allowing more than just companies to seek your expertise on the
            platform. CXOs, VPs, and other team leaders build AdvisoryClouds to
            tap into advisors for insight on projects, key initiatives, and
            more.
          </Typography>
          <br />
          <Typography sx={classes.paperText}>
            Joining an AdvisoryCloud is another fantastic way to maximize your
            opportunities as an advisor. Once you accept this invitation, you
            will be able to collaborate with other members of this
            AdvisoryCloud, communicate directly with the owner, contribute to
            conversations, and provide insight to any questions currently posed
            to the group.
          </Typography>
          <br />
          <Typography sx={classes.paperText}>
            NOTE: Accepting this AdvisoryCloud invitation does not count toward
            your total available board seats on your current membership.
          </Typography>
        </Paper>
        {!isJoined && (
          <div
            style={{
              ...classes.bottomContainer,
              marginBottom: isSM ? '150px' : '0px',
            }}
          >
            <div style={{ transform: 'translateY(-60px)' }}>
              <Typography sx={classes.title}>
                Join this AdvisoryCloud today
              </Typography>
              {!isSM ? (
                <div style={{ marginBottom: 0 }}>
                  <BoardJoinButton
                    company={company}
                    boardId={companyId}
                    companyCEO={companyCEO}
                    staticClass="joinButtonBottom"
                    hideDeclineInvitation
                  />
                  <div style={classes.bottomSubtitleContainer}>
                    <Typography sx={classes.subtitle}>
                      Join {companyCEO.displayName}'s AdvisoryCloud to
                      contribute and collaborate as an advisor
                    </Typography>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default AcPlusPreview;
