import { useState, useEffect } from 'react';
import { useAccount } from '../contexts/Account';
import { getMessagePermission } from '../services/Backend/account';

function useCanMessage(reciverId, reciverType) {
  const { isFreeTrial, isFreemium, type, asLoggedUserDetails, isImpersonated } =
    useAccount();

  const [canMessage, setCanMessage] = useState();

  async function queryMessagePermission() {
    const response = await getMessagePermission({
      SENDER_ID: asLoggedUserDetails.userId,
      SENDER_TYPE: type,
      RECEIVER_ID: reciverId,
      RECEIVER_TYPE: reciverType,
    });
    return response && response.data && response.data.getMessagePermission;
  }

  useEffect(() => {
    let isMounted = true;
    if (isFreeTrial && !isFreemium) {
      setCanMessage(false);
    } else if (isFreemium && !isImpersonated) {
      if (reciverId) {
        queryMessagePermission()
          .then((res) => {
            if (isMounted) {
              setCanMessage(res);
            }
          })
          .catch((err) => {
            console.log(err);
            setCanMessage(false);
          });
      }
    } else {
      setCanMessage(true);
    }

    return () => {
      isMounted = false;
    };
  }, [reciverId, isFreeTrial, isFreemium]);

  return canMessage;
}
export default useCanMessage;
