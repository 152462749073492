import { Box, Typography } from "@mui/material";
import { extractUrl } from "../../utils/urlUtils";

function CurrentGoal({ goal }) {
  return (
    <Box
      id="boardroom-current-goal"
      sx={{
        bgcolor: '#F1F9FF',
        borderRadius: '8px',
        py: '5px',
        px: '10px',
        width: '100%',
        borderTop: '1px solid #4FB5F9',
        borderRight: '1px solid #4FB5F9',
        borderBottom: '1px solid #4FB5F9',
        borderLeft: '5px solid #4FB5F9',
        display: goal ? 'block' : 'none',
      }}
    >
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: { xxs: 14, xs: 16 },
          color: '#00548B',
          lineHeight: '21px',
        }}
      >
        Current Challenge or Goal:
      </Typography>
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: { xxs: 12, xs: 14 },
          color: '#00548B',
          lineHeight: { xxs: '18px', xs: '21px' },
        }}
      >
        {goal ? extractUrl(goal) : 'No current goal'}
      </Typography>
    </Box>
  );
}

export default CurrentGoal;