import React, { useState } from 'react';
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  Box,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useAccount } from '../../contexts/Account';
import LoadingLogoSkeleton from '../../components/SkeletonLoading/loadingLogoSkeleton';
import ACSnackbar from '../../components/ACSnackbar';
import CompanyLogoUploader from '../../components/Company/LogoFreemiumUploader';
import {
  companyOnboardingUpdate,
  uploadCompanyLogoCall,
  companyUploadPostCall,
  updateCompanyOnBoarding,
} from '../../services/company_services';

const styles = () => ({
  label: {
    fontSize: '13px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
    color: '#646D7A',
    marginBottom: '8px',
  },
  info: {
    fontStyle: 'italic',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '24px',
    letterSpacing: '-0.4300000071525574px',
    color: '#646D7A',
    marginBottom: '8px',
  },
  textField: {
    marginBottom: '8px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      '& fieldset': {
        borderColor: '#C7D0DE',
      },
    },
  },
  gridContainerProps: {
    container: true,
    spacing: 0,
    justifyContent: 'center',
  },
  gridItemProps: {
    item: true,
    xxs: 12,
    md: 8,
  },
});

function getCompanyNameValue(accountDetails, asLoggedUserDetails) {
  const user = accountDetails.contacts.find(
    (contact) => contact.id === asLoggedUserDetails.userId
  );

  if (
    user &&
    accountDetails.companyName !== `${user.givenName} ${user.surName}`
  ) {
    return accountDetails.companyName;
  }
  return '';
}

const CompanyOnboardingForm = () => {
  const {
    editAccountInformation,
    accountDetails,
    currentPlan,
    asLoggedUserDetails,
  } = useAccount();

  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = styles();
  const [plannedUsage, setPlannedUsage] = useState('');
  const [skill, setSkill] = useState('');
  const [firstQuestion, setFirstQuestion] = useState('');
  const [companyName, setCompanyName] = useState(
    getCompanyNameValue(accountDetails, asLoggedUserDetails)
  );
  const [uploaderOutput, setUploaderOutput] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openQuestionSnackbar, setOpenQuestionSnackbar] = useState(false);

  const plannedUsageOptions = [
    {
      label: "For my business (I'm a CEO/Founder/Owner)",
      value: 'For my organization',
    },
    { label: 'For my team at my company', value: 'For my team' },
    { label: 'For a personal side-project or my career', value: 'For myself' },
  ];

  const skillOptions = [
    { label: 'Marketing', value: 'sk_1021' },
    { label: 'Sales', value: 'sk_1083' },
    { label: 'Operations', value: 'sk_1672' },
    ,
    { label: 'HR', value: 'sk_1322' },
    { label: 'Product', value: 'sk_1343' },
    { label: 'Finance', value: 'sk_1670' },
    { label: 'General Leadership', value: 'sk_1674' },
    { label: 'Entrepreneurship', value: 'sk_921' },
    { label: 'Career', value: 'sk_1553' },
    { label: 'Other', value: 'none' },
  ];

  async function sendFormData() {
    try {
      await companyOnboardingUpdate({
        CONTACT_ID: asLoggedUserDetails.userId,
        COMPANY_NAME:
          companyName !== accountDetails.companyName ? companyName : '',
        PLANNED_USAGE: plannedUsage,
        SKILL_TAGS_IDS: skill === 'none' ? [] : [skill],
        CURRENT_GOAL: firstQuestion,
      });

      return {
        companyName: companyName,
        displayName: companyName,
        plannedUsage: plannedUsage,
      };
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
    }
  }

  async function addPost() {
    try {
      if (!firstQuestion) {
        return;
      }
      await companyUploadPostCall({
        BODY: firstQuestion,
        COMPANY_CONTACT_ID: asLoggedUserDetails.userId,
        COMPANY_ID: accountDetails.id,
        HIDE_TO_ADVISORS: false,
        HIDE_TO_CONTACTS: false,
        TYPE: 'question',
        ONBOARDING_POST: true,
      });
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
    }
  }

  async function sendOriginalLogo() {
    try {
      if (
        !uploaderOutput ||
        !uploaderOutput.image ||
        !uploaderOutput.originalImage
      ) {
        return {};
      }
      const response = await uploadCompanyLogoCall({
        CONTACT_ID: asLoggedUserDetails.userId,
        COMPANY_LOGO_EDITED: uploaderOutput.image,
        COMPANY_LOGO_ORIGINAL: uploaderOutput.originalImage,
      });
      const fixedLocation =
        response.data.uploadCompanyLogo.location.split('https://');

      return {
        image: {
          id: response.data.uploadCompanyLogo.id,
          location: fixedLocation[1],
        },
      };
    } catch (err) {
      console.log(err);
      setOpenSnackbar(true);
    }
  }

  const disableButton = () => {
    if (
      isLoading ||
      !plannedUsage ||
      !skill ||
      !companyName ||
      !firstQuestion
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (firstQuestion.length < 15) {
        setOpenQuestionSnackbar(true);
        return;
      }
      setIsLoading(true);
      await addPost();
      const result = await Promise.all([sendFormData(), sendOriginalLogo()]);
      await updateCompanyOnBoarding({
        CONTACT_ID: asLoggedUserDetails.userId,
        ON_BOARDING_STATE: true,
        PHONE_COUNTRY_CODE: accountDetails.phoneNumber?.countryCode || 'US',
      });
      let updatedInfo = { ...result[0], ...result[1] };
      if (
        updatedInfo.image &&
        updatedInfo.image.id &&
        !updatedInfo.image.id.includes('.png')
      ) {
        updatedInfo.image.id = `${updatedInfo.image.id}.png`;
      }
      editAccountInformation(updatedInfo);
      setTimeout(() => {
        window.location.assign(currentPlan.onboardingRedirectUrl);
      }, 500);
    } catch (error) {
      setIsLoading(false);
      setOpenSnackbar(true);
      console.error(error);
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      <Grid {...classes.gridContainerProps}>
        <Grid {...classes.gridItemProps}>
          <Typography sx={classes.label}>
            How are you planning on using your advisory board?
          </Typography>
        </Grid>
        <Grid {...classes.gridItemProps} mb="8px">
          <Select
            fullWidth
            value={plannedUsage}
            onChange={(e) => setPlannedUsage(e.target.value)}
            displayEmpty
            sx={{
              marginBottom: '8px',
              borderRadius: '10px',
            }}
          >
            <MenuItem value="" disabled>
              Select one
            </MenuItem>
            {plannedUsageOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid {...classes.gridItemProps}>
          <Typography sx={classes.label}>
            What types of experience would be most helpful to have on your
            advisory board?
          </Typography>
        </Grid>
        <Grid {...classes.gridItemProps} mb="8px">
          <Select
            fullWidth
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
            displayEmpty
            sx={{
              marginBottom: '8px',
              borderRadius: '10px',
            }}
          >
            <MenuItem value="" disabled>
              Select one
            </MenuItem>
            {skillOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid {...classes.gridItemProps}>
          <Typography sx={classes.label}>
            What is your biggest current challenge or goal?
          </Typography>
        </Grid>
        <Grid {...classes.gridItemProps} mb="8px">
          <TextField
            fullWidth
            placeholder="i.e. Help me get fundraising for my company. Help my team reach our Q4 goals. Give me feedback on my personal side-project or career."
            value={firstQuestion}
            onChange={(e) => setFirstQuestion(e.target.value)}
            sx={classes.textField}
            multiline
            maxRows={6}
            minRows={3}
          />
        </Grid>

        <Grid {...classes.gridItemProps}>
          <Typography sx={classes.label}>
            Enter a name for your advisory board:
          </Typography>
          <Typography sx={classes.info}>
            i.e. Parentfile Advisory Board, Marketing Advisory Board at Vibe
            Bikes, Jon Frank's Advisory Board
          </Typography>
        </Grid>
        <Grid {...classes.gridItemProps} mb="8px">
          <TextField
            fullWidth
            placeholder="ex: Acme Inc. Advisory Board"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            sx={classes.textField}
          />
        </Grid>

        <Grid {...classes.gridItemProps}>
          <Typography sx={classes.label}>
            Upload a logo, icon or picture for your advisory board:
          </Typography>
        </Grid>
        <Grid {...classes.gridItemProps} mb="8px">
          <CompanyLogoUploader
            resultFiles={setUploaderOutput}
            status={() => {}}
            imageOnly
            notPreload
          />
        </Grid>

        <Grid {...classes.gridItemProps} md={5}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={disableButton()}
            sx={{
              borderRadius: '50px',
              backgroundColor: '#3171F6',
              '&:hover': {
                backgroundColor: '#3171F6',
              },
              height: '48px',
              textTransform: 'none',
              fontSize: '16px',
              fontWeight: 700,
              mb: isSM ? '24px' : null,
            }}
          >
            {isLoading ? <LoadingLogoSkeleton /> : 'View My Advisory Board'}
          </Button>
        </Grid>
      </Grid>
      <ACSnackbar
        onClose={() => setOpenSnackbar(false)}
        open={openSnackbar}
        severity="error"
        text="Something went wrong"
      />
      <ACSnackbar
        onClose={() => setOpenQuestionSnackbar(false)}
        open={openQuestionSnackbar}
        severity="info"
        text="Your current challenge or goal must be at least 15 characters"
      />
    </Box>
  );
};

export default CompanyOnboardingForm;
