import { Dialog, DialogContent, IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useAccount } from "../contexts/Account";
import { getAdvisorQueryParams } from "../utils/queryParams";

function LeadershipFrame({ showLeadershipFrame = false, setShowLeadershipFrame = () => { } }) {
  const { accountDetails, isFreeTrial, isFreemium } = useAccount();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  return (
    <Dialog
      fullScreen={isXS}
      PaperProps={{
        sx: {
          width: isXS ? '100vw' : "800px",
          height: isXS ? '100vh' : "600px",
        }
      }}
      open={showLeadershipFrame}
      onClose={() => setShowLeadershipFrame(false)}>
      <IconButton
        onClick={() => setShowLeadershipFrame(false)}
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          zIndex: 1,
          display: { xs: 'block', md: 'none' },
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ p: 0, height: '100%', width: '100%' }}>

        <iframe
          about="Thought Leadership"
          style={{ border: 'none', width: '100%', height: '100%' }}
          src={`https://register.advisorycloud.com/platform/thought-leadership?${getAdvisorQueryParams({ accountDetails, isFreeTrial, isFreemium })}`}
          title="Thought Leadership"
        />
      </DialogContent>
    </Dialog>
  );
}

export default LeadershipFrame;