import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Typography, Grid, useMediaQuery } from '@mui/material';
import { useAccount } from '../../contexts/Account';
import PublicProfileCard from './PublicProfileCard';
import Experience from './Experience';
import LoginModal from './LoginModal';
import Tag from '../../components/Tag';
import BoardPosition from '../../components/Advisor/Profile/components/boardPosition';
import ButtonGroupBar from '../../components/Advisor/Profile/components/buttonGroupBar';
import IframeEmbedModal from '../../components/IframeEmbedModal';
import useCanMessage from '../../hooks/useMessagePermission';
import { AccountType } from '../../services/utils/types';
import eventBus, { eventBusValues } from '../../eventBus';

const useStyles = ({ isSM, isSeeMoreSelected }) => ({
  card: {
    width: '100%',
    marginBottom: '20px',
    borderRadius: '25px',
    boxShadow: '0px 8px 30px rgba(54, 118, 244, 0.1)',
    maxWidth: '1200px',
    marginTop: isSM ? '30px' : '60px',
    marginLeft: isSM ? '10px' : '5%',
    marginRight: isSM ? '10px' : '5%',
  },
  advisorPurpleDot: {
    fontWeight: 'bold',
    width: '10px',
    height: '10px',
    marginRight: '10px',
    borderColor: '#0F78FD',
    borderRadius: '10px',
    background: '#6736FA',
  },
  companyInformationSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '20px',
    width: '100%',
    paddingBottom: '40px',
    // marginLeft: '200px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  mainButton: {
    fontWeight: 700,
    width: isSM ? '90%' : '14vw',
    minWidth: '150px',
    height: isSM ? '50px' : '35px',
    textTransform: 'none',
    fontSize: isSM ? '18px' : '12px',
    marginLeft: '10px',
    borderColor: '#6736FA',
    borderRadius: '4px',
    background: '#6736FA',
    color: '#FFF',
    '&:hover': {
      background: '#6736FA',
    },
    whiteSpace: 'nowrap',
  },
  mainInformationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: isSM ? '0 20px 0 20px' : '0 60px 0 60px',
  },
  leftContainer: {
    width: '65%',
    padding: isSM ? '0 0 0 20' : '0 0 0 60px',
  },
  rightContainer: {
    width: '25%',
    padding: isSM ? '0 20px 0 0' : '0 60px 0 0',
  },
  tagsTitle: {
    fontWeight: 600,
    marginTop: '30px',
    fontSize: '20px',
    marginBottom: '15px',
  },
  advisorButtonText: {
    fontSize: '15px',
    fontWeight: 400,
  },
  experienceTitle: {
    fontWeight: 600,
    fontSize: '20px',
    marginTop: '30px',
    marginBottom: '-15px',
  },
  tagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    width: '100%',
    marginTop: isSM ? '20px' : -25,
    maxWidth: '100%',
  },
  commonSpacing: {
    marginLeft: '8px',
    marginRight: '8px',
    marginBottom: '5px',
  },
  industryAndInterestsTagsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  boardsContainer: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
  boards: {
    maxHeight: isSeeMoreSelected ? '143px' : '',
    width: '127%',
    overflowY: isSeeMoreSelected ? 'scroll' : 'hidden',
  },
  advisorButtonContainer: {
    height: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '30px',
    backgroundColor: '#F5F7FA',
    marginBottom: '20px',
  },
  seeMoreTitle: {
    color: 'primary.main',
    cursor: 'pointer',
  },
  biographyText: {
    fontWeight: 400,
    whiteSpace: 'pre-wrap',
    fontSize: '100%',
    fontSize: '16px',
    color: '#232B35',
  },
  bodyContainer: {
    display: isSM ? '' : 'flex',
    justifyContent: 'space-between',
    margin: isSM ? '0 20px' : '',
    maxWidth: isSM ? '90%' : '',
  },
  rightSideTitle: {
    marginRight: '8px',
    marginBottom: '20px',
    fontWeight: 'bold',
    marginTop: '30px',
    fontSize: '20px',
  },
});

function AdvisorPublicProfile(props) {
  let { advisor } = props;
  const { t } = useTranslation();
  const {
    tags,
    type,
    conversations,
    isAuthenticated,
  } = useAccount();
  const [iFrameSource, setIframeSource] = useState('');
  const canMessage = useCanMessage(advisor.id, AccountType.ADVISOR);
  let industries = [...tags.industries];
  const [loginModal, setLoginModal] = useState(false);
  const [isSeeMoreSelected, setIsSeeMoreSelected] = useState(false);
  const [totalBoardsCount, setTotalBoardsCount] = useState(3);
  const [displaySkills, setDisplaySkills] = useState([]);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isSM, isSeeMoreSelected });

  function boardsArray(boards) {
    let filteredArray = boards.slice(0, totalBoardsCount);
    return filteredArray.map((company) => (
      <BoardPosition
        publicProfile
        key={company.id}
        companyId={company.id}
        advisorId={advisor.id}
      />
    ));
  }

  function seeMoreActions() {
    setIsSeeMoreSelected(true);
    setTotalBoardsCount(totalBoardsCount + 5);
  }

  const advisorIndustries = advisor.industryTagIds
    ? advisor.industryTagIds
    : [];
  industries = industries
    ? industries.filter((industry) => advisorIndustries.includes(industry.id))
    : [];

  function sortExperience(a, b) {
    if (a.endDate === 'Present' && b.endDate !== 'Present') return -1;
    if (a.endDate !== 'Present' && b.endDate === 'Present') return 1;
    if (Number(a.startDate) < Number(b.startDate)) return 1;
    if (Number(a.startDate) > Number(b.startDate)) return -1;
    return 0;
  }

  const experienceSorted = advisor.experiences
    ? advisor.experiences.sort(sortExperience)
    : [];

  useEffect(() => {
    let skills = [];
    if (advisor.skillTagIds) {
      skills = [...skills, ...advisor.skillTagIds];
    }
    if (advisor.favoriteSkillsIds) {
      advisor.favoriteSkillsIds.forEach((favSkill) => {
        const fromIndex = skills.indexOf(favSkill);
        const element = skills.splice(fromIndex, 1)[0];
        skills = [element, ...skills];
      });
    }
    skills = skills.slice(0, 5);
    skills = skills.map((skillId) =>
      tags.skills.find((skill) => skillId === skill.id)
    );

    setDisplaySkills(skills);
  }, []);

  function handleConversation(event) {
    const interest = "Advisor Services";
    event?.stopPropagation();
    if (!isAuthenticated) {
      handleUnauthenticatedUser(interest);
      return;
    }
    if (!canMessage) return handleUnableToMessage();

    const conversation = conversations?.find(
      (c) => c.conversationMemberAccountId === advisor.id
    );

    dispatchMessage(interest, conversation?.conversationId);
  }

  function dispatchMessage(interest, conversationId = null) {
    eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
      senderId: advisor.id,
      senderName: advisor.displayName,
      interest,
      ...(conversationId && { conversationId }),
    });
  }

  function handleUnauthenticatedUser(actionName) {
    setIframeSource(
      `https://register.advisorycloud.com/profile/connect/?trigger=${actionName}&email=${advisor.email}&first_name=${advisor.givenName}&last_name=${advisor.surName}&sosfcontactid=${advisor.salesforceContactId}`
    );
    setLoginModal(true);
  }

  function handleUnableToMessage() {
    eventBus.dispatch(
      type === AccountType.COMPANY
        ? eventBusValues.triggerCompanyUpgradeAction
        : eventBusValues.triggerAdvisorUpgradeAction,
      {
        action: () => {
          eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
            senderId: advisor.id,
            senderName: advisor.displayName,
          });
        },
      }
    );
    return;
  }

  return (
    <>
      {!isSM ? (
        <Paper sx={classes.card}>
          <div style={classes.companyInformationSectionContainer}>
            <ButtonGroupBar
              interests={advisor?.interests}
              isPublicProfile
              isPublicProfileView
              advisor={advisor}
            />
            <div style={classes.mainInformationContainer}>
            <PublicProfileCard
                advisor={advisor}
              />

              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  fontSize: '20px',
                  marginBottom: '42px',
                }}
              >
                Skills and Expertise
              </Typography>
              <div style={classes.tagsContainer}>
                {displaySkills.map((skill, index) => (
                  <Box
                    sx={{
                      ...classes.commonSpacing,
                      marginLeft: index === 0 ? '0px' : '8px',
                    }}
                    key={index}
                  >
                    <Tag label={skill.label} publicProfile />
                  </Box>
                ))}
              </div>
            </div>
            <div style={classes.bodyContainer}>
              <div style={classes.leftContainer}>
                {advisor.services && advisor.services.length ? (
                  <div style={classes.subContainer} id="advisor-services">
                    <Typography
                      sx={{
                        fontWeight: 600,
                        marginTop: '30px',
                        fontSize: '20px',
                      }}
                    >
                      {advisor.givenName}'s Advisory Services
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: 400,
                        fontSize: '16px',
                        lineHeight: '24px',
                        letterSpacing: '-0.43px',
                        color: '#646D7A',
                        marginBottom: '11px',
                      }}
                    >
                      {advisor.givenName} is available as an advisor, or for
                      project-based work related to the following services:
                    </Typography>
                    <Grid
                      container
                      style={{
                        marginBottom: '10px',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      {advisor.services.map((service, index) => (
                        <Box
                          id={`advisor-service-${index + 1}`}
                          class="advisor-service-card"
                          key={service.id}
                          onClick={handleConversation}
                          style={{
                            border: '1px solid #EFF0F7',
                            borderRadius: '12px',
                            marginRight: isSM ? '0px' : '1rem',
                            marginBottom: isSM ? '20px' : '0px',
                            width: '330px',
                            padding: '24px',
                            margin: '14px 15px 14px 0px',
                            cursor: 'pointer',
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: '14px',
                              lineHeight: '24px',
                              letterSpacing: '-0.43px',
                              color: '#646D7A',
                              marginBottom: '11px',
                            }}
                          >
                            {service.description}
                          </Typography>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <div></div>
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '24px',
                              }}
                            >
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                              }).format(service.price)}
                            </Typography>
                          </div>
                        </Box>
                      ))}
                    </Grid>
                  </div>
                ) : null}
                <Typography variant="body1" sx={classes.tagsTitle}>
                  {t('PUBLIC-ADVISOR-BIOGRAPHY-LABEL')}
                </Typography>

                <Typography variant="body1" sx={classes.biographyText}>
                  {advisor.biography}
                </Typography>

                <Typography variant="body1" sx={classes.experienceTitle}>
                  {t('PUBLIC-ADVISOR-EXPERIENCE-LABEL')}
                </Typography>

                {experienceSorted
                  ? experienceSorted.map((experience, index) => (
                      <Experience
                        content={experience}
                        publicProfile
                        key={index}
                      />
                    ))
                  : null}
              </div>
              <div style={classes.rightContainer}>
                {advisor.interests && advisor.interests.length > 0 ? (
                  <div style={classes.boardsContainer}>
                    <Typography variant="body1" sx={classes.rightSideTitle}>
                      {t('ADVISOR-ACCOUNT-INTEREST-LABEL')}
                    </Typography>

                    <div style={classes.industryAndInterestsTagsContainer}>
                      {advisor.interests.map((interest, index) => (
                        <Box sx={classes.commonSpacing} key={index}>
                          <Tag label={interest} publicProfile />
                        </Box>
                      ))}
                    </div>
                  </div>
                ) : null}

                <div style={classes.boardsContainer}>
                  <Typography variant="body1" sx={classes.rightSideTitle}>
                    {t('PUBLIC-ADVISOR-INDUSTRY-LABEL')}
                  </Typography>

                  <div style={classes.industryAndInterestsTagsContainer}>
                    {industries.map((industry, index) => (
                      <Box sx={classes.commonSpacing} key={index}>
                        <Tag label={industry.label} publicProfile />
                      </Box>
                    ))}
                  </div>
                </div>

                <div style={classes.boardsContainer}>
                  <Typography variant="body1" sx={classes.rightSideTitle}>
                    Board Positions
                  </Typography>
                  <div style={classes.boards}>
                    {advisor.boards.memberBoards &&
                    advisor.boards.memberBoards.length ? (
                      <>{boardsArray(advisor.boards.memberBoards)}</>
                    ) : null}
                  </div>
                </div>
                {advisor.boards.memberBoards.length > totalBoardsCount && (
                  <Typography
                    onClick={() => seeMoreActions()}
                    sx={classes.seeMoreTitle}
                  >
                    {t('PUBLIC-SEE-MORE-BOARDS-LABEL')}
                  </Typography>
                )}
              </div>
            </div>
            <>
              <IframeEmbedModal
                openModal={loginModal}
                setOpenModal={setLoginModal}
                iframeEmbedLink={iFrameSource}
                width={isSM ? '100%' : '700px'}
                height="475px"
              />
            </>
          </div>
        </Paper>
      ) : (
        <Paper sx={classes.card} elevation={3}>
          <div style={classes.companyInformationSectionContainer}>
            <div style={classes.mainInformationContainer}>
              <PublicProfileCard advisor={advisor} />
            </div>
            <div style={classes.tagsContainer}>
              {displaySkills.map((skill, index) => (
                <Box sx={classes.commonSpacing} key={index}>
                  <Tag label={skill.label} publicProfile />
                </Box>
              ))}
            </div>
            <div style={classes.bodyContainer}>
              {advisor.services && advisor.services.length ? (
                <div style={classes.subContainer} id="advisor-services">
                  <Typography
                    sx={{
                      fontWeight: 600,
                      marginTop: '30px',
                      fontSize: '20px',
                    }}
                  >
                    {advisor.givenName}’s Advisory Services
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 400,
                      fontSize: '16px',
                      lineHeight: '24px',
                      letterSpacing: '-0.43px',
                      color: '#646D7A',
                      marginBottom: '11px',
                      lineBreak: 'auto',
                    }}
                  >
                    {advisor.givenName} is available as an advisor, or for
                    project-based work related to the following services:
                  </Typography>
                  <Grid
                    container
                    style={{
                      marginBottom: '10px',
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    {advisor.services.map((service, index) => (
                      <div
                        id={`advisor-service-${index + 1}`}
                        class="advisor-service-card"
                        key={service.id}
                        style={{
                          border: '1px solid #EFF0F7',
                          borderRadius: '12px',
                          marginRight: '0px',
                          marginBottom: '20px',
                          width: '330px',
                          padding: '24px',
                          margin: '14px 15px 14px 0px',
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 400,
                            fontSize: '14px',
                            lineHeight: '24px',
                            letterSpacing: '-0.43px',
                            color: '#646D7A',
                            marginBottom: '11px',
                          }}
                        >
                          {service.description}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div></div>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: '16px',
                              lineHeight: '24px',
                            }}
                          >
                            {new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency: 'USD',
                            }).format(service.price)}
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </Grid>
                </div>
              ) : null}
              <Typography variant="body1" sx={classes.tagsTitle}>
                {t('PUBLIC-ADVISOR-BIOGRAPHY-LABEL')}
              </Typography>

              <Typography variant="body1" sx={classes.biographyText}>
                {advisor.biography}
              </Typography>
              <div style={classes.boardsContainer}>
                <Typography variant="body1" sx={classes.rightSideTitle}>
                  Board Positions
                </Typography>
                <div style={classes.boards}>
                  {advisor.boards.memberBoards &&
                  advisor.boards.memberBoards.length ? (
                    <>{boardsArray(advisor.boards.memberBoards)}</>
                  ) : null}
                </div>
              </div>
              {advisor.boards.memberBoards.length > totalBoardsCount && (
                <Typography
                  onClick={() => setTotalBoardsCount(totalBoardsCount + 5)}
                  sx={classes.seeMoreTitle}
                >
                  {t('PUBLIC-SEE-MORE-BOARDS-LABEL')}
                </Typography>
              )}

              <Typography variant="body1" sx={classes.experienceTitle}>
                {t('PUBLIC-ADVISOR-EXPERIENCE-LABEL')}
              </Typography>

              {experienceSorted
                ? experienceSorted.map((experience, index) => (
                    <Experience
                      content={experience}
                      publicProfile
                      key={index}
                    />
                  ))
                : null}
            </div>
            <>
              <IframeEmbedModal
                openModal={loginModal}
                setOpenModal={setLoginModal}
                iframeEmbedLink={iFrameSource}
                width={isSM ? '100%' : '700px'}
                height="475px"
              />
            </>
          </div>
        </Paper>
      )}
    </>
  );
}

export default AdvisorPublicProfile;
