import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Typography } from '@mui/material';
import ResetPasswordForm from '../../components/CreatePasswordForm';
import CardScene from './Card';
import ErrorReset from '../ErrorReset';
import { Transitions } from '../../services/Transition';

const useStyles = () => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '448px',
    height: '336px',
    backgroundColor: 'white',
  },
  text: {
    maxWidth: '400px',
    textAlign: 'center',
    color: 'secondary.dark',
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '-0.4300000071525574px',
  },
});

const CreatePassword = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [catchedError, setCatchedError] = useState('NONE');

  function errorInfo(value) {
    if (value) {
      setCatchedError(value);
    } else {
      setCatchedError('NONE');
    }
  }

  function contentSelector() {
    if (catchedError === 'NONE') {
      return <ResetPasswordForm errorType={errorInfo} />;
    }
    if (catchedError === Transitions.Types.EXPIRED_CODE) {
      return <ErrorReset errorType={Transitions.Types.EXPIRED_CODE} />;
    }
    if (catchedError === Transitions.Types.FAIL_CODE) {
      return <ErrorReset errorType={Transitions.Types.FAIL_CODE} />;
    }
    return <></>;
  }

  function headerSelector() {
    if (catchedError === 'NONE') {
      return (
        <Typography variant="h2" color="textSecondary" sx={classes.text}>
          Please create a new password for your account.
        </Typography>
      );
    }
    if (
      catchedError === Transitions.Types.EXPIRED_CODE ||
      catchedError === Transitions.Types.FAIL_CODE
    ) {
      return (
        <Typography variant="h2" color="textSecondary" sx={classes.text}>
          {t('OOPS')}
        </Typography>
      );
    }
    return <></>;
  }

  const RenderFormWindow = () => {
    return (
      <>
        <Helmet>
          <title>{t('PASSWORD_RESET_HEADER_TITLE')}</title>
        </Helmet>
        <CardScene header={headerSelector()}>
          <div style={classes.container}>{contentSelector()}</div>
        </CardScene>
      </>
    );
  };

  return (
    <>
      <RenderFormWindow />
    </>
  );
};

export default CreatePassword;
