import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Box, Link, Typography, useMediaQuery } from '@mui/material';
import ACSnackbar from '../../components/ACSnackbar';
import { useAccount } from '../../contexts/Account';
import SinglePost from '../../components/Post/SinglePost';
import {
  getSinglePostCall,
  likePostCall,
  getCompanyInfoBoardroomMenu,
} from '../../services/company_services';
import { AccountType, PostType } from '../../services/utils/types';
import DashboardSkeleton from '../../components/SkeletonLoading/dashboardSkeleton';
import LeftSideBoardmeetingPost from './components/LeftSideBoardmeetingPost';
import RightSideBoardmeetingPost from './components/RightSideBoardmeetingPost';

const useStyles = () => ({
  container: {
    width: '100%',
  },
});

function IndividualPost() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const history = useHistory();

  const [post, setPost] = useState(false);
  const [company, setCompany] = useState({});
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const { accountDetails, asLoggedUserDetails, type, isFreemium } =
    useAccount();
  const [errorMessage, setErrorMessage] = useState('');
  const params = useParams('/question/:postId');
  let contact = { id: undefined };

  if (type === AccountType.COMPANY) {
    contact = accountDetails.contacts.find(
      (c) => c.id === asLoggedUserDetails.userId
    );
  }

  let localeHour = new Date(post?.meetingTime);
  const today = new Date();
  const [time, setTime] = useState('past');

  useEffect(() => {
    localeHour = new Date(post?.meetingTime);

    if (time == 'future' && differenceInHours(localeHour, today) < 2) {
      const interval = setInterval(() => {
        const today = new Date();
        setTime(
          localeHour > today
            ? 'future'
            : differenceInHours(today, localeHour) < 1
              ? 'live'
              : 'past'
        );
      }, 60 * 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [post, time]);

  function differenceInHours(dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return diff;
  }

  useEffect(() => {
    getSinglePostCall({
      ACCOUNT_TYPE:
        type === AccountType.COMPANY
          ? AccountType.CONTACT
          : AccountType.ADVISOR,
      USER_ID: type === AccountType.COMPANY ? contact.id : accountDetails.id,
      POST_ID: params.postId,
    })
      .then(function (resp) {
        setPost(resp.data.getPost);
        console.log(resp.data.getPost)
        localeHour = new Date(resp?.data?.getPost?.meetingTime);
        setTime(
          localeHour > today
            ? 'future'
            : differenceInHours(today, localeHour) < 1
              ? 'live'
              : 'past'
        );
        getCompanyInfoBoardroomMenu({
          companyId: resp.data.getPost.companyId,
        }).then((response) => {
          if (response.data.getCompany) {
            setCompany(response.data.getCompany);
          }
        });
      })
      .catch((error) => {
        console.log('Error fetching post', error);
        setErrorMessage('Error fetching post');
        setShowErrorSnackbar(true);
        setTimeout(() => {
          const unauthorizedError = JSON.parse(
            error.errors.find(
              (error) => JSON.parse(error.message).code === 'Unauthorized'
            ).message
          );
          if (unauthorizedError) {
            history.push({
              pathname: '/board-directory',
              state: { companyId: unauthorizedError.companyId },
            });
          }
        }, 2000);
      });
  }, []);

  function setUserName() {
    if (type === AccountType.COMPANY) {
      return `${contact.givenName} ${contact.surName}`;
    }
    return accountDetails.givenName;
  }

  function postLikeAction(postInfo) {
    if (postInfo.postCompanyId === accountDetails.id) {
      setErrorMessage('Not allowed to like your own content');
      setShowErrorSnackbar(true);
    } else {
      addPostLike(postInfo);
      likePostCall({
        ACCOUNT_TYPE:
          type === AccountType.COMPANY
            ? AccountType.CONTACT
            : AccountType.ADVISOR,
        USER_ID: asLoggedUserDetails.userId,
        POST_ID: postInfo.postId,
      });
    }
  }

  function isAlreadyLiked(postLikesArray, userId) {
    return (
      postLikesArray.filter((element) => element.userId === userId).length > 0
    );
  }

  function addPostLike(likeDTO) {
    if (post) {
      if (!isAlreadyLiked(likeDTO.postLikes, likeDTO.userId)) {
        setPost({
          ...post,
          likesCount: post.likesCount + 1,
          likes: [
            ...post.likes,
            {
              accountType: likeDTO.type,
              name: likeDTO.name,
              userId: likeDTO.userId,
            },
          ],
        });
      } else {
        setPost({
          ...post,
          likesCount: post.likesCount - 1,
          likes: post.likes.filter(
            (likeElement) => likeElement.userId !== likeDTO.userId
          ),
        });
      }
    }
  }

  function LegacySinglePost() {
    return (
      <SinglePost
        postData={post}
        replyLoading={() => { }}
        doNotShowBackClick={true}
        singlePostLike={() =>
          postLikeAction({
            postId: post.id,
            userId:
              type === AccountType.COMPANY ? contact.id : accountDetails.id,
            companyId: type === AccountType.COMPANY && accountDetails.id,
            advisorId: type === accountDetails.id,
            postCompanyId: post.companyId,
            accountType: type,
            name: setUserName(),
            postLikes: post.likes,
          })
        }
        postLikesFilled={
          post.likes.filter(
            (element) => element.userId === asLoggedUserDetails.userId
          ).length > 0
        }
      />
    );
  }

  function BoardMeetingSinglePost() {
    return post.type !== PostType.BOARD_MEETING ? (
      <LegacySinglePost />
    ) : (
      <>
        <Typography
          onClick={() => {
            history.push(`/${company.slug}/boardroom`);
          }}
          sx={{
            fontSize: '14px',
            fontWeight: 400,
            marginBlock: '10px',
            cursor: 'pointer',
          }}
        >
          <Link>{`${post.postCompanyContact.companyName} Boardroom`}</Link> {`> Board Meeting`}
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: !isSM ? 'row' : 'column',
            columnGap: '24px',
          }}
        >
          <LeftSideBoardmeetingPost post={post} time={time} />
          <RightSideBoardmeetingPost post={post} time={time} />
        </Box>
      </>
    );
  }

  return (
    <>
      <ACSnackbar
        open={showErrorSnackbar}
        severity="error"
        text={errorMessage}
        onClose={() => setShowErrorSnackbar(false)}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <div
        style={{
          ...classes.container,
          paddingInline:
            isSM && post.type === PostType.BOARD_MEETING ? '2px' : '24px',
        }}
      >
        {post ? (
          <BoardMeetingSinglePost />
        ) : (
          <Box sx={{ width: { xxs: '100%', sm: '80%' } }}>
            <DashboardSkeleton />
          </Box>
        )}
      </div>
    </>
  );
}

export default IndividualPost;
