import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Grid, Typography } from '@mui/material';
import BoardTile from './BoardTile';
import CurrentAdvisorsTile from './CurrentAdvisorsTile';
import eventBus, { eventBusValues } from './../../eventBus';
import { ReactComponent as PaperPlusIcon } from './../../icons/paper-plus.svg';
import { ReactComponent as CalendarCheckIcon } from './../../icons/calendar-check.svg';
import { ReactComponent as BooksIcon } from './../../icons/books.svg';
import { getCompanyBySlug } from '../../services/company_services';
import { useAccount } from './../../contexts/Account';
import BoardroomHeader from './BoardroomHeader';
import AddToResumeDialog from './AddToResumeDialog';
import Meetings from './Meetings';
import MeetingsLegacy from './MeetingsLegacy';
import { FirstViewModal } from './FirstViewModal';
import { addBoardView } from '../../services/advisor_services';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';
import { getAdvisorQueryParams } from './../../utils/queryParams';
import UpsellDialog from './UpsellDialog';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';

const tileTitleStyle = {
  fontSize: { xxs: 16, xs: 20 },
  lineHeight: { xxs: '20px', xs: '26px' },
  fontWeight: '600',
  marginBottom: { xxs: '7px', xs: '14px' },
  textAlign: 'left',
};

const tileDescriptionStyle = {
  fontSize: 12,
  lineHeight: '18px',
  fontWeight: '400',
  color: '#4D586F',
  textAlign: 'left',
};

export default function Boardroom() {
  const postContainerRef = useRef(null);
  const params = useParams('/:companySlug/');
  const { companySlug } = params;
  const [company, setCompany] = useState({});
  const [memberBoard, setMemberBoard] = useState({});
  const [showBoardMeeting, setShowBoardMeeting] = useState(false);
  const [addToResumeDialogOpen, setAddToResumeDialogOpen] = useState(false);
  const { accountDetails, currentPlan, isFreeTrial, isFreemium, currentUserInfo } = useAccount();
  const { specialOffersUrl } = currentPlan;
  const finalUrl = `${specialOffersUrl}?${getAdvisorQueryParams({
    accountDetails,
    isFreeTrial,
    isFreemium,
  })}`;
  const [isFirstView, setIsFirstView] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const memoizedCompany = useMemo(() => company, [company]);
  const memoizedPostContainerRef = useRef(postContainerRef);
  const history = useHistory();

  const activeSortedBoardAdvisors = useMemo(() => {
    if (!company || !company.boardAdvisors) return [];
    return company?.boardAdvisors
      ?.filter(
        (advisor) =>
          advisor.advisorStatus === 'active' || advisor.advisorStatus === 'new'
      )
      .sort((a, b) => b.joinedOnDate.localeCompare(a.joinedOnDate));
  }, [company]);

  useEffect(() => {
    if (!company.id || !accountDetails) return;
    const isJoined =
      company?.boardAdvisors?.some(
        (boardAdvisor) => boardAdvisor.id === accountDetails.id
      ) || false;

    if (!isJoined) {
      history.push({
        pathname: '/board-directory',
        state: { companyId: company.id },
      });
    }
  }, [company]);

  useEffect(async () => {
    try {
      const { data } = await getCompanyBySlug({ slug: companySlug });
      if (data && data.getCompany) {
        setInitialLoad(false);
        setCompany(data.getCompany);
        const memberBoard = accountDetails.boards.memberBoards.find(
          (board) => board.id === data.getCompany.id
        );
        if (memberBoard) {
          setMemberBoard(memberBoard);
        }
      }
    } catch (error) {
      // TODO: Handle error
      console.error({ error });
      setInitialLoad(false);
    }
  }, []);

  function addToResume() {
    if (isFreeTrial) {
      // window.location.replace(
      //   `${accountDetails.upgradeUrl}?iwm_advisor_id=${accountDetails.iwmId}&sosfcontactid=${accountDetails.salesforceContactId}&email=${accountDetails.email}&plancode=${accountDetails.recurlyPlanCode}`
      // );
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction);
    } else {
      setAddToResumeDialogOpen(true);
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  //useEffect to check if the board has been viewed before
  useEffect(() => {
    if (!company.id || !accountDetails) return;
    if (
      !accountDetails.viewedBoards ||
      (accountDetails.viewedBoards &&
        !accountDetails.viewedBoards.includes(company.id))
    ) {
      setIsFirstView(true);
    }

    if (isFirstView) {
      try {
        addBoardView({
          ADVISOR_ID: accountDetails.id,
          COMPANY_ID: company.id,
        });
        accountDetails.viewedBoards.push(company.id);
      } catch (error) {
        console.error('Error: ', error);
      }
    }
  }, [company, isFirstView]);

  function seeOpportunity(companyId) {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      companyId,
    });
  }

  if (initialLoad) {
    return (
      <div
        style={{
          alignItems: 'center',
          backgroundColor: '#FFF',
          bottom: '0px',
          display: 'flex',
          justifyContent: 'center',
          left: '0px',
          position: 'fixed',
          right: '0px',
          top: '0px',
        }}
      >
        <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
      </div>
    );
  }

  return (
    <Box sx={{ p: { xxs: 0, xs: 2 } }}>
      <Grid container>
        <Box sx={{ p: 2 }}>
          <BoardroomHeader
            company={company}
            setIsLoading={setIsLoading}
            seeOpportunity={seeOpportunity}
          />
          <Grid container spacing={2}>
            <BoardTile
              image={<CalendarCheckIcon style={{ width: 60, height: 60 }} />}
              onClick={() => seeOpportunity(company?.id)}
            >
              <Typography sx={tileTitleStyle}>Get Up to Speed</Typography>
              <Typography sx={tileDescriptionStyle} ref={postContainerRef}>
                Learn more about the company, mission and management team.
              </Typography>
            </BoardTile>
            <BoardTile
              image={<BooksIcon style={{ width: 90, height: 90 }} />}
              onClick={() => setShowBoardMeeting(!showBoardMeeting)}
            >
              <Typography sx={tileTitleStyle}>Board Meetings</Typography>
              <Typography sx={tileDescriptionStyle}>
                View any current or previous board meetings from this company.
              </Typography>
            </BoardTile>

            <BoardTile
              image={<PaperPlusIcon style={{ width: 60, height: 60 }} />}
              onClick={addToResume}
            >
              <Typography sx={tileTitleStyle}>Add to Resume</Typography>
              <Typography sx={tileDescriptionStyle}>
                Get the approved wording for how to add this advisory board to
                your resume.
              </Typography>
            </BoardTile>

            <Grid item xxs={12} xs={6} md={3}>
              <CurrentAdvisorsTile
                tileTitleStyle={tileTitleStyle}
                tileDescriptionStyle={tileDescriptionStyle}
                boardMembers={activeSortedBoardAdvisors}
                setOpenDialog={setOpenDialog}
              />
            </Grid>
          </Grid>
          {showBoardMeeting && company && company.id && (
            <Meetings
              company={memoizedCompany}
              postContainerRef={memoizedPostContainerRef.current}
              scrollOnFirstLoad
            />

          )}
          <AddToResumeDialog
            open={addToResumeDialogOpen}
            onClose={() => setAddToResumeDialogOpen(false)}
            company={company}
            memberBoard={memberBoard}
          />
          <UpsellDialog
            open={openDialog}
            onClose={handleCloseDialog}
            finalUrl={finalUrl}
          />
          {isFirstView && company ? (
            <FirstViewModal companyBoardData={company} />
          ) : null}
          {isLoading && (
            <Box
              sx={{
                alignItems: 'center',
                bottom: '0px',
                display: 'flex',
                justifyContent: 'center',
                left: '0px',
                position: 'fixed',
                right: '0px',
                top: '0px',
                zIndex: 9999,
              }}
            >
              <LoadingIndicatorLogo
                size={200}
                iconFontSize={93}
                iconRight={105}
              />
            </Box>
          )}
        </Box>
      </Grid>
    </Box>
  );
}
