import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useAccount } from '../../contexts/Account';
import { AccountType } from '../../services/utils/types';
import LoadingLogoSkeleton from '../SkeletonLoading/loadingLogoSkeleton';
import { cancelAdvisorFreeTrialMembership } from '../../services/advisor_services';
import ACSnackbar from '../ACSnackbar';

const styles = {
  button: {
    width: '100%',
    height: '50px',
    marginBottom: '12px',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '20px',
  },
};

const FreeTrialCancelationPopUp = () => {
  const { type, isFreeTrial, isFreemium, accountDetails } = useAccount();
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const location = useLocation();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'))

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const onClose = () => {
    if (loading) {
      return;
    }
    setOpen(false);
  };

  const cancelMembership = async () => {
    setLoading(true);
    try {
      await cancelAdvisorFreeTrialMembership({
        advisorId: accountDetails.id,
      });
      window.location.href =
        'https://register.advisorycloud.com/deactivate-free-trial-cxl-confirmation/';
    } catch (error) {
      setLoading(false);
      setIsSnackbarVisible(true);
    }
  };

  useEffect(() => {
    if (location && location.search) {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.get('cxl_request') === 'true') {
        setOpen(true);
      }
    }
  }, []);

  if (type !== AccountType.ADVISOR || !isFreeTrial || isFreemium) return null;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={isXS}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: isXS ? null : '560px',
          padding: '12px',
          borderRadius: isXS ? null :'8px',
        },
      }}
    >
      <ACSnackbar
        text="Error: there was a problem processing your request. Please reach out to customer service to finalize your cancellation."
        onClose={() => setIsSnackbarVisible(false)}
        open={isSnackbarVisible}
        severity="error"
        variant="filled"
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <Box width="100%" display="flex" alignItems="self-end" paddingLeft="24px">
        <Box
          sx={{
            backgroundColor: '#2B71F6',
            width: '100%',
            height: '4px',
          }}
        />
        <IconButton
          onClick={onClose}
          sx={{
            padding: '0px 0px 16px 0px',
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogTitle
        sx={{
          color: '#001030',
          fontWeight: 600,
          fontSize: '32px',
          lineHeight: '130%',
          letterSpacing: '0%',
        }}
      >
        We'll be sad to see you go
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            color: '#4D586F',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '150%',
            letterSpacing: '0%',
            marginBottom: '14px',
          }}
        >
          If you choose to you cancel your membership, your account will be
          immediately terminated, you will be logged out of AdvisoryCloud and
          lose all access to your advisor profile and any boards you have
          joined.
        </Typography>
        <Typography
          sx={{
            color: '#001030',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '130%',
            letterSpacing: '0%',
            marginBottom: '8px',
          }}
        >
          Are you sure you want to continue?
        </Typography>
        <FormControlLabel
          disabled={loading}
          id="cancelTermsCheckbox"
          sx={{
            marginBottom: '16px',
          }}
          control={
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              name="confirmCancel"
              color="primary"
            />
          }
          label={
            <Typography
              sx={{
                color: '#4D586F',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '150%',
                letterSpacing: '0%',
              }}
            >
              I agree to these cancellation terms
            </Typography>
          }
        />
        <Button
          id="terminateMembershipButton"
          onClick={cancelMembership}
          color="primary"
          disabled={!checked || loading}
          variant="contained"
          sx={styles.button}
        >
          {loading ? <LoadingLogoSkeleton /> : 'Terminate'}
        </Button>
        <Button
          id="neverMindButton"
          onClick={onClose}
          variant="white"
          sx={styles.button}
          disabled={loading}
        >
          Never mind
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default FreeTrialCancelationPopUp;
