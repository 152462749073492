export const companyAllowedRoutes = [
  '/dashboard',
  '/account',
  '/board-meetings',
  '/post/:postId?',
  '/advisors',
  '/advisors/search',
  '/advisors/saved',
  '/advisors/top_matches',
  '/advisors/:publicProfileUrl',
  '/advisor_ideas',
  '/company/onboarding/terms-of-service',
  '/company/onboarding/step1',
  '/company/onboarding/step2',
  '/company/onboarding/call',
  '/company/onboarding',
  '/company-member/onboarding/profile',
  '/onboarding/call/upgrade',
  '/company-member/welcome',
];
