import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Avatar, Button, IconButton, Badge } from '@mui/material';
import AdvisoryCloudLogo from '../AdvisoryCloudLogo2';
import { useAccount } from '../../contexts/Account';
import CalendarSyncMenu from '../CalendarSyncMenu';
import MobileMenu from './MobileMenu';
import DefaultAdvisorAvatar from '../../icons/user-avatar.svg';
import ProfileMenu from './ProfileMenu';
import { getAdvisorQueryParams } from '../../utils/queryParams';
import { SnackbarType } from '../../services/utils/types';
import ACSnackbar from '../ACSnackbar';
import LoadingLogoSkeleton from '../SkeletonLoading/loadingLogoSkeleton';
import UpsellBanner from './UpsellBanner';
import HoverMenuItem from '../HoverMenuItem';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import eventBus, { eventBusValues } from '../../eventBus';
import {
  closeChild,
  getAccessToken,
  isLinkedinAuthenticated,
} from '../../services/LinkedIn';
import LinkedInPostModal from '../LinkedInPostModal';
// import IFrameDrawer from '../IFrameDrawer';

const TopBar = () => {
  const history = useHistory();
  const { accountDetails, signOut, isFreeTrial, isFreemium, conversations } =
    useAccount();
  function getUnreadMessages() {
    let totalUnread = 0;
    conversations.forEach((conv) => {
      totalUnread += conv.unreadCount;
    });
    return totalUnread;
  }
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [calendarAnchorEl, setCalendarAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [snackSeverity, setSnackSeverity] = useState(SnackbarType.SUCCESS);
  const [googleSync, setGoogleSync] = useState(
    accountDetails?.calendarTokens?.google || false
  );
  const [appleSync, setAppleSync] = useState(
    accountDetails?.calendarTokens?.apple || false
  );
  // const [openIFrameDrawer, setOpenIFrameDrawer] = useState(false);
  // const [iframeUrl, setIframeUrl] = useState('');
  const [linkedInPostModal, setLinkedInPostModal] = useState(false);

  const { pathname, search } = useLocation();
  const fullPath = `${pathname}${search}`;
  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const showSnackbar = (message, severity = SnackbarType.SUCCESS) => {
    setSnackMessage(message);
    setSnackSeverity(severity);
    setSnackbarOpen(true);
  };

  const redirectWithParams = (url) => {
    window.location.assign(
      `${url}?${getAdvisorQueryParams({
        accountDetails,
        isFreeTrial,
        isFreemium,
      })}`
    );
  };

  function navigateToPersonalAssistance() {
    redirectWithParams(
      'https://register.advisorycloud.com/platform/engagement-call/'
    );
  }

  const menuItems = [
    { label: 'Dashboard', to: '/dashboard', id: 'menu-dashboard' },
    {
      label: 'Advisor Profile',
      to: '',
      id: 'menu-professional-brand',
      onClick: () => {
        eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
          ...accountDetails,
          openInEdit: false,
        });
      },
    },
    {
      label: 'Join Advisory Boards',
      to: '/board-directory',
      id: 'menu-join-advisory-boards',
      containerWidth: '220px',
      leftOptionsPosition: 54,
      paths: ['/board-directory', '/peer-directory'],
      mainOnClick: () => {
        history.push('/board-directory');
      },
      options: [
        {
          label: 'Startups',
          id: 'submenu-startups',
          closeOnClick: true,
          onClick: () => {
            history.push('/board-directory?keyword=startup');
          },
        },
        {
          label: 'Private Companies',
          id: 'submenu-private-companies',
          closeOnClick: true,
          onClick: () => {
            history.push('/board-directory');
          },
        },
        {
          label: 'International Companies',
          id: 'submenu-international-companies',
          closeOnClick: true,
          onClick: () => {
            history.push('/board-directory?keyword=international');
          },
        },
        {
          label: 'Non-Profits',
          id: 'submenu-non-profits',
          closeOnClick: true,
          onClick: () => {
            history.push('/board-directory?keyword=non-profit');
          },
        },
        {
          label: 'Peer Advisory Boards',
          id: 'submenu-peer-advisory-boards',
          closeOnClick: true,
          onClick: () => {
            history.push('/peer-directory');
          },
        },
      ],
    },
    {
      label: 'Tools & Resources',
      id: 'menu-tools-resources',
      to: '/tools-resources',
    },
  ];

  const menuItemStyle = {
    textDecoration: 'none',
    fontSize: '14px',
    fontWeight: '500',
    padding: '5px 18px',
    borderRadius: '8px',
  };

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileAnchorEl(null);
  };

  const handleLogout = () => {
    handleProfileMenuClose();
    if (typeof signOut === 'function') {
      signOut();
    } else {
      console.log('signOut function is not provided');
    }
  };

  async function linkedInResponse(linkedinResponse) {
    if (linkedinResponse === 201) {
      showSnackbar('Your profile is shared successfully');
    } else if (linkedinResponse === 422) {
      showSnackbar(
        'You have already posted this content, either update the post or try again in 10 minutes',
        SnackbarType.ERROR
      );
    } else {
      showSnackbar(
        'An error occurred while sharing your profile',
        SnackbarType.ERROR
      );
    }
  }

  useEffect(() => {
    window.linkedinWait = true;

    const interval = setInterval(() => {
      if (window.linkedinSuccess) {
        clearInterval(interval);
        closeChild();
        getAccessToken(window.linkedinSuccess);
        setLinkedInPostModal(true);
      }
    }, 600);

    if (isLinkedinAuthenticated()) {
      window.linkedinWait = undefined;
      clearInterval(interval);
    }
    return () => {
      window.linkedinWait = undefined;
      clearInterval(interval);
    };
  }, []);

  function MenuOptions() {
    return (
      <>
        {menuItems.map((item, index) => {
          if (item.options) {
            return (
              <HoverMenuItem
                key={index}
                label={item.label}
                id={item.id}
                options={item.options}
                paths={item.paths}
                menuItemStyle={menuItemStyle}
                closeOnClick={item.closeOnClick}
                containerWidth={item.containerWidth}
                leftOptionsPosition={item.leftOptionsPosition}
                mainOnClick={item.mainOnClick}
              />
            );
          }
          const isActive = fullPath === item.to;
          if (item.onClick) {
            return (
              <Button
                id={item.id}
                key={index}
                onClick={item.onClick}
                sx={{
                  ...menuItemStyle,
                  color: isActive ? '#2B71F6' : '#2E3B56',
                  backgroundColor: isActive ? '#F4F8FF' : 'transparent',
                  cursor: 'pointer',
                  background: 'none',
                  border: 'none',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
              >
                {item.label}
              </Button>
            );
          }

          return (
            <NavLink
              id={item.id}
              key={index}
              to={item.to || '#'}
              style={{
                ...menuItemStyle,
                color: isActive ? '#2B71F6' : '#2E3B56',
                backgroundColor: isActive ? '#F4F8FF' : 'transparent',
              }}
            >
              {item.label}
            </NavLink>
          );
        })}
      </>
    );
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        zIndex: 1200,
        width: '100%',
        backgroundColor: '#fff',
        overscrollBehavior: 'contain',
      }}
    >
      <UpsellBanner />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: { xxs: '10px 3px 10px 5px', sm: '10px 25px' },
          backgroundColor: '#fff',
          borderBottom: '1px solid #ddd',
          height: 60,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AdvisoryCloudLogo
            height={'27px'}
            removeHyperlink
            onClick={() => {
              history.push(`/dashboard`);
            }}
            cursor="default"
          />
        </Box>

        <Box
          sx={{
            display: 'none',
            '@media (min-width: 944px)': {
              display: 'flex',
            },
            alignItems: 'center',
          }}
        >
          <MenuOptions />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          {/* DO NOT DELETE, WILL BE REUSED WITH NEW INFO */}
          {/* Also, remove the temporal padding from src/components/TopBar/UpsellBanner.jsx when re use the button */}
          {/* <IconButton
            sx={{
              display: 'flex',
              '@media (min-width: 1110px)': {
                display: 'none',
              },
              color: '#2563eb',
            }}
            onClick={navigateToPersonalAssistance}
          >
            <PhoneInTalkOutlinedIcon />
          </IconButton>
          <Button
            variant="outlined"
            startIcon={<PhoneInTalkOutlinedIcon />}
            id="sync-calendar-button"
            disabled={isLoading}
            sx={{
              display: 'none',
              '@media (min-width: 1110px)': {
                display: 'flex',
              },
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: '600',
              padding: '0',
              borderRadius: '4px',
              borderColor: '#2B71F6',
              color: '#2B71F6',
              width: 220,
              height: 32,
              '&:hover': {
                borderColor: '#1e3a8a',
                backgroundColor: '#f1f5ff',
              },
            }}
            onClick={navigateToPersonalAssistance}
          >
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <LoadingLogoSkeleton />
              </div>
            ) : (
              'Personal Assistance'
            )}
          </Button> */}

          <Badge
            color="error"
            variant="dot"
            invisible={getUnreadMessages() <= 0}
            sx={{
              '& .MuiBadge-badge': {
                top: '85%',
                right: '15%',
                transform: 'translate(50%, -50%)',
              },
            }}
          >
            <Avatar
              src={
                accountDetails.image?.imageKey
                  ? `${process.env.REACT_APP_IMAGES_URL}${accountDetails.image.imageKey}`
                  : DefaultAdvisorAvatar
              }
              alt={accountDetails.displayName}
              sx={{
                width: 32,
                height: 32,
                cursor: 'pointer',
                display: { xxs: 'none', md: 'block' },
              }}
              onClick={handleProfileMenuOpen}
            />
          </Badge>
          <IconButton
            id="menu-icon-button"
            onClick={toggleDrawer(true)}
            sx={{ display: { xs: 'block', md: 'none' }, color: '#2563eb' }}
          >
            <MenuIcon />
          </IconButton>

          <MobileMenu
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            menuItems={menuItems}
            pathname={pathname}
          />
        </Box>

        <ProfileMenu
          profileAnchorEl={profileAnchorEl}
          handleProfileMenuClose={handleProfileMenuClose}
          handleLogout={handleLogout}
          accountDetails={accountDetails}
        />

        <CalendarSyncMenu
          anchorEl={calendarAnchorEl}
          setAnchorEl={setCalendarAnchorEl}
          syncStates={{
            googleSync,
            appleSync,
          }}
          setSyncStates={{
            setGoogleSync,
            setAppleSync,
          }}
          setIsLoading={setIsLoading}
          showSnackbar={showSnackbar}
        />
        <ACSnackbar
          style={{ marginTop: isFreemium ? '50px' : '0px' }}
          open={snackbarOpen}
          text={snackMessage}
          severity={snackSeverity}
          onClose={() => {
            setSnackbarOpen(false);
          }}
          autoHideDuration={5000}
        />
      </Box>
      {/* <IFrameDrawer
        open={openIFrameDrawer}
        setOpen={setOpenIFrameDrawer}
        url={iframeUrl}
        title="FAQs"
      /> */}
      {linkedInPostModal ? (
        <>
          <LinkedInPostModal
            linkedInPostModal={linkedInPostModal}
            setLinkedInPostModal={setLinkedInPostModal}
            linkedInResponse={linkedInResponse}
            fontFamily={'Poppins'}
            shareProfile
          />
        </>
      ) : null}
    </Box>
  );
};

export default TopBar;
