import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, useMediaQuery } from '@mui/material';
import LoadingIndicatorLogo from '../../LoadingIndicatorLogo';
import ACSnackbar from '../../ACSnackbar';
import { useAccount } from '../../../contexts/Account';
import {
  requestJoinBoard,
  respondBoardInvitation,
} from '../../../services/advisor_services';
import RequestModal from './RequestModal';
import NoMoreBoardSeats from './NoMoreBoardSeats';
import eventBus, { eventBusValues } from '../../../eventBus';
import { AccountType } from '../../../services/utils/types';
import useCanMessage from '../../../hooks/useMessagePermission';
import { sendFSEventInviteViewed } from '../../../services/FullStory';
import useAdvisorCanJoin from '../../../hooks/useAdvisorCanJoin';
import {
  addBoardRoomAppleCalendar,
  addBoardRoomGoogleCalendar,
} from '../../../services/company_services';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  mainButton: {
    fontWeight: 700,
    width: isSM ? '90%' : '14vw',
    minWidth: '150px',
    height: isSM ? '55px' : '45px',
    marginBottom: isSM ? '10px' : '20px',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: '15px',
    borderRadius: '40px',
    backgroundColor: '#6736FA',
    borderColor: '#6736FA',
    color: '#FFF',
    '&:hover': {
      background: '#6736FA',
    },
    '&:disabled': {
      backgroundColor: '#FFF',
      fontFamily: 'Poppins',
      border: '2px solid #6736FA',
      color: '#6736FA !important',
      borderColor: '#6736FA !important',
    },
    whiteSpace: 'nowrap',
  },
  declineMainButton: {
    fontWeight: 700,
    width: isSM ? '90%' : '14vw',
    minWidth: '150px',
    height: isSM ? '55px' : '45px',
    marginBottom: isSM ? '10px' : '20px',
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontSize: '15px',
    background: '#FFF',
    borderColor: '#0F78FD',
    borderRadius: '40px',
    color: '#0F78FD',
    marginLeft: isSM ? '0px' : '15px',
    whiteSpace: 'nowrap',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: isSM ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: isSM ? 'inherit' : 'auto',
  },
  loadingContainer: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
  },
});

function BoardJoinButton(props) {
  const {
    boardId,
    company,
    staticClass,
    companyCEO,
    hideDeclineInvitation,
    invitation,
    sx = {},
  } = props;

  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isSM);
  const {
    accountDetails,
    type,
    isFreeTrial,
    asLoggedUserDetails,
    conversations,
    isFreemium,
  } = useAccount();
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [isPending, setIsPending] = useState(
    accountDetails.boards.appliedBoards.some((bc) => bc.id === boardId)
  );
  const [isJoined, setIsJoined] = useState(
    accountDetails.boards.memberBoards.some((bc) => bc.id === boardId)
  );
  const [isInvited, setIsInvited] = useState(
    accountDetails.boards.invitedBoards.some((bc) => bc.id === boardId)
  );
  const canMessage = useCanMessage(companyCEO.id, AccountType.COMPANY);
  const canJoin = useAdvisorCanJoin(company);
  const advisorId = accountDetails?.id;
  const calendarTokens = accountDetails?.calendarTokens || {};
  const googleToken = calendarTokens.google?.isSync;
  const appleToken = calendarTokens.apple?.isSync;

  useEffect(() => {
    setIsPending(
      accountDetails.boards.appliedBoards.some((bc) => bc.id === boardId)
    );
    setIsJoined(
      accountDetails.boards.memberBoards.some((bc) => bc.id === boardId)
    );
    setIsInvited(
      accountDetails.boards.invitedBoards.some((bc) => bc.id === boardId)
    );
  }, [boardId]);

  const [snackMessage, setSnackMessage] = useState('');
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);

  async function goToNewBoardroom() {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      close: true,
    });
    history.push({
      pathname: `/${company.slug}/boardroom`,
    });
  }

  function canJoinCodes() {
    if (canJoin.code === 'NO_AVAILABLE_BOARDS') {
      setUpgradePlanModal(true);
      return;
    } else if (canJoin.code === 'P2P_BOARD_ALREADY_EXISTS') {
      setSnackMessage('You are already a member of a P2P board.');
    } else if (canJoin.code === 'COMPANY_BOARD_ALREADY_EXISTS') {
      setSnackMessage('You are already a member of this company board.');
    } else {
      setSnackMessage('There was an error while processing your request.');
    }
    setErrorSnackbar(true);
  }

  async function join() {
    if (isFreemium) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction);
      return;
    }
    if (!canJoin.value) {
      canJoinCodes();
      return;
    }

    setIsDisabled(true);
    const now = new Date().toISOString();
    try {
      const response = await requestJoinBoard({
        ADVISOR_ID: accountDetails.id,
        COMPANY_ID: boardId,
        IS_FREE_TRIAL: isFreeTrial,
      });
      const success = response.data.requestJoinBoard;

      const companyHasInstantJoin = company.instantJoinEnabled;
      const boardObj = {
        createdAt: now,
        id: boardId,
        companyLogo: company.image ? `${company.image.location}` : '',
        companyName: company.displayName,
        slug: company.slug,
        isComplimentaryBoardPosition: company.complimentaryBoardPosition,
        boardType: company.boardType,
      };

      if (companyHasInstantJoin && success) {
        if (googleToken) {
          try {
            console.log(`Syncing Google Calendar for posts:`);
            await addBoardRoomGoogleCalendar({
              companyId: company.id,
              advisorId: advisorId,
              token: calendarTokens.google.token,
            });
          } catch (error) {
            console.error(`Error syncing calendars for posts:`, error.message);
          }
        }
        if (appleToken) {
          try {
            console.log(`Syncing Apple Calendar for posts:`);
            await addBoardRoomAppleCalendar({
              companyId: company.id,
              advisorId: advisorId,
              email: calendarTokens.apple.email,
              password: calendarTokens.apple.password,
            });
          } catch (error) {
            console.error(`Error syncing calendars for posts:`, error.message);
          }
        }
        setSnackMessage(
          `Success, you have joined ${company.displayName} 's advisory board!`
        );
        setSuccessSnackbar(true);
        setIsDisabled(false);
        accountDetails.boards.memberBoards.push(boardObj);
        setIsJoined(true);
        goToNewBoardroom();
      } else if (success) {
        setOpenRequestModal(true);
        setIsDisabled(false);
        accountDetails.boards.appliedBoards.push(boardObj);
        eventBus.dispatch(eventBusValues.advisorRequestedToJoinCompany);
      } else {
        setUpgradePlanModal(true);
        // setSnackMessage(
        //   'You can not apply to join to this board, please check if you have enough seats available.'
        // );
        // setErrorSnackbar(true);
        setIsDisabled(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function acceptInvitation() {
    invitation.status = 'accepted';
    if (isFreemium) {
      eventBus.dispatch(eventBusValues.triggerAdvisorUpgradeAction);
      return;
    }
    if (!canJoin.value) {
      canJoinCodes();
      return;
    }
    setIsDisabled(true);

    respondBoardInvitation({
      ADVISOR_ID: accountDetails.id,
      COMPANY_ID: boardId,
      STATUS: 'accepted',
      IS_FREE_TRIAL: isFreeTrial,
    })
      .then(async () => {
        await sendFSEventInviteViewed({
          companyDisplayName: company.displayName,
          asLoggedUserDetails,
          isBulkInvite: invitation.bulk,
          action: 'accept',
          outcome: 'joined',
          inviteId: invitation.invitationId,
          error: null,
          isFreeTrial: isFreeTrial ? true : false,
        });

        console.log('Posts loaded, starting calendar sync.');

        if (googleToken) {
          try {
            console.log(`Syncing Google Calendar for posts:`);
            await addBoardRoomGoogleCalendar({
              companyId: company.id,
              advisorId: advisorId,
              token: calendarTokens.google.token,
            });
          } catch (error) {
            console.error(`Error syncing calendars for posts:`, error.message);
          }
        }
        if (appleToken) {
          try {
            console.log(`Syncing Apple Calendar for posts:`);

            await addBoardRoomAppleCalendar({
              companyId: company.id,
              advisorId: advisorId,
              email: calendarTokens.apple.email,
              password: calendarTokens.apple.password,
            });
          } catch (error) {
            console.error(`Error syncing calendars for posts:`, error.message);
          }
        }

        setSnackMessage("You have been added to this company's board!");
        setSuccessSnackbar(true);
        const now = new Date();
        setIsDisabled(false);
        accountDetails.boards.memberBoards.push({
          createdAt: now.toISOString(),
          id: boardId,
          companyLogo: company.image ? `${company.image.location}` : '',
          companyName: company.displayName,
          slug: company.slug,
          isComplimentaryBoardPosition: company.complimentaryBoardPosition,
          boardType: company.boardType,
          comesFromNonBulkInvite: !invitation.bulk,
        });

        const index = accountDetails.boards.invitedBoards.findIndex(
          (bc) => bc.id === boardId
        );
        if (index > -1) {
          accountDetails.boards.invitedBoards.splice(index, 1);
        }
        eventBus.dispatch(eventBusValues.advisorAnsweredInvitation, true);
        goToNewBoardroom();
      })
      .catch(async (err) => {
        console.log('error', err);

        await sendFSEventInviteViewed({
          companyDisplayName: company.displayName,
          asLoggedUserDetails,
          isBulkInvite: invitation.bulk,
          action: 'accept',
          outcome: 'error',
          inviteId: invitation.invitationId,
          error: err,
          isFreeTrial: isFreeTrial ? true : false,
        });
        if (err.errors && err.errors[0] && err.errors[0].message) {
          if (err.errors[0].message.includes('limit of boards')) {
            setUpgradePlanModal(true);
          } else if (
            err.errors[0].message.includes('Error: P2P Board already exists')
          ) {
            setSnackMessage('You are already a member of a P2P board.');
            setErrorSnackbar(true);
          }
        } else {
          setSnackMessage(
            'An error occurred while processing your request. Please contact the member services team.'
          );
          setErrorSnackbar(true);
        }
        setIsDisabled(false);
      });
  }

  function declineInvitation() {
    setIsDisabled(true);
    invitation.status = 'declined';
    respondBoardInvitation({
      ADVISOR_ID: accountDetails.id,
      COMPANY_ID: boardId,
      STATUS: 'declined',
      IS_FREE_TRIAL: isFreeTrial,
    })
      .then(async () => {
        await sendFSEventInviteViewed({
          action: 'decline',
          outcome: 'declined',
          asLoggedUserDetails,
          inviteId: invitation.invitationId,
          companyDisplayName: company.displayName,
          isBulkInvite: invitation.bulk,
          error: null,
          isFreeTrial: isFreeTrial ? true : false,
        });
        setSnackMessage(
          'Invitation has been declined and removed from your dashboard'
        );
        setSuccessSnackbar(true);
        setIsDisabled(false);
        accountDetails.boards.invitedBoards =
          accountDetails.boards.invitedBoards.filter((bc) => bc.id !== boardId);
        eventBus.dispatch(eventBusValues.advisorAnsweredInvitation, false);
      })
      .catch(async (err) => {
        await sendFSEventInviteViewed({
          action: 'decline',
          outcome: 'error',
          asLoggedUserDetails,
          inviteId: invitation.invitationId,
          companyDisplayName: company.displayName,
          isBulkInvite: invitation.bulk,
          error: err,
          isFreeTrial: isFreeTrial ? true : false,
        });
        console.log(err);
        setIsDisabled(false);
      });
  }

  function buttonLabel() {
    return isPending
      ? t('BOARD-APPLICATION-PENDING')
      : company.instantJoinEnabled
      ? t('JOIN-THIS-BOARD')
      : t('REQUEST-TO-JOIN');
  }

  function conversationClick() {
    if (canMessage === undefined) return;
    if (canMessage) {
      const found = conversations.find(
        (conversation) =>
          conversation.conversationMemberAccountId === companyCEO.id
      );
      if (found) {
        eventBus.dispatch(eventBusValues.triggerConversationDrawerV2, {
          conversationId: found.conversationId,
        });
      } else {
        eventBus.dispatch(eventBusValues.onSendConversationNewMessage, {
          senderId: companyCEO.id,
          senderName: companyCEO.displayName,
        });
      }
    } else {
      eventBus.dispatch(
        type === AccountType.COMPANY
          ? eventBusValues.triggerCompanyUpgradeAction
          : eventBusValues.triggerAdvisorUpgradeAction,
        {}
      );
    }
  }

  function renderButton() {
    return isInvited ? (
      <div
        style={
          !sx.buttonContainer
            ? classes.buttonsContainer
            : { ...classes.buttonContainer, ...sx.buttonContainer }
        }
      >
        <Button
          variant="outlined"
          onClick={() => acceptInvitation()}
          sx={
            sx?.button
              ? { ...classes.mainButton, ...sx.button }
              : classes.mainButton
          }
          disabled={isDisabled}
        >
          {t('BOARD-ACCEPT-INVITATION')}
        </Button>
        {hideDeclineInvitation ? null : (
          <Button
            variant="outlined"
            onClick={() => declineInvitation()}
            sx={
              sx.button
                ? { ...classes.declineMainButton, ...sx.button }
                : classes.declineMainButton
            }
            disabled={isDisabled}
          >
            {t('BOARD-DECLINE-INVITATION')}
          </Button>
        )}
      </div>
    ) : (
      <Button
        variant="outlined"
        onClick={isJoined ? () => conversationClick() : () => join()}
        className={staticClass || ''}
        sx={
          sx?.button
            ? { ...classes.mainButton, ...sx.button }
            : classes.mainButton
        }
        style={{
          display:
            location.pathname.includes('/messages') && isJoined
              ? 'none'
              : 'flex',
        }}
        disabled={isPending || isDisabled}
      >
        {isJoined ? t('CHAT') : buttonLabel()}
      </Button>
    );
  }

  useEffect(() => {
    eventBus.on(eventBusValues.advisorRequestedToJoinCompany, () => {
      setIsPending(true);
    });

    eventBus.on(eventBusValues.advisorAnsweredInvitation, (isAccepted) => {
      setIsInvited(false);
      if (isAccepted) {
        setIsJoined(true);
      }
    });

    return () => {
      eventBus.remove(eventBusValues.advisorRequestedToJoinCompany);
      eventBus.remove(eventBusValues.advisorAnsweredInvitation);
    };
  }, []);

  return (
    <>
      <NoMoreBoardSeats
        openModal={upgradePlanModal}
        setOpenModal={setUpgradePlanModal}
      />
      <ACSnackbar
        open={successSnackbar}
        text={snackMessage || ''}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <ACSnackbar
        open={errorSnackbar}
        text={snackMessage || ''}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
        style={{ marginTop: isFreemium ? '50px' : '0px' }}
      />
      <RequestModal isOpen={openRequestModal} setIsOpen={setOpenRequestModal} />
      {isDisabled ? (
        <div style={classes.loadingContainer}>
          <LoadingIndicatorLogo size={200} iconFontSize={93} iconRight={105} />
        </div>
      ) : null}
      <div style={sx ? { ...classes.container, ...sx } : classes.container}>
        {renderButton(true)}
      </div>
    </>
  );
}

export default BoardJoinButton;
