import React, { useEffect, useState } from 'react';
import { useMediaQuery, Paper } from '@mui/material';
import AdvisoryCloudLogo from '../../components/AdvisoryCloudLogo';
import { useHistory } from 'react-router';
import AdvisorStepper from './AdvisorStepper';

const useStyles = ({ isFreemiumOnboarding }) => ({
  card: {
    borderRadius: '5px',
    marginTop: '50px',
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: isFreemiumOnboarding ? 0 : '40px',
    marginBottom: '50px',
    width: 'auto',
  },
  onboardingContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '40px',
    width: 'auto',
    marginBottom: '-20px',
    backgroundColor: 'white',
  },
  header: {
    display: 'flex',
    marginTop: '30px',
    marginBottom: '-10px',
  },
  advisorHeader: {
    display: 'flex',
    marginTop: '-20px',
    marginBottom: '-10px',
  },
});

const CardScene = (props) => {
  const {
    children,
    paperId,
    header,
    footer,
    isAdvisorOnboarding,
    isFreemiumOnboarding,
    clearPaper,
    step,
    sceneHeight,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isHeader, setIsHeader] = useState(false);
  const history = useHistory();
  const classes = useStyles({ isFreemiumOnboarding });

  useEffect(() => {
    if (header === undefined || header === null) {
      setIsHeader(false);
    } else {
      setIsHeader(true);
    }
  }, []);

  const Header = () => {
    return (
      <>
        <div
          style={
            !isAdvisorOnboarding
              ? {
                  ...classes.header,
                  marginTop: isFreemiumOnboarding ? '18px' : '30px',
                }
              : classes.advisorHeader
          }
        >
          {header}
        </div>
      </>
    );
  };

  return (
    <>
      <div
        style={
          isAdvisorOnboarding
            ? { ...classes.onboardingContainer, height: sceneHeight || '100%' }
            : { ...classes.container, backgroundColor: 'white' }
        }
      >
        {isSM && !clearPaper && !isFreemiumOnboarding && (
          <AdvisorStepper activeStep={step} />
        )}
        <AdvisoryCloudLogo
          removeHyperlink
          onClick={() => history.push('/dashboard')}
          width={isAdvisorOnboarding || isFreemiumOnboarding ? 189 : ''}
        />
        {isHeader && <Header />}
        {clearPaper ? (
          children
        ) : (
          <Paper
            id={paperId}
            elevation={!isSM ? 3 : 0}
            sx={
              isAdvisorOnboarding
                ? {
                    ...classes.card,
                    marginBottom: isSM ? 0 : 50,
                    width: !isSM ? 800 : '100%',
                    backgroundColor: 'white',
                  }
                : {
                    ...classes.card,
                    width: !isSM ? (isFreemiumOnboarding ? 800 : 600) : '100%',
                    borderRadius: isFreemiumOnboarding ? '10px' : '5px',
                    backgroundColor: 'white',
                  }
            }
          >
            {children}
          </Paper>
        )}
        {footer}
      </div>
    </>
  );
};

export default CardScene;
