import React from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Button, useMediaQuery } from '@mui/material';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';

const useStyles = ({ compactView, isSM }) => ({
  title: {
    fontFamily: 'Poppins',
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.43px',
    textAlign: 'center',
    maxWidth: isSM ? '90%' : compactView ? 520 : 430,
  },
  subtitle: {
    marginTop: compactView ? '10px' : '15px',
    maxWidth: isSM ? '90%' : 530,
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.75px',
    textAlign: 'center',
  },
  watchVideoText: {
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '32px',
    letterSpacing: '0.5px',
    textAlign: 'left',
  },
  mainButton: {
    width: '165px',
    height: '48px',
    borderRadius: '25px',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    letterSpacing: '-0.43px',
    textAlign: 'left',
    textTransform: 'none',
    boxShadow: 'none',
    margin: '15px',
    background: 'rgba(49, 113, 246, 1)',
  },
});

const CompanyTopTiles = ({
  children,
  circleAlternative,
  circleFirstColor,
  circleSecondColor,
  title,
  subtitle,
  basicButtons,
  compactView,
  hideWatchTheVideo,
  titleClass,
  subtitleClass,
}) => {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const classes = useStyles({ compactView, isSM });
  const location = useLocation();

  return (
    <div
      style={{
        width: '100%',
        height: isSM ? '362px' : '326px',
        position: 'relative',
        overflow: 'hidden',
        borderRadius: '16px',
        marginBottom: '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {circleAlternative ? (
        <>
          <div
            style={{
              width: '234px',
              height: '234px',
              borderRadius: '50%',
              background: circleFirstColor,
              position: 'absolute',
              top: '-65px',
              left: '-55px',
              zIndex: '-1',
            }}
          ></div>
          <div
            style={{
              width: '300px',
              height: '300px',
              borderRadius: '50%',
              background: circleSecondColor,
              position: 'absolute',
              right: '-78px',
              bottom: '-100px',
              zIndex: '-1',
            }}
          ></div>
        </>
      ) : (
        <>
          <div
            style={{
              width: '286px',
              height: '286px',
              borderRadius: '50%',
              background: circleFirstColor,
              position: 'absolute',
              bottom: '-65px',
              left: '-53px',
              zIndex: '-1',
            }}
          ></div>
          <div
            style={{
              width: '300px',
              height: '300px',
              borderRadius: '50%',
              background: circleSecondColor,
              position: 'absolute',
              top: '-150px',
              right: '-150px',
              zIndex: '-1',
            }}
          ></div>
        </>
      )}
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography sx={titleClass ? titleClass : classes.title}>
          {title}
        </Typography>
        <Typography sx={subtitleClass ? subtitleClass : classes.subtitle}>
          {subtitle}
        </Typography>

        <div>
          {basicButtons ? (
            <Button
              variant="contained"
              style={classes.mainButton}
              id="TopBanner-GetStarted"
            >
              Get started
            </Button>
          ) : (
            children
          )}
        </div>
        {!hideWatchTheVideo && location.pathname.includes('dashboard') ? (
          <div
            id="TopBanner-WatchVideo"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <PlayCircleOutlineOutlinedIcon
              style={{
                fontSize: '22px',
                marginRight: '5px',
              }}
            />
            <Typography sx={classes.watchVideoText}>Watch the video</Typography>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CompanyTopTiles;
