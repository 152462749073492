import React, { useEffect, useState } from 'react';
import { Box, SwipeableDrawer, useMediaQuery } from '@mui/material';
import eventBus, { eventBusValues } from '../../../eventBus';
import ConversationContainer from '../ContainerV2';
import ConversationLeftMenu from '../leftMenuV2';
import { useAccount } from '../../../contexts/Account';

const ConversationDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [conversationId, setConversationId] = useState();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const [showLeftMenu, setShowLeftMenu] = useState(false);
  const { conversations } = useAccount();

  useEffect(() => {
    eventBus.on(eventBusValues.triggerConversationDrawerV2, (data) => {
      if (data.close) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
      if (data.conversationId) {
        setConversationId(data.conversationId);
      } else {
        setConversationId(
          conversations[0] ? conversations[0].conversationId : null
        );
      }
    });

    return () => {
      eventBus.remove(eventBusValues.triggerConversationDrawerV2);
    };
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <SwipeableDrawer
        disableSwipeToOpen
        anchor="right"
        open={isOpen}
        onOpen={() => {}}
        onClose={() => setIsOpen(false)}
      >
        {conversations && conversations.length > 0 && (
          <>
            <Box
              sx={{
                display: 'flex',
                width: isSM ? '100vw' : '1000px',
              }}
            >
              <Box sx={{ flex: 1 }}>
                <ConversationContainer
                  showLeftMenu={showLeftMenu}
                  setShowLeftMenu={setShowLeftMenu}
                  propConversationId={conversationId}
                  setPropConversationId={setConversationId}
                />
              </Box>
              <Box sx={{ width: isXS ? 'auto' : '300px' }}>
                <ConversationLeftMenu
                  setConversationId={setConversationId}
                  conversationId={conversationId}
                  show={showLeftMenu}
                  setShow={setShowLeftMenu}
                />
              </Box>
            </Box>
          </>
        )}
      </SwipeableDrawer>
    </div>
  );
};

export default ConversationDrawer;
