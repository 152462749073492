import React from 'react';
import { Card, Grid, Link, Typography, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AdvisoryCloudLogo from '../../../components/AdvisoryCloudLogo';
import { withRouter } from 'react-router';
import FormLogin from '../../../components/LoginForm/index';
import ModalResetPassword from './ModalForgotPassword';

const useStyles = () => ({
  container: {
    display: 'flex',
    height: '100vh',
  },
  gridContainer: {
    backgroundColor: '#F9F9FC',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-center',
    marginTop: '-5vh',
  },
  loginText: {
    textAlign: 'center',
    color: 'theme.palette.secondary.dark',
    marginTop: '3vh',
  },
  linkContainer: {
    textAlign: 'center',
    marginTop: '2vh',
  },
});

function Clogin() {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');

  function handleClick(value) {
    if (value === undefined) {
      setOpen(!open);
    } else {
      setOpen(value);
    }
  }

  const LostPasswordComponent = (
    <Grid item>
      <Typography variant="h3" sx={classes.linkContainer}>
        <Link
          color="primary"
          underline="always"
          variant="h3"
          style={{ cursor: 'pointer' }}
          onClick={() => handleClick(true)}
        >
          {t('LOGIN_LOST_PASWORD')}
        </Link>
      </Typography>
    </Grid>
  );

  return (
    <div style={{ ...classes.container, marginTop: isSM ? '-15px' : 0 }}>
      <Grid container spacing={0} justify="center" sx={classes.gridContainer}>
        <Card
          sx={{
            maxWidth: isSM ? '80%' : '300px',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid container justify="center" direction="column">
            <Grid item>
              <AdvisoryCloudLogo width="100%" />
            </Grid>
            <Grid item>
              <Typography variant="h3" sx={classes.loginText}>
                {t('LOGIN_TEXT')}
              </Typography>
              <ModalResetPassword
                prePopulatedEmail={email}
                open={open}
                onChange={handleClick}
              />
            </Grid>
            <Grid item>
              <FormLogin LostPasswordComponent={LostPasswordComponent} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </div>
  );
}

export default withRouter(Clogin);
