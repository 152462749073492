import React, { useEffect, useState } from 'react';
import NotificationsSettings from '../NotificationsSettings';
import NotificationsSettingsFreemiumView from '../NotificationsSettings/FreemiumView';
import { useAccount } from '../../contexts/Account';
import { AccountType } from '../../services/utils/types';

function CompanyPreferences({
  freemiumView,
  hidePlanDetails,
  hideBillingInformation,
}) {
  const { accountDetails, asLoggedUserDetails } = useAccount();
  const [contact, setContact] = useState({});
  const [toggleTextValues] = useState({
    blockNewAdvisorsEmail: 'COMPANY-NOTIFICATIONS-NEW-ADVISORS',
  });
  const [toggleValues] = useState(['blockNewAdvisorsEmail']);
  const [formValues, setFormValues] = useState({});

  useEffect(() => {
    let isMounted = true;
    const currentContact = accountDetails.contacts.find(
      (cUser) => cUser.id === asLoggedUserDetails.userId
    );
    if (isMounted) {
      setContact(currentContact);
    }
    const defaultEmailObject = {};
    if (isMounted && currentContact.emailPreferences) {
      toggleValues.forEach((value) => {
        defaultEmailObject[value] =
          currentContact.emailPreferences[value] || false;
      });
      setFormValues(defaultEmailObject);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return !freemiumView ? (
    <NotificationsSettings
      formValues={formValues}
      setFormValues={setFormValues}
      toggleValues={toggleValues}
      toggleTextValues={toggleTextValues}
      user={contact}
      companyId={accountDetails.id}
      acountType={AccountType.COMPANY}
    />
  ) : (
    <NotificationsSettingsFreemiumView
      formValues={formValues}
      setFormValues={setFormValues}
      toggleValues={toggleValues}
      toggleTextValues={toggleTextValues}
      user={contact}
      companyId={accountDetails.id}
      acountType={AccountType.COMPANY}
      hideBillingInformation={hideBillingInformation}
      hidePlanDetails={hidePlanDetails}
    />
  );
}

export default CompanyPreferences;
