import React, { useState } from 'react';
import {
  Typography,
  Box,
  Button,
  Dialog,
  IconButton,
  TextField,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAccount } from '../../contexts/Account';
import { ReactComponent as EditGoal } from '../../icons/EditNewGoal.svg';
import { ReactComponent as EditGoalGray } from '../../icons/EditNewGoalGray.svg';
import { ReactComponent as Goal1 } from '../../icons/goal_1.svg';
import { ReactComponent as Goal2 } from '../../icons/goal_2.svg';
import { ReactComponent as Goal3 } from '../../icons/goal_3.svg';
import GoalTemplateImage from '../../icons/goal_2.svg';
import { addNewGoal, editNewGoal } from '../../services/advisor_services';
import LoadingLogoSkeleton from '../../components/SkeletonLoading/loadingLogoSkeleton';

export default function ProfessionalGoals() {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  const { accountDetails } = useAccount();
  const [open, setOpen] = useState(false);
  const [goalText, setGoalText] = useState('');
  const [loading, setLoading] = useState(false);
  const [goals, setGoals] = useState(
    accountDetails.advisorGoals
      ? [...accountDetails.advisorGoals].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      : []
  );
  const [selectedGoalId, setSelectedGoalId] = useState(null);

  const updateGoal = async (updatedFields) => {
    try {
      setLoading(true);
      const response = await editNewGoal({
        id: selectedGoalId,
        advisorId: accountDetails.id,
        text: goalText.trim(),
        ...updatedFields, // Merge additional fields (e.g., completed)
      });

      if (response.data?.editNewGoal.id) {
        const updateFunction = (goal) =>
          goal.id === selectedGoalId
            ? { ...goal, ...updatedFields, text: goalText.trim() }
            : goal;
        accountDetails.advisorGoals = accountDetails.advisorGoals.map((goal) =>
          updateFunction(goal)
        );
        setGoals((prevGoals) => prevGoals.map((goal) => updateFunction(goal)));
        resetDialog();
      }
    } catch (error) {
      console.error('Error updating goal:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddGoal = async () => {
    if (!goalText.trim()) return;

    try {
      setLoading(true);

      if (selectedGoalId) {
        await updateGoal({ completed: false }); // Editing an existing goal
      } else {
        const response = await addNewGoal({
          text: goalText.trim(),
          advisorId: accountDetails.id,
        });

        if (response.data?.addNewGoal.id) {
          // manually updating the context to void full app's state restart
          accountDetails.advisorGoals.unshift(response.data.addNewGoal);
          setGoals((prevGoals) => [response.data.addNewGoal, ...prevGoals]);
          resetDialog();
        }
      }
    } catch (error) {
      console.error('Error saving goal:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleMarkComplete = () => updateGoal({ completed: true }); // Marking as complete

  const resetDialog = () => {
    setOpen(false);
    setGoalText('');
    setSelectedGoalId(null);
  };

  const getRandomGoalIcon = () => {
    const icons = [Goal1, Goal2, Goal3];
    return icons[Math.floor(Math.random() * icons.length)];
  };

  const GoalComponent = ({ id, text }) => {
    // Temporary removal of random icon generation, we'll come back to it later
    // const IconComponent = getRandomGoalIcon();
    const IconComponent = Goal2;

    return (
      <Box
        onClick={() => {
          setSelectedGoalId(id);
          setGoalText(text);
          setOpen(true);
        }}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          padding: '20px',
          borderRadius: '8px',
          marginBottom: '16px',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          {/* <IconComponent width={"40px"} height={"40px"} /> */}
          <img src={GoalTemplateImage}></img>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 16,
              marginLeft: '10px',
              color: '#2E3B56',
            }}
          >
            {text}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#FFFFFF',
            width: '40px',
            height: '40px',
            borderRadius: '90px',
            boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            cursor: 'pointer',
            '&:hover': { backgroundColor: '#2B71F6' },
            '&:hover .default-icon': { display: 'none' },
            '&:hover .hover-icon': { display: 'block' },
          }}
        >
          <Box className="default-icon" sx={{ position: 'absolute' }}>
            <EditGoalGray />
          </Box>
          <Box
            className="hover-icon"
            sx={{ display: 'none', position: 'absolute' }}
          >
            <EditGoal />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isXS ? 'column' : 'row',
          justifyContent: 'space-between',
          marginTop: '30px',
          marginBottom: '20px',
        }}
      >
        <Box>
          <Typography
            id="boardroom-advisor-name"
            sx={{
              display: 'inline',
              fontWeight: 600,
              fontSize: { xxs: 24, xs: 40 },
              color: '#2B71F6',
            }}
          >
            {accountDetails.givenName}'s&nbsp;
          </Typography>
          <Typography
            sx={{
              display: 'inline',
              fontWeight: 600,
              fontSize: { xxs: 24, xs: 40 },
              color: '#001030',
            }}
          >
            Professional Goals
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: 16,
              color: '#2E3B56',
              marginTop: '10px',
            }}
          >
            You can add up to 10 goals.
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
          sx={{
            textTransform: 'none',
            fontSize: 14,
            padding: '6px 12px',
            borderRadius: 2,
            borderColor: '#2B71F6',
            color: '#2B71F6',
            width: isXS ? '100%' : '164px',
            height: '42px',
            marginTop: { xxs: '15px', xs: 0 },
          }}
        >
          <Typography sx={{ marginRight: '10px', fontWeight: 600 }}>
            +
          </Typography>{' '}
          Add new goal
        </Button>
      </Box>

      {goals
        .filter((goal) => !goal.completed)
        .map((goal) => (
          <GoalComponent key={goal.id} id={goal.id} text={goal.text} />
        ))}

      <Dialog
        open={open}
        onClose={resetDialog}
        fullScreen
        sx={{
          '& .MuiDialog-paper': {
            width: '544px',
            height: '380px',
            borderRadius: '10px',
            padding: '36px 24px',
          },
        }}
      >
        <IconButton
          onClick={resetDialog}
          sx={{ position: 'absolute', top: 6, right: 10, color: '#2E3B56' }}
        >
          <CloseIcon />
        </IconButton>

        <Box display="flex" flexDirection="column" gap={2} height="100%">
          <Typography fontSize={20} fontWeight={600} color="#2E3B56">
            {!selectedGoalId ? 'New Goal' : 'Edit Goal'}
          </Typography>

          <TextField
            value={goalText}
            onChange={(e) => setGoalText(e.target.value)}
            placeholder="Enter your goals"
            multiline
            rows={5}
            fullWidth
            inputProps={{ maxLength: 250 }}
            InputProps={{ sx: { borderRadius: '8px', fontSize: 16 } }}
          />

          <Box display="flex" gap={2} justifyContent="center">
            <Button
              fullWidth
              variant="contained"
              onClick={resetDialog}
              sx={{
                height: '42px',
                backgroundColor: '#F44336',
                '&:hover': { backgroundColor: '#d32f2f' },
                fontWeight: 600,
                borderRadius: '8px',
              }}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              disabled={loading || !goalText.trim()}
              onClick={handleAddGoal}
              sx={{
                height: '42px',
                backgroundColor: '#2B71F6',
                '&:hover': { backgroundColor: '#1a5ed9' },
                fontWeight: 600,
                borderRadius: '8px',
              }}
            >
              {loading ? (
                <LoadingLogoSkeleton />
              ) : !selectedGoalId ? (
                'Create'
              ) : (
                'Update'
              )}
            </Button>
          </Box>

          {selectedGoalId ? (
            <Button
              onClick={handleMarkComplete}
              disabled={loading}
              fullWidth
              variant="outlined"
              sx={{
                height: '42px',
                fontWeight: 600,
                borderRadius: '8px',
                color: '#2E3B56',
                borderColor: '#ccc',
              }}
            >
              Mark Complete
            </Button>
          ) : null}
        </Box>
      </Dialog>
    </>
  );
}
