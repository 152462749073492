import React, { useEffect, useRef, useState } from 'react';
import GeneralTile from './GeneralTile';
import Invitations from './Invitations';
import MyBoards from './MyBoards';
import calendar_check from '../../icons/calendar-check.svg';
import books from '../../icons/books_transparent.svg';
import schedule from '../../icons/schedule.svg';
import professional_goals from '../../icons/professional_goals.svg';
import { Box, useMediaQuery } from '@mui/material';
import eventBus, { eventBusValues } from '../../eventBus';
import { useAccount } from '../../contexts/Account';
import LeadershipFrame from '../../components/LeadershipFrame';
import ProfessionalGoals from './ProfessionalGoals';
import Treatment from '../../components/Treatment';
import { featureFlags } from '../../splitSetup';

function BoardsAndInvitations() {
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const { accountDetails, currentUserInfo } = useAccount();
  const [showDetails, setShowDetails] = useState(false);
  const [showLeadershipFrame, setShowLeadershipFrame] = useState(false);
  const [showProfessionalGoals, setShowProfessionalGoals] = useState(false);
  const [showAdvisoryBoards, setShowAdvisoryBoards] = useState(false);

  const detailsRef = useRef(null);

  useEffect(() => {
    if (showDetails) {
      setTimeout(() => {
        detailsRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      }, 100);
    }
  }, [showDetails]);

  function openUserDrawer() {
    eventBus.dispatch(eventBusValues.triggerAdvisorDrawerAction, {
      ...accountDetails,
      openInEdit: false,
    });
  }

  const sections = {
    advisoryBoards: setShowAdvisoryBoards,
    thoughtLeadership: setShowProfessionalGoals,
  };

  function toggleSection(sectionKey) {
    const isCurrentlyOpen = sectionKey === 'advisoryBoards' ? showAdvisoryBoards : showProfessionalGoals;

    // Close all sections first
    Object.values(sections).forEach(setSection => setSection(false));

    // Toggle the selected section
    sections[sectionKey](!isCurrentlyOpen);

    // Show details if a section is opening, hide otherwise
    setShowDetails(!isCurrentlyOpen);
  };

  return (
    <>
      <LeadershipFrame
        showLeadershipFrame={showLeadershipFrame}
        setShowLeadershipFrame={setShowLeadershipFrame}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: isXS ? 'column' : 'row',
          justifyContent: 'space-between',
          columnGap: '20px',
          rowGap: '20px',
        }}
      >
        <GeneralTile
          ids={{
            tileID: 'my-professional-brand-tile-id',
            buttonID: 'my-professional-brand-button-id',
          }}
          title="My Advisor Profile"
          onClick={openUserDrawer}
          description="View or update your profile so you can be contacted for the types of advisory boards and professional opportunities you're interested in."
          image={calendar_check}
        />
        <GeneralTile
          ids={{
            tileID: 'my-advisory-boards-tile-id',
            buttonID: 'my-advisory-boards-button-id',
          }}
          title="My Advisory Boards"
          onClick={() => toggleSection('advisoryBoards')}
          description="View your current advisory boards, share your insights, attend board meetings, and/or review joining new advisory boards."
          image={books}
        />

        {showDetails && isXS ? (
          <Box ref={detailsRef} sx={{ scrollMarginTop: '130px' }}>
            {(showDetails && showAdvisoryBoards && showAdvisoryBoards) && <>
              <Invitations />
              <MyBoards />
            </>}
            {(showDetails && !showAdvisoryBoards && showProfessionalGoals) && <>

              <Treatment
                userId={currentUserInfo?.username || 'not_id'}
                splitNames={featureFlags.advisor_goals}
                updateOnSdkTimedout
                evaluatedComponent={<ProfessionalGoals />}
                conditionFlag
                conditionFailedComponent={<></>}

              />
            </>}
          </Box>
        ) : null}

        <Treatment
          userId={currentUserInfo?.username || 'not_id'}
          splitNames={featureFlags.advisor_goals}
          updateOnSdkTimedout
          evaluatedComponent={
            <GeneralTile
              ids={{
                tileID: 'my-professional-goals-tile-id',
                buttonID: 'my-professional-goals-button-id',
              }}
              title="My Professional Goals"
              onClick={() => toggleSection('thoughtLeadership')}
              description="View or update your long-term professional aspirations, 5-year career goals, and more."
              image={professional_goals}
              buttonText={'View'}
            />
          }
          conditionFlag
          conditionFailedComponent={
            <GeneralTile
              ids={{
                tileID: 'my-thought-leadership-tile-id',
                buttonID: 'my-thought-leadership-button-id',
              }}
              title="My Thought Leadership"
              onClick={() => setShowLeadershipFrame(true)}
              description="Grow your audience and professional brand by sharing thought leadership with recommendations from AdvisoryCloud that keep you moving forward professionally."
              image={schedule}
              buttonText={'Coming in Q2'}
            />
          }
        />
      </Box>

      {showDetails && !isXS ? (
        <Box
          ref={detailsRef}
          // sx={{ marginTop: '20px', scrollMarginTop: isSM ? '40px' : '0px' }}
        >
          {(showDetails && showAdvisoryBoards && showAdvisoryBoards) && <>
            <Invitations />
            <MyBoards />
          </>}
          {(showDetails && !showAdvisoryBoards && showProfessionalGoals) && <>

            <Treatment
              userId={currentUserInfo?.username || 'not_id'}
              splitNames={featureFlags.advisor_goals}
              updateOnSdkTimedout
              evaluatedComponent={<ProfessionalGoals />}
              conditionFlag
              conditionFailedComponent={<></>}
            />
          </>}
        </Box>
      ) : null}

    </>
  );
}

export default BoardsAndInvitations;
