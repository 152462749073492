import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  Avatar,
  AvatarGroup,
  Switch,
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { useAccount } from '../../../contexts/Account';
import DefaultAdvisorAvatar from './../../../icons/user-avatar.svg';
import eventBus, { eventBusValues } from '../../../eventBus';
import { getAdvisorQueryParams } from './../../../utils/queryParams';

const useStyles = ({ isSM }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '25px',
  },
  rectangle: {
    width: isSM ? '100%' : '245px',
    height: '238px',
    borderRadius: '16px',
    padding: isSM ? '15px' : '21px',
    display: 'flex',
    flexDirection: 'column',
    boxShadow: 'none',
  },
  data: {
    textAlign: 'left',
    paddingTop: '8px',
  },
  data50: {
    textAlign: isSM ? 'center' : 'left',
    paddingTop: '20px',
    flexBasis: '50%',
  },
  tileTitle: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#232B35',
    letterSpacing: '0.5px',
    fontFamily: 'Poppins',
  },
  description: {
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#232B35',
    marginTop: '5px',
    fontFamily: 'Poppins',
  },
  dataDisplay: {
    fontWeight: 600,
    fontSize: '36px',
    lineHeight: '32px',
    color: '#232B35',
    letterSpacing: '-1.5px',
    fontFamily: 'Poppins',
  },
  sliderLabel: {
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#232B35',
    letterSpacing: '0.5px',
    fontFamily: 'Poppins',
  },
  listItem: {
    display: 'list-item',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#232B35',
    letterSpacing: '-0.43px',
    fontFamily: 'Poppins',
    py: 0,
    mx: '18px',
    my: 0,
  },
  infoTitle: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px',
    color: '#232B35',
    letterSpacing: '-0.43px',
    fontFamily: 'Poppins',
    marginTop: '8px',
  },
  infoBody: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    color: '#232B35',
    letterSpacing: '-0.43px',
    fontFamily: 'Poppins',
  },
});

const OnOffSwitch = styled(Switch)(({ theme }) => ({
  width: 68, // Ancho total del switch
  height: 26, // Alto total del switch
  padding: 0,
  // Estilos para la "base" del switch (la parte clicable)
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    transform: 'translateX(4px)', // Ajusta la posición inicial del thumb
    '&.Mui-checked': {
      transform: 'translateX(38px)', // Ajusta la posición cuando está encendido
      '& + .MuiSwitch-track': {
        backgroundColor: '#DBF7EB',
        opacity: 1,
        // Cambiamos el texto cuando está encendido
        '&:before': {
          content: '"On"',
          left: '8px', // Muéstralo hacia la izquierda
          right: 'auto', // Anulamos el "right"
        },
      },
    },
  },
  // Estilos para el "thumb" (círculo que se desplaza)
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff',
    width: 22,
    height: 22,
    boxShadow: 'none',
  },
  // Estilos para la pista (track)
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9E9', // color cuando está apagado
    opacity: 1,
    position: 'relative', // ¡importante para poder usar :before con position:absolute!
    '&:before': {
      content: '"Off"',
      position: 'absolute',
      right: '8px', // texto "Off" alineado a la derecha
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: 14,
      color: '#232B35',
      fontWeight: '600',
    },
  },
}));

export function MyOnOffSwitch({ checked, handleChange }) {
  return <OnOffSwitch checked={checked} onChange={handleChange} />;
}

export function AdvisoryCloudInfo() {
  const classes = useStyles({});
  return (
    <Box
      sx={{
        px: { xxs: 0, xs: 0, sm: '24px' },
      }}
    >
      <Typography
        sx={{ ...classes.infoTitle, mt: { xxs: 2, xs: 0 } }}
        gutterBottom
      >
        Where should I use this?
      </Typography>
      <List sx={{ listStyleType: 'disc' }}>
        <ListItem disableGutters sx={classes.listItem}>
          <ListItemText
            sx={{ my: 0 }}
            primary="Share the URL in a social media post for others to join your advisory board"
          />
        </ListItem>
        <ListItem disableGutters sx={classes.listItem}>
          <ListItemText
            sx={{ my: 0 }}
            primary="Add to your personal profile on LinkedIn – one click and your followers can join"
          />
        </ListItem>
        <ListItem disableGutters sx={classes.listItem}>
          <ListItemText
            sx={{ my: 0 }}
            primary="Add to your bios on social media like Facebook, Instagram, X, TikTok, and more"
          />
        </ListItem>
        <ListItem disableGutters sx={classes.listItem}>
          <ListItemText
            sx={{ my: 0 }}
            primary="Use the Promote button above to announce your advisory board to your existing LinkedIn network"
          />
        </ListItem>
        <ListItem disableGutters sx={classes.listItem}>
          <ListItemText
            sx={{ my: 0 }}
            primary="Invite your existing customers to get a behind-the-scenes look"
          />
        </ListItem>
        <ListItem disableGutters sx={classes.listItem}>
          <ListItemText
            sx={{ my: 0 }}
            primary="Email team members or anyone else who’d want to stay in the loop"
          />
        </ListItem>
        <ListItem disableGutters sx={classes.listItem}>
          <ListItemText
            sx={{ my: 0 }}
            primary="Add your advisory board icon next to the social media icons on your website to turn followers into advisors"
          />
        </ListItem>
        <ListItem disableGutters sx={classes.listItem}>
          <ListItemText
            sx={{ my: 0 }}
            primary="Add anywhere else you have traffic where you can form deeper relationships with your audience"
          />
        </ListItem>
      </List>
      <Typography sx={classes.infoTitle} gutterBottom>
        Why do I want my advisory board so big?
      </Typography>
      <Typography sx={classes.infoBody} paragraph>
        The bigger your advisory board, the more advocates and ideas for your
        business, team, or personal project. Plus, diversity of ideas is really
        important. There’s just no way a 5–10 person advisory board can have
        that. You want different aged people, different stages of life,
        different genders, different locations, races and viewpoints. When you
        follow a business or person on social media, it’s fairly superficial.
        Someone that is on your advisory board feels much more empowered to help
        you and your business in any way you’d find helpful. Wait until you see
        all the ways and ideas that a truly large advisory board can help your
        business, team, or special project reach new heights faster.
      </Typography>
      <Typography sx={classes.infoTitle} gutterBottom>
        Want to be able to invite top executives already on AdvisoryCloud?
      </Typography>
      <Typography sx={classes.infoBody} paragraph>
        Inviting others from your own network is free, upgrade today to invite
        top executives already on AdvisoryCloud wanting to be on advisory
        boards. There is no additional fee to them to be on your board, simply
        upgrade your plan and you can immediately invite any of these people.
      </Typography>
    </Box>
  );
}

const CompanyOwnProfileTilesSlideTemporary = ({ company }) => {
  const [teamSeatsAvailable, setTeamSeatsAvailable] = useState(0);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles({ isSM });
  const { accountDetails, currentPlan, isFreeTrial, isFreemium } = useAccount();
  const tier = currentPlan?.salesforceValues?.tier;
  console.log('tier', tier);
  const avatarsToDisplay =
    company && company.dashboardStats
      ? company.dashboardStats.advisorImages.slice(0, 3)
      : [];
  const activeAdvisors = Array.from(
    new Map(
      accountDetails.boardAdvisors
        .filter((advisor) => advisor.advisorStatus === 'active')
        .map((advisor) => [advisor.id, advisor])
    ).values()
  );
  function getAdvisorImagePath(image) {
    if (image) {
      return image;
    }
    return null;
  }

  useEffect(() => {
    if (company && company.boardSeatsAvailable && company.contacts) {
      const teamSeatsAvailable =
        parseInt(company.boardSeatsAvailable) -
        company.contacts.filter(
          (contact) =>
            contact.accountStatus === 'active' ||
            contact.accountStatus === 'new'
        ).length;

      setTeamSeatsAvailable(teamSeatsAvailable);
    }
  }, [company]);

  function goToUpgradePage() {
    if (isFreemium) {
      eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction, {
        gatedAction: true,
        companyName: company ? company.companyName : '',
      });
    } else if (currentPlan.upgradeUrl) {
      window.location.replace(
        `${currentPlan.upgradeUrl}?${getAdvisorQueryParams({
          accountDetails,
          isFreeTrial,
          isFreemium,
        })}`
      );
    }
  }

  function onboardingTitle() {
    return (
      <>
        <Typography
          style={{
            ...classes.dataDisplay,
            fontSize: isSM ? '18px' : '20px',
            lineHeight: '24px',
            letterSpacing: '-1px',
          }}
        >
          {isFreemium ? 'Free Advisory Board' : 'Advisory Board'}
        </Typography>
        {isFreemium || currentPlan.upgradeUrl ? (
          <Typography
            onClick={() => goToUpgradePage()}
            id="company-own-profile-account-upgrade"
            style={{
              ...classes.description,
              cursor: 'pointer',
              color: '#3171F6',
              fontWeight: 700,
            }}
          >
            See all upgrade options
          </Typography>
        ) : null}
      </>
    );
  }

  return (
    <Grid container justifyContent={isSM ? 'center' : 'left'}>
      <Grid
        item
        xs={12} // En pantallas pequeñas ocupa el 100%
        sm="auto" // Automático para evitar que se apile
        sx={{
          flexBasis: '250px',
          flexGrow: 0, // No permite crecer más allá de 250px
          maxWidth: { xxs: '100%', xs: '250px' }, // Ancho máximo para pantallas pequeñas y grandes
        }}
      >
        <Paper style={{ ...classes.rectangle, backgroundColor: '#DBF7EB' }}>
          <Grid
            container
            style={{
              margin: 0,
              padding: 0,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'start',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: '0px',
            }}
          >
            <Grid
              item
              xs={6}
              style={{
                textAlign: 'left',
                flexBasis: '50%',
              }}
            >
              <Typography style={classes.tileTitle}>Advisors</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                textAlign: 'left',
                flexBasis: '50%',
                marginTop: '-25px',
              }}
            ></Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6} style={classes.data50}>
              <Typography style={classes.dataDisplay}>
                {activeAdvisors.length}
              </Typography>
              <Typography style={classes.description}>Advisors</Typography>
            </Grid>
            <Grid item xs={6} style={classes.data50}>
              <Typography style={classes.dataDisplay}>
                {company.dashboardStats.savedAdvisorCount}
              </Typography>
              <Typography style={classes.description}>Saved</Typography>
            </Grid>
            <Grid item xs={6} style={classes.data50}>
              <Typography style={classes.dataDisplay}>
                {company.dashboardStats.lastMonthAdvisors}
              </Typography>
              <Typography style={classes.description}>New advisors</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                textAlign: 'left',
                paddingTop: '20px',
                flexBasis: isSM ? '25%' : '50%',
              }}
            >
              <AvatarGroup
                style={{ paddingTop: '0px' }}
                renderSurplus={(surplus) => (
                  <span>+{surplus.toString()[0]}k</span>
                )}
              >
                {avatarsToDisplay.map((avatar, index) => {
                  const imagePath = getAdvisorImagePath(avatar);
                  if (!imagePath) {
                    return null;
                  }
                  return (
                    <Avatar
                      key={index}
                      src={imagePath}
                      size={isSM ? 'md' : 'sm'}
                      sx={{
                        border: 'none !important',
                        width: '35.5px',
                        height: '35.5px',
                        marginLeft: '-16px !important',
                      }}
                    />
                  );
                })}
              </AvatarGroup>
            </Grid>
          </Grid>
        </Paper>
        <Paper
          style={{
            ...classes.rectangle,
            marginTop: '20px',
            backgroundColor: '#FFEED6',
          }}
          id="company-own-profile-account-slide"
        >
          <Typography style={classes.tileTitle}>Account</Typography>
          <Grid container>
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'left',
                paddingTop: '8px',
              }}
            >
              {onboardingTitle()}
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'left',
                paddingTop: '20px',
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography style={classes.sliderLabel}>
                  Invite your contacts
                </Typography>
                <MyOnOffSwitch
                  checked={true}
                  handleChange={() => goToUpgradePage()}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={1}
              >
                <Typography style={classes.sliderLabel}>
                  Access our network
                </Typography>
                <MyOnOffSwitch
                  checked={!isFreemium}
                  handleChange={() => goToUpgradePage()}
                />
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography style={classes.sliderLabel}>
                  Board Meetings
                </Typography>
                <MyOnOffSwitch
                  checked={['Executive'].includes(tier)}
                  handleChange={() => goToUpgradePage()}
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid
        item
        xs={12} // Ocupa el 100% en pantallas pequeñas
        sm // Automático en pantallas medianas o mayores
        sx={{
          flexGrow: 1, // Ocupa el resto del espacio disponible
          px: { xs: 2, md: 0 }, // Padding vertical
        }}
      >
        <AdvisoryCloudInfo />
      </Grid>
    </Grid>
  );
};

export default CompanyOwnProfileTilesSlideTemporary;
