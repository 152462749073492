import React, { useState, useRef, useEffect } from 'react';
import {
  Paper,
  Box,
  Typography,
  Avatar,
  IconButton,
  Button,
  useMediaQuery,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useAccount } from '../../contexts/Account';
import { timeAgo } from '../../utils/dateFormat';
import eventBus, { eventBusValues } from '../../eventBus';
import { respondBoardInvitation } from '../../services/advisor_services';
import ACSnackbar from '../../components/ACSnackbar';
import LoadingIndicatorLogo from '../../components/LoadingIndicatorLogo';
import DefaultCompany from '../../icons/building_round.svg';

function Invitations() {
  const { accountDetails } = useAccount();
  const [isDisabled, setIsDisabled] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [successSnackbar, setSuccessSnackbar] = useState(false);
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [invitationLoading, setInvitationLoading] = useState(false);
  const [hideScroll, setHideScroll] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [width, setWidth] = useState(0);

  const scrollContainerRef = useRef(null);
  const containerRef = useRef(null);
  const isXs = useMediaQuery((theme) => theme.breakpoints.down('xs'));

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const newWidth = containerRef.current.offsetWidth;
        const viewportWidth = window.innerWidth - 80;
        setWidth(Math.min(newWidth, viewportWidth));
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      const { scrollWidth } = container;
      if (scrollWidth <= width) {
        setHideScroll(true);
      } else {
        setHideScroll(false);
      }
    }
  }, [width, invitationLoading]);

  function seeOpportunity(companyId) {
    eventBus.dispatch(eventBusValues.triggerCompanyDrawerAction, {
      companyId,
    });
  }

  async function acceptInvitation(invitation) {
    setInvitationLoading(invitation.id);
    setIsDisabled(true);
    try {
      await respondBoardInvitation({
        ADVISOR_ID: accountDetails.id,
        COMPANY_ID: invitation.id,
        STATUS: 'accepted',
        IS_FREE_TRIAL: accountDetails.isFreeTrial,
      });

      const now = new Date().toISOString();
      accountDetails.boards.memberBoards.push({
        createdAt: now,
        id: invitation.id,
        companyLogo: invitation.companyLogo,
        companyName: invitation.companyName,
        slug: invitation.slug,
        isComplimentaryBoardPosition: invitation.isComplimentaryBoardPosition,
        boardType: invitation.boardType,
        headline: invitation.headline,
      });
      eventBus.dispatch(eventBusValues.advisorAnsweredInvitationV2, {
        createdAt: now,
        id: invitation.id,
        companyLogo: invitation.companyLogo,
        companyName: invitation.companyName,
        slug: invitation.slug,
        isComplimentaryBoardPosition: invitation.isComplimentaryBoardPosition,
        boardType: invitation.boardType,
        headline: invitation.headline,
      });

      setSnackMessage("You have been added to this company's board!");
      setSuccessSnackbar(true);
    } catch (err) {
      console.log(err);
      setSnackMessage(
        'An error occurred while accepting this invitation. Please try again later.'
      );
      setErrorSnackbar(true);
    } finally {
      setIsDisabled(false);
      setInvitationLoading(false);
      accountDetails.boards.invitedBoards =
        accountDetails.boards.invitedBoards.filter(
          (inv) => inv.id !== invitation.id
        );
    }
  }

  async function declineInvitation(invitation) {
    setIsDisabled(true);
    try {
      setInvitationLoading(invitation.id);
      await respondBoardInvitation({
        ADVISOR_ID: accountDetails.id,
        COMPANY_ID: invitation.id,
        STATUS: 'declined',
        IS_FREE_TRIAL: accountDetails.isFreeTrial,
      });
      setSnackMessage(
        'Invitation has been declined and removed from your dashboard'
      );
      setSuccessSnackbar(true);
    } catch (err) {
      console.log(err);
      setSnackMessage(
        'An error occurred while declining this invitation. Please try again later.'
      );
      setErrorSnackbar(true);
    } finally {
      setIsDisabled(false);
      setInvitationLoading(false);
      accountDetails.boards.invitedBoards =
        accountDetails.boards.invitedBoards.filter(
          (inv) => inv.id !== invitation.id
        );
    }
  }

  const allInvitations = [
    ...accountDetails.boards.invitedBoards
      .filter(
        (b) =>
          b.opportunityStage !== 'position_filled' &&
          b.opportunityStage !== 'dormant' &&
          b.opportunityStage !== 'pending_cancellation' &&
          b.boardStatus !== 'inactive' &&
          b.boardStatus !== 'paused'
      )
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)),
  ];
  if (!allInvitations.length) return null;

  let displayedInvitations = allInvitations;
  if (isXs && allInvitations.length > 2 && !expanded) {
    displayedInvitations = allInvitations.slice(0, 2);
  }
  const showMoreButton = isXs && allInvitations.length > 2;

  const scrollRight = () => {
    if (!scrollContainerRef.current) return;
    const container = scrollContainerRef.current;
    const { scrollLeft, scrollWidth, clientWidth } = container;
    const jump = 300;
    if (scrollLeft + clientWidth >= scrollWidth) {
      container.scrollTo({
        left: 0,
        behavior: 'smooth',
      });
    } else {
      container.scrollBy({
        left: jump,
        behavior: 'smooth',
      });
    }
  };

  const scrollLeft = () => {
    if (!scrollContainerRef.current) return;
    const container = scrollContainerRef.current;
    const jump = 300;
    container.scrollBy({
      left: -jump,
      behavior: 'smooth',
    });
  };

  return (
    <Paper
      id="invitations-tile"
      ref={containerRef}
      elevation={1}
      sx={{
        padding: '15px',
        borderRadius: '12px',
        marginBottom: '20px',
        boxShadow: 'none',
        maxWidth: '100%',
        position: 'relative',
      }}
    >
      <Typography
        sx={{
          fontWeight: '600',
          fontSize: 16,
          marginBottom: '4px',
          color: '#001030',
        }}
      >
        Invitations
      </Typography>
      <Typography
        sx={{
          fontWeight: '400',
          fontSize: 14,
          color: '#4D586E',
          fontStyle: 'italic',
          marginBottom: '16px',
        }}
      >
        Companies are looking for new and diverse opinions which is why some
        invitations may be outside your area of expertise.
      </Typography>
      {!hideScroll && (
        <IconButton
          onClick={scrollLeft}
          sx={{
            display: { xxs: 'none', xs: 'flex' },
            position: 'absolute',
            borderRadius: '8px',
            left: 15,
            bottom: 50,
            height: 20,
            width: 20,
            transform: 'translateY(-50%)',
            zIndex: 10,
            background: 'linear-gradient(90deg, #2C71F6 0%, #25A4FF 100%)',
            color: '#fff',
            boxShadow: 1,
            '&:hover': {
              background: 'linear-gradient(90deg, #2C71F6 0%, #25A4FF 100%)',
            },
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      <Box>
        <Box
          ref={scrollContainerRef}
          sx={{
            display: { xxs: 'block', xs: 'flex' },
            overflowX: { xxs: 'hidden', xs: 'auto' },
            whiteSpace: { xxs: 'normal', xs: 'nowrap' },
            scrollBehavior: 'smooth',
            margin: { xxs: '0', xs: hideScroll ? 0 : '0 40px 0 40px' },
            maxWidth: {
              xxs: '100%',
              xs: width - 40,
              md: width + 200,
              lg: width + 500,
            },
          }}
        >
          {displayedInvitations.map((invitation, index) => (
            <Paper
              className={`invitation-${invitation.boardType === 'P2P' ? 'p2p' : 'card'
                }`}
              key={index}
              elevation={0}
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 12px',
                borderRadius: '12px',
                border: '1px solid #e5e7eb',
                backgroundColor: '#ffffff',
                width: { xxs: '100%', xs: '350px' },
                minWidth: { xxs: '100%', xs: '350px' },
                height: '68px',
                marginRight: '16px',
                marginBottom: { xxs: '8px', md: 0 },
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '12px',
                  cursor: 'pointer',
                }}
                onClick={() => seeOpportunity(invitation.id)}
              >
                {invitationLoading === invitation.id && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 2,
                    }}
                  >
                    <LoadingIndicatorLogo
                      size={40}
                      iconFontSize={18}
                      iconRight={14}
                    />
                  </Box>
                )}
                <Avatar
                  src={
                    invitation.companyLogo &&
                      !invitation.companyLogo.includes('undefined')
                      ? `https://${invitation.companyLogo}`
                      : DefaultCompany
                  }
                  alt={invitation.companyName}
                  sx={{
                    width: '32px',
                    height: '32px',
                    backgroundColor: '#dbeafe',
                    borderRadius: '8px',
                  }}
                />
                <Box sx={{ minWidth: 0 }}>
                  <Typography
                    sx={{
                      fontSize: '16px',
                      fontWeight: 500,
                      color: '#001030',
                      lineHeight: '20px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: '-webkit-box',
                      WebkitLineClamp: 1,
                      WebkitBoxOrient: 'vertical',
                      whiteSpace: 'normal',
                    }}
                  >
                    {invitation.companyName}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#4D586E',
                      fontWeight: '400',
                    }}
                  >
                    {timeAgo(invitation.createdAt)}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  paddingLeft: '8px',
                  marginLeft: '8px',
                  height: 44,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={() => seeOpportunity(invitation.id)}
                  sx={{
                    textTransform: 'none',
                    fontSize: '14px',
                    padding: '6px 25px',
                    borderRadius: 2,
                    borderColor: 'hsla(219, 92%, 57%, 1)',
                    color: 'hsla(219, 92%, 57%, 1)',
                    borderWidth: '1px',
                    backgroundColor: 'white',
                  }}
                >
                  View
                </Button>
              </Box>
            </Paper>
          ))}
        </Box>
      </Box>
      {!hideScroll && (
        <IconButton
          onClick={scrollRight}
          sx={{
            display: { xxs: 'none', xs: 'flex' },
            position: 'absolute',
            borderRadius: '8px',
            right: 15,
            bottom: 50,
            height: 20,
            width: 20,
            transform: 'translateY(-50%)',
            zIndex: 10,
            background: 'linear-gradient(90deg, #2C71F6 0%, #25A4FF 100%)',
            color: '#fff',
            boxShadow: 1,
            '&:hover': {
              background: 'linear-gradient(90deg, #2C71F6 0%, #25A4FF 100%)',
            },
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      )}
      {showMoreButton && (
        <Box sx={{ textAlign: 'center', marginTop: '0px' }}>
          <Button
            variant="text"
            onClick={() => setExpanded(!expanded)}
            sx={{
              border: '1px solid #2C71F64D',
              borderRadius: '8px',
              color: '#2C71F6',
              backgroundColor: '#ffffff',
              padding: '4px 16px',
              textTransform: 'none',
              fontSize: '14px',
              fontWeight: '600',
              width: '100%',
              height: '40px',
              '&:hover': {
                backgroundColor: '#F3F4F6',
              },
            }}
          >
            {expanded ? 'Less' : 'More'}
          </Button>
        </Box>
      )}

      <ACSnackbar
        open={successSnackbar}
        text={snackMessage || ''}
        severity="success"
        onClose={() => setSuccessSnackbar(false)}
        autoHideDuration={6000}
      />
      <ACSnackbar
        open={errorSnackbar}
        text={snackMessage || ''}
        severity="error"
        onClose={() => setErrorSnackbar(false)}
        autoHideDuration={6000}
      />
    </Paper>
  );
}

export default Invitations;
