export function getAdvisorQueryParams({
  accountDetails,
  isFreeTrial,
  isFreemium,
}) {
  return `currentEmail=${accountDetails.email}&sosfcontactid=${
    accountDetails.salesforceContactId
  }&name=${accountDetails.displayName}&phone=${
    accountDetails.phoneNumber?.phoneNumber
  }&free_trial=${isFreeTrial && !isFreemium}&recurly_account_id=${
    accountDetails.recurlyAccountCode
  }&advisorkocstatus=${accountDetails.kocStatus}&advisorkocdatetime=${
    accountDetails.kocDateTime
  }&advisorkocuser=${accountDetails.kocUser}`;
}

export function getCompanyQueryParams({ accountDetails }) {
  const params = new URLSearchParams({
    email: accountDetails.userData.email,
    currentEmail: accountDetails.userData.email,
    sosfid: accountDetails.salesforceContactId || '',
    firstName: accountDetails.userData.givenName,
    lastName: accountDetails.userData.surName,
    planCode: accountDetails.recurlyPlanCode,
  });

  return params.toString();
}
