import React from 'react';
import { useMediaQuery, Typography } from '@mui/material';
import { ReactComponent as ACCheckCircleIcon } from '../../../icons/ACCheckCircleGreenIcon.svg';

const useStyles = (isSM) => ({
  columnContainer: {
    width: isSM ? '100%' : null,
    display: 'flex',
    alignItems: 'center',
    textAlign: isSM ? 'center' : 'left',
    padding: isSM ? '10px 5px' : '30px',
    flexDirection: 'column',
  },
  perkItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  checkIcon: {
    color: '#19AD6E',
    marginRight: '6px',
    height: '100%',
    width: '38px',
  },
  columnTitle: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#232B35',
  },
  biggerColumnTitle: {
    fontSize: '16px',
    fontWeight: 600,
    marginTop: '7px',
    color: '#232B35',
  },
  columnText: {
    fontSize: '13px',
    fontWeight: 400,
    color: '#232B35',
    textAlign: 'center',
    marginTop: '10px',
  },
  imageContainer: {
    display: 'flex',
    height: '150px',
    alignItems: 'center',
    marginBottom: '10px',
  },
});

const RequestModalItem = (props) => {
  const { titleWIthIcon, title, text, image, biggerImage } =
    props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);

  return (
    <div
      style={{
        ...classes.columnContainer,
        width: titleWIthIcon && !isSM ? '60%' : '',
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          alignItems: 'center',
          marginBottom: biggerImage ? '30px' : '35px',
        }}
      >
        <img
          style={{
            height: biggerImage ? '122px' : '85px',
          }}
          src={`https://cdn.advisorycloud.com/images/${image}`}
          alt={image}
        />
      </div>
      {titleWIthIcon ? (
        <div style={classes.perkItem}>
          <ACCheckCircleIcon
            style={{
              color: '#19AD6E',
              marginRight: '6px',
              height: '100%',
              width: '38px',
              width: '20px',
            }}
            fill="#19AD6E"
          />
          <Typography sx={classes.columnTitle}>{title}</Typography>
        </div>
      ) : (
        <Typography sx={biggerImage ? classes.biggerColumnTitle : classes.columnTitle}>{title}</Typography>
      )}
      <Typography sx={classes.columnText}>{text}</Typography>
    </div>
  );
};

export default RequestModalItem;
