import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery } from '@mui/material';
import AdvisoryCloudLogo from '../../components/AdvisoryCloudLogo2';
import CompanyOnboardingForm from './CompanyOnboardingForm';

const useStyles = (isSM) => ({
  main: {
    with: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '600px',
  },
  title: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: isSM ? '40px' : '24px',
    letterSpacing: '-0.4300000071525574px',
    margin: isSM ? '8px 0px' : '32px 0px 16px',
    padding: isSM ? '0 16px' : null,
    textAlign: 'center',
  },
});

function CompanyOnboarding() {
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Profile Builder - AdvisoryCloud</title>
      </Helmet>

      <Box sx={classes.main}>
        <Box sx={classes.body}>
          <AdvisoryCloudLogo
            removeHyperlink
            onClick={() => {}}
            width={isSM ? 240 : 180}
            height={isSM ? 80 : 60}
            cursor="default"
          />
          <Typography sx={classes.title}>
            Let's Set Up Your Advisory Board
          </Typography>

          <CompanyOnboardingForm />
        </Box>
      </Box>
    </>
  );
}

export default CompanyOnboarding;
