import { useState, useEffect } from 'react';

export default function useTouchDevice() {
  // hook to detect if the user is using a touch device (cellphone, tablet, etc)
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(hover: none)');
    const handleChange = () => setIsTouchDevice(mediaQuery.matches);

    handleChange(); // Detectar inicialmente
    mediaQuery.addEventListener('change', handleChange);

    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  return isTouchDevice;
};
