import React, { useEffect } from 'react';
import LoadingIndicatorLogo from '../components/LoadingIndicatorLogo';
import { useAccount } from '../contexts/Account';

const useStyles = () => ({
  container: {
    alignItems: 'center',
    backgroundColor: '#FFF',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
  },
});

function Logout() {
  const { signOut } = useAccount();
  const classes = useStyles();

  useEffect(() => {
    async function handleSignOut() {
      await signOut();
      window.location.replace('/login');
    }

    handleSignOut();
  }, []);

  return (
    <div style={classes.container}>
      <LoadingIndicatorLogo iconFontSize={15} iconRight={13} />
    </div>
  );
}

export default Logout;
