import { Storage } from 'aws-amplify';
import { Enviroments } from '../utils/types';

export async function uploadToS3({
  BLOB_LOAD,
  CONTENT_TYPE,
  FILE_ID,
  S3_BUCKET,
}) {
  let region = process.env.REACT_APP_APPSYNC_REGION;
  let targetBucket = S3_BUCKET;
  if (
    S3_BUCKET === process.env.REACT_APP_S3_BUCKET_IMAGES &&
    process.env.NODE_ENV === Enviroments.PRODUCTION
  ) {
    region = 'us-east-1';
    targetBucket = targetBucket.replace('us-west-2', 'us-east-1');
  }
  await Storage.put(FILE_ID, BLOB_LOAD, {
    bucket: targetBucket,
    region,
    contentType: CONTENT_TYPE,
    cacheControl: 'no-cache',
  });
}

export async function bigFileUpload({
  NAME,
  FILE,
  S3_BUCKET,
  CONTENT_TYPE,
  ON_PROGRESS,
  ON_ERROR,
  ON_COMPLETE,
}) {
  // just a test, permissions not anabled in prod
  let region = process.env.REACT_APP_APPSYNC_REGION;
  let targetBucket = S3_BUCKET;
  if (
    S3_BUCKET === process.env.REACT_APP_S3_BUCKET_IMAGES &&
    process.env.NODE_ENV === Enviroments.PRODUCTION
  ) {
    region = 'us-east-1';
    targetBucket = targetBucket.replace('us-west-2', 'us-east-1');
  }

  return Storage.put(NAME, FILE, {
    bucket: targetBucket,
    region,
    contentType: CONTENT_TYPE,
    cacheControl: 'no-cache',
    resumable: true,
    completeCallback: (event) => {
      console.log(`Successfully uploaded ${event.key}`);
      if (ON_COMPLETE) {
        ON_COMPLETE();
      }
    },
    progressCallback: (progress) => {
      console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      if (ON_PROGRESS) {
        ON_PROGRESS(progress);
      }
    },
    errorCallback: (err) => {
      console.error('Unexpected error while uploading', err);
    },
  });
}
