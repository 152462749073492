import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Paper, Modal, CircularProgress } from '@mui/material';

const useStyles = () => ({
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '386px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'start',
  },
  detailsContainer: {
    fontFamily: 'Poppins',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
    borderRadius: '40px',
    color: '#232B35',
    padding: '10px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    marginTop: '10px',
  },
  button: {
    width: '94px',
    height: '44px',
    borderRadius: '10px',
    letterSpacing: -0.43,
    fontStyle: 'normal',
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    backgroundColor: '#3171F6',
    color: 'white',
    // marginLeft: '10px',
    // marginTop: '10px',
    '&:hover': {
      backgroundColor: '#3171F6',
    },
    '&:disabled': {
      opacity: 0.5,
      backgroundColor: '#3171F6',
      color: 'white',
    },
  },
  secondaryButton: {
    width: '94px',
    height: '44px',
    letterSpacing: -0.43,
    fontStyle: 'normal',
    textTransform: 'none',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#646D7A',
    '&:disabled': {
      opacity: 0.5,
      color: '#646D7A',
    },
  },
});

function GenericConfirmationModal({
  isVisible,
  onClose,
  onConfirm,
  confirmationMessage,
  modalId,
  confirmButtonId,
  cancelButtonId,
}) {
  const [buttonLoading, setLoading] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  if (!isVisible) return null;

  return (
    <Modal open={isVisible} onClose={onClose} sx={classes.modalContainer}>
      <Paper
        sx={classes.container}
        id={modalId || 'generic-confirmation-modal'}
      >
        <div style={classes.detailsContainer}>{confirmationMessage}</div>

        <div style={classes.buttonsContainer}>
          <Button
            variant="text"
            color="primary"
            sx={classes.secondaryButton}
            onClick={onClose}
            id={cancelButtonId || 'generic-confirmation-modal-cancel-button'}
          >
            Cancel
          </Button>
          <div style={{ width: '10px' }} />
          <Button
            variant="contained"
            color="primary"
            sx={classes.button}
            onClick={() => {
              setLoading(true);
              onConfirm();
            }}
            id={confirmButtonId || 'generic-confirmation-modal-confirm-button'}
          >
            {buttonLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              'Confirm'
            )}
          </Button>
        </div>
      </Paper>
    </Modal>
  );
}

export default GenericConfirmationModal;
