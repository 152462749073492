import React, { useEffect, useState } from 'react';
import ACButton from '../ACButton';
import { useHistory } from 'react-router-dom';
import { useMediaQuery, Typography, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAccount } from '../../contexts/Account';
import Input from '../Company/OnboardingFreemium/Input';
import OnboardingAddTeamMembers from '../Company/OnboardingFreemium/OnboardingAddTeamMembers';
import CancelButton from './CancelButton';
import SaveButton from './SaveButton';
import {
  updateCompanyProfileData,
  uploadCompanyContactImgCall,
} from '../../services/company_services';
import ACSnackbar from '../ACSnackbar';
import FilePondProfile from '../ProfileUploaderFreemium';
import { inviteCompanyContact } from '../../services/company_services';

const useStyles = (isXS) => ({
  companyWebsiteContainer: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
    marginTop: '15px',
  },
  continueButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '95px',
  },
  fieldContainer: {
    marginBottom: '15px',
  },
  row: {
    display: 'flex',
    flexDirection: !isXS ? 'row' : 'column',
    width: '100%',
    height: '100%',
  },
  thumbnailContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxHeight: '250px',
    width: !isXS ? '170px' : '100%',
  },
  thumbnailProfileContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxHeight: '180px',
    width: !isXS ? '210px' : '100%',
  },
  updateCompanyNameContainer: {
    minWidth: isXS ? '94.8vw' : '370px',
    paddingRight: '20px',
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 700,
    letterSpacing: -0.43,
  },
  subtitleOptional: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#232B35',
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: -0.43,
  },
  subtitleDescription: {
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: '400',
    color: '#646D7A',
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: -0.43,
    paddingBottom: '20px',
    marginTop: '10px',
  },
  continueButton: {
    textTransform: 'none',
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '16px',
    height: '45px',
    width: '225px',
    lineHeight: '24px',
    borderRadius: '40px',
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#3171F6',
    },
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    flex: 'auto',
    justifyContent: 'flex-end',
    marginRight: '10px',
    width: '220px',
  },
});

function Step2Form(props) {
  const {
    handleBlur,
    handleChange,
    values,
    isUpdate,
    updateSubmit,
    onInformationUploaded,
    onCancelClick,
  } = props;
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState(values.members || []);
  const {
    asLoggedUserDetails,
    editAccountInformation,
    accountDetails,
    editCompanyContactInformation,
    currentPlan,
  } = useAccount();
  const { t } = useTranslation();
  const history = useHistory();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isXS);
  const [filePondProfileStatus, setFilePondProfileStatus] = useState(true);
  const [uploaderProfileOutput, setUploaderProfileOutput] = useState([]);

  const [textError, setTextError] = useState('');
  let updatedInfo = {};
  let updatedInfoCompany = {};

  function setAccountInfoCompany(final, value) {
    updatedInfoCompany = { ...updatedInfoCompany, ...value };

    if (final) {
      editCompanyContactInformation(updatedInfoCompany);
    }
  }

  function setAccountInfo(final, value) {
    updatedInfo = { ...updatedInfo, ...value };

    if (final) {
      editAccountInformation(updatedInfo);
    }
  }

  function getErrorMessageFromAPI(error) {
    try {
      const parsedError = JSON.parse(error.errors[0].message);
      if (parsedError && parsedError.error) {
        if (typeof parsedError.error === 'string') {
          return parsedError.error;
        }
        if (parsedError.error.length) {
          return parsedError.error[0];
        }
        if (
          parsedError.error.error &&
          typeof parsedError.error.error === 'string'
        ) {
          return parsedError.error.error;
        }
      }
      return '';
    } catch (err) {
      console.log(err);
      return '';
    }
  }

  async function onSendInvitationClick(data, callback = () => {}) {
    // setButtonLoading(true);
    try {
      await inviteCompanyContact({
        userId: asLoggedUserDetails.userId,
        companyId: accountDetails.id,
        email: data.email,
        givenName: data.firstName,
        phoneCountryCode:
          values.phoneNumber && values.phoneNumber.countryCode
            ? values.phoneNumber.countryCode
            : 'US',
        phoneNumber:
          values.phoneNumber && values.phoneNumber.phoneNumber
            ? values.phoneNumber.phoneNumber
            : '5555555555',
        surName: data.lastName,
        title: '',
      }).then((res) => {
        if (
          callback &&
          members.length + 2 >= accountDetails.boardSeatsAllowed
        ) {
          callback(false);
        }
        setMembers([...members, data]);
        return res;
      });
      // onInvitationSend(response.data.inviteCompanyContact);
    } catch (error) {
      console.log(error);
      const errorMessage = t('STEP1-ERROR-SNACKBAR-TEXT');
      let errorMessageFromAPI = getErrorMessageFromAPI(error);

      setTextError(
        errorMessageFromAPI.length === 0 ? errorMessage : errorMessageFromAPI
      );
      setIsSnackbarVisible(true);
    }
  }

  async function sendContactFormData() {
    try {
      const contact = accountDetails.contacts.find(
        (companyContact) => companyContact.id === asLoggedUserDetails.userId
      );
      const phoneSettedAndUpdated =
        contact &&
        contact.phoneNumber?.phoneNumber &&
        values.yourPhoneNumber &&
        contact.phoneNumber.phoneNumber !== values.yourPhoneNumber;

      const updatePayload = {
        CONTACT_ID: asLoggedUserDetails.userId,
        CONTACT_FIRST_NAME: values.yourFirstName,
        CONTACT_LAST_NAME: values.yourLastName,
        CONTACT_TITLE: values.yourTitle,
        PHONE_COUNTRY_CODE: values.yourPhoneCountryCode,
        PHONE_NUMBER: phoneSettedAndUpdated
          ? values.yourPhoneNumber
          : '5555555555',
      };
      if (isUpdate) {
        updatePayload.CONTACT_BIO = values.yourBiography;
      }

      const updatedContact = await updateCompanyProfileData(updatePayload);

      const companyContactModifiedValues = {
        givenName: values.yourFirstName,
        surName: values.yourLastName,
        title: values.yourTitle,
        phoneNumber: updatedContact.data.updateCompanyContact.phoneNumber,
      };
      if (isUpdate) {
        companyContactModifiedValues.biography = values.yourBiography;
      }
      setAccountInfoCompany(false, companyContactModifiedValues);
    } catch (err) {
      throw err;
    }
  }

  async function sendProfileImg() {
    try {
      const response = await uploadCompanyContactImgCall({
        CONTACT_ID: asLoggedUserDetails.userId,
        CONTACT_EDITED_IMG: uploaderProfileOutput.image,
        CONTACT_ORIGINAL_IMG: uploaderProfileOutput.originalImage,
      });

      const fixedLocation =
        response.data.uploadContactImage.location.split('https://');

      const companyContactModifiedValues = {
        image: {
          ...response.data.uploadContactImage,
          location: fixedLocation[1],
        },
      };

      setAccountInfoCompany(false, companyContactModifiedValues);
      if (onInformationUploaded) {
        onInformationUploaded();
      }
    } catch (err) {
      console.log(err);
      throw new Error();
    }
  }

  function submitStep1Form() {
    setIsLoading(true);
    const promisesToResolve = [sendContactFormData()];
    if (uploaderProfileOutput && uploaderProfileOutput.image) {
      promisesToResolve.push(sendProfileImg());
    }
    Promise.all(promisesToResolve)
      .then((x) => {
        const accountDetailsModifiedValues = {
          onboardingProcess: 'Complete',
          industryTagIds: values.companyIndustry
            ? values.companyIndustry.map((d) => d.id)
            : [],
        };
        setAccountInfo(true, accountDetailsModifiedValues);
        setAccountInfoCompany(true, {});
        if (isUpdate) {
          history.push('/account');
          onCancelClick();
        } else {
          window.location.replace(currentPlan.onboardingRedirectUrl);
        }
      })
      .catch((error) => {
        console.log(error);
        setTextError(getErrorMessageFromAPI(error));
        setIsSnackbarVisible(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function renderSaveAndCancelButtons() {
    return (
      <>
        <div style={classes.saveButtonContainer}>
          <SaveButton onClick={submitStep1Form} disabled={isLoading} />
          <div style={{ width: '20px' }}></div>
          <CancelButton onClick={onCancelClick} />
        </div>
      </>
    );
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isUpdate && updateSubmit) {
      updateSubmit(submitStep1Form);
    }
    return () => {
      isMounted = false;
    };
  }, [submitStep1Form]);

  return (
    <>
      <form>
        <ACSnackbar
          id="onboarding-step1-error-snackbar"
          onClose={() => setIsSnackbarVisible(false)}
          open={isSnackbarVisible}
          severity="error"
          text={textError || t('STEP1-ERROR-SNACKBAR-TEXT')}
        />
        <Grid container sx={{ height: '25px' }} justifyContent="space-between">
          <Grid item>
            <Typography sx={{ ...classes.subtitle }}>About you</Typography>
          </Grid>
          {isUpdate && <Grid item>{renderSaveAndCancelButtons()}</Grid>}
        </Grid>
        <Typography sx={classes.subtitleDescription}>
          Fill out your personal information
        </Typography>
        <div style={classes.row}>
          <div style={classes.thumbnailProfileContainer}>
            <FilePondProfile
              resultFiles={setUploaderProfileOutput}
              status={setFilePondProfileStatus}
            />
          </div>
          <div style={classes.companyWebsiteContainer}>
            <Grid
              container
              sx={{ display: !isXS ? 'flex' : 'inline' }}
              spacing={1}
            >
              <Grid item sm={6} xs={12}>
                <Input
                  style={classes.fieldContainer}
                  id="yourFirstName"
                  InputProps={{
                    'data-cy': 'txtYourFirstName',
                    style: {
                      height: '44px',
                      borderRadius: '10px',
                    },
                  }}
                  label="First name"
                  placeholder="First name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.yourFirstName}
                  // placeholder={firstNamePlaceholder}
                  hideLabel
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Input
                  style={classes.fieldContainer}
                  id="yourLastName"
                  InputProps={{
                    'data-cy': 'txtYourLastName',
                    style: {
                      height: '44px',
                      borderRadius: '10px',
                    },
                  }}
                  label="Last name"
                  placeholder="Last name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.yourLastName}
                  // placeholder={lastNamePlaceholder}
                  hideLabel
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Input
                  style={classes.fieldContainer}
                  id="yourTitle"
                  InputProps={{
                    'data-cy': 'txtYourTitle',
                    style: {
                      height: '44px',
                      borderRadius: '10px',
                    },
                  }}
                  label="Title"
                  placeholder="Title"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  size="small"
                  value={values.yourTitle}
                  hideLabel
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <div style={classes.phoneContainer}>
                  <Input
                    style={classes.fieldContainer}
                    id="yourPhoneNumber"
                    InputProps={{
                      'data-cy': 'txtYourPhoneNumber',
                      style: {
                        height: '44px',
                        borderRadius: '10px',
                      },
                    }}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    size="small"
                    value={values.yourPhoneNumber}
                    placeholder="Phone number"
                    hideLabel
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        {isUpdate && (
          <div style={{ ...classes.row, marginTop: '20px' }}>
            <Input
              style={classes.fieldContainer}
              id="yourBiography"
              InputProps={{
                'data-cy': 'txtYourBiography',
                style: {
                  borderRadius: '10px',
                },
              }}
              label="Biography"
              multiline
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('STEP2-YOUR-BIOGRAPHY-INPUT-PLACEHOLDER')}
              rows={4}
              value={values.yourBiography}
            />
          </div>
        )}
        <OnboardingAddTeamMembers
          members={members}
          addMember={onSendInvitationClick}
          boardSeatsAllowed={accountDetails.boardSeatsAllowed}
        />
        {!isUpdate && (
          <div style={classes.continueButtonContainer}>
            <ACButton
              data-testid="btnStep1Continue"
              color="primary"
              loading={isLoading ? 1 : 0}
              data-cy="btnStep1Continue"
              style={classes.continueButton}
              onClick={() => submitStep1Form()}
            >
              {t('CONTINUE-BUTTON-TEXT')}
            </ACButton>
          </div>
        )}
      </form>
    </>
  );
}

export default Step2Form;
