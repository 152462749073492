import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';
import { Box, Backdrop, Paper, Typography } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import useTouchDevice from '../hooks/useTouchDevice';

function HoverMenuItem(props) {
  const {
    label,
    id,
    options = [],
    paths = [],
    menuItemStyle = {},
    containerWidth = '240px',
    leftOptionsPosition = 42,
    mainOnClick,
    optionsMenuStyle = {},
  } = props;
  const boxRef = useRef(null);
  const paperRef = useRef(null);
  const [hoverOpen, setHoverOpen] = useState(false);
  const isTouchDevice = useTouchDevice();
  const [alreadyEnteredOnMenu, setAlreadyEnteredOnMenu] =
    useState(isTouchDevice);
  const [mainLabelWidth, setMainLabelWidth] = useState(50);
  const location = useLocation();

  useEffect(() => {
    try {
      // sets the menu items under the nav bar button
      const boxNode = ReactDOM.findDOMNode(boxRef.current);
      const paperNode = ReactDOM.findDOMNode(paperRef.current);
      const boxRect = boxNode.getBoundingClientRect();
      setMainLabelWidth(boxRect.width);

      paperNode.style.left = `${boxRect.left - leftOptionsPosition}px`;

      let topBaseValue = 7; // default for menu with 5 options
      if (options.length === 4) {
        topBaseValue = 25;
      } else if (options.length === 3) {
        topBaseValue = 43;
      }

      paperNode.style.top = `${boxRect.top - topBaseValue}px`;
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Box
      id={id}
      style={{
        color: paths.includes(location.pathname) ? '#2B71F6' : '#2E3B56',
        backgroundColor: paths.includes(location.pathname)
          ? '#F4F8FF'
          : 'transparent',
        cursor: 'pointer',
        border: 'none',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        ...menuItemStyle,
      }}
      onMouseEnter={() => {
        if (isTouchDevice) {
          return;
        }
        setHoverOpen(true);
      }}
    >
      <Box
        ref={boxRef}
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
        onClick={() => {
          if (!hoverOpen && isTouchDevice) {
            setHoverOpen(true);
          } else if (mainOnClick && !isTouchDevice) {
            mainOnClick();
          } else if (
            options.length > 0 &&
            options[0].onClick &&
            !isTouchDevice
          ) {
            options[0].onClick();
          }
        }}
      >
        {label}
        <ArrowDropDown sx={{ width: '16px' }} />
      </Box>
      <Backdrop
        sx={{ backgroundColor: 'transparent' }}
        open={hoverOpen}
        onTouchStart={() => {
          if (isTouchDevice) {
            return;
          }
          setHoverOpen(false);
        }}
      >
        <Paper
          ref={paperRef}
          sx={{
            width: '320px',
            height: '240px',
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            // backgroundColor: 'yellow',
            border: 'none',
          }}
          elevation={0}
          onClick={() => {
            if (isTouchDevice) {
              setHoverOpen(false);
            }
          }}
          onMouseLeave={() => {
            setHoverOpen(false);
            setAlreadyEnteredOnMenu(false);
          }}
          onMouseEnter={() => {
            setAlreadyEnteredOnMenu(true);
          }}
        >
          {/* Invisible box to close the menu item on reaching the side one and to add the main click action */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'left',
              width: '100%',
              height: '40px',
              paddingLeft: '40px',
            }}
          >
            <Box
              onClick={() => {
                if (mainOnClick) {
                  mainOnClick();
                } else if (options.length > 0 && options[0].onClick) {
                  options[0].onClick();
                }
                setHoverOpen(false);
              }}
              onMouseEnter={() => {
                setAlreadyEnteredOnMenu(true);
              }}
              sx={{
                height: '40px',
                width: `${mainLabelWidth}px`,
                // backgroundColor: 'red',
              }}
            />
            <Box
              onMouseEnter={() => {
                if (alreadyEnteredOnMenu) {
                  setHoverOpen(false);
                }
              }}
              sx={{
                height: '40px',
                flex: '1',
                // backgroundColor: 'green',
              }}
            />
          </Box>

          <Box
            sx={{
              width: containerWidth,
              backgroundColor: 'white',
              boxShadow: '0px 1px 10px rgba(230, 237, 254, 0.25)',
              border: '1px solid #F5F7FA',
              borderRadius: '12px',
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '20px',
              ...optionsMenuStyle,
            }}
          >
            {options.map((option) => (
              <Box
                id={option.id || null}
                key={option.label}
                sx={{
                  padding: '5px 0px',
                }}
                onClick={() => {
                  if (option.onClick) {
                    option.onClick();
                  }
                  if (option.closeOnClick) {
                    setHoverOpen(false);
                  }
                }}
                display="flex"
                position="relative"
              >
                {option.icon && (
                  <img
                    src={option.icon}
                    alt={option.label}
                    style={{ marginRight: option.mr || '12px' }}
                  />
                )}
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '232B35',
                    paddingTop: '2px',
                  }}
                >
                  {option.label}
                </Typography>
              </Box>
            ))}
          </Box>
        </Paper>
      </Backdrop>
    </Box>
  );
}

export default HoverMenuItem;
