import React, { useEffect, useState, useMemo } from 'react';
import ACButton from './../../ACButton';
import ACSnackbar from './../../ACSnackbar';
import { Typography, useMediaQuery } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { AddCircleOutlineOutlined } from '@mui/icons-material';
import { useAccount } from './../../../contexts/Account';
import Input from './Input';
import Autocomplete from './Autocomplete';
import BenefitCard from './benefitCard';
import employees from './employeesData.json';
import FilePondCompany from './../LogoUploader/index';
import AddBenefitModal from './AddBenefitModal';
import GenericConfirmationModal from './../GenericConfirmationModal';
import {
  updateCompanyAccountData,
  uploadCompanyLogoCall,
} from './../../../services/company_services';

const useStyles = (isXS) => ({
  companyWebsiteContainer: {
    display: 'flex',
    flex: 'auto',
    flexDirection: 'column',
  },
  continueButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: '25px',
  },
  dividerMargin: {
    marginBottom: '15px',
  },
  fieldContainer: {
    marginBottom: '15px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
  },
  thumbnailContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    maxHeight: '250px',
    width: '170px',
  },
  updateCompanyNameContainer: {
    minWidth: isXS ? '94.8vw' : '370px',
    paddingRight: '20px',
  },
  plusIcon: {
    fontSize: '20px',
    marginRight: '15px',
    marginLeft: '3px',
  },
  addContentContainer: {
    marginRight: '15px',
    paddingBottom: '15px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

function Step1Form(props) {
  const {
    errors,
    handleBlur,
    handleChange,
    isDisabled,
    setFieldValue,
    touched,
    values,
    isUpdate,
    onValid,
    updateSubmit,
    onInformationUploaded,
  } = props;
  const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [benefits, setBenefits] = useState(values.benefits ?? []);
  const [benefitToEdit, setBenefitToEdit] = useState();
  const [benefitToDelete, setBenefitToDelete] = useState();
  const [addBenefitModal, setAddBenefitModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const companyNamePlaceholder = useMemo(() => values.companyName, []);
  const [
    companyStoryAndMissionInputValue,
    setCompanyStoryAndMissionInputValue,
  ] = useState('');
  const {
    asLoggedUserDetails,
    editAccountInformation,
    tags,
    isImpersonated,
    isFreemium,
    accountDetails,
  } = useAccount();
  const { t } = useTranslation();
  const isXS = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const classes = useStyles(isXS);
  const [filePondStatus, setFilePondStatus] = useState(true);
  const [uploaderOutput, setUploaderOutput] = useState([]);
  const [textError, setTextError] = useState('');
  const isContinueButtonDisabled =
    companyStoryAndMissionInputValue &&
    companyStoryAndMissionInputValue.length < 30;
  let updatedInfo = {};

  function setAccountInfo(final, value) {
    if (!final) {
      updatedInfo = { ...value };
    } else {
      updatedInfo = { ...updatedInfo, ...value };
      if (
        updatedInfo.image &&
        updatedInfo.image.id &&
        !updatedInfo.image.id.includes('.png')
      ) {
        updatedInfo.image.id = `${updatedInfo.image.id}.png`;
      }
      editAccountInformation(updatedInfo);
    }
  }

  const removeBenefit = () => {
    const updatedBenefits = benefits.filter(
      (benefit) =>
        benefit.details !== benefitToDelete.details ||
        benefit.category !== benefitToDelete.category
    );
    setBenefits(updatedBenefits);
  };

  const editBenefit = (benefit) => {
    setAddBenefitModal(true);
    setBenefitToEdit(benefit);
  };

  const featuredBenefit = (benefitToFeatured) => {
    const featuredCount = benefits
      ? benefits.filter((bnf) => bnf.featured).length
      : 0;
    if (featuredCount == 3 && !benefitToFeatured.featured) {
      setTextError(
        'You have exceeded the limit of 3 board featured per company'
      );
      setIsSnackbarVisible(true);
      return;
    }
    const updatedBenefits = benefits.map((bnf) => {
      if (
        bnf.category == benefitToFeatured.category &&
        bnf.details == benefitToFeatured.details
      ) {
        return {
          ...benefitToFeatured,
          featured: benefitToFeatured.featured ? false : true,
        };
      }
      return bnf;
    });
    setBenefits(updatedBenefits);
  };

  async function sendFormData() {
    try {
      let data = {
        CONTACT_ID: asLoggedUserDetails.userId,
        COMPANY_NAME:
          values.companyName !== accountDetails.companyName
            ? values.companyName
            : '',
        COMPANY_WEBSITE: values.companyWebsite,
        COMPANY_STORY: values.companyStoryAndMission,
        COMPANY_INDUSTRY: values.companyIndustry.map((d) => d.id),
        COMPANY_SKILLS: values.advisorSkills.map((d) => d.id),
        COMPANY_PERKS: values.companyPerks,
        COMPANY_BENEFITS: benefits.map((e) => JSON.stringify(e)),
      };

      if (isUpdate) {
        data = {
          ...data,
          COMPANY_SIZE: values.companySize,
          DISPLAY_NAME: values.displayName,
          HEADLINE: values.headline,
        };
      }
      if (!isUpdate && values.companyName !== accountDetails.companyName) {
        data = {
          ...data,
          DISPLAY_NAME: values.companyName,
        };
      }
      
      await updateCompanyAccountData(data);

      if (isUpdate) {
        const accountDetailsModifiedValues = {
          companyName: values.companyName,
          website: values.companyWebsite,
          storyAndMission: values.companyStoryAndMission,
          industryTagIds: values.companyIndustry.map((d) => d.id),
          desiredSkillsTagIds: values.advisorSkills.map((d) => d.id),
          perks: values.companyPerks,
          companySize: values.companySize,
          displayName: values.displayName,
          headline: values.headline,
          benefits: benefits,
        };
        const updateUIInfo =
          !uploaderOutput ||
          !uploaderOutput.image ||
          !uploaderOutput.originalImage;
        setAccountInfo(updateUIInfo, accountDetailsModifiedValues);
      }
    } catch (err) {
      const errorMessage = t('STEP1-ERROR-SNACKBAR-TEXT');
      let errorMessageFromAPI = '';
      try {
        errorMessageFromAPI = JSON.parse(err.errors[0].message).error.error;
      } catch (error) {
        console.log(error);
      }
      setTextError(
        errorMessageFromAPI.length === 0 ? errorMessage : errorMessageFromAPI
      );
      throw new Error();
    }
  }

  async function sendOriginalLogo() {
    try {
      if (
        !uploaderOutput ||
        !uploaderOutput.image ||
        !uploaderOutput.originalImage
      ) {
        return;
      }
      const response = await uploadCompanyLogoCall({
        CONTACT_ID: asLoggedUserDetails.userId,
        COMPANY_LOGO_EDITED: uploaderOutput.image,
        COMPANY_LOGO_ORIGINAL: uploaderOutput.originalImage,
      });
      const fixedLocation =
        response.data.uploadCompanyLogo.location.split('https://');

      const accountDetailsModifiedValues = {
        image: {
          id: response.data.uploadCompanyLogo.id,
          location: fixedLocation[1],
        },
      };
      setAccountInfo(true, accountDetailsModifiedValues);
      if (onInformationUploaded) {
        onInformationUploaded();
      }
    } catch (err) {
      const errorMessage = t('STEP1-ERROR-SNACKBAR-TEXT');
      let errorMessageFromAPI = '';
      try {
        errorMessageFromAPI = JSON.parse(err.errors[0].message).error.error;
      } catch (error) {
        console.log(error);
      }
      setTextError(
        errorMessageFromAPI.length === 0 ? errorMessage : errorMessageFromAPI
      );
      throw new Error();
    }
  }

  function addNewBenefit(benefit) {
    if (benefitToEdit) {
      const updatedBenefits = benefits.map((bnf) => {
        if (
          bnf.category == benefitToEdit.category &&
          bnf.details == benefitToEdit.details
        ) {
          return benefit;
        }
        return bnf;
      });
      setBenefits(updatedBenefits);
      setBenefitToEdit(null);
    } else {
      setBenefits([...benefits, benefit]);
    }
  }

  function submitStep1Form() {
    setIsLoading(true);
    const promisesToResolve = [sendFormData(), sendOriginalLogo()];
    Promise.all(promisesToResolve)
      .then(() => {
        if (!isUpdate) {
          props.history.push('/company/onboarding/step2');
        }
      })
      .catch(() => {
        setIsSnackbarVisible(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function hasError(id) {
    if (Object.keys(touched).includes(id)) {
      return errors[id];
    }

    return null;
  }

  function handleCompanyIndustryAutocompleteChange(event, vals) {
    setFieldValue('companyIndustry', vals);
  }

  function handleAdvisorSkillsAutocompleteChange(event, vals) {
    setFieldValue('advisorSkills', vals);
  }

  function handleCompanyEmployeesAutocompleteChange(event, vals) {
    setFieldValue('companySize', vals ? vals.id : '');
  }

  function handleCompanyStoryAndMissionChange({ target: { value } }) {
    setFieldValue('companyStoryAndMission', value);
    setCompanyStoryAndMissionInputValue(value);
  }

  function renderCompanySizeOption(option) {
    return t('STEP1-EMPLOYEES-LABEL', { employees: option.label });
  }

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setCompanyStoryAndMissionInputValue(values.companyStoryAndMission);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isUpdate && updateSubmit) {
      updateSubmit(submitStep1Form);
    }
    return () => {
      isMounted = false;
    };
  }, [submitStep1Form]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && onValid) {
      onValid(!isContinueButtonDisabled);
    }
    return () => {
      isMounted = false;
    };
  }, [isContinueButtonDisabled]);

  return (
    <>
      <form>
        <ACSnackbar
          id="onboarding-step1-error-snackbar"
          onClose={() => setIsSnackbarVisible(false)}
          open={isSnackbarVisible}
          severity="error"
          text={textError}
        />
        <div style={classes.row}>
          <div
            style={
              isUpdate
                ? classes.updateCompanyNameContainer
                : classes.thumbnailContainer
            }
          >
            <FilePondCompany
              resultFiles={setUploaderOutput}
              status={setFilePondStatus}
            />
          </div>

          <div style={classes.companyWebsiteContainer}>
            {!isUpdate && (
              <Input
                parentStyle={classes.fieldContainer}
                id="companyName"
                inputProps={{
                  'data-cy': 'txtCompanyName',
                }}
                label={t('STEP1-COMPANY-NAME-INPUT-LABEL')}
                onBlur={handleBlur}
                onChange={handleChange}
                size="small"
                value={values.companyName || ''}
                placeholder={companyNamePlaceholder}
              />
            )}

            <Input
              parentStyle={classes.fieldContainer}
              errorTextInRed
              // helperText={hasError('companyWebsite')}
              id="companyWebsite"
              inputProps={{
                'data-cy': 'txtCompanyWebsite',
              }}
              label={t('STEP1-COMPANY-WEBSITE-INPUT-LABEL')}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder="yourwebsite.com"
              size="small"
              value={values.companyWebsite || ''}
            />
            {isUpdate && (
              <Autocomplete
                autoHighlight
                parentStyle={classes.fieldContainer}
                onChange={handleCompanyEmployeesAutocompleteChange}
                onBlur={handleBlur}
                getOptionLabel={renderCompanySizeOption}
                defaultValue={{
                  id: values.companySize,
                  label: values.companySize,
                }}
                id="companySize"
                input-data-cy="txtCompanySize"
                label={t('STEP1-COMPANY-SIZE-SELECT-LABEL')}
                options={employees}
                multiple={false}
              />
            )}
          </div>
        </div>

        {isUpdate && (
          <div style={classes.row}>
            <div style={classes.updateCompanyNameContainer}>
              <Input
                parentStyle={classes.fieldContainer}
                id="companyName"
                inputProps={{
                  'data-cy': 'txtCompanyName',
                }}
                label={t('STEP1-COMPANY-NAME-INPUT-LABEL')}
                onBlur={handleBlur}
                onChange={handleChange}
                size="small"
                value={values.companyName || ''}
                placeholder={companyNamePlaceholder}
              />
            </div>
            <Input
              parentStyle={classes.fieldContainer}
              errorTextInRed
              helperText={hasError('displayName')}
              id="displayName"
              inputProps={{
                'data-cy': 'txtDisplayName',
              }}
              label={t('STEP1-DISPLAY-NAME-INPUT-LABEL')}
              onBlur={handleBlur}
              onChange={handleChange}
              size="small"
              value={values.displayName || ''}
            />
          </div>
        )}

        {isImpersonated && isUpdate && (
          <div style={classes.row}>
            <Input
              parentStyle={classes.fieldContainer}
              helperText={
                <Trans i18nKey="STEP1-COMPANY-HEADLINE-HELPER-TEXT" />
              }
              id="headline"
              inputProps={{
                'data-cy': 'txtHeadline',
                maxLength: 100,
              }}
              label={t('STEP1-COMPANY-HEADLINE-INPUT-LABEL')}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={t('STEP1-COMPANY-HEADLINE-INPUT-PLACEHOLDER')}
              value={values.headline || ''}
            />
          </div>
        )}

        <div style={classes.row}>
          <Input
            parentStyle={classes.fieldContainer}
            id="companyStoryAndMission"
            inputProps={{
              'data-cy': 'txtCompanyStoryAndMission',
            }}
            label={t('STEP1-COMPANY-STORY-AND-MISSION-INPUT-LABEL')}
            onBlur={handleBlur}
            onChange={handleCompanyStoryAndMissionChange}
            multiline
            placeholder={t('STEP1-COMPANY-STORY-AND-MISSION-INPUT-PLACEHOLDER')}
            rows={4}
            value={companyStoryAndMissionInputValue || ''}
          />
        </div>

        <div style={classes.row}>
          <Autocomplete
            parentStyle={classes.fieldContainer}
            defaultValue={values.companyIndustry}
            onChange={handleCompanyIndustryAutocompleteChange}
            onBlur={handleBlur}
            getOptionLabel={(option) => option.label}
            helperText={
              <>
                3-10 industries are <strong>recommended</strong>.
                {/* <Trans i18nKey="STEP1-COMPANY-INDUSTRY-SELECT-HELPER-TEXT" /> */}
              </>
            }
            id="companyIndustry"
            input-data-cy="txtCompanyIndustry"
            label={t('STEP1-COMPANY-INDUSTRY-SELECT-LABEL')}
            limitTags={3}
            options={tags.industries}
            placeholder={t('STEP1-COMPANY-INDUSTRY-SELECT-PLACEHOLDER')}
          />
        </div>
        {!isFreemium && (
          <>
            <div style={classes.row}>
              <Autocomplete
                parentStyle={classes.fieldContainer}
                defaultValue={values.advisorSkills}
                onChange={handleAdvisorSkillsAutocompleteChange}
                onBlur={handleBlur}
                getOptionLabel={(option) => option.label}
                helperText={
                  <>
                    5-30 skills are <strong>recommended</strong>.
                    {/* <Trans i18nKey="STEP1-ADVISOR-SKILLS-SELECT-HELPER-TEXT" /> */}
                  </>
                }
                id="advisorSkills"
                input-data-cy="txtAdvisorSkills"
                label={t('STEP1-ADVISOR-SKILLS-SELECT-LABEL')}
                limitTags={3}
                options={tags.skills}
                placeholder={t('STEP1-ADVISOR-SKILLS-SELECT-PLACEHOLDER')}
              />
            </div>
            <div>
              <div
                style={{
                  fontSize: '0.875rem',
                }}
              >
                {t('STEP1-COMPANY-PERKS-INPUT-LABEL')}
              </div>
              <div style={classes.addContentContainer}>
                <ACButton
                  color="primary"
                  size="medium"
                  variant="text"
                  style={{
                    borderWidth: 2,
                    borderRadius: 5,
                    paddingLeft: 0,
                  }}
                  onClick={() => {
                    setAddBenefitModal(true);
                  }}
                >
                  <AddCircleOutlineOutlined sx={classes.plusIcon} />
                  <Typography variant="old_font">Add a benefit</Typography>
                </ACButton>
              </div>
              {benefits.map((benefit) => (
                <BenefitCard
                  benefit={benefit}
                  removeBenefit={() => {
                    setBenefitToDelete(benefit);
                    setConfirmationModal(true);
                  }}
                  featuredBenefit={featuredBenefit}
                  editBenefit={editBenefit}
                />
              ))}
            </div>
          </>
        )}

        {!isUpdate && (
          <div style={classes.continueButtonContainer}>
            <ACButton
              sx={classes.continueButton}
              data-testid="btnStep1Continue"
              color="primary"
              //disabled={isContinueButtonDisabled}
              loading={isLoading ? 1 : 0}
              data-cy="btnStep1Continue"
              style={{ width: 200 }}
              onClick={() => submitStep1Form()}
            >
              {t('CONTINUE-BUTTON-TEXT')}
            </ACButton>
          </div>
        )}
      </form>
      {addBenefitModal ? (
        <AddBenefitModal
          addBenefitModal={addBenefitModal}
          setAddBenefitModal={setAddBenefitModal}
          addNewBenefit={addNewBenefit}
          benefitToEdit={benefitToEdit}
          setBenefitToEdit={setBenefitToEdit}
        />
      ) : null}
      {confirmationModal ? (
        <GenericConfirmationModal
          isVisible={confirmationModal}
          confirmationMessage="Are you sure you want to delete this benefit?"
          onConfirm={() => {
            removeBenefit();
            setConfirmationModal(false);
          }}
          onClose={() => setConfirmationModal(false)}
        />
      ) : null}
    </>
  );
}

export default Step1Form;
