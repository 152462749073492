import React from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import CustomIcon from './../../../../../icons/customIcon';

const getClasses = (isSM, type) => ({
  text: {
    fontStyle: 'normal',
    fontWeight: 500,
    letterSpacing: '.75px',
    fontSize: isSM ? '12px' : '16px',
  },
  iconStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 25,
    marginRight: isSM ? '-4px' : '6px',
  },
  contentContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: 'fit-content',
    height: 'fit-content',
    padding: isSM ? '5px 10px 5px 10px' : '8px 15px 8px 15px',
    borderRadius: '6px',
    marginRight: type === 'boardMeeting' && !isSM ? '50px' : '0px',
  },
  mainContainer: {
    display: 'flex',
    flexShrink: 0,
  },
});

function PostTypeDecorator(props) {
  const { type } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = getClasses(isSM, type);

  const Question = () => {
    return (
      <div style={{ ...classes.contentContainer, background: '#D7F1FD' }}>
        <CustomIcon iconname="bubbles-question" sx={classes.iconStyle} />
        {!isSM ? <Typography sx={classes.text}>Question</Typography> : null}
      </div>
    );
  };

  const Update = () => {
    return (
      <div style={{ ...classes.contentContainer, background: '#D7E2FD' }}>
        <CustomIcon iconname="bullhorn-outline" sx={classes.iconStyle} />
        {!isSM ? <Typography sx={classes.text}>Update</Typography> : null}
      </div>
    );
  };

  const BoardMeeting = () => {
    return (
      <div style={{ ...classes.contentContainer, background: '#DBF7EB' }}>
        <CustomIcon
          style={{ marginRight: isSM ? '-2px' : '6px' }}
          iconname="calendar-meeting"
          sx={classes.iconStyle}
        />
        {!isSM ? (
          <Typography sx={classes.text}>Board Meeting</Typography>
        ) : null}
      </div>
    );
  };

  const Meeting = () => {
    return (
      <div style={{ ...classes.contentContainer, background: '#FFEED6' }}>
        <CustomIcon iconname="post-meetings" sx={classes.iconStyle} />
        {!isSM ? <Typography sx={classes.text}>Meeting</Typography> : null}
      </div>
    );
  };

  const Insight = () => {
    return (
      <div style={{ ...classes.contentContainer, background: '#DBF7EB' }}>
        <CustomIcon iconname="insights" sx={classes.iconStyle} />
        {!isSM ? <Typography sx={classes.text}>Insight</Typography> : null}
      </div>
    );
  };

  function renderContents() {
    if (type === 'question') {
      return <Question />;
    }
    if (type === 'update') {
      return <Update />;
    }
    if (type === 'boardMeeting') {
      return <BoardMeeting />;
    }
    if (type === 'meeting') {
      return <Meeting />;
    }
    if (type === 'insight') {
      return <Insight />;
    }
    return null;
  }

  return <div style={classes.mainContainer}>{renderContents()}</div>;
}

export default PostTypeDecorator;
