import React from 'react';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as Rocket } from '../../images/charm-rocket.svg';
import { useAccount } from '../../contexts/Account';
import { getAdvisorQueryParams } from '../../utils/queryParams';

const HighlightedButton = () => {
  const { accountDetails, isFreeTrial, isFreemium } = useAccount();
    const isMD = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <Button
      id="buildBoardMenuOption"
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#232b35',
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
        padding: isMD ? '0px 0px 4px 8px' : '6px 0px 4px 20px',
        marginRight: '-20px',
        marginLeft: '-20px',
        width: isMD ? '100%' :'310px',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '32px',
        letterSpacing: '0.75px',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      }}
      onClick={() =>
        window.location.assign(
          `https://register.advisorycloud.com/platform/special-offers/?${getAdvisorQueryParams(
            { accountDetails, isFreeTrial, isFreemium }
          )}`,
          '_blank'
        )
      }
      type="button"
    >
      <Box
        sx={{
          backgroundColor: '#D7F1FD',
          display: 'flex',
          paddingRight: '14px',
          alignItems: 'center',
          height: '68px',
          borderRadius: '12px',
          paddingLeft: '12px',
          width: '100%',
        }}
      >
        <Rocket
          style={{
            marginLeft: '17px',
            marginRight: '11px',
            fontSize: '23px',
            marginLeft: '6px',
            marginBottom: '8px',
            display: isMD ? 'none' : 'block',
          }}
        />
        <Box>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '32px',
              letterSpacing: '-0.25px',
              textAlign: 'left',
              color: '#232B35',
              height: '18px',
              marginTop: '10px',
            }}
          >
            Special Offers
          </Typography>
          <Typography
            sx={{
              fontSize: '10px',
              fontWeight: '400',
              lineHeight: '32px',
              letterSpacing: '-0.25px',
              textAlign: 'left',
              color: '#1D95CD',
              marginTop: '4px',
            }}
          >
            Limited-time offers available
          </Typography>
        </Box>
      </Box>
    </Button>
  );
};

export default HighlightedButton;
