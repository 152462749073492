import React from 'react';
import { Box } from '@mui/material';
import CustomIcon from '../../icons/customIcon';
import { ReactComponent as Ring } from './load_ring.svg';

const useStyles = ({ size }) => ({
  box: {
    width: size,
    height: size,
    minWidth: '30px',
    minHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  logo: {
    borderRadius: '100%',
    width: '69%',
    height: '69%',
    position: 'absolute',
  },
  ring: {
    borderRadius: '100%',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
});

export default function LoadingIndicatorLogo({ size, iconFontSize, iconRight }) {
  const props = {
    size: `${size}px`,
  };
  const classes = useStyles(props);
  return (
    <Box sx={classes.box}>
      <Ring
        style={{ ...classes.ring, animation: `spin 1s linear infinite` }}
        viewBox="0 0 400 400"
      />
      <CustomIcon
        iconname="cloud-logo"
        sx={{
          color: '#0F78FD',
          fontSize: iconFontSize,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '17px',
          position: 'absolute',
          right: iconRight,
        }}
      />
    </Box>
  );
}

LoadingIndicatorLogo.defaultProps = {
  size: "300",
};
