import React, { useState } from 'react';
import {
  Typography,
  useMediaQuery,
  Paper,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
} from '@mui/material';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DvrIcon from '@mui/icons-material/Dvr';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import ACButton from '../ACButton';
import { useTranslation } from 'react-i18next';
import ACSnackbar from '../ACSnackbar';
import { mutationUpdateContactEmailPreferences } from '../../services/company_services';
import { mutationUpdateAdvisorEmailPreferences } from '../../services/advisor_services';
import { AccountType } from '../../services/utils/types';
import { useAccount } from '../../contexts/Account';
import eventBus, { eventBusValues } from '../../eventBus';
import { editBillingInfo, viewPastInvoices } from '../../services/Recurly';
import { getAdvisorQueryParams } from '../../utils/queryParams';

const useStyles = (isSM) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerNotification: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  sectionTitle: {
    marginLeft: isSM ? '10px' : '0px',
    color: 'grey.500',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    display: 'inline-flex',
    flexDirection: 'row',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  notificationsTitle: {
    fontWeight: 'bold',
  },
  notificationsTextContainer: {
    maxWidth: '1200px',
    wordWrap: 'break-word',
  },
  longText: {
    whiteSpace: 'pre-wrap',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  iconContainer: {
    width: isSM ? '15%' : '7%',
    paddingTop: 4,
  },
  iconContainerBell: {
    width: isSM ? '15%' : '7%',
  },
  actionsTable: {
    width: '100%',
  },
  actionCell: {
    height: isSM ? '60px' : '30px',
    width: isSM ? '30%' : '30%',
    textAlign: 'center',
    color: 'primary.main',
    cursor: 'pointer',
  },
  text: {
    fontWeight: 'bold',
    width: isSM ? '85%' : '93%',
    fontSize: '14px',
  },
  bottomRow: {
    display: 'flex',
    width: '100%',
    marginTop: '15px',
    flexGrow: 1,
  },
  card2: {
    width: '100%',
    backgroundColor: 'white',
    marginBottom: '25px',
    paddingTop: '25px',
    paddingLeft: isSM ? '15px' : '38px',
    paddingRight: isSM ? '15px' : '38px',
    paddingBottom: isSM ? '15px' : '35px',
    maxWidth: '1200px',
  },
  dialogContainer: {
    borderRadius: '10px',
    boxShadow: '0 5px 20px rgba(0, 0, 0, 0.07)',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogText: {
    color: 'black',
  },
});

function NotificationsSettings(props) {
  const {
    toggleValues,
    toggleTextValues,
    user,
    companyId,
    acountType,
    formValues,
    setFormValues,
  } = props;
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isSM);
  const { t } = useTranslation();
  const [isErrorSnackbarVisible, setIsErrorSnackbarVisible] = useState(false);
  const [isSuccessSnackbarVisible, setIsSuccessSnackbarVisible] =
    useState(false);
  const [disableSwitchs, setDisableSwitchs] = useState(false);
  const { accountDetails, isFreeTrial, isFreemium, currentPlan } = useAccount();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [allEmails, setAllEmails] = useState(
    Object.values(formValues).some((v) => !v) ||
      Object.keys(formValues).length === 0
  );
  const CEO = accountDetails.contacts.find((c) => c.isBoardOwner);

  function getBillingCycleValue() {
    if (currentPlan) {
      if (currentPlan.recurlyPlanCodeTerm === 'None') {
        return 'None';
      }
      if (
        currentPlan.salesforceValues &&
        currentPlan.salesforceValues.billingCycle
      ) {
        return currentPlan.salesforceValues.billingCycle;
      }
    }
    return '';
  }

  const billingCycle = getBillingCycleValue();

  function upgradePlan() {
    if (isFreemium) {
      eventBus.dispatch(eventBusValues.triggerCompanyUpgradeAction);
    } else if (currentPlan.upgradeUrl) {
      window.location.replace(
        `${currentPlan.upgradeUrl}?${getAdvisorQueryParams({
          accountDetails,
          isFreeTrial,
          isFreemium,
        })}`
      );
    }
  }

  function goToCancelMembership() {
    setIsModalVisible(true);
    window.location =
      'mailto:advisory.solutions@advisorycloud.com?subject=Cancel%20My%20Company%20Membership';
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');
  }

  function companyBillingEdit() {
    if (acountType == AccountType.COMPANY) {
      editBillingInfo(accountDetails.companyRecurlyHostedLoginToken);
    } else {
      editBillingInfo(accountDetails.advisorRecurlyHostedLoginToken);
    }
  }

  function viewPastInvoice() {
    if (acountType == AccountType.COMPANY) {
      viewPastInvoices(accountDetails.companyRecurlyHostedLoginToken);
    } else {
      viewPastInvoices(accountDetails.advisorRecurlyHostedLoginToken);
    }
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  function getAccountStatusText() {
    if (isFreemium) {
      return 'Free Account Active';
    }
    if (isFreeTrial) {
      return 'Free Trial Active';
    }
    return CEO.accountStatus || '';
  }

  function changedSwitch(checked, formKey) {
    setDisableSwitchs(true);
    if (formKey === 'all') {
      setAllEmails(checked);
      let newFormValues = { ...formValues };
      toggleValues.forEach((value) => {
        newFormValues[value] = !checked;
      });
      setFormValues(newFormValues);
    } else {
      setFormValues({
        ...formValues,
        [formKey]: !checked,
      });
      if (!allEmails) {
        setAllEmails(
          Object.values({ ...formValues, [formKey]: !checked }).some((v) => !v)
        );
      }
    }
    if (acountType == AccountType.COMPANY) {
      mutationUpdateContactEmailPreferences({
        ATTRIBUTE: formKey,
        COMPANY_ID: companyId,
        CONTACT_ID: user.id,
        VALUE: !checked,
      })
        .then(() => {
          setIsSuccessSnackbarVisible(true);
        })
        .catch(() => {
          setFormValues({
            ...formValues,
            [formKey]: checked,
          });
          setIsErrorSnackbarVisible(true);
        })
        .finally(() => {
          setDisableSwitchs(false);
        });
    } else {
      mutationUpdateAdvisorEmailPreferences({
        ATTRIBUTE: formKey,
        ADVISOR_ID: user.id,
        VALUE: !checked,
      })
        .then(() => {
          setIsSuccessSnackbarVisible(true);
        })
        .catch(() => {
          setFormValues({
            ...formValues,
            [formKey]: checked,
          });
          setIsErrorSnackbarVisible(true);
        })
        .finally(() => {
          setDisableSwitchs(false);
        });
    }
  }

  return (
    <>
      <ACSnackbar
        id="notifications-error-snackbar"
        onClose={() => setIsErrorSnackbarVisible(false)}
        open={isErrorSnackbarVisible}
        severity="error"
        text={t('COMPANY-MEMBERS-GENERIC-ERROR')}
      />

      <ACSnackbar
        id="notifications-success-snackbar"
        onClose={() => setIsSuccessSnackbarVisible(false)}
        open={isSuccessSnackbarVisible}
        text={t('COMPANY-NOTIFICATIONS-SUCCESS')}
      />

      <div style={classes.container}>
        <div
          style={{
            ...classes.buttonContainer,
            justifyContent:
              acountType == AccountType.COMPANY ? 'flex-end' : 'flex-start',
            width: '728px',
          }}
        ></div>

        <Paper elevation={0} sx={classes.card2}>
          <section>
            <div style={classes.topRow}>
              <div style={classes.iconContainer}>
                <CreditCardIcon icconname="key-outlined" />
              </div>
              <Typography variant="h1_old_font" sx={classes.text}>
                {t('COMPANY-ACCOUNT-YOUR-BILLING')}
              </Typography>
            </div>
            <div style={classes.bottomRow}>
              {!isSM ? <div style={classes.iconContainer} /> : null}
              <table style={classes.actionsTable}>
                <tbody>
                  <tr>
                    <td>{accountDetails.companyName}</td>
                    {accountDetails.companyRecurlyHostedLoginToken ? (
                      <td style={classes.actionCell}>
                        <Typography onClick={() => companyBillingEdit()}>
                          {t('COMPANY-ACCOUNT-EDIT-BILLING')}
                        </Typography>
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                  <tr>
                    <td>
                      {t('COMPANY-ACCOUNT-BILLEDCYCLE', {
                        billedCycle: billingCycle || '',
                      })}
                    </td>
                    {accountDetails.companyRecurlyHostedLoginToken ? (
                      <td style={classes.actionCell}>
                        <Typography onClick={() => viewPastInvoice()}>
                          {t('COMPANY-ACCOUNT-PAST-INVOICES')}
                        </Typography>
                      </td>
                    ) : (
                      <td />
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </Paper>

        <Paper elevation={0} sx={classes.card2}>
          <section>
            <div style={classes.topRow}>
              <div style={classes.iconContainer}>
                <DvrIcon icconname="key-outlined" />
              </div>
              <Typography variant="h1_old_font" sx={classes.text}>
                {t('COMPANY-MEMBERSHIP-YOUR-MEMBERSHIP')}
              </Typography>
            </div>
            <div style={classes.bottomRow}>
              {!isSM ? <div style={classes.iconContainer} /> : null}
              <table style={classes.actionsTable}>
                <tbody>
                  <tr>
                    <td>
                      {t('COMPANY-MEMBERSHIP-STARTDATE', {
                        startDate: formatDate(accountDetails.createdAt) || '',
                      })}
                    </td>
                    <td style={classes.actionCell}>
                      <Typography onClick={() => upgradePlan()}>
                        {t('COMPANY-MEMBERSHIP-UPGRADE-MEMBERSHIP')}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td style={classes.accountStatus}>
                      {t('COMPANY-MEMBERSHIP-STATUS', {
                        status: getAccountStatusText() || '',
                      })}
                    </td>
                    <td style={classes.actionCell}>
                      <Typography onClick={() => goToCancelMembership()}>
                        {t('COMPANY-MEMBERSHIP-CANCEL-MEMBERSHIP')}
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {t('COMPANY-MEMBERSHIP-BOARDS-JOINED', {
                        boards:
                          accountDetails.boardSeatsAllowed -
                          accountDetails.boardSeatsAvailable,
                        boardsCanJoinCount: accountDetails.boardSeatsAllowed,
                      })}
                    </td>
                    <td style={classes.actionCell} />
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
        </Paper>

        <Paper elevation={0} sx={classes.card2}>
          <div style={classes.containerNotification}>
            <div style={classes.iconContainerBell}>
              <NotificationsOutlinedIcon icconname="key-outlined" />
            </div>
            <div style={classes.contentContainer}>
              <Typography
                variant="body1_old_font"
                sx={classes.notificationsTitle}
              >
                {t('COMPANY-NOTIFICATIONS-PREFERENCES-TITLE')}
              </Typography>

              <div style={classes.notificationsTextContainer}>
                <pre style={classes.longText}>
                  <Typography variant="body1_old_font">
                    {t('COMPANY-NOTIFICATIONS-INFO-TEXT')}
                  </Typography>
                </pre>
              </div>
              <div style={classes.switchContainer}>
                <Typography
                  variant="body1"
                  sx={classes.sectionTitle}
                  style={{
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                >
                  Opt-in to email notifications from AdvisoryCloud
                </Typography>
                <Switch
                  checked={allEmails}
                  onChange={(event, checked) => {
                    if (allEmails) {
                      setOpenConfirmationDialog(true);
                    } else {
                      changedSwitch(checked, 'all');
                    }
                  }}
                  color="primary"
                  disabled={disableSwitchs}
                />
              </div>
              {toggleValues.map((value) => {
                return (
                  <div style={classes.switchContainer} key={value}>
                    <Typography
                      variant="body1_old_font"
                      sx={classes.sectionTitle}
                      style={{
                        fontWeight: 'bold',
                        color: 'black',
                      }}
                    >
                      {t(toggleTextValues[value])}
                    </Typography>
                    <Switch
                      checked={!formValues[value]}
                      onChange={(event, checked) =>
                        changedSwitch(checked, value)
                      }
                      color="primary"
                      disabled={disableSwitchs}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </Paper>
      </div>
      <Dialog
        open={isModalVisible}
        sx={classes.dialogContainer}
        maxWidth="md"
        onClose={() => {
          if (!isModalLoading) {
            setIsModalVisible(false);
          }
        }}
      >
        <div style={classes.dialogContainer}>
          <DialogContent>
            <DialogContentText sx={classes.dialogText}>
              {t('COMPANY-CANCEL-MEMBERSHIP-INTRUCTIONS')}
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={classes.dialogActions}>
            <ACButton
              variant="contained"
              color="primary"
              onClick={() => {
                setIsModalVisible(false);
              }}
              style={{
                paddingTop: isSM ? '30px' : '0px',
                paddingBottom: isSM ? '30px' : '0px',
              }}
            >
              OK
            </ACButton>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        maxWidth="xs"
        open={openConfirmationDialog}
        PaperProps={{
          sx: classes.dialogContainer,
        }}
        onClose={() => setOpenConfirmationDialog(false)}
      >
        <div>
          <DialogContent>
            <DialogContentText sx={classes.dialogText}>
              By disabling this option, you may not receive critical account
              notifications from AdvisoryCloud. We recommend keeping this
              setting on during the duration of your membership
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Button
              onClick={() => {
                changedSwitch(false, 'all');
                setOpenConfirmationDialog(false);
              }}
              color="primary"
              variant="outlined"
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                fontFamily: 'Poppins',
                height: 37,
                borderRadius: 20,
                textTransform: 'none',
              }}
            >
              Opt-out
            </Button>
            <Button
              onClick={() => setOpenConfirmationDialog(false)}
              color="primary"
              variant="contained"
              style={{
                paddingTop: 15,
                paddingBottom: 15,
                fontFamily: 'Poppins',
                height: 37,
                borderRadius: 20,
                textTransform: 'none',
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}

export default NotificationsSettings;
