import React, { useState } from 'react';
import { Box, Dialog, DialogContent, useMediaQuery } from '@mui/material';
import LoadingIndicatorLogo from '../../LoadingIndicatorLogo';
import { Clear } from '@mui/icons-material';
import { useAccount } from '../../../contexts/Account';

const classes = {
  contentWithBorder: {
    alignSelf: 'center',
    height: '80%',
    margin: '4px',
    border: '1px solid',
    borderColor: '#C9C9DB',
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    padding: '0 0 20px 0',
    marginBottom: '10px',
    boxShadow: '2px 2px 18px #888888',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    width: '470',
  },
  contentNoBorder: {
    width: '470',
  },
  clearSend: {
    marginRight: '5px',
  },
  loadingIndicatorPosition: {
    alignItems: 'center',
    bottom: '0px',
    display: 'flex',
    justifyContent: 'center',
    left: '0px',
    position: 'fixed',
    right: '0px',
    top: '0px',
  },
};

export default function TrustPilotEmbedModal({
  trustModal,
  setTrustModal,
  trustPilotLink,
}) {
  const { accountDetails, type, updateHideTrustPilot } = useAccount();
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(true);
  function handleClose() {
    setTrustModal(false);
    updateHideTrustPilot(accountDetails.id, type, true);
  }

  function handlePersonalizedClose() {
    setTrustModal(false);
    updateHideTrustPilot(accountDetails.id, type, true);
  }

  function onLoad() {
    setLoading(false);
  }

  return (
    <div style={classes.contentWithBorder}>
      <Dialog
        open={trustModal}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={classes.clearSend}>
          <Clear onClick={handlePersonalizedClose} style={{ float: 'right' }} />
        </div>
        <DialogContent style={classes.contentNoBorder}>
          <Box
            style={{
              height: isSM ? 235 : 750,
              width: '470px',
            }}
          >
            {loading ? (
              <div>
                <div style={classes.loadingIndicatorPosition}>
                  <LoadingIndicatorLogo
                    size={200}
                    iconFontSize={93}
                    iconRight={105}
                  />
                </div>
              </div>
            ) : null}
            <iframe
              style={loading ? { display: 'none' } : {}}
              src={trustPilotLink}
              onLoad={onLoad}
              title="demoVideo"
              allow="autoplay; fullscreen; picture-in-picture"
              width="100%"
              height="100%"
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
