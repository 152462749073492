import React, { useEffect, useState } from 'react';
import { SwipeableDrawer, useMediaQuery } from '@mui/material';
import OptionsList from './OptionsList';
import ProfileList from './ProfileList';
import eventBus, { eventBusValues } from './../../../eventBus';

const useStyles = (isMD) => ({
  dNone: {
    display: 'none',
  },
  container: {
    display: isMD ? 'flex' : 'none',
    zIndex: 1302,
    position: 'absolute',
    right: '0px',
    top: '0px',
    flexDirection: 'column',
    minHeight: '100%',
    backgroundColor: 'white',
  },
  drawerPaper: {
    width: '100%',
  },
  containerMask: {
    position: 'absolute',
    left: '0px',
    top: '0px',
    right: '0px',
    bottom: '0px',
    backgroundColor: '#fff',
    zIndex: 1301,
  },
  hiddenMask: {
    display: 'none',
  },
  shownMask: {
    display: 'flex',
    position: 'fixed',
  },
  shownContainer: {
    animation: `$open 0.5s ease-in-out`,
    position: 'initial',
    right: '0px',
  },
  hiddenContainer: {
    animation: `$close 0.5s ease-in-out`,
    right: '-228px',
  },
});

function CompanyRightMenu({ onDismiss, accountDetails }) {
  const [isViewingProfile, setIsViewingProfile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const isSM = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const classes = useStyles(isMD);

  useEffect(() => {
    eventBus.on(eventBusValues.mobileCompanyMenuTrigger, () => {
      setIsOpen((prevValue) => !prevValue);
    });

    return () => {
      eventBus.remove(eventBusValues.mobileCompanyMenuTrigger);
    };
  }, []);

  function getContainerClass() {
    if (!isSM) {
      return { ...classes.dNone, ...classes.hiddenContainer };
    }

    if (isOpen) {
      return { ...classes.container, ...classes.shownContainer };
    }

    return { ...classes.dNone, ...classes.hiddenContainer };
  }

  function getContainerMaskClass() {
    if (!isSM) {
      return classes.hiddenMask;
    }

    if (isOpen) {
      return { ...classes.shownMask, ...classes.containerMask };
    }

    return classes.hiddenMask;
  }

  function renderMenuContent() {
    if (isViewingProfile) {
      return (
        <ProfileList
          setIsOpen={setIsOpen}
          onBackClick={() => setIsViewingProfile(false)}
        />
      );
    }

    return (
      <OptionsList
        onDismiss={onDismiss}
        accountDetails={accountDetails}
        onProfileClick={() => setIsViewingProfile(true)}
        setIsOpen={setIsOpen}
      />
    );
  }

  return (
    <SwipeableDrawer
      disableSwipeToOpen
      anchor="right"
      open={isOpen}
      onOpen={() => {
        setIsOpen(true);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      PaperProps={{ style: classes.drawerPaper }}
      style={{
        backgroundColor: '#fff',
      }}
    >
      <div style={getContainerClass()}>{renderMenuContent()}</div>
      <div style={getContainerMaskClass()} onClick={onDismiss} />
    </SwipeableDrawer>
  );
}

export default CompanyRightMenu;
